<template>
    <el-dialog  :visible="modelValue" width="800px" top="5vh"  center @close="handleClose">
        <template slot="title">
            <div class="preview-image-title shadow s-p-b-3">
                <div class="user-block" style="position: relative;">

                    <el-avatar size="medium" :src="userinfo.avatar | cdnurl"></el-avatar>
                    <i class="status" :class="{ 'icon-al-zhuangtai': true, 'online-isactive': userinfo.userDetail.online == 1 }"></i>
                    <span class="username s-m-t-1 s-m-l-4">{{ userinfo.nickname }}</span>
                    <span class="step">
                        {{ index + 1 }}/{{ imageList.length }}
                    </span>
                </div>
            </div>
        </template>

        <el-row class="swiper-row">
            <div class="item left" v-if="zoom == false">
                <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                    <swiper-slide class="slide" style="width:70px;height:70px;" v-for="item in imageList" :key="item.id">
                        <img style="width:70px;height:70px;object-fit: cover;" :src="item.cover | cdnurl" alt="" />
                        <i class='icon-al-bofang'></i>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white" slot="button-next">
                        <div>
                            <i class="el-icon-arrow-down"></i>
                        </div>
                    </div>
                    <div class="swiper-button-prev swiper-button-white" slot="button-prev">
                        <div>
                            <i class="el-icon-arrow-up"></i>
                        </div>
                    </div>
                </swiper>
            </div>
            <div class="item middle" v-if="zoom == false">
                <swiper class="swiper gallery-top swiper-no-swiping" :options="swiperOptionTop" ref="swiperTop">
                    <swiper-slide class="slide-1 local-blur" v-for="(item, index)  in imageList" :key="item.id">
                        <template v-if="item.file == ''">
                            <img :src="item.cover | cdnurl"
                                style="height:70vh;width:100%;object-fit: cover;position: absolute;z-index: 1;" alt="" />
                            <i class='icon-al-bofang' @click="play(item)"></i>
                        </template>
                        <player-video v-else :src="item.file" :volume="volume" height="70vh" :ref="`video`"></player-video>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                </swiper>
            </div>
            <div class="footer">
                <el-button v-if="liked > 0" disabled> 
                    <i class="icon-al-icon_goodfill"></i> 
                    {{ $t('Liked') }}
                </el-button>
                <el-button v-else @click="like()">
                    <i class="icon-al-zans"></i> 
                    {{ $t('Like') }}
                </el-button>
                <el-button type="primary" @click="jump(`/message?id=${userinfo.id}`)">
                    {{ $t('GoChat') }} 
                    <i class="icon-al-jiaoyou"></i> 
                </el-button>
            </div>
        </el-row>
    </el-dialog>
</template>

<script>
import { cdnurl } from '../../../filters'
import i18n from "../../../lang";
import PlayerVideo from "./player-video.vue";
export default {
    components: {
        PlayerVideo
    },
    computed: {
        video() {
            return this.$refs.video;
        },
    },
    mounted() {
        // 实现swiper双向控制
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.swiper
            const swiperThumbs = this.$refs.swiperThumbs.swiper
            swiperTop.controller.control = swiperThumbs
            swiperThumbs.controller.control = swiperTop
        })
        this.index = this.defaultIndex;
        this.liked = this.list[this.index].liked;
        this.imageList = this.list;

    },
    watch: {
        zoom(val) {
            if (!val) {
                this.$nextTick(() => {
                    const swiperTop = this.$refs.swiperTop.swiper
                    const swiperThumbs = this.$refs.swiperThumbs.swiper
                    swiperTop.controller.control = swiperThumbs
                    swiperThumbs.controller.control = swiperTop
                    swiperTop.slideTo(this.index, 0, false)
                    swiperThumbs.slideTo(this.index, 0, false)
                })
            }

        },
        index(index, oldIndex) {
            console.log(index)
            console.log(oldIndex)
            this.liked = this.imageList[index].liked
            if (this.video.length > 0) {
                for (let i = 0; i < this.video.length; i++) {
                    this.video[i].stop()
                }
            }

        }
    },
    model: {
        prop: 'modelValue',
        event: 'update:modelValue'
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        userinfo: {
            type: Object
        },
        list: {
            type: Array,
            required: true
        },
        defaultIndex: {
            type: Number,
        },
        event: {
            type: String,
        }
    },
    data() {
        return {
            //轮播大图配置

            swiperOptionTop: {
                width: 650,
                initialSlide: this.defaultIndex, // 初始化索引
                zoom: false,
                loop: false,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                // autoplay: {  //自动轮播
                //   delay: 2000,
                //   disableOnInteraction: false
                // },

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            swiperOptionThumbs: {
                initialSlide: this.defaultIndex, // 初始化索引
                direction: "vertical",
                loop: false,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,

                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true,
                autoScrollOffset: 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    slideChange: () => {
                        this.index = this.$refs.swiperThumbs.swiper.realIndex
                    },
                }

            },
            index: 0,
            zoom: false,
            liked: 0,
            imageList: [],
            videoUrl: "",
            volume: 0.5,
        }
    },

    methods: {
        handleClose() {
            this.$emit('update:modelValue', false)
        },
        jump(url) {
            let that = this;
            that.$router.push({ path: url })
        },
        carouselChange(index) {
            this.index = index;
        },
        like() {
            let that = this;
            let file = that.imageList[that.index];
            that.$http.post('/file/like', { id: file.id })
                .then((res) => {
                    if (res.code == 1) {
                        file.liked = 1
                        that.liked = 1
                    } else {
                        that.$message.error(res.msg);
                    }

                })

        },
        play(item) {
            let that = this
            that.$http.post('/file/play', { id: item.id })
                .then((res) => {
                    if (res.code == 1) {
                        item.file = res.data
                    }  else {
                        that.$message.error(res.msg);
                    }

                })
        }
    },

}
</script>

<style  scoped>
h3 {
    margin: 20px 0 0 10px;
}

.swiper-row {
    min-height: 80vh;
    position: relative;
    padding-top: 20px;
}

.swiper-row .zoom {
    position: absolute;
    right: -20px;
    top: 20px;
    z-index: 5;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.gallery-top {
    height: 70vh;
    width: 100%;
}

.gallery-thumbs {
    height: 70vh;
    box-sizing: border-box;
    padding: 10px 0px;
    width: 100%;
}

.gallery-thumbs .swiper-button-next {
    left: 0px;
}

.gallery-thumbs .swiper-button-prev {
    left: 0px;
}


.gallery-thumbs .swiper-button-prev {
    background: linear-gradient(rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
    width: 70px;
    text-align: center;
    height: 70px;
    top: 20px;
}

.gallery-thumbs .swiper-button-next {
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    width: 70px;
    text-align: center;
    height: 70px;
    top: 65vh;
}

.gallery-thumbs .swiper-button-prev div {
    margin-top: 10px;

}

.gallery-thumbs .swiper-button-next div {
    margin-top: 30px;
}

.gallery-thumbs .swiper-button-next div img,
.gallery-thumbs .swiper-button-prev div img {
    margin: 7px 0 0 2px;
    width: 30px;
}


.gallery-thumbs .swiper-slide {
    width: 20%;
    height: 80px;
}

.gallery-thumbs .swiper-slide-active {
    border: 2px solid red;
}

.swiper-button-white .el-icon-arrow-up,
.swiper-button-white .el-icon-arrow-down {
    line-height: 45px;
    font-size: 26px;
    color: #666666;
    font-weight: 600;
}

.swiper-row .item {
    float: left;
}

.swiper-row .left {
    width: 100px;
}

.swiper-row .middle {
    width: 650px;
}

.swiper-row .right {
    width: 50px;
}

.swiper-row .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}


/* 伪元素 after 有两个冒号， ::after */
.local-blur::after {
    /* 为元素必须写一个 content，里面放个空格也行 */
    content: " ";
    display: block;

    /* 这里借助绝对定位，拉扯这个元素，实现变相设置元素大小 */
    position: absolute;
    left: 0;
    top: -50px;
    right: 0;
    bottom: -50px;
    background: inherit;
    /* 设置模糊，不用 filter */
    /* backdrop-filter: blur(5px); */
    filter: blur(20px);
    /* background-color: rgba(128, 255, 0, 0.404); */

    /* 需要设置 ::after 的叠放层级，否则它将在 local-blur 的下方 */


}


.slide .icon-al-bofang {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: rgb(19 50 146 / 19%);
    font-size: 36px;
    border-radius: 50%;
    padding: 10px;
    color: #f7f7f7;
    cursor: pointer;
}

.slide-1 .icon-al-bofang {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: rgb(19 50 146 / 19%);
    font-size: 100px;
    border-radius: 50%;
    padding: 10px;
    color: #f7f7f7;
    cursor: pointer;
    z-index: 2;
}
</style>

<style lang="scss" scoped>
.preview-image-title {
    margin-left: -20px;
    margin-right: -20px;

    .user-block {
        display: flex;
        text-align: left;
        padding-left: 25px;

        .status {
            position: absolute;
            bottom: 0px;
            left: 55px;
        }

        .username {
            display: inline-block;
        }

        .step {
            position: absolute;
            left: 50%;
            top: 10px;
            font-size: 16px;
            font-weight: 600;
        }
    }
}
</style>