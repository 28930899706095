<template>
    <div class="">
      <div class="main-wrapper">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">
            <mailbox-list></mailbox-list>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6">
            <right-sider-bar></right-sider-bar>
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  <script>
  import RightSiderBar from './components/common/RightSiderBar.vue'
  import mailboxList from './components/mailbox/list.vue'
  export default {
    name: 'Mailbox',
    data: function () {
      return {
        
      }
    },
    components: { 
      RightSiderBar,
      mailboxList 
    },
    created() {
     
    },
  }
  </script>
    
  <style></style>
    