import Vue from 'vue'
import axios from 'axios'
import {MessageBox} from 'element-ui'
import {Dialog} from 'vant'
import setting from '../config/setting.js'
import router from '@/router'
import Auth from './auth.js'
import {validateIsMobile} from '@/utils/utils.js'
import loading from '../page/components/loading/index.js'

Vue.use(loading)
// axios 配置
axios.defaults.timeout = 1000000;

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '/api';
} else {
    axios.defaults.baseURL = setting.apiUrl
}

// http request 拦截器
axios.interceptors.request.use(
    config => {
        if (!(config.url == "/websocket/reSend" || config.url == "/websocket/send")) {
            Vue.$showLoading.show()
        }

        if (Auth.authenticated()) {
            let userinfo = Auth.getToken();
            config.headers.token = userinfo ? userinfo.token : '';
        }
        let lang = localStorage.getItem('language')
        config.headers.lang = `${lang}`;

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
axios.interceptors.response.use(
    response => {
        Vue.$showLoading.hide()

        if (response.data.code == 401) {
            Auth.logout()
            if (router.currentRoute.path === '/login') {
                router.replace({
                    path: router.currentRoute.fullPath
                })
                return Promise.reject(response.data)
            } else {
                router.replace({
                    path: 'login',
                    query: {redirect: router.currentRoute.fullPath}
                })
                return Promise.reject(response.data)
            }
        }
        if (response.data.code == 2) {
            // 移动端提示
            if (validateIsMobile()) {
                Dialog.confirm({
                    title: '',
                    message: '<i class="van-icon van-icon-warning" style="font-size:20px;color:var(--theme-primary);transform:translate(-7px, 3px);"></i>Only after completing the information can the operation be carried out!',
                })
                    .then(() => {
                        router.push({
                            path: '/benefits',
                            query: {type: 'money'}
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
            MessageBox.confirm('Only after completing the information can the operation be carried out!', '', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
            }).then(() => {
                router.push({
                    path: '/user',
                    query: {profile: 1}
                })
            }).catch(() => {

            });
        } else if (response.data.code == 3) {
            // 移动端提示
            if (validateIsMobile()) {
                Dialog.confirm({
                    title: '',
                    message: '<i class="van-icon van-icon-warning" style="font-size:20px;color:var(--theme-primary);transform:translate(-7px, 3px);"></i>Lack of credit, whether to jump to the recharge page?',
                })
                    .then(() => {
                        router.push({
                            path: '/benefits',
                            query: {type: 'money'}
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
            MessageBox.confirm('Lack of credit, whether to jump to the recharge page?', '', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
            }).then(() => {
                router.push({
                    path: '/benefits',
                    query: {type: 'money'}
                })
            }).catch(() => {

            });
        } else if (response.data.code == 4) {
            // 移动端提示
            if (validateIsMobile()) {
                Dialog.confirm({
                    title: '',
                    message: '<i class="van-icon van-icon-warning" style="font-size:20px;color:var(--theme-primary);transform:translate(-7px, 3px);"></i>Expire of vip, whether to jump to the recharge page?',
                })
                    .then(() => {
                        router.push({
                            path: '/benefits',
                            query: {type: 'vip'}
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
            MessageBox.confirm('Expire of vip, whether to jump to the recharge page?', '', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                type: 'warning'
            }).then(() => {
                router.push({
                    path: '/benefits',
                    query: {type: 'vip'}
                })
            }).catch(() => {

            });
        }
        return response.data;
    },
    error => {
        Vue.$showLoading.hide()
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 401 清除token信息并跳转到登录页面
                    Auth.logout()
                    router.replace({
                        path: 'login',
                        query: {redirect: router.currentRoute.fullPath}
                    })
            }
        }
        return Promise.reject(error)
    });
export default axios;