<template>
    <div class="voice-chart-room">
        <div class="main-wrapper">
            <CompVoiceChat />
        </div>
    </div>
</template>

<script>
import CompVoiceChat from '@/components/common/CompVoiceChat.vue'

export default {
    name: 'VoiceChat',
    components: {
        CompVoiceChat,
    },
    data() {
        return {
            
        }
    },
    created() {
        
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>

</style>