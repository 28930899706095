<template>
    <div class="">
      <div class="main-wrapper">
        <el-row :gutter="15">
          <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">
  
            <el-col :xs="24" :sm="24" :md="24" class="hidden-xs-only">
              <el-card class="box-card">
                <userinfo></userinfo>
              </el-card>
            </el-col>
            <el-col style="margin-top: 15px;" :xs="24" :sm="6" :md="6" class="hidden-xs-only">
              <el-card class="siderbar-card">
                <siderbar></siderbar>
              </el-card>
            </el-col>
            <el-col class="right-content m-no-padding m-no-margin" style="margin-top: 15px;" :xs="24" :sm="18" :md="18">
  
              <el-card class="box-card">
                <h1 class="header-title" style="margin-bottom: 20px;"><i class="icon-al-tupian"
                    style="font-size: 24px;"></i>
                  {{ $t('MyPhotos') }}
                  
                </h1>
                <CheckPhoto event="view"></CheckPhoto>
                
              </el-card>
            </el-col>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6">
            <right-sider-bar></right-sider-bar>
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
           
  <script>
  import RightSiderBar from '../components/common/RightSiderBar.vue'
  import userinfo from '../components/user/userinfo.vue'
  import siderbar from '../components/user/siderbar.vue'
  import auth from '../../services/auth'
  import CheckPhoto from "../components/chat/check-photo.vue";
  export default {
    name: 'MyPhotos',
    components: { userinfo, siderbar, RightSiderBar,CheckPhoto },
    data: function () {
      return {
       
      }
    },
    methods: {
     
     
      
    },
    created() {
    },
  
  }
  </script>
           
  <style>
  .el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
    text-align: left !important;
  }
  
  .user-tabs {
    height: calc(100vh - 280px);
    ;
  }
  
  .user-tabs .el-tabs__item {
    height: 50px;
    line-height: 50px;
  }
  
  .siderbar-card .el-card__body {
    padding: 0;
  }
  
  .right-content {
    height: calc(100vh - 307px);
    overflow: auto;
  }
  
  .footer-delete {
    margin-left: 20px;
    cursor: pointer;
  }
  </style>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  .right-content {
    overflow: auto;
    height: auto;
  }
}
</style>