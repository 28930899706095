export default {
  'The room does not exist, please confirm the room number or create a room!':
    '房间不存在，请确认房间号或创建房间！',
  'Log out': '退出登录',
  'Edit profile': '编辑资料',
  'User Name': '用户名',
  'Please input user name': '请输入名称',
  'Username length should be greater than 0': '昵称长度应该大于0',
  Save: '保存',
  Camera: '摄像头',
  Mic: '麦克风',
  'Off Camera': '摄像头已关闭',
  'Mic settings': '麦克风设置',
  Output: '输出',
  Speaker: '扬声器',
  Test: '测试',
  Stop: '停止测试',
  Preview: '视频画面',
  Mirror: '视频镜像',
  Resolution: '分辨率',
  'More Camera Settings': '更多摄像头设置',
  'Join the room ?': '是否进入房间?',
  'You are invited to room ': '您被邀请进入 ',
  Room: '房间',
  Join: '加入',
  'Switch Theme': '换肤',
  Layout: '布局',
  'New Room': '新建房间',
  'Room Type': '房间类型',
  'Your Name': '您的名字',
  'Turn on the microphone': '开启麦克风',
  'Turn on the speaker': '开启扬声器',
  'Turn on the video': '开启视频',
  'Free Speech Room': '自由发言房间',
  'On-stage Speaking Room': '上台发言房间',
  'Join Room': '进入房间',
  'Enter room ID': '输入房间号',
  'video conferencing': ' 的视频会议',
  'Quick Conference': '的快速会议',
  'Room ID': '房间号',
  'Low Definition': '流畅',
  'Standard Definition': '标清',
  'High Definition': '高清',
  'Super Definition': '超清',
  'End sharing': '结束共享',
  'Stop sharing?': '是否停止屏幕共享？',
  'Stop sharing': '停止共享',
  'You are sharing the screen...': '你正在共享屏幕...',
  Sure: '确定',
  Sharing: '屏幕共享中',
  'Share screen': '共享屏幕',
  'Grant permission to screen recording': '打开屏幕录制权限',
  'Open the system preferences settings': '打开系统便好设置',
  'Due to macOS 10.15 system requirements, please check the current application in "System Preferences - Security & Privacy - Screen Recording".':
    '由于macos 10.15 系统要求，请在"系统偏好设置-安全性与隐私-屏幕录制"中勾选当前应用程序。',
  'Others will no longer see your screen after you stop sharing. Are you sure you want to stop?':
    '是否结束当前的共享屏幕，停止后所有人将无法继续观看屏幕内容',
  'Others will no longer see your screen after you stop sharing.':
    '停止后所有人将无法继续观看屏幕内容',
  'You currently do not have sharing permission, please raise your hand to apply for sharing permission first':
    '您当前没有共享权限，请先举手申请上台获取共享权限',
  Host: '房主',
  Me: '我',
  me: '我',
  Admin: '管理员',
  RoomOwner: '主持人',
  More: '更多',
  'AI Assistant': 'AI 助手',
  'AI real-time conference content': 'AI 实时会议内容',
  'Turn on AI real-time subtitles': '开启AI实时字幕',
  'Turn off AI real-time subtitles': '关闭AI实时字幕',
  'Enable AI real-time meeting recording': '开启AI实时会议记录',
  'Raise hand': '举手',
  Agree: '同意',
  Chat: '聊天',
  Cancel: '取消',
  Exit: '退出全屏',
  'Full screen': '全屏',
  Members: '成员',
  'Search Member': '搜索成员',
  'Make host': '转交房主',
  'Set as administrator': '设为管理员',
  'Remove administrator': '撤销管理员',
  'The administrator status of sb has been withdrawn': ({ named }: any) =>
    `已将 ${named('name')} 的管理员身份撤回`,
  'sb has been set as administrator': ({ named }: any) =>
    `已将 ${named('name')} 设为管理员`,
  'You have been invited by the administrator to step down, please raise your hand if you need to speak':
    '您已被管理员邀请下台，需要发言请先举手',
  Invite: '邀请',
  Settings: '设置',
  VirtualBackground: '虚拟背景',
  Close: '关闭',
  BlurredBackground: '背景模糊',
  EndPC: '结束房间',
  EndH5: '结束',
  Tips: '提示',
  'You are currently the host of the room, please choose the corresponding operation. If you choose "End Room", the current room will be disbanded and all members will be removed. If you choose "Leave Room", the current room will not be disbanded, and your hosting privileges will be transferred to other members.':
    '您当前是房间主持人，请选择相应操作。若选择“结束房间”，将解散当前房间并将全体成员移出。若选择“离开房间”，当前房间不会解散，您的主持人权限将移交给其他成员。',
  'You are currently the host of the room, please choose the corresponding operation. If you choose "End Room", the current room will be disbanded and all members will be removed.':
    '您当前是房间主持人，请选择相应操作。若选择“结束房间”，将解散当前房间并将全体成员移出。',
  'If you do not want to end the meeting, please designate a new host before leaving the meeting.':
    '如果您不想结束会议，请在离开会议前指定新的主持人。',
  'Are you sure you want to leave this room?': '确定离开房间吗？',
  'New host': '选择主持人',
  'Transfer and leave': '移交并离开',
  'Leave room?': '是否要离开房间',
  'Select a new host': '请选择新的房间主持人',
  'Appoint a new host': '指定一位新的主持人',
  'The host closed the room.': '主持人结束会议，已解散房间',
  Note: '通知',
  Confirm: '确认',
  Leave: '离开房间',
  Dismiss: '结束房间',
  'The administrator has been changed to ': '管理员已变更为',
  'Muted by the moderator': '已被主持人禁言',
  'Type a message': '说点什么...',
  Send: '发送',
  'Failed to send the message': '发送消息失败',
  'Applying for the stage': '正在申请上台',
  'Apply for the stage': '申请上台',
  'Cancel Apply': '取消申请',
  'Are you sure you want to step down': '确定要下台吗',
  Check: '查看',
  Neglect: '忽略',
  'Disable all audios': '全体静音',
  'Enable all audios': '解除全体静音',
  'Disable all videos': '全体静画',
  'Enable all videos': '解除全体静画',
  'All mute': '全体静音',
  'All current and new members will be muted': '所有及新加入的成员将被静音',
  'All and new members will be banned from the camera':
    '所有及新加入的成员将被禁止视频',
  'All stop video': '全体禁画',
  'Lift all mute': '取消全体静音',
  'Lift stop all video': '取消全体禁画',
  'No relevant user found.': '未找到相关用户',
  'Member List': '成员列表',
  Unmute: '解除静音',
  'Cancel Unmute': '取消解除禁言',
  Mute: '静音',
  members: '人',
  'Failed to get chat message': '获取聊天消息失败',
  'Agree to the stage': '同意上台',
  'Take the stage now': '立即上台',
  'Stay off the stage': '暂不上台',
  'Cancel stage': '取消邀请上台',
  'Invite stage': '邀请上台',
  'Refuse stage': '拒绝上台',
  'Members applying on stage': '成员上台申请',
  'Enable video': '请求开启视频',
  'Cancel Enable video': '取消解除禁画',
  'Disable video': '关闭视频',
  'Enable chat': '解除禁言',
  'Disable chat': '禁言',
  'Kick out': '踢出房间',
  'is sharing their screen': '的屏幕分享',
  'Apply to stage': '上台申请',
  'Apply to stage application': '请求上台申请',
  'Member Onstage Application': '成员上台申请',
  Reject: '拒绝',
  'Reject All': '全部拒绝',
  'Agree All': '全部同意',
  'Please raise your hand to apply': '如果您想发言请先举手申请上麦',
  'You can turn on the microphone and camera once you are on stage':
    '上台后可以打开麦克风和摄像头',
  'Sb invites you to speak on stage': ({ named }: any) =>
    `${named('role')}邀请您上台发言`,
  'After agreeing to go on stage, you can turn on the camera and microphone. Do you agree to go on stage?':
    '同意上台后可打开摄像头和麦克风，是否同意上台？',
  'Hand down': '手放下',
  'Step down(Master)': '邀请下台',
  'Step down': '下台',
  'sb has rejected your application for the stage': ({ named }: any) =>
    `${named('role')}拒绝了你的上台申请`,
  'You have been invited by the host to step down, please raise your hand if you need to speak':
    '您已被主持人邀请下台，需要发言请先举手',
  'Select a screen/window': '选择屏幕/窗口',
  Screen: '屏幕',
  Window: '窗口',
  Share: '开始分享',
  'Safety Reminder': '安全提醒',
  'Continue sharing': '继续共享',
  'Select a screen or window first': '请选择要分享的屏幕或者窗口',
  Grid: '一屏九等分',
  'Gallery on right': '右侧成员列表',
  'Gallery at top': '顶部成员列表',
  'Network ': '网络',
  Latency: '延迟',
  Stability: '稳定',
  Fluctuation: '波动',
  Lag: '卡顿',
  Disconnected: '已断开',
  'Packet loss': '丢包率',
  'Share the room ID or invite link':
    '您可以通过复制房间号或者邀请链接的方式邀请更多人加入房间',
  'Invite by room number': '通过房间号邀请',
  'Invite via room link': '通过房间链接邀请',
  'Room Link': '房间链接',
  'You can share the room number or link to invite more people to join the room.':
    '您可以分享房间号或链接邀请更多人加入房间。',
  'You can share the room number to invite more people to join the room':
    '您可以分享房间号邀请更多人加入房间',
  'If you have any questions, please feel free to join our QQ group or send an email':
    '如果有任何问题欢迎加入我们的QQ群或者发送邮件。',
  'If you have any questions, please email us.':
    '如果有任何问题欢迎加入我们的交流群或者发送邮件。',
  'Join our product discussion group': '加入产品交流群',
  'Our email address': '我们的邮件地址',
  'Join now': '立即加入',
  'Room QR code': '房间二维码',
  Participants: '参会成员',
  'Search for conference attendees': '搜索参会成员',
  'Invite via client scheme': '通过客户端 scheme 邀请',
  'Contact us': '联系我们',
  'Member management': '成员管理',
  'Grid view': '宫格视图',
  'Join our QQ group chat or email us.':
    '如果有任何问题欢迎加入我们的QQ群或者发送邮件',
  'group chat': 'QQ 群',
  Email: '邮箱地址',
  'Audio settings': '声音设置',
  'Camera settings': '摄像头设置',
  Copy: '复制',
  'Copied successfully': '复制成功',
  'Copied failure': '复制失败',
  'This model does not support copying at this time': '此机型暂不支持复制',
  'accepted the invitation to the stage': '接受了上台邀请',
  'declined the invitation to the stage': '拒绝了上台邀请',
  'All audios disabled': '已开启全体静音',
  'All audios enabled': '已解除全体静音',
  'Your microphone has been turned off': '已关闭您的麦克风',
  'Sb invites you to turn on the microphone': ({ named }: any) =>
    `${named('role')}邀请你打开麦克风`,
  'All videos disabled': '已开启全体禁画',
  'All videos enabled': '已解除全体禁画',
  'Your camera has been turned off': '已关闭您的摄像头',
  'Sb invites you to turn on the camera': ({ named }: any) =>
    `${named('role')}邀请你打开摄像头`,
  'The host has turned off your screen sharing': '主持人已关闭您的屏幕分享',
  'Turn on the camera': '打开摄像头',
  'Keep it closed': '保持关闭',
  'You have been banned from text chat': '您被禁止文字聊天',
  'You are allowed to text chat': '您被允许文字聊天',
  'kicked out of the room by the host': '被主持人踢出房间',
  'kicked out of the room by other device': '相同账号在其他客户端进入房间',
  'kicked out of the room by serve': '被服务端踢出房间',
  'Reject sb on stage failed, please retry': ({ named }: any) =>
    `拒绝 ${named('name')} 上台失败, 请重试`,
  'Agree sb on stage failed, please retry': ({ named }: any) =>
    `同意 ${named('name')} 上台失败, 请重试`,
  'Has been fully muted and cannot open the microphone':
    '已被全员静音，无法打开麦克风',
  'Has been muted by the host and cannot open the microphone':
    '已被主持人静音，无法打开麦克风',
  'To apply to speak in the room, please raise your hand first to apply for the microphone':
    '申请发言房间，请先举手申请上麦',
  'Has been full static painting, can not open the video':
    '已被全员静画，无法打开视频',
  'It has been stilled by the host and cannot open the video':
    '已被主持人静画，无法打开视频',
  'Failed to enter the room.': '进入房间失败！',
  'Has been full static painting, can not share your screen':
    '已被全员静画，无法打开屏幕分享',
  'Microphone not detected on current device': '当前设备未检测到麦克风',
  'Camera not detected on current device': '当前设备未检测到摄像头',
  'Camera And Microphone not detected on current device':
    '当前设备未检测到摄像头和麦克风',
  'Another user is sharing the screen.': '其他成员正在屏幕共享',
  'Screen sharing is currently active, cannot start the whiteboard.':
    '当前正在屏幕共享，无法开启白板',
  'This action causes the room to be exited, does it continue?':
    '该操作会导致退出房间，是否继续？',
  'This action causes the room to be disbanded, does it continue?':
    '该操作会导致解散房间，是否继续？',
  'Sharing screens may lead to the leakage of private information such as SMS verification codes and passwords, resulting in financial losses. Please be vigilant against various forms of fraud.':
    '共享屏幕时可能会泄露短信验证码、密码等隐私信息，造成财产损失，请警惕各种诈骗行为',
  'Please enter the room number': '请输入房间号',
  'The current browser does not support capturing audio and video':
    '当前浏览器不支持采集音频和视频',
  'The current browser does not support getting microphone and camera list':
    '当前浏览器不支持获取麦克风和摄像头列表',
  'The current browser does not support screen sharing':
    '当前浏览器不支持屏幕分享',
  'The current browser does not support capturing audio':
    '当前浏览器不支持采集音频',
  'The current browser does not support capturing video':
    '当前浏览器不支持采集视频',
  'The current browser does not support audio and video communication capabilities':
    '当前浏览器不支持音视频通信能力',
  'whether to kick sb off the room': ({ named }: any) =>
    `是否将 ${named('name')} 移出房间`,
  'An invitation to open the microphone has been sent to sb.': ({
    named,
  }: any) => `已向 ${named('name')} 发出开启麦克风邀请`,
  'An invitation to open the camera has been sent to sb.': ({ named }: any) =>
    `已向 ${named('name')} 发出开启摄像头邀请`,
  'Make host failed, please try again.': '转交房主失败，请重试',
  'Sb has been set as an administrator': ({ named }: any) =>
    `已将 ${named('name')} 设为管理员`,
  'Succeed on stage': '上台成功',
  'After unlocking, users can freely turn on the microphone':
    '解除后用户可以自由开启麦克风',
  'Members will not be able to open the microphone': '成员将无法开启麦克风',
  'After unlocking, users can freely turn on the camera':
    '解除后用户可以自由开启视频',
  'Members will not be able to open the camera': '成员将无法开启视频画面',
  'You have become a administrator': '您已成为管理员',
  'You are now a room owner': '您已成为房主',
  'Your administrator status has been revoked': '您的管理员身份被收回',
  'After transfer the room owner, you will become a general user':
    '转交房主后将成为普通成员',
  'Transfer the roomOwner to sb': ({ named }: any) =>
    `将房主转移给${named('name')}`,
  'Confirm transfer': '确认转交',
  'The room owner has been transferred to sb': ({ named }: any) =>
    `已将房主转交给${named('name')}`,
  'Application to go on stage was rejected': '上台申请被拒绝',
  'You are now an administrator': '您已成为管理员',
  'The RoomOwner has withdrawn your administrator privileges':
    '房主已收回您的管理员权限',
  'This member has already received the same request, please try again later':
    '该成员已收到相同请求，请稍后再试',
  'The request to go on stage has timed out': '上台请求已超时',
  'The invitation to sb to go on stage has timed out': ({ named }: any) =>
    `对${named('name')}的上台邀请已超时`,
  'Currently no member has applied to go on stage': '暂无成员申请上台',
  Operate: '操作',
  'Stage management': '上台管理',
  'On stage': '已上台',
  'Not on stage': '未上台',
  'To go on stage again, you need to reapply and wait for the roomOwner/administrator to approve':
    '再次上台需重新发起申请，并等待房主/管理员通过',
  'To go on stage again, a new application needs to be initiated':
    '再次上台需重新发起申请',
  'The request to go on stage has been sent out, please wait for the roomOwner/administrator to approve it':
    '上台申请已发出，请等待房主/管理员通过',
  'Cancellation request has been sent, please wait for the roomOwner/administrator to approve it':
    '取消上台申请已发出，请等待房主/管理员通过',
  'Canceled application to go on stage': '已取消上台申请',
  'and so on': '等',
  'people applying to stage': '人正在申请上台',
  'and so on number people applying to stage': ({ named }: any) =>
    `等 ${named('number')} 人正在申请上台`,
  'The stage is full, please contact the host': '台上人数已满，请联系主持人',
  'The stage is full': '台上人数已满',
  'change name': '修改名称',
  // Room Chat Fusion Card Translation
  'quick conference': '快速会议',
  Meeting: '会议',
  'Meeting in progress': '会议 进行中',
  Initiating: '正在发起',
  'X people have joined': ({ named }: any) => `${named('number')} 人已入会`,
  'Waiting for members to join the meeting': '等待成员入会',
  'X people are in the meeting': ({ named }: any) =>
    `${named('number')} 人在会议中`,
  'Already joined': '已入会',
  'Enter the meeting': '进入会议',
  'Ending meeting': '正在结束会议',
  'The meeting has ended': '会议已结束',
  'Currently in a meeting, please exit the current meeting before proceeding.':
    '正在会议中，请先退出当前会议后再进行操作',
  'Failed to initiate meeting': '发起会议失败',
  'Failed to enter the meeting': '进入会议失败',
  'Failed to disable chat': '禁言失败',
  'All members can share screen': '全体成员可共享屏幕',
  'Screen sharing for host/admin only': '仅房主/管理员可共享屏幕',
  'Failed to initiate screen sharing, currently only host/admin can share screen.':
    '发起共享失败，当前仅房主/管理员可以共享',
  'Is it turned on that only the host/admin can share the screen?':
    '是否开启仅房主/管理员可共享屏幕？',
  "Other member is sharing the screen is now, the member's sharing will be terminated after you turning on":
    '当前有成员正在共享屏幕, 开启后该成员的共享将会被终止',
  'Your screen sharing has been stopped': '共享屏幕停止',
  'Your screen sharing has been stopped, Now only the host/admin can share the screen':
    '已停止您的共享，当前仅房主/管理员可以共享屏幕',
  'The system prohibits the current browser from accessing the screen content, please enable the screen sharing privilege.':
    '系统禁止当前浏览器获取屏幕内容, 请开启屏幕分享权限。',
  'User canceled screen sharing': '用户取消屏幕分享',
  'An unknown error occurred while screen sharing, please try again.':
    '屏幕分享发生了未知错误，请重试',
  'I got it': '我知道了',
  Attention: '注意',
  'Audio playback failed. Click the "Confirm" to resume playback':
    '音频播放失败。单击 “确认 ”恢复播放。',
  // Schedule Room
  'x people selected': ({ named }: any) => `已选择${named('number')}人`,
  Modify: '修改',
  'view details': '查看详情',
  'modify room': '修改房间',
  'cancel room': '取消房间',
  'No room available for booking': '暂无预订房间',
  Ongoing: '进行中',
  finished: '已结束',
  'History room': '历史房间',
  'sb temporary room': ({ named }: any) => `${named('name')}的临时房间`,
  year: '年',
  month: '月',
  day: '日',
  Schedule: '预订房间',
  'Modify Room': '修改房间',
  'Room Name': '房间名称',
  'please enter the room name': '请输入房间名称',
  'Room type': '房间类型',
  'Starting time': '开始时间',
  'Room duration': '房间时长',
  'Time zone': '时区',
  Attendees: '参与成员',
  'Please enter the member name': '请输入成员名称',
  people: '人',
  Contacts: '联系人',
  'No relevant members found': '暂无相关成员',
  'Selected Contact': '已选联系人',
  'Scheduled meetings': '预订的会议',
  minute: '分钟',
  hour: '小时',
  minutes: '分钟',
  hours: '小时',
  'The start time cannot be earlier than the current time':
    '开始时间不能早于当前时间',
  'Room Time': '房间时间',
  Creator: '发起人',
  'Room Details': '房间详情',
  'Enter Now': '立即进入',
  'Invited members': '邀请成员',
  'The room is closed': '房间已结束',
  'Entry Time': '进房时间',
  'Duration of participation': '参会时长',
  'No cancellation': '暂不取消',
  'Cancellation of scheduled rooms': '取消已预订的房间',
  'Other members will not be able to join after cancellation':
    '取消后其他成员将无法加入',
  'Inviting members to join': '邀请成员加入',
  'Invitation by room ID': '通过房间号邀请',
  'Invitation via room link': '通过房间链接邀请',
  'Copy the conference number and link': '复制会议号与链接',
  'Schedule successful, invite members to join': '预订成功，邀请成员加入',
  'Name changed successfully': '名称修改成功',
  'schedule year': '年',
  'schedule month': '月',
  'schedule day': '日',
  'The room name cannot exceed 100 characters': '房间名称不能超过100个字节',
  'The room name cannot be empty': '房间名不能为空',
  'The meeting is in progress and any meeting information cannot be modified':
    '进行中的会议，不能修改任何会议信息',
  'Schedule room loading': '预定房间加载中...',
  'The user name cannot exceed 32 characters': '用户名称不能超过32个字节',
  'sb invites you to join the conference': ({ named }: any) =>
    `${named('name')} 邀请您加入会议`,
  // media privilege
  microphone: '麦克风',
  camera: '摄像头',
  'Unable to use the device': ({ named }: any) =>
    `无法使用${named('deviceType')}`,
  'media device failed to open, please check the media device and try again': ({
    named,
  }: any) =>
    `打开${named('deviceType')}失败, 请检查${named('deviceType')}设备并重试`,
  'The current device is not authorized, please allow access to the device permissions':
    ({ named }: any) =>
      `${named('deviceType')}设备未授权, 请允许访问${named('deviceType')}权限`,
  'The current device is occupied by other apps, try to close other apps or change the device':
    ({ named }: any) =>
      `当前${named('deviceType')}被其他应用程序占用，请尝试关闭其他应用程序或更换${named('deviceType')}`,
  'Turn on device privileges': ({ named }: any) =>
    `打开${named('deviceType')}权限`,
  'You can go to "System Preferences - Security & Privacy - Device" to enable device permissions':
    ({ named }: any) =>
      `你可前往"系统设置 - 隐私与安全性 - ${named('deviceType')}"开启设备权限。`,
  'Go to Settings': '前往设置',
  addMember: '添加成员',
  shareRoom: '分享房间',
  'Invitation sent, waiting for members to join.':
    '邀请信息已发出，等待成员加入',
  'Not joining for now': '暂不入会',
  'Not Entered': '未进入',
  Entered: '已进房',
  Call: '呼叫',
  'Calling...': '呼叫中...',
  'Call all': '呼叫所有人',
  'Busy line': '忙线中',
  // room password
  'Entering the room now...': '正在进入房间...',
  'The room is encrypted': '房间已加密',
  'Please enter the password': '请输入入会密码',
  'Your room password format is incorrect, please check it':
    '房间密码格式不正确，请检查',
  Security: '安全设置',
  'Room Password': '进房密码',
  'Enter 6-digit password': '请输入6位进房密码',
  'Wrong password': '密码错误',
  Encryption: '房间加密',
  'Open whiteboard': '开启白板',
  'Close whiteboard': '关闭白板',
  'Start annotating': '开始批注',
  'End annotating': '结束批注',
  'You are sharing the whiteboard, please stop sharing first':
    '您正在共享白板，请先停止共享',
  'Line Size': '线条粗细',
  'Line Color': '线条颜色',
  'Arrow Size': '箭头大小',
  'Arrow Color': '箭头颜色',
  'Text Size': '文字大小',
  'Text Color': '文字颜色',
  'Shape Type': '图形类型',
  'Shape Style': '形状样式',
  'Shape Color': '图形颜色',
  'Shape Size': '图形大小',
  // Beauty
  Beauty: '美颜',
  'Beauty Effects': '美颜特效',
  Smoother: '磨皮',
  Whitening: '美白',
  Ruddy: '红润',
  Compare: '对比',
  Reset: '重置',
  Degree: '程度',
  'Sure you want to reset the beauty effect?': '确定要重置美颜效果吗?',
  'All beauty parameters will revert to default after reset':
    '重置后所有美颜参数将恢复至默认',
};
