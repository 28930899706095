import Vue from "vue"
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import pcRoutes from "./pcRoutes"
import mobileRoutes from "./mobileRoutes"

const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: isMobile ? mobileRoutes : pcRoutes
})

export default router