<script>
import {mapGetters} from 'vuex'

export default {
    name: "lemonMessageText",
    inheritAttrs: false,
    inject: ["IMUI"],
    computed: {
        ...mapGetters({
            systemVersion: 'getSystemVersion',
        })
    },
    created() {
        //console.log('this.systemVersion', this.systemVersion)
    },
    render() {
        return (
            <lemon-message-basic
                class="lemon-message-text"
                props={{...this.$attrs}}
                scopedSlots={{
                    content: props => {

                        // 如果是链接
                        if (this.isUrl(props.content)) {
                            return <p>
                                <a target="_blank" href={props.content}>{props.content}</a>
                            </p>;
                        } else {
                            const content = props.translatedContent !== "" ? this.IMUI.emojiNameToImage(props.translatedContent) : this.IMUI.emojiNameToImage(props.content);
                            return <p>
                                <p domProps={{innerHTML: content}}/>
                            </p>;
                            // if (this.systemVersion != "A") {
                            //     return <p>
                            //         <p domProps={{innerHTML: translatedContent != "" ? translatedContent : content}}/>
                            //     </p>;
                            // } else {
                            //     return <p>
                            //         <p domProps={{innerHTML: content}}/>
                            //         <p class='translated-text' domProps={{innerHTML: translatedContent}}/>
                            //     </p>;
                            // }

                        }

                    }
                }}
            />
        );
    }
};
</script>
<style lang="scss" scoped>
.translated-text {
    color: #35d863
}

.lemon-message--reverse .lemon-message__inner .lemon-message__content-flex .lemon-message__content .translated-text {
    color: #f3f3f3
}
</style>
