<template>
    <el-dialog :close-on-click-modal="false" :visible.sync="show" align-center class="payino-dialog"
        width="450px" @close="close()">
        <div class="payinfo-form">
            <div class="modal">
                <div class="form">
                    <div class="separator">
                        <hr class="line">
                        <p>pay using credit card</p>
                        <hr class="line">
                    </div>
                    <div class="credit-card-info--form">
                        <div class="input_container">
                            <label class="input_label" for="full_name">Card holder full name</label>
                            <input id="full_name" v-model="formData.name" class="input_field" name="input-name"
                                placeholder="Enter your full name" title="Inpit title" type="text">
                        </div>
                        <div class="input_container">
                            <label class="input_label" for="card_number">Card holder phone number</label>
                            <input id="card_number" v-model="formData.phone" class="input_field" name="input-name"
                                placeholder="Enter your phone number" title="Inpit title" type="number">
                        </div>
                    </div>
                    <button class="purchase--btn" @click="confirm()">Checkout</button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        show: {
            require: true,
            default: false
        }
    },
    data() {
        return {
            formData: {
                name: '',
                phone: '',
            },
            rules: [
                {key: 'name', errMsg: 'Please enter card holder full name'},
                {key: 'phone', errMsg: 'Please enter card holder phone number'},
            ],
        }
    },
    created() {

    },
    methods: {
        confirm() {
            for (let index = 0; index < this.rules.length; index++) {
                const rule = this.rules[index];
                if (this.formData[rule.key] === '') {
                    this.$message.error(rule.errMsg)
                    return
                }
            }
            this.$emit('confirm', this.formData)
        },
        close() {
            this.formData = this.$options.data().formData
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>