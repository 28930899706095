<template>
    <div ref="scrollRef">
        

        <ConferenceMainView></ConferenceMainView>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { ConferenceMainView, conference, RoomEvent  } from '@/components/TUIRoom/index.ts'
import { cdnurl } from '@/filters';

export default {
    name: 'ListView',
    components: {
        ConferenceMainView,
    },
    data: function () {
        return {
            sdkAppId: '',
            userSig: '',
            roomnum: '',
        }
    },
    watch: {
        userinfo: function (newVal) {
            this.$http.post('/room/get-params', { user_id: newVal.id}).then(async res => {
                await conference.login({
                    sdkAppId: +res.data.params.sdkAppId,
                    userId: newVal.id + '',
                    userSig: res.data.params.userSig, 
                });
                    
                await conference.join(this.roomnum, {
                    roomName: 'chart room',
                    isOpenMicrophone: true,
                })
                
                conference.setSelfInfo({
                    userName: newVal.nickname,
                    avatarUrl: cdnurl(newVal.avatar)
                });
            })
        }
    },
    computed: {
        ...mapState({
            userinfo: 'userinfo',
        }),
    },
    created() {
        this.roomnum = this.$route.query.roomnum

        // 离开会议
        conference.on(RoomEvent.ROOM_LEAVE, () => {
            window.close()
        })
        // 会议解散
        conference.on(RoomEvent.ROOM_DISMISS, () => {
            window.close()
        })
        // 用户登出
        conference.on(RoomEvent.USER_LOGOUT, () => {
            window.close()
        })
    },
    beforeDestroy() {
        conference.leave();
    },
    methods: {
        
        async startConference() {
            await conference.login({    
                // 获取 sdkAppId 可参考文档开通服务部分，https://cloud.tencent.com/document/product/647/104842
                sdkAppId: 1600058730,
                // 用户在您业务中的唯一标示 Id
                userId: this.userinfo.id + '',
                // 本地开发调试可在 https://console.cloud.tencent.com/trtc/usersigtool 页面快速生成 userSig, 注意 userSig 与 userId 为一一对应关系
                userSig: 'eJwtjMsKgkAYRt9ltoXM-SK0SFdJJIMFbaWZ5EeKYbKQondPG5ffOR-ng477Jnv5iHJEM4zW-w3O3we4QsISGyIX83B9GwI4lBOJMRZaMZyMHwNEP3EhBJ1UogPcZja9lNKcyaUC3RyuzSGe7duPtixJRamA1cVtdfHsq85oGxpe7Gp-Yi3foO8PqusvXg__', 
            });
            await conference.start(this.userinfo.id + '', {
                roomName: 'TestRoom',
                isSeatEnabled: false,
                isOpenCamera: false,
                isOpenMicrophone: false,
            });
            
        },
    },
}
</script>

<style lang="scss" scoped>


</style>