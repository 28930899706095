<script>

import {mapGetters} from "vuex";

export default {
    name: "lemonMessageVideo",
    inheritAttrs: false,
    inject: ["IMUI"],
    computed: {
        ...mapGetters({
            systemVersion: 'getSystemVersion',
            userinfo: 'getUserinfo'
        })
    },
    render() {
        return (
            <lemon-message-basic
                class="lemon-message-video"
                props={{...this.$attrs}}
                scopedSlots={{
                    content: props => {

                        if (this.userinfo.gender == 0 && props.ispay == 1) {
                            return (
                                <div class="video-poster">
                                    <img src={require('@/assets/bg-black.png')}/>
                                    <i class='icon-al-bofang'></i>
                                    <i class='el-icon-check'></i>
                                </div>
                            );
                        } else {
                            return (
                                <div class="video-poster">
                                    <img src={require('@/assets/bg-black.png')}/>
                                    <i class='icon-al-bofang'></i>
                                </div>
                            );

                        }

                    }
                }}
            />
        );
    }
};
</script>
<style scoped>


.lemon-message.lemon-message-video .lemon-message__content::before {
    display: none;
}
</style>
<style lang="scss" scoped>
.lemon-message-video {
    user-select: none;

    .lemon-message__content {
        .video-poster {
            width: 120px;
            height: 120px;

            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                object-fit: contain;
                border-radius: 4px;
                -webkit-filter: brightness(50%);
                filter: brightness(90%);
            }

            .icon-al-bofang {
                position: absolute;
                top: 50%;
                left: 50%;
                padding: 10px;
                border-radius: 50%;
                background-color: rgba(19, 50, 146, 0.19);
                font-size: 36px;
                color: #fff;
                cursor: pointer;
                transform: translate(-50%, -50%);
            }

            .el-icon-check {
                position: absolute;
                top: 20px;
                left: 120px;
                padding: 10px;
                border-radius: 50%;
                font-size: 36px;
                color: #ffffff;
                cursor: pointer;
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>
