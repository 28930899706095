var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.layoutControlConfig.visible)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_setup.handleClickOutSide),expression:"handleClickOutSide"}],staticClass:"layout-container"},[_c(_setup.IconButton,{attrs:{"title":_setup.t('Layout'),"disabled":_setup.isStreamNumberLessThanTwo,"layout":_setup.IconButtonLayout.HORIZONTAL},on:{"click-icon":_setup.handleClickLayoutIcon}},[_c(_setup.LayoutIcon)],1),(_setup.showLayoutList)?_c('div',{staticClass:"layout-list"},[_c('div',{class:[
        'layout1',
        'layout-item',
        `${_setup.layout === _setup.LAYOUT.NINE_EQUAL_POINTS ? 'checked' : ''}`,
      ],on:{"click":function($event){return _setup.handleClick(_setup.LAYOUT.NINE_EQUAL_POINTS)}}},[_c('div',{staticClass:"layout-block-container"},_vm._l((new Array(9).fill('')),function(item,index){return _c('div',{key:index,staticClass:"layout-block"})}),0),_c('span',{staticClass:"layout-title"},[_vm._v(_vm._s(_setup.t('Grid')))])]),_c('div',{class:[
        'layout2',
        'layout-item',
        `${_setup.layout === _setup.LAYOUT.RIGHT_SIDE_LIST ? 'checked' : ''}`,
      ],on:{"click":function($event){return _setup.handleClick(_setup.LAYOUT.RIGHT_SIDE_LIST)}}},[_c('div',{staticClass:"layout-block-container"},[_c('div',{staticClass:"left-container"}),_c('div',{staticClass:"right-container"},_vm._l((new Array(3).fill('')),function(item,index){return _c('div',{key:index,staticClass:"layout-block"})}),0)]),_c('span',{staticClass:"layout-title"},[_vm._v(_vm._s(_setup.t('Gallery on right')))])]),_c('div',{class:[
        'layout3',
        'layout-item',
        `${_setup.layout === _setup.LAYOUT.TOP_SIDE_LIST ? 'checked' : ''}`,
      ],on:{"click":function($event){return _setup.handleClick(_setup.LAYOUT.TOP_SIDE_LIST)}}},[_c('div',{staticClass:"layout-block-container"},[_c('div',{staticClass:"top-container"},_vm._l((new Array(3).fill('')),function(item,index){return _c('div',{key:index,staticClass:"layout-block"})}),0),_c('div',{staticClass:"bottom-container"})]),_c('span',{staticClass:"layout-title"},[_vm._v(_vm._s(_setup.t('Gallery at top')))])])]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }