<script>
import i18n from '../../../lang'
export default {
  name: "lemonMessageWink",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-wink"
        props={{ ...this.$attrs }}
        scopedSlots={{
          content: props => {
            return <span class="wink-content"><img class='wink' src={this.cdnurl("/assets/img/wink.png")} /> {i18n.t("Winked")}</span>;
          }
        }}
      />
    );
  }
};
</script>
<style scoped>
.wink-content{
    text-align: center;
    font-size: 12px;
    color: gray;
}
.wink{
    background-color: unset !important;
    display: block;
    width: 50px !important;
    height: 50px !important;
}
</style>
