<template>
    <div class="chart-view">
        <el-row :gutter="1" class="card-header shadow">
            <div class="card-header-row">
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                    <h1 class="header-title">
                        <i class="icon-al-jiaoyou" style="font-size: 24px"></i>
                        {{ $t("message") }}
                        <span style="float: right;cursor: pointer;" @click="dialogSettingVisible = true">
              <i class="el-icon-s-tools"></i>
            </span>
                    </h1>
                </el-col>
            </div>
        </el-row>

        <el-row class="card-body" style="padding: 0 0 0 0 ">
            <lemon-imui ref="IMUI" :avatarCricle="OpenChatRoundAvatar" :contact-contextmenu="contactContextmenu"
                        :contactTimeFormat="messageTimeFormat"
                        :hide-menu="hideMenu" :hide-menu-avatar="hideMenuAvatar"
                        :hide-message-name="WhetherToHideContactNames" :hide-message-time="WhetherToHideTime"
                        :loadendText="$t('NoMore')" :messageTimeFormat="messageTimeFormat"
                        :sendKey="checkSend" :sendText="$t('send')"
                        :simple="simple" :theme="theme"
                        :timeFormat="messageTimeFormat"
                        :user="userInfo" :wrapKey="checkWrap"
                        class="lemon-custom"
                        @send="handleSend" @change-menu="handleChangeMenu" @change-contact="handleChangeContact"
                        @pull-messages="handlePullMessages" @message-click="handleMessageClick"
                        @menu-avatar-click="handleMenuAvatarClick">
                <!-- 左侧消息列表顶部插槽(固定) -->
                <template #sidebar-message-fixedtop="instance">
                    <el-autocomplete v-model="searchContactText" :fetch-suggestions="querySearch"
                                     :placeholder="$t('SearchContacts')"
                                     :trigger-on-focus="false" class="inline-input"
                                     size="small"
                                     @select="searchHandleSelect">
                        <i slot="prefix" class="icon-al-chazhao el-input__icon"> </i>
                        <template slot-scope="{ item }">
                            <div class="search-item lemon-contact">
                                <span class="lemon-badge lemon-contact__avatar">
                                    <span class="lemon-avatar" style="
                                        width: 40px;
                                        height: 40px;
                                        line-height: 40px;
                                        font-size: 20px;
                                        ">
                                        <img :src="item.avatar | cdnurl"/>
                                    </span>
                                    <span v-if="item.unread > 0" class="lemon-badge__label">
                                        {{ item.unread }}
                                    </span>
                                </span>
                                <div class="lemon-contact__inner">
                                    <p class="lemon-contact__label" style="position: relative;bottom: 5px;">
                                        <span class="lemon-contact__name f-w-m">{{ item.displayName }}</span>
                                        <span class="lemon-contact__time">
                                            <i v-if="item.isvip > 0" class="lemon-message-vip-icon icon-al-VIP"></i>
                                            {{ item.lastSendTime ? messageTimeFormat(item.lastSendTime) : "" }}
                                        </span>
                                    </p>
                                    <p class="lemon-contact__content"
                                       style="position: absolute;bottom: 5px;left: 55px;width: 120px;">
                                        <span
                                            v-html="item.lastContent == ' ' ? $t('NoChatContent') : item.lastContent"></span>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </el-autocomplete>
                </template>

                <!-- 固定在左侧联系人列表的顶部 -->
                <template #sidebar-contact-fixedtop>
                    {{ $t("Contacts") }}
                </template>

                <!-- 封面插槽 -->
                <template #cover>
                    <div class="cover">
                        <i class="icon-al-jiaoyou"></i>
                        <!-- <p><b>自定义封面 Lemon</b> IMUI</p> -->
                    </div>
                </template>

                <!-- 消息列表插槽 -->
                <template #sidebar-message="contact">
                    <span class="lemon-badge lemon-contact__avatar">
                        <span class="lemon-avatar" style="
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 20px;
                        ">
                            <img :src="contact.avatar | cdnurl"/>
                            <i :class="{ 'icon-al-zhuangtai': true, 'online-isactive': contact.online > 0 }"
                               style="position: absolute;top:25px;left:25px"></i>
                        </span>
                        <span v-if="contact.unread > 0" class="lemon-badge__label">
                            {{ contact.unread }}
                        </span>
                    </span>
                    <div class="lemon-contact__inner">
                        <p class="lemon-contact__label">
                            <span class="lemon-contact__name f-w-m ">{{ contact.displayName }}</span>
                            <span class="lemon-contact__time">
                                <i v-if="contact.isvip > 0" class="lemon-message-vip-icon icon-al-VIP"></i>
                                {{ contact.lastSendTime ? messageTimeFormat(contact.lastSendTime) : "" }}
                            </span>
                        </p>
                        <p class="lemon-contact__content">
                            <span class="lemon-contact__content_span"
                                  v-html="contact.lastContent == ' ' ? $t('NoChatContent') : contact.lastContent"></span>
                            <span v-if="contact.pin == 1" style="float: right;font-size: 18px;">
                                <i class="el-icon-paperclip"></i>
                            </span>
                        </p>
                    </div>
                </template>

                <!-- 联系人插槽 -->
                <template #sidebar-contact="contact">
                    <span class="lemon-badge lemon-contact__avatar">
                        <span class="lemon-avatar" style="
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 20px;
                        ">
                            <img :src="contact.avatar | cdnurl"/>
                        </span>
                    </span>
                    <i v-if="contact.isvip > 0" class="lemon-contact-vip-icon icon-al-VIP"></i>
                    <div class="lemon-contact__inner">
                        <p class="lemon-contact__label">
                            <span class="lemon-contact__name f-w-m ">{{ contact.displayName }}</span>
                        </p>
                    </div>
                </template>

                <!-- 消息列表的标题 -->
                <template #message-title="contact">
                    <i class="el-icon-arrow-left show-contacts" @click="showContacts()"></i>
                    <span class="lemon-avatar" @click="jump('/memberinfo/' + contact.id)">
                        <img :src="contact.avatar | cdnurl"/>
                        <i :class="{ 'icon-al-zhuangtai': true, 'online-isactive': contact.online > 0  }"
                           class="status"></i>
                    </span>
                    <span class="d-flex flex-column s-m-l-3">
                        <span class="lemon-nickname f-w-m" @click="jump('/memberinfo/' + contact.id)">
                            {{ contact.displayName }}
                        </span>
                        <span>
                            <i v-if="contact.isvip > 0" class=" lemon-vip icon-al-VIP"></i>
                        </span>
                    </span>
                    <span class="s-m-l-3 cursor-pointer" @click="handleIntimacy">
                        <el-progress
                            v-if="intimacyInfo.intervalNum >0"
                            :format="() => intimacyInfo.sendNum + '/' + intimacyInfo.intervalNum"
                            :percentage="intimacyInfo.percentage"
                            :width="48"
                            type="circle"
                        >
                        </el-progress>
                    </span>
                    <small class="more">
                        <el-button class="s-m-r-2" size="mini" type="warning"
                                   @click="giveCredits(contact, $refs.IMUI)">
                            credits
                        </el-button>
                        <el-button class="s-m-r-2" size="mini" type="primary"
                                   @click="giveVip(contact, $refs.IMUI)">
                            vip
                        </el-button>
                        <el-button plain size="mini" type="info">
                            <i class="el-icon-more" @click="changeDrawer(contact, $refs.IMUI)"></i>
                        </el-button>
                    </small>
                    <br/>
                </template>

                <!-- 消息输入框底部 -->
                <template #editor-footer>
                    <!-- <el-col :xs="12" :sm="6" :md="6" :lg="6">
                      <p class="chat-tips-1">{{ $t('Attachment') }}</p>
                      <p class="chat-tips-2">{{ $t('HowManyCredits', {
                        digit: Pricing.send_photos_price, s:
                          Pricing.send_photos_price > 1 ? "s" : ""
                      }) }} / {{ $t('picture') }}</p>
                    </el-col>
                    <el-col :xs="12" :sm="6" :md="6" :lg="6">
                      <p class="chat-tips-1">{{ $t('Sticker') }}</p>
                      <p class="chat-tips-2">{{ $t('HowManyCredits', {
                        digit: Pricing.stickers_price, s: Pricing.stickers_price > 1
                          ? "s" : ""
                      }) }} / {{ $t('sticker') }}</p>
                    </el-col>
                    <el-col :xs="12" :sm="6" :md="6" :lg="6"
                      style="background:linear-gradient(to right, #fafafa 10%,  #c6d5f3);border-radius: 5px;">
                      <div style="float: left;">
                        <el-popover placement="top-start" :title="$t('HowItWorks')" width="325" trigger="hover">
                          <div>
                            <el-timeline>
                              <el-timeline-item type="warning" size="large">
                                {{ $t('chatPrice1') }} {{ $t('HowManyCredits', {
                                  digit: Pricing.chat_price, s: Pricing.chat_price >
                                    1 ? "s" : ""
                                }) }}.
                              </el-timeline-item>
                              <el-timeline-item type="warning" size="large">
                                {{ $t('chatPrice2') }}
                              </el-timeline-item>
                              <el-timeline-item type="warning" size="large">
                                {{ $t('chatPrice3') }}
                              </el-timeline-item>
                            </el-timeline>
                          </div>
                          <el-button slot="reference" circle> <i style="color: #3B5998;" class="el-icon-warning"></i></el-button>
                        </el-popover>
                      </div>
                      <div style="margin-left: 50px;margin-top: 5px;">
                        <p class="chat-tips-1">{{ $t('Chat') }}</p>
                        <p class="chat-tips-2">{{ $t('freeForVip') }}</p>
                      </div>
                    </el-col>
                    <el-col :xs="12" :sm="6" :md="6" :lg="6">
                      <el-button @click="jump('/benefits')" style="float: right;color: #3B5998;"><i class="icon-al-jinbi1"></i>
                        <span style="font-weight: 600;margin-left: 10px;">{{ $t('Recharge') }} </span></el-button>
                    </el-col> -->
                </template>

                <!-- 自定义抽屉 -->
                <template #drawer="contact">
                    <div class="drawer-content">
                        <ul class="drawer-ul">
                            <li class="drawer-li" style="display:flex;justify-content:space-between;">
                                <span style="font-weight: bold;">{{ contact.displayName }}</span>
                                <i class="el-icon-close" @click="closeDrawer()"></i>
                            </li>
                            <li class="drawer-li" style="display:flex;justify-content:space-between;"
                                @click="handleMark(contact)">
                                {{ contact.pin === 1 ? $t('MessageUnmark') : $t('MessageMark') }}
                                <el-switch v-model="contact.pin" :active-value="1" :inactive-value="0"></el-switch>
                            </li>
                            <li class="drawer-li" style="display:flex;justify-content:space-between;"
                                @click="handleInvisibilitk(contact)">
                                {{ contact.hidden === 1 ? $t('MessageVisible') : $t('MessageInvisibilitk') }}
                                <el-switch v-model="contact.hidden" :active-value="1" :inactive-value="0"></el-switch>
                            </li>
                            <li class="drawer-li" style="display:flex;justify-content:space-between;"
                                @click="handleBlacklist(contact)">
                                {{ contact.block === 1 ? $t('MessageRemoveTheBlacklist') : $t('MessageAddBlacklist') }}
                                <el-switch v-model="contact.block" :active-value="1" :inactive-value="0"></el-switch>
                            </li>
                            <li class="drawer-li" @click="handleClearRecord(contact)">
                                <el-button size="mini" style="width:100%;">Clear chat record</el-button>
                            </li>
                            <li class="drawer-li" @click="handleDeleteContact(contact)">
                                <el-button size="mini" style="width:100%;">Delete Contact</el-button>
                            </li>
                        </ul>
                    </div>
                </template>
            </lemon-imui>
        </el-row>

        <!-- 选择图片模板 -->
        <el-dialog v-if="photoVisible" :title="$t('SelectImage')" :visible.sync="photoVisible"
                   :width="simple ? '95%' : '750px'"
                   custom-class="select-image">
            <CheckPhoto ref="imageComponent"></CheckPhoto>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelFile">{{ $t("cancel") }} </el-button>
                <el-button type="primary" @click="appendImageOrVideoMessage('image')">
                    {{ $t("confirm") }}
                </el-button>
            </span>
        </el-dialog>

        <!-- 选择视频模板 -->
        <el-dialog v-if="videoVisible" :title="$t('SelectVideo')" :visible.sync="videoVisible"
                   :width="simple ? '95%' : '750px'"
                   custom-class="select-image">
            <CheckVideo ref="videoComponent" :toUserId="contactInfo.id"></CheckVideo>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelFile">{{ $t("cancel") }} </el-button>
                <el-button type="primary" @click="appendImageOrVideoMessage('video')">
                    {{ $t("confirm") }}
                </el-button>
            </span>
        </el-dialog>

        <!-- 选择礼物模板 -->
        <el-dialog v-if="giftVisible" :title="$t('SelectGift')" :visible.sync="giftVisible"
                   :width="simple ? '95%' : '750px'"
                   custom-class="select-image">
            <CheckGift @changeFileUrl="changeFileUrl"></CheckGift>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelFile">{{ $t("cancel") }} </el-button>
                <el-button type="primary" @click="appendCustomMessage('gift')">
                    {{ $t("confirm") }}
                </el-button>
            </span>
        </el-dialog>

        <!-- 选择贴纸模版 -->
        <el-dialog v-if="stickerVisible" :title="$t('SelectSticker')" :visible.sync="stickerVisible"
                   :width="simple ? '95%' : '750px'"
                   custom-class="select-image">
            <CheckSticker @changeFileUrl="changeFileUrl"></CheckSticker>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelFile">{{ $t("cancel") }} </el-button>
                <el-button type="primary" @click="appendCustomMessage('sticker')">
                    {{ $t("confirm") }}
                </el-button>
            </span>
        </el-dialog>

        <!-- 播放视频 -->
        <el-dialog :visible.sync="playVisible" :width="simple ? '95%' : '750px'" append-to-body
                   custom-class="player-video"
                   @close="closeDialog" @opened="openedDialog">
            <player-video ref="video" :src="videoUrl" :volume="volume" height="600px"></player-video>
            <div slot="footer" class="dialog-footer">
                <el-button @click="playVisible = false">{{ $t("close") }}</el-button>
            </div>
        </el-dialog>

        <!-- 设置 -->
        <el-dialog :title="$t('ChatSettings')" :visible.sync="dialogSettingVisible" width="400px">
            <el-switch v-model="newMessageNotice" :active-text="$t('EnableNewMessageVoiceReminder')"
                       class="setting-switch">
            </el-switch>
            <el-switch v-model="OpenChatRoundAvatar" :active-text="$t('OpenChatRoundAvatar')"
                       class="setting-switch"></el-switch>
            <el-switch v-model="WhetherToHideContactNames" :active-text="$t('WhetherToHideContactNames')"
                       class="setting-switch">
            </el-switch>
            <el-switch v-model="WhetherToHideTime" :active-text="$t('WhetherToHideTime')"
                       class="setting-switch"></el-switch>
        </el-dialog>

        <el-dialog :visible.sync="transferVisible" append-to-body custom-class="transferDialog" top="50vh"
                   width="500px">
            <div>
                <el-input v-model="transferAmount" :autofocus="true" placeholder="Please enter credits">
                    <template slot="append">credits</template>
                </el-input>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="transferVisible = false">{{ $t("close") }}</el-button>
                <el-button type="primary" @click="handleTransfer">Submit</el-button>
            </div>
        </el-dialog>

        <ElImageViewer v-if="showViewer" :on-close="closeViewer" :url-list="viewerList"/>
    </div>
</template>
<script>
import Vue from "vue";
import {mapState, mapGetters, mapMutations} from 'vuex';
import Auth from "@/services/auth.js";
import EmojiData from "@/services/emoji";
import ChatJs from "@/services/chat.js";
import CheckPhoto from "@/page/components/chat/check-photo.vue";
import CheckVideo from "@/page/components/chat/check-video.vue";
import CheckGift from "@/page/components/chat/check-gift.vue";
import CheckSticker from "@/page/components/chat/check-sticker.vue";
import i18n from "@/lang";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

import LemonMessageVoice from "@/page/components/chat/lemon-message-voice.vue";

Vue.component(LemonMessageVoice.name, LemonMessageVoice);

import LemonMessageVideo from "@/page/components/chat/lemon-message-video.vue";

Vue.component(LemonMessageVideo.name, LemonMessageVideo);

import LemonMessageGift from "@/page/components/chat/lemon-message-gift.vue";

Vue.component(LemonMessageGift.name, LemonMessageGift);

import LemonMessageWink from "@/page/components/chat/lemon-message-wink.vue";

Vue.component(LemonMessageWink.name, LemonMessageWink);

import LemonMessageSticker from "@/page/components/chat/lemon-message-sticker.vue";

Vue.component(LemonMessageSticker.name, LemonMessageSticker);

import smileDataUrl from '@/assets/smile.dataurl'

import PlayerVideo from "@/page/components/common/player-video.vue";
import {cdnurl} from "@/filters";
import Chat from "@/services/chat";

const getTime = () => {
    return new Date().getTime();
};
const generateRandId = () => {
    return Math.random().toString(36).substr(-8);
};
const generateRandWord = () => {
    return Math.random().toString(36).substr(2);
};
export default {
    name: "Chat",
    components: {
        CheckPhoto,
        CheckVideo,
        PlayerVideo,
        ElImageViewer,
        CheckGift,
        CheckSticker,
    },
    inject: ['showGift'],
    props: {
        id: "",
    },
    data() {
        return {
            emojiImg: "/assets/img/emoji.gif",
            imageImg: "/assets/img/camera.png",
            videoImg: "/assets/img/video.png",
            giftImg: "/assets/img/gift.png",
            stickerImg: "/assets/img/sparkling_heart.gif",
            langImg: "/assets/img/lang.png",
            newMessageNotice: true,
            OpenChatRoundAvatar: true,
            WhetherToHideContactNames: false,
            WhetherToHideTime: false,
            dialogSettingVisible: false,
            searchContactText: "",
            showViewer: false,
            viewerList: [],
            videoUrl: "",
            volume: 0.5,
            simple: false,
            photoVisible: false,
            stickerVisible: false,
            videoVisible: false,
            playVisible: false,
            giftVisible: false,
            transferVisible: false, // 是否显示转账浮层
            transferAmount: '',
            theme: "default",
            Pricing: [],
            contactContextmenu: [
                {
                    text: this.$t("MarkOrUnmark"),
                    click: (e, instance, hide) => {
                        const {IMUI, contact} = instance;
                        this.$http.post("/contacts/topContacts", {to_user_id: contact.id}).then((res) => {
                            if (res.code == 1) {
                                this.$http.post("/contacts/getContacts", {}).then((res) => {
                                    if (res.code == 1) {
                                        let contactList = this.operaEmoji(res.data)
                                        IMUI.initContacts(contactList);
                                        this.contacts_list = contactList;

                                    } else {
                                        this.$message({
                                            showClose: true,
                                            message: res.msg,
                                            type: "error",
                                        });
                                    }
                                });
                                hide();
                            }
                        });
                    },
                },
                {
                    text: this.$t("ClearChatRecord"),
                    click: (e, instance, hide) => {
                        const {IMUI, contact} = instance;

                        this.$http.post("/message/deleteMessage", {to_user_id: contact.id}).then((res) => {
                            if (res.code == 1) {
                                IMUI.changeContact(null);
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                        });
                        hide();
                    },
                },

                {
                    click(e, instance, hide) {
                        hide();
                        let that = this
                        const {IMUI, contact} = instance;
                        Vue.prototype
                            .$confirm(i18n.t("DeleteContactConfirm"), i18n.t("warning"), {
                                confirmButtonText: i18n.t("confirm"),
                                cancelButtonText: i18n.t("cancel"),
                                type: "warning",
                            })
                            .then(() => {
                                Vue.prototype.$http.post("/contacts/deleteContacts", {to_user_id: contact.id}).then((res) => {
                                    if (res.code == 1) {
                                        IMUI.removeContact(contact.id);
                                        if (IMUI.currentContactId == contact.id)
                                            IMUI.changeContact(null);

                                    }
                                });

                            });
                    },
                    color: "red",
                    text: this.$t("DeleteContact"),
                },
            ],
            contextmenu: [
                {
                    click: (e, instance, hide) => {
                        const {IMUI, message} = instance;
                        const data = {
                            id: generateRandId(),
                            type: "event",
                            //使用 jsx 时 click必须使用箭头函数（使上下文停留在vue内）
                            content: (
                                <div>
                  <span>
                    你撤回了一条消息{" "}
                      <span
                          v-show={message.type == "text"}
                          style="color:#333;cursor:pointer"
                          content={message.content}
                          on-click={(e) => {
                              IMUI.setEditorValue(e.target.getAttribute("content"));
                          }}
                      >
                      重新编辑
                    </span>
                  </span>
                                </div>
                            ),

                            toContactId: message.toContactId,
                            sendTime: getTime(),
                        };
                        IMUI.removeMessage(message.id);
                        IMUI.appendMessage(data, true);
                        hide();
                    },
                    visible: (instance) => {
                        return instance.message.fromUser.id == this.userInfo.id;
                    },
                    text: "撤回消息",
                },
                {
                    visible: (instance) => {
                        return instance.message.fromUser.id != this.userInfo.id;
                    },
                    text: "举报",
                },
                {
                    text: "转发",
                },
                {
                    visible: (instance) => {
                        return instance.message.type == "text";
                    },
                    text: "复制文字",
                },
                {
                    visible: (instance) => {
                        return instance.message.type == "image";
                    },
                    text: "下载图片",
                },
                {
                    visible: (instance) => {
                        return instance.message.type == "file";
                    },
                    text: "下载文件",
                },
                {
                    click: (e, instance, hide) => {
                        const {IMUI, message} = instance;
                        IMUI.removeMessage(message.id);
                        hide();
                    },
                    icon: "lemon-icon-folder",
                    color: "red",
                    text: "删除",
                },
            ],
            hideMenuAvatar: false,
            hideMenu: true,
            hideMessageName: false,
            hideMessageTime: false,
            userInfo: {},
            fileUrl: "",
            contacts_list: [],
            contactInfo: {},  // 正在聊天的联系人的信息
            currentLanguageCode: '',
            langActions: [
                {text: 'Don\'t translate', key: ''},
                {text: 'English', key: 'en'},
                {text: '한국어', key: 'kor'},
                {text: 'Português', key: 'pt'},
                {text: '中文', key: 'zh'},
                {text: '日本語', key: 'jp'},
                {text: 'Français', key: 'fra'},
                {text: 'بالعربية', key: 'ara'},
                {text: 'Deutsch', key: 'de'},
            ],
            intimacyInfo: {}, // 亲密值
        };
    },
    watch: {
        newMessageNotice(val) {
            localStorage.setItem("newMessageNotice", val);
        },
        OpenChatRoundAvatar(val) {
            localStorage.setItem("OpenChatRoundAvatar", val);
        },
        WhetherToHideContactNames(val) {
            localStorage.setItem("WhetherToHideContactNames", val);
        },
        WhetherToHideTime(val) {
            localStorage.setItem("WhetherToHideTime", val);
        },
        newMessageInfo(msgInfo) {
            // console.log('有新的消息', msgInfo)
            this.onMessage(msgInfo)
        }
    },
    computed: {
        ...mapState({
            newMessageInfo: 'newMessageInfo'
        }),
        ...mapGetters({
            unreadMessageCount: 'getUnreadMessageCount',
            userinfo: 'getUserinfo',
            isVIP: 'getIsVIP',
            systemVersion: 'getSystemVersion',
            customerId: 'getCustomerId',
            residue: 'getResidue'
        }),
        video() {
            return this.$refs.video;
        },
    },
    mounted() {
        let that = this;
        const {IMUI} = this.$refs;
        IMUI._setCustomerId(that.customerId)
        // 判断手机端
        that.simple = that.isMobile();
        if (that.simple && !that.id) {
            document.querySelector('.lemon-sidebar').style.display = "block"
            let lemonContainer = document.getElementsByClassName('lemon-container')
            lemonContainer[1].style.display = "none"
        }
        // 将联系人加入联系人列表
        if (that.id) {
            this.$http.post("/contacts/addContacts", {to_user_id: that.id}).then((res) => {
                this.$http.post("/contacts/getContacts").then((res) => {
                    if (res.code == 1) {
                        let contactList = this.operaEmoji(res.data ? res.data : [])
                        IMUI.initContacts(contactList);
                        this.contacts_list = contactList;
                        if (contactList.length > 0) {
                            IMUI.initContacts(contactList);
                            IMUI.changeContact(that.id);
                        }
                    }
                });
            });
        } else {
            // 获取联系人列表
            this.$http.post("/contacts/getContacts").then((res) => {
                if (res.code == 1) {
                    let contactList = this.operaEmoji(res.data ? res.data : [])
                    this.contacts_list = contactList;
                    if (contactList.length > 0) {
                        IMUI.initContacts(contactList);
                        if (that.id) {
                            IMUI.changeContact(that.id);

                        }
                    }
                }
            });
        }


        IMUI.initMenus([
            {
                name: "messages",
            },
            {
                name: "contacts",
            },
            // {
            //   name: "custom1",
            //   title: "自定义按钮1",
            //   unread: 0,
            //   render: menu => {
            //     return <i class="lemon-icon-attah" />;
            //   },
            //   renderContainer: () => {

            //   },
            //   isBottom: true,
            // },
            {
                name: "setting",
                title: this.$t("ChatSettings"),
                unread: 0,
                click: () => {
                    this.dialogSettingVisible = true;
                },
                render: (menu) => {
                    return <i class="el-icon-s-tools"/>;
                },
                isBottom: true,
            },
        ]);
        let editorTools = [

            {
                name: "sticker",
                title: this.$t("Sticker"),
                click: () => {
                    this.stickerVisible = true;
                },
                render: () => {
                    return <button class="toolButton"><img class='toolImg' src={this.cdnurl(this.stickerImg)}/>
                        <span>{i18n.t("Sticker")}</span></button>;
                }
            },
            {
                name: "uploadImage",
                title: this.$t("image"),
                click: () => {
                    this.photoVisible = true;
                },
                render: () => {
                    return <button class="toolButton"><img class='toolImg' src={this.cdnurl(this.imageImg)}/>
                        <span>{i18n.t("image")}</span></button>;
                },
            },
            {
                name: "uploadFile",
                title: this.$t("video"),
                click: () => {
                    this.videoVisible = true;
                },
                render: () => {
                    return <button class="toolButton"><img class='toolImg' src={this.cdnurl(this.videoImg)}/>
                        <span>{i18n.t("video")}</span></button>;
                },
            },
            {
                name: "gift",
                title: this.$t("gift"),
                click: () => {
                    if (this.systemVersion === 'A' || this.systemVersion === 'E') {
                        this.giftVisible = true;
                    } else {
                        const CurrentContact = IMUI.getCurrentContact()
                        this.$router.push(`presents?to_uid=${CurrentContact.id}&nickname=${CurrentContact.displayName}`)
                    }
                },
                render: () => {
                    return <button class="toolButton"><img class='toolImg' src={this.cdnurl(this.giftImg)}/>
                        <span>{i18n.t("gift")}</span></button>;
                },
            },
            {
                name: "selectLang",
                title: "Select translation language",
                render: () => {
                    return (
                        <div>
                            <el-dropdown trigger="click">
                                <button class="toolButton">
                                    <img class='toolImg' src={this.cdnurl(this.langImg)}/>
                                    <span>{this.currentLanguageCode == "" ? '' : this.currentLanguageCode} </span>
                                </button>
                                <el-dropdown-menu slot="dropdown">
                                    {this.langActions.map(item => (
                                        <el-dropdown-item>
                                            <p onClick={() => this.handleLangSelect(item)}>{item.text}</p>
                                        </el-dropdown-item>
                                    ))}
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    )
                },
            },
            {
                name: "transfer",
                title: "transfer",
                click: () => {
                    //this.transferVisible = true;
                    const contact = IMUI.getCurrentContact();
                    this.$router.push(`benefits?type=transfer&to_uid=${contact.id}&nickname=${contact.displayName}`)
                },
                render: () => {
                    return (
                        <button class="toolButton">
                            <img class='toolImg' src={require('@/assets/svg/wealth_l.svg')}/>
                        </button>
                    )
                },
            },
            // {
            //   name: "test1",
            //   click: () => {
            //     IMUI.$refs.editor.selectFile("application/vnd.ms-excel");
            //   },
            //   render: () => {
            //     return <span>Excel</span>;
            //   },
            // },
            // {
            //   name: "test1",
            //   click: () => {
            //     IMUI.initEditorTools([{ name: "uploadFile" }, { name: "emoji" }]);
            //   },
            //   render: () => {
            //     return <span>重制工具栏</span>;
            //   },
            // },
            // {
            //   name: "test2",
            //   isRight: true,
            //   title: "上传 Excel",
            //   click: () => {
            //     alert("点击了 ··· ");
            //   },
            //   render: () => {
            //     return <b>···</b>;
            //   },
            // },
        ]
        if (this.systemVersion === "B") {
            editorTools.unshift({
                name: "phone",
                title: "Call Me",
                click: () => {
                    //this.transferVisible = true;
                    const contact = IMUI.getCurrentContact();
                    this.$router.push(`/presents/phone?id=${contact.id}`)
                },
                render: () => {
                    return (
                        <button class="toolButton">
                            <img class='toolImg' src={require('@/assets/svg/phone.svg')}/>

                        </button>
                    )
                },
            })
        } else {
            editorTools.unshift({
                name: "emoji",
                title: this.$t("emoji"),
                render: () => {
                    return <button class="toolButton"><img class='toolImg' src={this.cdnurl(this.emojiImg)}/>
                        <span>{i18n.t("emoji")}</span></button>;
                }
            })
        }
        IMUI.initEditorTools(editorTools);
        IMUI.initEmoji(EmojiData);
        IMUI.setLastContentRender("text", (message) => {
            return IMUI.emojiNameToImage(message.content)
        });
        IMUI.setLastContentRender("image", (message) => {
            return `[${this.$t("image")}]`;
        });
        IMUI.setLastContentRender("video", (message) => {
            return `[${this.$t("video")}]`;
        });
        IMUI.setLastContentRender("voice", (message) => {
            return `[${this.$t("voice")}]`;
        });
        IMUI.setLastContentRender("gift", (message) => {
            return `[${this.$t("gift")}]`;
        });
        IMUI.setLastContentRender("wink", (message) => {
            return `[${this.$t("wink")}]`;
        });
        IMUI.setLastContentRender("sticker", (message) => {
            return `[${this.$t("sticker")}]`;
        });

        // const { SimpleIMUI } = this.$refs;
        // contactData1.id = "11";
        // SimpleIMUI.initContacts([contactData1]);
        // SimpleIMUI.initEmoji(EmojiData);
        // SimpleIMUI.changeContact(contactData1.id);
        //收到消息时
        // that.websocket.ws.onmessage = (msgInfo) => {

        // };
    },
    methods: {
        ...mapMutations([
            'setUnreadMessageCount',
            'setIsShowTabbar',
            'setResidue',
        ]),
        /**
         * 转账
         */
        handleTransfer() {


            if (this.transferAmount <= 0) {
                this.$message.error('Please enter credits')
                return
            }
            this.transferVisible = false
            const message = {
                id: generateRandId(),
                status: "going",
                type: 'transfer',
                sendTime: getTime(),
                content: this.transferAmount,
                toContactId: contact.id,
                fromUser: this.userInfo,
            };
            this.submitMessage('transfer', message)
        },
        /**
         * 处理亲密值，当亲密值达到100%时，点击可跳转礼物页面
         */
        handleIntimacy() {
            if (this.intimacyInfo.sendNum >= this.intimacyInfo.intervalNum) {
                this.$router.push({
                    path: '/presents',
                    query: {
                        to_uid: this.contactInfo.id,
                        nickname: this.contactInfo.displayName
                    }
                })
            }
        },
        /**
         * 获取与当前联系人的亲密值
         */
        getIntimacy(id) {
            this.$http.post('/contacts/getIntimacy', {to_user_id: id || this.id})
                .then(res => {
                    if (res.code === 1) {
                        if (res.data.intervalNum > 0) {
                            if (res.data.sendNum > res.data.intervalNum) {
                                res.data.sendNum = res.data.intervalNum
                            }
                            res.data.percentage = res.data.sendNum / res.data.intervalNum * 100
                        } else {
                            res.data.percentage = 0
                        }
                        this.intimacyInfo = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        onMessage(msgInfo) {
            const that = this
            const {IMUI} = this.$refs;
            let msgData = JSON.parse(msgInfo.data);
            console.log("chat receive messages", msgData);
            if (msgData) {
                switch (msgData.type) {
                    case "online":
                        let toUser = IMUI.getContacts().find(item => item.id == msgData.from)
                        if (toUser) {
                            if (msgData.data.status == "online") {
                                IMUI.updateContact({
                                    id: msgData.from,
                                    online: 1,
                                });
                            }
                            if (msgData.data.status == "offline") {
                                IMUI.updateContact({
                                    id: msgData.from,
                                    online: 0,
                                });
                            }
                        }
                        break;
                    case "friend":
                        that.setUnreadMessageCount(that.unreadMessageCount + 1)
                        if (msgData.data.type == "gift") {
                            msgData.data.open = 0;
                            // 如果是礼物，以动画方式弹出
                            that.showGift(
                                {
                                    giftUrl: that.cdnurl(msgData.data.content),
                                    fromUserId: this.userInfo.id,
                                    fromUserName: this.userInfo.displayName,
                                }
                            )
                        }
                        let message = ChatJs.formatMessage(msgData.data);
                        IMUI.appendMessage(message, true);
                        let contact = ChatJs.getContact(msgData.data.toContactId, that.contacts_list);
                        if (!contact) {
                            // 全屏展示收到的礼物
                            let contact = msgData.data.fromUser;
                            IMUI.appendContact({
                                id: contact.id,
                                displayName: contact.displayName,
                                avatar: that.cdnurl(contact.avatar),
                                index: contact.index,
                                unread: 1,
                                lastSendTime: contact.lastSendTime,
                                lastContent: contact.lastContent,
                                isvip: contact.vip_expire_time,
                            });
                        } else {
                            IMUI.updateContact({
                                id: msgData.from,
                                lastSendTime: msgData.data.sendTime,
                                lastContent: msgData.data.translatedContent ? msgData.data.translatedContent : msgData.data.content,
                            });
                        }
                        if (!this.isVIP) {
                            that.appendEventMessage(message.toContactId)
                        }
                        if (this.systemVersion === "C") {
                            Chat.playVoice("receive.mp3")
                        } else {
                            Chat.playVoice("receive.wav")
                        }
                        break;
                }
            }
        },
        giveCredits(contact, instance) {
            if (this.systemVersion === "C") {
                this.$router.push(`benefits?type=money`)
            } else {
                const CurrentContact = instance.getCurrentContact()
                this.$router.push(`benefits?type=money&to_uid=${CurrentContact.id}&nickname=${CurrentContact.displayName}`)
            }

        },
        giveVip(contact, instance) {
            if (this.systemVersion === "C") {
                this.$router.push(`benefits?type=vip`)
            } else {
                const CurrentContact = instance.getCurrentContact()
                this.$router.push(`benefits?type=vip&to_uid=${CurrentContact.id}&nickname=${CurrentContact.displayName}`)
            }

        },
        handleLangSelect(action) {
            this.currentLanguageCode = action.key
            localStorage.setItem("currentLanguageCode" + this.userInfo.id, action.key);
            this.isShowlangActionSheet = false
        },
        // 处理联系人列表,将最后一条消息进行emoji表情转换
        operaEmoji(contactList) {
            const {IMUI} = this.$refs;
            for (let i = 0; i < contactList.length; i++) {
                contactList[i].lastContent = IMUI.emojiNameToImage(contactList[i].lastContent)
            }
            return contactList
        },
        showContacts() {
            this.setIsShowTabbar(true)
            const {IMUI} = this.$refs;
            IMUI.changeContact(null);
            document.querySelector('.lemon-sidebar').style.display = "block"
            let lemonContainer = document.getElementsByClassName('lemon-container')
            setTimeout(() => {
                lemonContainer[1].style.display = "none"
            }, 50);
        },
        isMobile() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        },
        closeDrawer() {
            const {IMUI} = this.$refs;
            IMUI.closeDrawer()
        },
        handleMark(contact) {
            const {IMUI} = this.$refs;
            this.$http.post("/contacts/topContacts", {to_user_id: contact.id}).then((res) => {
                if (res.code == 1) {
                    this.$http.post("/contacts/getContacts", {}).then((res) => {
                        if (res.code == 1) {
                            let contactList = this.operaEmoji(res.data)
                            IMUI.initContacts(contactList);
                            this.contacts_list = contactList;
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                        }
                    });
                }
            });
        },
        handleInvisibilitk(contact) {
            const {IMUI} = this.$refs;
            this.$http.post("/contacts/invisibleContacts", {to_user_id: contact.id}).then((res) => {
                if (res.code == 1) {
                    this.$http.post("/contacts/getContacts", {}).then((res) => {
                        if (res.code == 1) {
                            let contactList = this.operaEmoji(res.data)
                            IMUI.initContacts(contactList);
                            this.contacts_list = contactList;
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                        }
                    });
                }
            });
        },
        handleBlacklist(contact) {
            const {IMUI} = this.$refs;
            this.$http.post("/contacts/blockContacts", {to_user_id: contact.id}).then((res) => {
                if (res.code == 1) {
                    this.$http.post("/contacts/getContacts", {}).then((res) => {
                        if (res.code == 1) {
                            let contactList = this.operaEmoji(res.data)
                            IMUI.initContacts(contactList);
                            this.contacts_list = contactList;
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                        }
                    });
                }
            });
        },
        handleClearRecord(contact) {
            const {IMUI} = this.$refs;
            this.$http.post("/message/deleteMessage", {to_user_id: contact.id}).then((res) => {
                if (res.code == 1) {
                    IMUI.changeContact(null);
                } else {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
        handleDeleteContact(contact) {
            const {IMUI} = this.$refs;
            Vue.prototype
                .$confirm(i18n.t("DeleteContactConfirm"), i18n.t("warning"), {
                    confirmButtonText: i18n.t("confirm"),
                    cancelButtonText: i18n.t("cancel"),
                    type: "warning",
                })
                .then(() => {
                    Vue.prototype.$http.post("/contacts/deleteContacts", {to_user_id: contact.id}).then((res) => {
                        if (res.code == 1) {
                            IMUI.removeContact(contact.id);
                            if (IMUI.currentContactId == contact.id)
                                IMUI.changeContact(null);
                        }
                    });
                });
        },
        getPricing() {
            this.$http.get('/credits/getPricing', {})
                .then((res) => {
                    if (res.code == 1) {
                        this.Pricing = res.data
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        },
        jump(url) {
            this.$router.push(url)
        },
        closeViewer() {
            this.showViewer = false;
            this.viewerList = [];
        },
        changeFileUrl(params) {
            this.fileUrl = params.value;
            this.fileId = params.id;
        },
        cancelFile() {
            this.fileUrl = "";
            this.stickerVisible = false;
            this.photoVisible = false;
            this.videoVisible = false;
            this.giftVisible = false;
        },
        messageTimeFormat(a) {
            let date = "";
            if (new Date(a).toDateString() === new Date().toDateString()) {
                //今天
                date = this.$t("Today") + " " + this.formatDate(a, "hh:mm");
            } else if (new Date(a) < new Date()) {
                //之前
                date = this.formatDate(a, "MM/dd hh:mm");
            }

            return date;
        },
        changeTheme() {
            this.theme = this.theme == "default" ? "blue" : "default";
        },
        scrollToTop() {
            document.body.scrollIntoView();
        },
        handleMenuAvatarClick() {
            console.log("Event:menu-avatar-click");
        },

        changeMenuAvatarVisible() {
            this.hideMenuAvatar = !this.hideMenuAvatar;
        },
        changeMenuVisible() {
            this.hideMenu = !this.hideMenu;
        },
        changeMessageNameVisible() {
            this.hideMessageName = !this.hideMessageName;
        },
        changeMessageTimeVisible() {
            this.hideMessageTime = !this.hideMessageTime;
        },
        removeMessage() {
            const {IMUI} = this.$refs;
            const messages = IMUI.getCurrentMessages();
            const id = messages[messages.length - 1].id;
            if (messages.length > 0) {
                IMUI.removeMessage(id);
            }
        },
        updateMessage() {
            const {IMUI} = this.$refs;
            const messages = IMUI.getCurrentMessages();
            const message = messages[messages.length - 1];
            if (messages.length > 0) {
                const update = {
                    id: message.id,
                    status: "succeed",
                    type: "file",
                    fileName: "被修改成文件了.txt",
                    fileSize: "4200000",
                };
                if (message.type == "event") {
                    update.fromUser = this.userInfo;
                }
                IMUI.updateMessage(update);
                IMUI.messageViewToBottom();
            }
        },
        async appendImageOrVideoMessage(type) {
            const that = this;
            const {IMUI} = that.$refs;
            let contact = IMUI.getCurrentContact();
            const checkedUrl = this.$refs[type + 'Component'].checkedUrl
            const checkedId = this.$refs[type + 'Component'].checkedId

            if (Object.keys(contact).length == 0 || checkedUrl.length === 0) {
                let msg = ''
                if (type == 'image') {
                    msg = that.$t("NoPhotosSelected")
                } else if (type == 'video') {
                    msg = that.$t("NoVideoSelected")
                }
                that.$message.error(msg);
                return false;
            }

            that.photoVisible = false;
            that.videoVisible = false;
            for (let index = 0; index < checkedUrl.length; index++) {
                const fileUrl = checkedUrl[index];
                const fileId = checkedId[index];
                let message = {
                    id: generateRandId(),
                    status: "going",
                    type: type,
                    sendTime: getTime(),
                    content: fileUrl,
                    toContactId: contact.id,
                    fromUser: that.userInfo,
                    file_id: fileId
                };
                await this.submitMessage(type, message)
            }
        },
        async submitMessage(type, message) {
            let that = this;
            const {IMUI} = that.$refs;

            await that.$http.post("/websocket/send", message).then((res) => {
                if (res.code == 1) {
                    message.status = "succeed";
                    message.id = res.data.id;
                    if (type !== "text" && type != 'transfer') {
                        message.content = that.cdnurl(res.data.content);
                    } else {
                        message.content = message.translatedContent ? message.translatedContent : message.content
                    }

                    // console.log("appendCustomMessage", message);
                    IMUI.updateContact({
                        id: message.toContactId,
                        unread: 0,
                    });
                    ChatJs.playVoice("send.wav");
                    IMUI.appendMessage(message, true);
                    that.setUnreadMessageCount(res.data.notReadNum)
                    that.setResidue(res.data.residue)
                    if (!this.isVIP) {
                        that.appendEventMessage(message.toContactId)
                    }
                    // 更新亲密度
                    that.intimacyInfo.sendNum += 1
                    if (that.intimacyInfo.sendNum > that.intimacyInfo.intervalNum) {
                        that.intimacyInfo.sendNum = that.intimacyInfo.intervalNum
                    }
                    that.intimacyInfo.percentage = that.intimacyInfo.sendNum / that.intimacyInfo.intervalNum * 100
                    IMUI.messageViewToBottom();
                } else if (res.code == 0) {
                    IMUI.removeMessage(message.id);
                    IMUI.updateContact({
                        id: message.toContactId,
                        lastContent: " ",
                    });
                    that.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error",
                    });
                } else {
                    IMUI.removeMessage(message.id);
                    IMUI.updateContact({
                        id: message.toContactId,
                        lastContent: " ",
                    });
                }
            });
        },
        appendCustomMessage(type) {
            let that = this;
            const {IMUI} = that.$refs;
            let contact = IMUI.getCurrentContact();

            if (Object.keys(contact).length == 0 || that.fileUrl == "") {
                that.fileUrl = "";
                let msg = ''
                if (type == 'image') {
                    msg = that.$t("NoPhotosSelected")
                } else if (type == 'video') {
                    msg = that.$t("NoVideoSelected")
                } else if (type == 'gift') {
                    msg = that.$t("NoGiftSelected")
                } else if (type == 'sticker') {
                    msg = that.$t("NoStickerSelected")
                }
                that.$message.error(msg);
                return false;
            }

            let message = {
                id: generateRandId(),
                status: "going",
                type: type,
                sendTime: getTime(),
                content: that.fileUrl,
                toContactId: contact.id,
                fromUser: that.userInfo,
                file_id: that.fileId,
            };

            // return console.log(message) 
            that.$http.post("/websocket/send", message).then((res) => {
                if (res.code == 1) {
                    message.status = "succeed";
                    message.id = res.data.id;
                    if (type !== "text") {
                        message.content = that.cdnurl(res.data.content);
                    } else {
                        message.content = message.translatedContent ? message.translatedContent : message.content
                    }

                    // console.log("appendCustomMessage", message);
                    IMUI.updateContact({
                        id: message.toContactId,
                        unread: 0,
                    });
                    ChatJs.playVoice("send.wav");
                    IMUI.appendMessage(message, true);
                    that.setUnreadMessageCount(res.data.notReadNum)
                    that.setResidue(res.data.residue)
                    if (!this.isVIP) {
                        that.appendEventMessage(message.toContactId)
                    }
                    // 更新亲密度
                    that.intimacyInfo.sendNum += 1
                    if (that.intimacyInfo.sendNum > that.intimacyInfo.intervalNum) {
                        that.intimacyInfo.sendNum = that.intimacyInfo.intervalNum
                    }
                    that.intimacyInfo.percentage = that.intimacyInfo.sendNum / that.intimacyInfo.intervalNum * 100
                    IMUI.messageViewToBottom();
                } else if (res.code == 0) {
                    IMUI.removeMessage(message.id);
                    IMUI.updateContact({
                        id: message.toContactId,
                        lastContent: " ",
                    });
                    that.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error",
                    });
                } else {
                    IMUI.removeMessage(message.id);
                    IMUI.updateContact({
                        id: message.toContactId,
                        lastContent: " ",
                    });
                }
            });
            that.photoVisible = false;
            that.stickerVisible = false;
            that.videoVisible = false;
            that.giftVisible = false;
            that.fileUrl = "";
        },
        appendImageMessage() {
        },
        appendMessage() {
            const {IMUI} = this.$refs;
            const contact = IMUI.currentContact;
            const message = generateMessage("contact-3");
            message.fromUser = {
                ...message.fromUser,
                ...this.userInfo,
            };
            IMUI.appendMessage(message, true);
            console.log(
                "🚀 ~ file: App.vue ~ line 1508 ~ appendMessage ~ message",
                message
            );
        },
        appendEventMessage(toContactId) {
            if (this.customerId == toContactId || this.residue > 0) {
                return
            }
            const {IMUI} = this.$refs;
            let id = "noticeForVip"
            IMUI.removeMessage(id);
            const toUser = IMUI.getContacts().find(item => item.id == toContactId)
            const message = {
                id: id,
                type: "event",
                content: (
                    <div class="low-balance-inline__wrapper s-p-t-2 s-p-b-3">
                        <div class="low-balance__wrapper d-flex align-items-center flex-column align-self-center black">
                            <div class="low-balance__images-wrapper s-m-b-3 d-flex">
                                <svg t="1720245127237" class="icon low-balance__images-smile" viewBox="0 0 1541 1024"
                                     version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9355"
                                     id="mx_n_1720245127238" width="84.2734375" height="56"
                                     xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <path d="M748.437608 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                                          fill="#FFD85C" p-id="9356"></path>
                                    <path
                                        d="M298.449384 466.956995c-11.851371-24.144569-43.662944-32.799188-71.043249-19.349442-1.507411 0.740711-2.975838 1.533401-4.392284 2.378071-17.725076 10.512893-28.16 28.510863-28.16 46.326904 0-17.816041-10.434924-35.81401-28.16-46.326904-1.416447-0.84467-2.884873-1.63736-4.392284-2.378071-27.380305-13.449746-59.191878-4.795127-71.043249 19.349442-8.173807 16.646497-4.743147 36.309117 7.147208 51.316954l-0.948629 0.94863 97.396954 97.394355 97.396954-97.394355-0.948629-0.94863c11.890355-15.007838 15.321015-34.670457 7.147208-51.316954z"
                                        fill="#EA792D" p-id="9357"></path>
                                    <path
                                        d="M562.542623 506.180873c35.847797 0 64.909645-29.060548 64.909644-64.908345 0-35.852995-29.061848-64.913543-64.909644-64.913543-35.852995 0-64.913543 29.060548-64.913543 64.913543 0 35.847797 29.060548 64.908345 64.913543 64.908345zM1061.013608 491.196426l-25.854701-2.63537c0.170234-1.677645 0.28199-3.396873 0.320975-5.114802 0.462619-18.942701-7.087431-37.001746-21.259696-50.847838-14.429563-14.094294-33.937543-22.139452-54.933441-22.652751-42.996305-1.051289-78.732345 30.213198-79.696569 69.693076a66.415756 66.415756 0 0 0 0.346965 8.592244l-25.850803 2.715939a94.226193 94.226193 0 0 1-0.478213-11.939736c1.317685-53.809381 48.979168-96.43533 106.315371-95.043574 27.585624 0.674437 53.318173 11.343269 72.460995 30.042965 19.397523 18.946599 29.725888 43.834477 29.083939 70.077726a95.853157 95.853157 0 0 1-0.454822 7.112121z"
                                        fill="" p-id="9358"></path>
                                    <path
                                        d="M942.061973 742.659898S861.191963 852.467005 761.432531 852.467005s-180.629442-109.807107-180.629441-109.807107 80.87001-1.949239 180.629441-1.949238 180.629442 1.949239 180.629442 1.949238z"
                                        fill="#F29336" p-id="9359"></path>
                                </svg>
                                <img src={cdnurl(toUser.avatar)} class="low-balance__images-avatar"/>
                            </div>
                            <div class="low-balance__heading s-m-b-2 t-a-c"> Don’t Lose Your Connection!</div>
                            <div class="low-balance__message s-m-b-3"> Your VIP is Expired. <b>Recharge to continue
                                chatting </b>, and don't keep {toUser.displayName} waiting 🥰.
                            </div>
                            <button on-click={() => this.$router.push('/benefits?type=vip')}
                                    class="ui-button low-balance__button gap-1 ui-button__size-m ui-button__type-success ui-button__icon-position-left"
                                    default="default">
                                <div class="ui-icon ui-icon__display-inline"
                                     style="width: 24px; height: 24px; visibility: visible;">
                  <span class="ui-icon__display-block" style="width: 24px; height: 24px;">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M16.03 7.846c-1.54 0-2.996-.268-4.1-.756a5.008 5.008 0 0 1-.87-.484v1.09c0 .414.485.875 1.296 1.233.973.429 2.278.665 3.674.665 1.397 0 2.702-.236 3.674-.665C20.516 8.57 21 8.109 21 7.696v-1.09c-.249.176-.54.339-.87.484-1.104.488-2.56.756-4.1.756zM16.03 18.003c1.397 0 2.701-.236 3.674-.665.811-.358 1.296-.819 1.296-1.233v-1.09c-.25.177-.54.34-.87.485-1.104.487-2.56.755-4.1.755-.626 0-1.235-.044-1.817-.13a6.101 6.101 0 0 1-.293 1.684c.66.128 1.379.194 2.11.194z"></path>
                      <path
                          d="M12.674 11.86c.93.347 2.115.537 3.356.537 1.397 0 2.702-.236 3.674-.665.812-.358 1.296-.819 1.296-1.233V9.41c-.249.176-.54.339-.87.485-1.104.487-2.56.755-4.1.755-1.54 0-2.996-.268-4.1-.755a5.008 5.008 0 0 1-.87-.485v1.152c.61.34 1.155.78 1.614 1.299zM16.03 15.2c1.397 0 2.702-.236 3.674-.665.811-.358 1.296-.819 1.296-1.233v-1.09c-.25.177-.54.339-.87.485-1.104.487-2.56.755-4.1.755-.853 0-1.686-.084-2.447-.242.282.569.477 1.187.571 1.838.595.1 1.224.152 1.876.152zM12.356 6.125c.973.43 2.278.666 3.674.666 1.397 0 2.702-.237 3.674-.666C20.516 5.767 21 5.307 21 4.893c0-.414-.484-.875-1.296-1.233-.972-.429-2.277-.665-3.674-.665-1.396 0-2.701.236-3.674.665-.811.358-1.296.819-1.296 1.233 0 .414.485.874 1.296 1.232z"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.115 21.005a5.12 5.12 0 1 0 0-10.241 5.12 5.12 0 0 0 0 10.24zM6.895 18.2c.447.222.949.333 1.505.333.512 0 .969-.087 1.37-.26.401-.174.732-.425.993-.753l-1.121-.963c-.326.396-.71.594-1.151.594-.371 0-.67-.114-.896-.34-.225-.232-.338-.54-.338-.927 0-.386.113-.692.338-.919.226-.231.525-.347.896-.347.441 0 .825.198 1.151.593l1.121-.962a2.53 2.53 0 0 0-.993-.753 3.417 3.417 0 0 0-1.37-.26c-.556 0-1.058.113-1.504.34a2.57 2.57 0 0 0-1.054.94c-.25.4-.376.857-.376 1.368 0 .512.125.97.376 1.375.256.4.607.714 1.054.94z"></path>
                    </svg>
                  </span>
                                </div>
                                <div class="ui-button__label"> Get VIP Now</div>
                            </button>
                        </div>
                    </div>
                ),
                toContactId: toContactId,
                sendTime: getTime(),
            };
            IMUI.appendMessage(message, true);
        },
        updateContact() {
            this.$refs.IMUI.updateContact({
                id: "contact-3",
                unread: 10,
                displayName: generateRandWord(),
                lastSendTime: getTime(),
                lastContent: "修改昵称为随机字母",
            });
        },
        changeDrawer(contact, instance) {
            this.contactInfo = contact
            instance.changeDrawer({
                // width: 240,
                // height: "90%",
                // offsetX:0 ,
                // offsetY: 0,
                position: this.simple ? "rightInside" : "right",
                // inside: true,
                // offsetX: -280,
                // offsetY: -100,
                render: () => {
                    return (
                        ""
                    );
                },
            });
        },
        // 改变聊天窗口
        handleChangeContact(contact, instance) {
            // console.log("改变聊天窗口");
            this.contactInfo = contact;
            if (this.simple) {
                document.querySelector('.lemon-sidebar').style.display = "none"
            }
            instance.clearMessages();

            requestAnimationFrame(() => {
                if (!this.isVIP) {
                    this.appendEventMessage(contact.id)
                }
                instance.messageViewToBottom();
            })
            // setTimeout(() => {
            //   if (!this.isVIP) {
            //     this.appendEventMessage(contact.id)
            //   }
            //   instance.messageViewToBottom();
            // }, 100);
            instance.closeDrawer();
        },

        openedDialog() {
            this.video.play();
        },
        closeDialog() {
            this.video.stop();
        },
        //点击消息
        handleMessageClick(e, key, message, instance) {
            // console.log("点击消息", key, message);
            if (key === 'avatar') {
                this.$router.push(`/memberinfo/${message.fromUser.id}`)
            }
            if (message.type == "image") {
                // 如果不是VIP或已到期，提示跳转充值页面
                if (!this.isVIP && this.userinfo.id !== message.fromUser.id) {
                    this.$confirm(i18n.t('notVIP'), i18n.t("warning"), {
                        confirmButtonText: i18n.t("confirm"),
                        cancelButtonText: i18n.t("cancel"),
                        center: true,
                        type: 'warning'
                    }).then(() => {
                        this.jump("/benefits")
                    }).catch(() => {

                    });
                    return
                }
                this.viewerList.push(message.content);
                this.showViewer = true;
            }
            if (message.type == "gift") {
                instance.updateMessage({
                    id: message.id,
                    open: 1,
                });
                message.open = 1;
            }
            if (message.type == "video") {
                // 查找视频真实地址
                this.$http
                    .get("/message/get", {params: {id: message.id}})
                    .then((res) => {
                        if (res.code == 1) {
                            this.videoUrl = this.cdnurl(res.data.file);
                            this.playVisible = true;
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error",
                            });
                        }
                    });
            }
        },
        checkWrap(e) {
            if (e.keyCode == 13 && e.ctrlKey) {
                return true;
            }
        },
        checkSend(e) {
            if (e.keyCode == 13 && e.ctrlKey == false && e.shiftKey == false) {
                return true;
            }
        },
        // 发送事件
        handleSend(message, next, file) {
            let that = this;
            const {IMUI} = that.$refs;
            message.translateLang = this.currentLanguageCode;
            that.$http.post("/websocket/send", message).then((res) => {
                if (res.code == 1) {
                    message.status = "succeed";
                    message.id = res.data.id;
                    message.translatedContent = res.data.translatedContent
                    message.content = message.translatedContent ? message.translatedContent : message.content
                    IMUI.updateContact({
                        id: message.toContactId,
                        unread: 0,
                    });
                    that.setUnreadMessageCount(res.data.notReadNum)
                    that.setResidue(res.data.residue)
                    next();
                    if (!this.isVIP) {
                        that.appendEventMessage(message.toContactId)
                    }
                    // 更新亲密度
                    that.intimacyInfo.sendNum += 1
                    if (that.intimacyInfo.sendNum > that.intimacyInfo.intervalNum) {
                        that.intimacyInfo.sendNum = that.intimacyInfo.intervalNum
                    }
                    that.intimacyInfo.percentage = that.intimacyInfo.sendNum / that.intimacyInfo.intervalNum * 100
                    IMUI.messageViewToBottom();
                } else if (res.code == 0) {
                    IMUI.removeMessage(message.id);
                    IMUI.updateContact({
                        id: message.toContactId,
                        lastContent: " ",
                    });
                    that.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error",
                    });
                } else {
                    IMUI.removeMessage(message.id);
                    IMUI.updateContact({
                        id: message.toContactId,
                        lastContent: " ",
                    });
                }
            });
        },
        // 获取消息
        handlePullMessages(contact, next, instance) {
            this.setIsShowTabbar(false)
            //console.log("获取消息");
            let start = instance.getMessages(instance.currentContactId).length;

            let limit = 20;
            // const messages = ChatJs.getMessageList(contact.id, start, limit);
            // let isEnd = false;
            // if (messages.length < limit) {
            //   isEnd = true
            // }
            // next(messages, isEnd);
            this.$http
                .post("/message/getList", {
                    contacts_id: contact.id,
                    page: start / limit + 1,
                    limit: limit,
                })
                .then((res) => {
                    // 保存当前联系人的信息
                    this.contactInfo = contact
                    if (res.code == 1) {
                        // 更新与当前联系人的亲密值
                        this.getIntimacy(contact.id)
                        let isEnd = false;
                        if (res.data == null) {
                            res.data = []
                        }
                        if (res.data.length < limit) {
                            isEnd = true;
                        }
                        for (let i = 0; i < res.data.length; i++) {
                            res.data[i].fromUser.avatar = cdnurl(res.data[i].fromUser.avatar)
                            if (res.data[i].type != 'text' && res.data[i].type != 'transfer') {
                                res.data[i].content = cdnurl(res.data[i].content)
                            }
                        }
                        res.data.sort((a, b) => {
                            return a.sendTime - b.sendTime;
                        });

                        next(res.data, isEnd);
                    } else {
                    }
                });
        },
        handleChangeMenu(name) {
            const {IMUI} = this.$refs;
            IMUI.closeDrawer();
            console.log("Event:change-menu", name);
        },
        openCustomContainer() {
        },
        querySearch(queryString, cb) {
            var restaurants = this.contacts_list;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据

            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.displayName.toLowerCase().includes(queryString.toLowerCase()) ||
                    restaurant.id.toString().toLowerCase().includes(queryString.toLowerCase())
                );
            };
        },
        searchHandleSelect(item) {
            const {IMUI} = this.$refs;
            IMUI.changeContact(item.id);
        },
    },
    created() {
        let userInfo = Auth.getToken();
        this.userInfo = {
            id: userInfo.id,
            displayName: userInfo.nickname,
            avatar: this.cdnurl(userInfo.avatar),
        };
        this.newMessageNotice =
            localStorage.getItem("newMessageNotice") == "false" ? false : true;
        this.OpenChatRoundAvatar =
            localStorage.getItem("OpenChatRoundAvatar") == "false" ? false : true;
        this.WhetherToHideContactNames =
            localStorage.getItem("WhetherToHideContactNames") == "true"
                ? true
                : false;
        this.WhetherToHideTime =
            localStorage.getItem("WhetherToHideTime") == "true" ? true : false;
        this.currentLanguageCode = localStorage.getItem("currentLanguageCode" + this.userInfo.id)

        // this.getPricing() // 付费价格
        // 更新与当前联系人的亲密值
        this.getIntimacy()

    },
    beforeDestroy() {
        let that = this;
    },
};
</script>

<style>
#app .scroll-top {
    cursor: pointer;
    position: fixed;
    bottom: 40px;
    left: 50%;
    border-radius: 50%;
    background: #fff;
    font-size: 18px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    line-height: 40px;
    user-select: none;
    text-align: center;
    transform: rotate(-45deg) translateX(-50%);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

#app .scroll-top:hover {
    font-size: 22px;
}

a {
    color: #0c5ed9;
    text-decoration: none;
    font-size: 12px;
}

.action {
    margin-top: 20px;
}

.action .lemon-button {
    margin-right: 10px;
    margin-bottom: 10px;
}

.link {
    font-size: 14px;
    margin-top: 15px;
}

.link a {
    display: inline-block;
    margin: 0 5px;
    text-decoration: none;
    background: #ffba00;
    border-radius: 4px;
    padding: 5px 10px;
    color: rgba(0, 0, 0, 0.8);
}

.logo {
    position: relative;
    display: inline-block;
    margin: 60px auto;
    user-select: none;
}

.logo-text {
    font-size: 38px;
}

.logo-sub {
    font-size: 18px;
    color: #999;
    font-weight: 300;
}

.logo-badge {
    position: absolute;
    top: -10px;
    left: 230px;
    background: #000;
    border-radius: 16px;
    color: #f9f9f9;
    font-size: 12px;
    padding: 4px 8px;
}


.table {
    width: 100%;
    border-radius: 10px;
    background: #fff;
    border-collapse: collapse;
}

.table tr {
    cursor: pointer;
}

.table tr:not(.table-head):hover {
    background: #ffba00 !important;
}

.table tr:nth-of-type(even) {
    background: #f9f9f9;
}

.table th {
    user-select: none;
    color: #999;
}

.table td,
.table th {
    text-align: left;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: normal;
}

.lemon-wrapper {
    /* border: 1px solid #ddd; */
    width: 100% !important;
    min-height: calc(100vh - 230px);
    border-radius: 3px;
}

.lemon-drawer {
    border: 1px solid #ddd;
    border-left: 0;
}

.drawer-content {
    padding: 15px;
}

.bar {
    text-align: center;
    line-height: 30px;
    background: #fff;
    margin: 15px;
    color: #666;
    user-select: none;
    font-size: 12px;
}

.cover {
    text-align: center;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cover i {
    font-size: 84px;
    color: #e6e6e6;
}

.cover p {
    font-size: 18px;
    color: #ddd;
    line-height: 50px;
}

.article-item {
    line-height: 34px;
    cursor: pointer;
}

.article-item:hover {
    text-decoration: underline;
    color: #318efd;
}

pre {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
}

.lemon-container {
    background-color: #fff !important;
    white-space: normal !important;
}

.lemon-container__title {
    border-bottom: 1px solid #efefef;
    display: flex;
}

.lemon-simple .lemon-container {
    z-index: 5;
}

.lemon-simple .lemon-drawer {
    z-index: 4;
}

input#switch[type="checkbox"] {
    height: 0;
    width: 0;
    display: none;
}

label#switch-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 34px;
    height: 20px;
    background: #aaa;
    display: block;
    border-radius: 100px;
    position: relative;
}

label#switch-label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 20px;
    transition: 0.3s;
}

input#switch:checked + label {
    background: #0fd547;
}

input#switch:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

label#switch-label:active:after {
    width: 20px;
}

.lemon-editor {
    border-top: 1px solid #efefef;
}

.lemon-editor__emoji-item {
    width: 28px !important;
}

.lemon-contact {
    position: relative;
    border-bottom: 1px solid #e1e1e1;
}

.lemon-sidebar__label {
    background-color: #dfdfdf;
}

.lemon-contact-vip-icon {
    position: absolute;
    right: 7px;
    top: 0;
    font-size: 30px !important;
    color: #1538a2;
}

.lemon-contact__time {
    position: relative;
}

.lemon-message-vip-icon {
    font-size: 30px !important;
    color: #1538a2;
    position: absolute;
    right: 0;
    top: 20px;
}

.lemon-message__content {
    padding: 8px 10px;
    background: #f3f3f3 !important;
}

.lemon-message__content:before {
    border-right-color: #f3f3f3 !important;
}

.lemon-message--reverse .lemon-message__content {
    background: #35d863 !important;
}

.lemon-contact__content {
    width: 170px;
}

.lemon-contact__content_span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 140px;
    display: inline-block;
}

.select-image .el-dialog__header {
    padding: 20px 20px 0px;
}

.select-image .el-dialog__header .el-dialog__title {
    line-height: 0px !important;
    font-size: 16px !important;
}

.lemon-editor__tool-item [class^="icon-al-"] {
    line-height: 26px;
    font-size: 22px;
}

.lemon-menu {
    background: linear-gradient(to top, #2039a5, white) !important;
}

.lemon-menu__item--active {
    color: #1538a2 !important;
}

.lemon-sidebar__fixed-top {
    margin: 10px;
}

.search-item {
    padding: 5px !important;
}

.setting-switch {
    margin: 20px 20px 20px 0;
}

.toolButton {
    padding: 5px 10px;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    font-size: 14px;
    border-radius: 4px;
    color: #3B5998;
    font-weight: bold;
}

.toolButton:hover {
    background-color: #3B5998;
    border: 1px solid #3B5998;
    color: #fff;

}

.lemon-editor {
    height: 150px !important;
}

.lemon-editor__tool {
    padding: 0 !important;
}

.lemon-editor__input {
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    outline: none;
    box-shadow: inset 0px 0px 5px 0px #3B5998;
    padding: 10px 10px;
    border-radius: 5px;
}

.lemon-editor__inner {
    padding: 10px !important;
}

.lemon-wrapper img {
    vertical-align: sub !important;
    border-style: none;
}

.lemon-message-text .lemon-message__content span img {
    width: 30px !important;
    height: 30px !important;
}

.chat-footer-div {
    float: left;
    margin-left: 20px;
}

/* .lemon-editor__footer {
  justify-content: left !important;
  position: relative;
  background-color: #fafafa;
  height: 60px !important;
} */
.lemon-editor__footer {
    position: relative;
}

.lemon-editor__submit {
    position: absolute;
    right: 10px;
    top: -10px;
    height: 90px;
    transform: translate(0, -100%);
}

.lemon-editor__submit button {
    height: 100%;
    background-color: #3B5998;
    color: #fff;
    border: none;
}

.lemon-editor__submit button[disabled] {
    height: 100%;
    background-color: #3B5998;
    color: #fff;
    opacity: 0.5;
    border: none;
}

.lemon-editor__submit button:hover {

    color: #fff !important;
}

.lemon-editor__inner {
    width: calc(100% - 80px);
}

.lemon-editor__tip {
    width: 100%;
    margin-right: 0 !important;
}

.el-timeline-item__content {
    word-break: normal
}

.chat-tips-1 {
    font-weight: 600;
    color: #666666;
}

.chat-tips-2 {
    font-weight: 400;
    color: #6c6c6c;
}

.el-autocomplete-suggestion li {
    padding: 0 !important;
}

.show-contacts {
    display: none !important
}

.lemon-editor__footer {
    height: 0 !important;
}

@media only screen and (max-width: 767px) {
    .lemon-container {
        width: 100% !important;

    }

    .lemon-vessel {
        width: 100% !important;
    }

    .lemon-vessel__left {
        width: 100% !important;
    }

    .lemon-editor__tool-item {
        padding: 5px 5px !important;
    }


    .lemon-wrapper--simple .lemon-sidebar {
        width: 100% !important;
        background-color: #fff
    }

    /* .lemon-editor__footer {
      height: 80px !important;
    } */
    .lemon-editor__submit {
        height: 40px;
    }

    .show-contacts {
        display: inline-block !important;
        font-size: 24px;
        padding-right: 20px;
        font-weight: bold !important;
        padding-top: 10px;
    }

    .lemon-editor__emoji .lemon-popover {
        left: 0 !important
    }

    .lemon-editor {
        position: fixed !important;
        left: 0;
        bottom: 0;
        z-index: 100;
        width: 100%;
        height: auto !important;
        max-height: 200px;
        background-color: #fff;
    }

    .lemon-editor__footer {
        height: 0 !important;
    }

    .lemon-custom {
        min-height: calc(100vh - 60px - 50px);
    }

    .lemon-container__title {
        padding: 5px 5px !important;
    }

}
</style>

<style lang="scss" scoped>
.card-header {
    height: 60px;
    padding: 0 15px;
    border: 1px solid #ebebeb;
    border-bottom: none;

    .card-header-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.lemon-contact__avatar img {
    object-fit: cover;
}

.more {
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    position: absolute;
    top: 22px;
    right: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #080808;
    display: inline-block;
    border-radius: 4px;
    padding: 0 8px;
    background: unset !important;
}

.el-dropdown-menu {
    overflow: auto;
    max-height: 200px;
}

.lemon-vip {
    font-size: 24px;
    color: #1538a2;
}

.lemon-custom {
    .toolImg {
        width: 25px;
        height: 25px;
    }

    .lemon-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: none !important;
        font-size: 20px;
        cursor: pointer;

        .status {
            position: absolute;
            top: 25px;
            left: 25px;
        }
    }

    ::v-deep(.lemon-message-image) {
        .lemon-message__content {
            width: 120px;
            height: 120px;
            padding: 8px 10px;
            filter: brightness(90%);
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    ::v-deep(.lemon-editor) {
        padding-left: 10px;

        .lemon-editor__tool {
            width: 100%;
            margin-left: -10px;
            flex-shrink: 0;
        }

        .lemon-editor__inner {
            box-sizing: border-box;
            margin-top: 10px;
            margin-right: 0;
            margin-bottom: 10px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 5px 0px #3B5998;
            user-select: auto;

            &::-webkit-scrollbar {
                width: 0;
            }

            .lemon-editor__input {
                padding: 0 !important;
                box-shadow: none;
            }
        }
    }
}

.drawer-li {
    margin-bottom: 10px;
    cursor: pointer;
}

.title {
    font-size: 24px;
    line-height: 26px;
    border-left: 1px solid #ffba00;
    padding-left: 15px;
    margin-bottom: 15px;
    margin-top: 30px;
    user-select: none;
}

.low-balance-inline__wrapper {
    // margin: -5px -10px;
    // padding: 21px 10px 25px;
    // background-image: linear-gradient(180deg,#62b5e566,#0083cf66);

    .low-balance__wrapper {
        width: 323px;
        margin: auto;
        color: #212529;

        .low-balance__images-smile {
            z-index: 1;
        }

        .low-balance__images-avatar {
            position: relative;
            z-index: 0;
            left: -20px;
            width: 56px;
            height: 56px;
            border: 1px solid var(--blue-10);
            border-radius: 50%;
        }

        .low-balance__heading {
            font-size: 20px;
            font-weight: 700;
        }

        .low-balance__message {
            text-align: center;
            font-size: 16px;
        }

        .low-balance__button {
            position: relative;
            font-size: 16px;
            font-family: Open Sans, sans-serif;

            @keyframes pulse {
                70% {
                    opacity: 0;
                    box-shadow: 0 0 0 14px #fff
                }

                to {
                    opacity: 0;
                    box-shadow: 0 0 0 0 #fff
                }
            }

            &:before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                content: "";
                animation: pulse 1.5s infinite;
                opacity: .5;
                border-radius: 4px;
                box-shadow: 0 0 0 0 #fff;
            }
        }
    }
}
</style>