var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.PanelContainer,{attrs:{"visible":_setup.isDialogVisible,"title":_setup.t('Contacts')},on:{"input":function($event){_setup.isDialogVisible = $event}},scopedSlots:_vm._u([(_setup.props.isMobile)?{key:"footer",fn:function(){return [_c('div',{staticClass:"contact-footer"},[_c('div',{staticClass:"chosen-member"},[(_setup.selectedContacts.length)?_c('span',[_vm._v(" "+_vm._s(_setup.t('x people selected', { number: _setup.selectedContacts.length }))+" ")]):_vm._e()]),_c(_setup.TuiButton,{attrs:{"disabled":!_setup.selectedContacts.length},on:{"click":_setup.confirm}},[_vm._v(" "+_vm._s(_setup.t('Confirm') + `(${_setup.selectedContacts.length})`)+" ")])],1)]},proxy:true}:null],null,true)},[_c('div',{class:['contacts-container', _setup.props.isMobile && 'h5'],on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"contact"},[_c('div',{staticClass:"contact-search"},[_c(_setup.TuiInput,{staticClass:"contact-search-input",attrs:{"placeholder":_setup.t('Please enter the member name'),"search":_setup.searchContact},on:{"focus":element => {
              element.classList.add('focus');
            },"blur":element => {
              element.classList.remove('focus');
            }},scopedSlots:_vm._u([{key:"suffixIcon",fn:function(){return [_c(_setup.SearchIcon,{staticClass:"search-icon",on:{"mousedown":function($event){$event.preventDefault();}}})]},proxy:true}]),model:{value:(_setup.searchValue),callback:function ($$v) {_setup.searchValue=$$v},expression:"searchValue"}})],1),_c('div',{staticClass:"contact-list"},[(_setup.showList.length === 0 && _setup.searchValue)?_c('div',{staticClass:"no-result"},[_vm._v(" "+_vm._s(_setup.t('No relevant members found'))+" ")]):_vm._l((_setup.showList),function(item){return _c('div',{key:item.userInfo.userID,class:[
              'contact-list-item',
              item.disabled && 'contact-list-item-disabled',
            ]},[_c(_setup.TuiCheckbox,{staticClass:"contact-list-item-checkbox",attrs:{"model-value":item.selected,"disabled":item.disabled},on:{"input":function($event){item.selected = $event}}},[_c('span',{staticClass:"contact-list-item-checkbox-container"},[_c(_setup.TuiAvatar,{staticClass:"contact-list-item-avatar",attrs:{"img-src":item.userInfo.profile.avatar}}),_c('p',{staticClass:"contact-list-item-name",attrs:{"title":item.userInfo.profile.nick}},[_vm._v(" "+_vm._s(item.userInfo.profile.nick || item.userInfo.profile.userID)+" ")])],1)])],1)})],2)]),(!_setup.props.isMobile)?_c('div',{staticClass:"chosen"},[_c('div',{staticClass:"chosen-title"},[_vm._v(" "+_vm._s(_setup.t(`Selected Contact`) + `(${_setup.selectedContacts.length})`)+" ")]),_c('div',{staticClass:"chosen-list"},_vm._l((_setup.selectedContacts),function(item){return _c('div',{key:item.userInfo.userID,staticClass:"chosen-list-item"},[_c(_setup.TuiAvatar,{staticClass:"chosen-list-item-avatar",attrs:{"img-src":item.userInfo.profile.avatar}}),_c('p',{staticClass:"chosen-list-item-name",attrs:{"title":item.userInfo.profile.nick}},[_vm._v(" "+_vm._s(item.userInfo.profile.nick || item.userInfo.profile.userID)+" ")]),_c(_setup.CloseIcon,{staticClass:"chosen-list-item-remove",on:{"click":function($event){return _setup.removeSelectUser(item)}}})],1)}),0),_c('div',{staticClass:"chosen-footer"},[_c(_setup.TuiButton,{staticClass:"chosen-footer-button",attrs:{"type":"primary"},on:{"click":_setup.cancel}},[_vm._v(" "+_vm._s(_setup.t('Cancel'))+" ")]),_c(_setup.TuiButton,{staticClass:"chosen-footer-button",on:{"click":_setup.confirm}},[_vm._v(_vm._s(_setup.t('Confirm')))])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }