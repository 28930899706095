<script>
import {mapState, mapGetters} from 'vuex'

export default {
    name: "lemonMessageTransfer",
    inheritAttrs: false,
    inject: ["IMUI"],
    computed: {
        ...mapState({
            userinfo: 'userinfo',
        }),
        ...mapGetters({
            systemVersion: 'getSystemVersion',
        })
    },
    created() {
        //console.log('this.systemVersion', this.systemVersion)
    },
    render() {
        return (
            <lemon-message-basic
                class="lemon-message-transfer"
                props={{...this.$attrs}}
                scopedSlots={{
                    content: props => {
                        if (this.userinfo.id === props.fromUser.id) {
                            return (
                                <p class="selfSend">You
                                    transferred ${props.content} to the
                                    other party</p>
                            )
                        } else {
                            return (
                                <p class="otherSend">The other party
                                    transferred ${props.content} to you</p>
                            )
                        }
                    }
                }}
            />
        );
    }
};
</script>
<style lang="scss" scoped>
.selfSend {
    color: #ffff00;
}

.otherSend {
    color: #bfbf00;
}
</style>
