<template>
    <div class="">
        <div class="main-wrapper">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="18" :md="18">
                    <ChatView :id="id"></ChatView>
                </el-col>
                <el-col :xs="24" :sm="6" :md="6">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import RightSiderBar from '@/page/components/common/RightSiderBar.vue'
import ChatView from '@/components/mobile/chat/ChatView.vue'
export default {
    name: 'Message',
    data: function () {
        return {
            id: null,
        }
    },
    components: {
        ChatView,
        RightSiderBar
    },
    created() {
        let query = this.$route.query;
        this.id = query.id || this.id
    },
}
</script>