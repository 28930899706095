<template>
    <div class="send-mail" style="margin-bottom:20px">
        <el-form :model="mailForm" ref="mailForm" class="mailForm">
            <el-input class="send-content" v-model="mailForm.content" type="textarea"
                :placeholder="$t('TypeYourTextHere')"></el-input>
            <div class="attach" style="margin-top: 20px;">
                <!-- 已选照片 开始 -->
                <el-row style="padding: 0;" :gutter="20">
                    <el-col class="attach-list" :span="isMobile ? 8 : 4" v-for="(image, index) in mailForm.images" :key="index">
                        <el-image style="width: 100%; height: 100px" :src="image | cdnurl" fit="contain"
                            :preview-src-list="[cdnurl(image)]"></el-image>
                        <i class="icon-al-delete" @click="deleteItem('image', index)"></i>
                    </el-col>
                </el-row>
                <!-- 已选照片 结束 -->
                <!-- 已选视频 开始 -->
                <el-row style="padding: 0;" :gutter="20">
                    <el-col class="attach-list" :span="4" v-for="(video, index) in mailForm.videos" :key="index">
                        <el-image style="width: 100%; height: 100px" :src="video | cdnurl" fit="contain"></el-image>
                        <i class='icon-al-bofang' @click="play(video)"></i>
                        <i class="icon-al-delete" @click="deleteItem('video', index)"></i>
                    </el-col>
                </el-row>
                <!-- 已选视频 结束 -->
            </div>
            <el-alert style="margin-top: 10px;padding: 5px;" type="warning" :closable="false" class="mail-alert">
                <!-- 移动端样式 开始 -->
                <el-col :xs="24" :sm="12" style="padding: 0;text-align: right;" class="hidden-sm-and-up">
                    <el-button @click="send()" style="float:right ;margin-left: 20px;" size="medium" :disabled="mailForm.content == ''" type="primary">
                        {{ $t('SendMail') }}
                    </el-button>
                    <!-- <el-upload class="uploadAtta" :action="uploadUrl" :headers="headers" :before-upload="beforeUpload"
                        :on-success="uploadSuccess" multiple :show-file-list="false" accept="image/*,video/*">
                        <el-button size="medium"><i class="icon-al-round-add_a_photo-"></i></el-button>
                    </el-upload> -->
                    <el-button size="medium" @click="photoVisible = true"><i class="icon-al-round-add_a_photo-"></i></el-button>
                </el-col>
                <el-col :xs="24" :sm="12" class="tip-col">
                    {{ $t('UploadImgTips') }}<br>
                    {{ $t('UploadVideoTips') }}
                </el-col>
                <!-- 移动端样式 结束 -->
                <!-- pc端样式 开始 -->
                <el-col :xs="24" :sm="12" style="padding: 0;text-align: center;" class="hidden-xs-only">
                    <el-button @click="send()" style="float:right ;" size="medium" :disabled="mailForm.content == ''" type="primary">
                        {{ $t('SendMail') }}
                    </el-button>
                    <!-- <el-upload class="uploadAtta" :action="uploadUrl" :headers="headers" :before-upload="beforeUpload"
                        :on-success="uploadSuccess" multiple :show-file-list="false" accept="image/*,video/*">
                        <el-button size="medium"><i class="icon-al-round-add_a_photo-"></i></el-button>
                    </el-upload> -->
                    <el-button size="medium" @click="photoVisible = true"><i class="icon-al-round-add_a_photo-"></i></el-button>
                </el-col>
                <el-col :xs="24" :sm="24" class="tip-col">
                    {{ $t('firstMailCosts') }} 
                    <span class="f-w-b"> 
                        {{ $t('HowManyCredits', { digit: Pricing.send_mails_first, s: Pricing.send_mails_first > 1 ? "s" : "" }) }} 
                    </span>
                    , {{ $t('eachFollowingMail') }} 
                    <span class="f-w-b">
                        {{ $t('HowManyCredits', { digit: Pricing.send_mails_price, s: Pricing.send_mails_price > 1 ? "s" : "" }) }}
                    </span>
                </el-col>
                <!-- pc端样式 结束 -->
            </el-alert>

            <!-- 播放视频浮层 -->
            <el-dialog :visible.sync="videoVisible" append-to-body width="40%" custom-class="player-video"
                @close="closeDialog" @opened="openedDialog">
                <player-video :src="videoUrl" :volume="volume" ref="video"></player-video>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="videoVisible = false">{{ $t('close') }}</el-button>
                </div>
            </el-dialog>

            <el-dialog title="Select a photo or video" :visible.sync="photoVisible" :width="isMobile ? '95%' : '750px'"
                custom-class="select-file">
                <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
                    <el-tab-pane label="Select photos" name="image">
                        <CheckPhoto ref="imageComponent"></CheckPhoto>
                    </el-tab-pane>
                    <el-tab-pane label="Select videos" name="video">
                        <CheckVideo ref="videoComponent"></CheckVideo>
                    </el-tab-pane>
                </el-tabs>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelFile">{{ $t("cancel") }} </el-button>
                    <el-button type="primary" @click="handleAppendMailForm">
                        {{ $t("confirm") }}
                    </el-button>
                </span>
            </el-dialog>
        </el-form>
    </div>
</template>    
<script>
import { mapState } from 'vuex'
import CheckPhoto from "@/page/components/chat/check-photo.vue";
import CheckVideo from "@/page/components/chat/check-video.vue";
import { cdnurl } from '../../../filters';
import setting from '../../../config/setting'
import Auth from '../../../services/auth'
import PlayerVideo from "../common/player-video.vue";
import i18n from "../../../lang";
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        reply_id:{
            type: Number,
        }
    },
    name: 'send',
    components: { 
        CheckPhoto,
        CheckVideo,
        PlayerVideo 
    },
    data: function () {
        return {
            userinfo: [],

            mailForm: {
                reply_id:0,
                to_user_id: 0,
                content: "",
                images: [],
                videos: [],

            },
            uploadUrl: setting.apiUrl + "/common/upload",
            headers: {
                token: Auth.getToken().token
            },
            showViewer: false,
            viewerList: [],
            viewerIndex: 0,
            Pricing: [],
            videoUrl: "",
            videoVisible: false,
            volume: 0.5,

            activeName: 'image',
            photoVisible: false,
            fileInfo: {},
        }
    },
    computed: {
        ...mapState({
            isMobile: 'isMobile'
        }),
        video() {
            return this.$refs.video;
        },
    },
    methods: {
        handleAppendMailForm() {
            const checkedUrl = [...this.$refs[this.activeName + 'Component'].checkedUrl]
            if (checkedUrl.length) {
                if (this.activeName === 'image') {
                    this.mailForm.images.push(...checkedUrl)
                } else {
                    this.mailForm.videos.push(...checkedUrl)
                }
            }
            this.photoVisible = false
            this.$refs[this.activeName + 'Component'].checkedId = []
            this.$refs[this.activeName + 'Component'].checkedUrl = []
        },
        cancelFile() {
            this.fileUrl = "";
            this.photoVisible = false;
        },
        handleTabClick() {
            
        },
        beforeUpload(file) {
            if (file.type.indexOf("image") != -1) {
                if (!this.validUploadFile("img", file)) {
                    return false
                }
            }
            if (file.type.indexOf("video") != -1) {
                if (!this.validUploadFile("video", file)) {
                    return false
                }
            }
        },

        uploadSuccess(res, file, fileList) {
            let that = this;
            if (res.code == 1) {
                let url = res.data.url;
                if (file.raw.type.indexOf("image") != -1) {
                    that.mailForm.images.push(url)
                }
                if (file.raw.type.indexOf("video") != -1) {
                    that.mailForm.videos.push(url)
                }
            } else {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: 'error'
                });
            }
        },
        imageuploadExceed(files, fileList) {
            this.$message({
                showClose: true,
                message: this.$t("imageuploadExceed"),
                type: 'error'
            });
        },
        getPricing() {
            this.$http.get('/credits/getPricing', {})
                .then((res) => {
                    if (res.code == 1) {
                        this.Pricing = res.data
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        deleteItem(type, index) {
            let that = this
            if (type == "image") {
                that.mailForm.images.splice(index, 1)
            }
            if (type == "video") {
                that.mailForm.videos.splice(index, 1)
            }
        },
        play(url) {
            this.videoUrl = this.cdnurl(url);
            this.videoVisible = true;
        },
        openedDialog() {
            this.video.play();
        },
        closeDialog() {
            this.video.stop();
        },
        send() {
            let that = this
            that.$http.post('/mail/sendMail', that.mailForm)
                .then((res) => {
                    if (res.code == 1) {
                        that.mailForm.content = ""
                        that.mailForm.images = []
                        that.mailForm.videos = []
                        that.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'success'
                        });

                    }  else {
                        that.$message.error(res.msg);
                    }

                })
        },
        jump(url) {
            this.$router.push(url)
        },
    },
    created() {
        let that = this;
        that.mailForm.to_user_id = that.id
        that.mailForm.reply_id = that.reply_id
        this.getPricing()
    },
}
</script>
<style>
.send-content .el-textarea__inner {
    min-height: 300px !important;
}

.uploadImage,
.uploadVideo {
    margin-top: 20px;
}

.uploadImage .el-upload-list {
    width: 100%;
}

.uploadImage .el-upload-list__item {
    width: 30% !important;
}

.uploadImage .el-upload {
    width: 30% !important;
}

.el-upload-list__item-thumbnail {
    object-fit: cover;
}

.mailForm .el-alert__content,
.mailForm .el-alert__content .el-alert__description {
    width: 100%;
}

.uploadAtta {
    display: inline-block;
    margin-right: 5px;
    float: right;
}

.attach .attach-list {
    position: relative;
}

.attach .icon-al-delete {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: gray;
}

.attach .icon-al-delete:hover {
    color: red;
}

.attach .icon-al-bofang {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: rgb(19 50 146 / 19%);
    font-size: 26px;
    border-radius: 50%;
    padding: 10px;
    color: #f7f7f7;
    cursor: pointer;
}
</style>
  
<style lang="scss" scoped>
.send-mail {
    ::v-deep(.select-file) {
        margin-top: 6vh !important;
        margin-bottom: 0;

        .el-dialog__body {
            height: calc(100vh - 250px);
            padding-top: 10px;
        }

        .photo-wrapper {
            height: calc(100vh - 430px);
        }

        .video-wrapper {
            height: calc(100vh - 430px);
        }
    }
}

.mail-alert {

    .tip-col {
        margin-top: 10px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
</style>