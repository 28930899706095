import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhOrder from './order/zh'
import zhMessage from './message/en'
export default {
  ...zhOrder,
  ...zhMessage,
  home: '首页',
  name: '我是老六',
  login: "立即登录",
  logout: "退出登录",
  message: "聊天",
  search: "搜索",
  language: "语言",
  DynamicSquare: "动态",
  GiftOrder: "礼物订单",
  usernameLogin: "邮箱",
  usernameLoginRequire: "请输入用户名",
  usernameLoginRule: "用户名必须为6到30个字符",
  password: "密码",
  OldPassword:"旧密码",
  NewPassword:"新密码",
  repassword: "确认密码",
  passwordLoginRequire: "请输入密码",
  oldPasswordRequire: "请输入旧密码",
  newPasswordRequire: "请输入新密码",
  reNewPasswordRequire: "请再次输入新密码",
  repasswordRequire: "请再次输入密码",
  repasswordRule: "两次密码输入不一致",
  passwordLoginRule: "密码必须为6到30个字符",
  email: '电子邮箱',
  emailRegister: "请输入电子邮箱",
  emailRule: "邮箱格式不正确",
  nickname: '昵称',
  nicknameRegister: "请输入昵称",
  nicknameRule: "昵称不能为空",
  birthday: "生日",
  birthdayRule: "请选择出生日期",
  registerCode: "验证码",
  registerCodeRule: "验证码不能为空",
  register: "注册账号",
  forgotPassword: "忘记密码?",
  warning: "提示",
  send: "发送",
  waitBtnText: "{second} 秒",
  sendAgain: "重新获取",
  resetPwd: "重置密码",
  submit: "提交",
  registerSignInNow: "已注册?   立即登录!",
  signInNow: "立即登录",
  notNeedLogin: "您已登录,请不要重复登录",
  ageText: "{age}岁",
  DataIsEmpty: "未匹配到数据",
  FindABosomFriend: "寻找知音",
  trySearch: "试试搜索:用户名或者用户ID",
  MinimumAge: "最低年龄",
  MaximumAge: "最大年龄",
  MinimumHeight: "最低身高",
  MaximumHeight: "最大身高",
  MinimumWeight: "最低体重",
  MaximumWeight: "最大体重",
  Occupation: "职业",
  CancelFollow: "确认取消该关注?",
  cancel: "取消",
  confirm: "确认",
  emoji: "表情",
  ResendingMessage: "正在重新发送消息...",
  NewMsgNotice: "您有新消息",
  SaySomething: "说点什么吧?",
  Today: "今天",
  NoMore: "暂无更多",
  ReadMore:"更多",
  ShowLess:"收起",
  SelectImage: "选择图片",
  SelectVideo: "选择视频",
  SelectGift:"选择礼物",
  SelectSticker:"选择贴纸",
  image: "图片",
  video: "视频",
  voice: "语音",
  sendTips: "使用 Ctrl + Enter 快捷发送消息",
  public: "公开",
  privacy: "隐私",
  close:"关闭",
  deleteFileConfirm:"此操作将永久删除该文件, 是否继续?",
  PublishingContent:"发布内容",
  PublishingThink:"此刻的想法",
  JustPublished:"刚刚",
  PublishedAgo:" {nums} {unit}前",
  hour:"小时",
  minute:"分钟",
  Like:"赞",
  Wink:"打招呼",
  Winked:"打招呼",
  SendWink: "打招呼",
  Comments:"评论",
  ClearChatRecord:"清空聊天记录",
  DeleteContact:"删除联系人",
  DeleteContactConfirm:"此操作将删除本地联系人及聊天记录,是否继续?",
  SearchContacts:"输入用户名或ID搜索",
  Contacts:"联系人",
  ChatSettings:"聊天设置",
  EnableNewMessageVoiceReminder:"开启新消息声音提醒",
  OpenChatRoundAvatar:"开启聊天圆形头像（需要刷新）",
  WhetherToHideContactNames:"是否隐藏聊天窗口内的联系人名字",
  WhetherToHideTime:"是否隐藏聊天窗口内的消息发送时间",
  CommentList:"评论列表",
  WriteComment:"写下新的评论",
  reply:'回复',
  changepwd:'修改密码',
  UserInformation:'用户资料',
  PersonalCenter:'个人中心',
  VIPExpirationTime:'VIP到期时间',
  days:'{num} 天',
  hours:"{num} 小时",
  minutes:"{num} 分钟",
  notVIP:"您还不是VIP,立即开通",
  BuyNow:"立即购买",
  VIPExpired:"VIP已到期",
  RenewNow:"立即续费",
  Iliked:"关注",
  LikeMe:"粉丝",
  LikeEachOther:'互相关注',
  dataList:"数据列表",
  PersonalInformation:"个人资料",
  MyTopics:"我发布的热点",
  ChangePassword:"修改密码",
  gift:"礼物",
  PictureClipping:"图片裁剪",
  ReplacePicture:"更换图片",
  ClearPicture:"清除图片",
  anticlockwise:"左旋转",
  ClockwiseRotation:"右旋转",
  amplify:"放大",
  reduce:"缩小",
  delete:"删除",
  confirmDel:"确定删除该信息?",
  uploadVideoValid:"仅支持 {type} 类型文件上传",
  uploadFileNameValid:'上传文件名称不能带有字符"%","&"',
  uploadFileSizeValid:'上传文件不能超过{num}MB',
  avatar:'头像',
  country:'国家',
  height:'身高',
  weight:'体重',
  eyeColor:'瞳色',
  hairColor:'发色',
  marriageStatus:'婚姻状态',
  kidsNum:'孩子数量',
  Education:'学历',
  religion:'宗教信仰',
  smokingStatus:'抽烟状态',
  drinkingStatus:'喝酒状态',
  lookingfor:'理想伴侣',
  introduction:'自我介绍',
  interest:'兴趣',
  reset:'重置',
  PleaseEnterContent:"请输入内容",
  PleaseSelect:"请选择",
  MyGift:"我的礼物",
  EditProfile:"修改资料",
  uploadExceed:"最多上传6张照片!",
  UnFollow: "取消关注",
  All:"全部",
  Follow:"关注",
  Followed:"关注中",
  Following:"关注中",
  Online:"在线",
  BasicInformation:"基本信息",
  OtherInformation:"其他信息",
  MyProfile:"我的资料",
  AddPhotos:"添加图片",
  AddVideos:"添加视频",
  NoPhotosSelected:"未选择照片",
  NoVideoSelected:"未选择视频",
  NoGiftSelected:"未选择礼物",
  NoStickerSelected:"未选择贴纸",
  memberinfo:"用户信息",
  YouToOtherGift:"你送给对方的礼物",
  OtherToYouGift:"对方送给你的礼物",
  ViewProfile:"查看个人资料",
  SPosts:"的帖子",
  Liked:"已赞",
  Recharge:"充值",
  TopUpCredits:"积分",
  Credits:"积分",
  WhatAreCredits:"什么是积分?",
  CreditsDesOne:"积分是用于网站上付费服务的内部货币。",
  CreditsDesTwo:"注册后，您可以免费获得一些积分。之后，补充你的余额。",
  WhyDoYouNeedCredits:"您为什么需要积分？",
  RespondToMails:"回复邮件",
  MessageInChats:"发送聊天消息",
  ReplyWithStickers:"回复贴纸",
  ViewMediaInChat:"在聊天中查看媒体",
  ShareMediaInChat:"在聊天中共享媒体",
  SendVirtualGifts:"发送虚拟礼物",
  YourCredits:"您的积分",
  Pricing:"定价",
  Chats:"聊天",
  LiveChat:"实时聊天",
  StickersInChat:"聊天中的贴纸",
  SendingPhotosInChat:"在聊天中发送照片",
  OpeningVideosInChat:"在聊天中打开视频",
  Mails:"邮件",
  SendingMailsToOneParticularMember:"向某个特定成员发送邮件",
  OpeningMails:"打开邮件",
  SendingPhotosInMails:"在邮件中发送照片",
  OpeningPhotosInMails:"打开邮件中的照片",
  OpeningVideosInMails:"在邮件中打开视频",
  Other:"其他",
  OpeningVideosInProfiles:"在个人资料中打开视频",
  VirtualGifts:"虚拟礼物",
  ClickVideoTips:"点击视频后会立即向您收费",
  GiftsPriceTips:"在礼物目录中查找价格",
  HowManyCredits:"{digit} 积分",
  freeForVip:"vip免费",
  perMinute:"每分钟",
  firstMailCosts:"首次邮件费用",
  eachFollowingMail:"以下每封邮件",
  firstMailIsFree:"第一封邮件是免费的",
  eachFollowingMailCosts:"以下每个邮件费用",
  freeOfCharge:"免费",
  firstPhotoIsFree:"第一张照片是免费的",
  eachFllowingPhotos:"以下每张照片",
  perVideo:"每个视频",
  CreditFreeTips:"您可以获得作为欢迎积分或特惠积分的积分。",
  CreditPurchasedTips:"您额外购买并可以随时使用的积分。",
  Complimentary:"赠送积分",
  Purchased:"剩余积分",
  GetCredits:"获取积分",
  popular:"受欢迎的",
  best:"最佳",
  preferential:"特惠",
  WelcomeOffer:"欢迎选购",
  CreditsInsufficient:"您的积分不足,是否立即获取?",
  MarkOrUnmark:"置顶 / 取消置顶",
  Benefits:"获取积分",
  GetCreditsToUnlockAllFeatures:"获得积分以解锁所有功能!",
  GetVIPToUnlockAllFeatures:"获得VIP享受更多的特权!",
  RegularPrice:"原价",
  ChatWithAnyoneYouLike:"  与任何你喜欢的人聊天",
  ViewHiddenPrivateContent:"  查看隐藏的私人内容",
  ReplyWithStickersInChats:"  在聊天中用贴纸回复",
  Sticker:"贴纸",
  sticker:"贴纸",
  Mailbox:"邮箱",
  GoChat:"去聊天",
  TypeYourTextHere:"在此处输入您的文本...",
  UploadImgTips:"GIF、JPG和PNG文件的最大不能超过 5 Mb。",
  UploadVideoTips:"MP4,MOV和AVI文件的最大不能超过 30 Mb",
  Inbox:"收件箱",
  Starred:"收藏信件",
  Outbox:"发件箱",
  Trash:"垃圾箱",
  RestoreFromTrash:"恢复",
  SendYourLetterTo:"将您的信件发送至 ",
  maildetail:"邮件详情",
  SendMail:"发送邮件",
  AttachmentImages:"附件图片",
  AttachmentVideos:"附件视频",
  MyPhotos:"我的照片",
  MyVideos:"我的视频",
  picture:"图片",
  Attachment:"附件",
  Chat:"聊天",
  HowItWorks:"计费说明",
  chatPrice1:"发送您的第一条信息需要花费",
  chatPrice2:"无论你发送了多少条信息，你每发送60秒就会被收取费用。",
  chatPrice3:"如果您在超过60秒的时间内未发送任何消息，计时器将重置。",
  GetMoreWithCredits:"获取更多积分",
  MyActivity:"我的动态",
  NoChatContent:"没有聊天内容",
  AddCategory:"添加分类",
  CategoryName:"分类名称",
  ConfirmDelete:"您确定要删除吗?",
  MovePhoto:"移动照片",
  MovePhotoCategoryNamePlaceholder:"请选择一个分类",
  TopUpVIP:"会员",
  VIP:"会员",
  Day:"天",
  Month:"月",
  Pay:"支付",
  BackToHomePage:"返回首页",
  Unread:"未读邮件",
  Unanswered:"未答复邮件",
  DeleteSelected:"批量删除",
  DoYouLike:"你喜欢",
  QuestionMark:"吗? ",
  EmptyRecommend:"请先完善信息",
  EmptyPrompt:"请先添加分类",
  SeeNext: "看看下一个是谁",
  SeeMore: "她们将是下一个，继续浏览她们！",
  PaymentResult: "支付结果",
  People: "朋友",
  Article: "文章",
  MyVIP: "VIP介绍",
  VIPExpirationDate: "到期时间",
  VIPPurchasedTips:"续费以获取更多权益",
  GetVIP: "续费VIP",
  NotVIPActivateed: "未开通VIP",
  Continue: "继续选礼物",
  Tip: "提示",
  GiftDelivered: "礼物正在配送中",
  ConfirmOrder: "确认要下单吗？",
  OrderNow: "立即下单",
  VIPIntro1: "免费聊天",
  VIPIntro2: "免费发送贴纸",
  VIPIntro3: "免费查看信件",
  OrderAll: "全部",
  OrderDelivery: "配送中",
  OrderNo: "订单编号",
  OrderAmount: "订单金额",
  OrderTo: "送给",
  OrderInDelivery: "配送中",
  OrderReceived: "已签收",
  SurpriseWithPresent: "给你个惊喜！",
  SpecialDeliveryDirectlyTo: "特殊的礼物送给",
  ChoosePresent: "挑个礼物",
  ...zhLocale,
}