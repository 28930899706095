<template>
    <el-menu :default-active="activeIndex" class="el-menu-vertical-demo"  @select="handleSelect">
        
        <el-menu-item index="/user">
            <i class="icon-al-gerenzhongxin"></i> 
            <span slot="title" class="siderbar-title">{{ $t('PersonalCenter') }}</span>
        </el-menu-item>
    
        <el-menu-item index="/user/myhot" >
            <i class="icon-al-xianxingduoseremen"></i>
            <span slot="title" class="siderbar-title">{{ $t('MyTopics') }}</span>
        </el-menu-item>

        <el-menu-item index="/user/myphotos" >
            <i class="icon-al-tupian"></i>
            <span slot="title" class="siderbar-title">{{ $t('MyPhotos') }}</span>
        </el-menu-item>

        <el-menu-item index="/user/myvideos" >
            <i class="icon-al-shipinbofang"></i>
            <span slot="title" class="siderbar-title">{{ $t('MyVideos') }}</span>
        </el-menu-item>

        <el-menu-item index="/user/changepwd">
            <i class="icon-al-xiugaimima"></i>
            <span slot="title" class="siderbar-title">{{$t('ChangePassword')}}</span>
        </el-menu-item>
    </el-menu>
</template>
         
<script>

export default {
    name: 'siderbar',
    props: {

    },
    components: {},
    data: function () {
        return {
            activeIndex: '',
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            this.drawer = false
            this.$router.push({ path: key })
        }
    },
    created() {
        this.activeIndex = this.$route.path
    },

}
</script>
         
<style>
.siderbar-title{
    margin-left: 10px;
}
</style>