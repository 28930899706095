<script>
import auth from '../../../services/auth'
import i18n from '../../../lang'
export default {
    name: "lemonMessageGift",
    inheritAttrs: false,
    inject: ["IMUI"],
    render() {
        return (
            <lemon-message-basic
                class="lemon-message-gift"
                props={{ ...this.$attrs }}
                scopedSlots={{
                    content: props => {
                        let userToken = auth.getToken()
                        let msg  = "YouToOtherGift"
                        if (props.fromUser.id != userToken.id){
                                msg = "OtherToYouGift"
                        }
                        return <div class='gift-opend'>
                            <img class='gift' src={this.cdnurl(props.content)} />
                            {/* <span style='line-height:40px'><img  class='gift-border' src={this.cdnurl('/assets/img/gift.png')} /> {i18n.t(msg)}</span> */}
                            </div>;
                       
                    }
                }}
            />
        );
    }
};
</script>
<style scoped>
.lemon-message.lemon-message-gift {
    user-select: none;
}
.lemon-message.lemon-message-gift .lemon-message__content {
    padding: 10px;
    background: unset;
}
.lemon-message.lemon-message-gift .lemon-message__content img {
    display: block;
}
.lemon-message.lemon-message-gift .lemon-message__content .gift-close{
    width: 100px;
    height: 100px;
    cursor: pointer; 
}
.lemon-message.lemon-message-gift .lemon-message__content .gift-border{
    width: 20px;
    height: 20px;
    background: unset;
    display: inline-block;
}
.lemon-message.lemon-message-gift .lemon-message__content .gift-opend{
    position: relative;
}
.lemon-message.lemon-message-gift .lemon-message__content .gift{
    width: 120px;
    height: 120px;
    margin: 0 auto;
}
.lemon-message.lemon-message-gift .lemon-message__content .guide-gift{
    position: absolute;
    top: -10px;
    left: 68px;
    width: 150px;
    height: 30px;
    background: none;
}
.lemon-message.lemon-message-gift .lemon-message__content::before {
    display: none;
}</style>
