import setting from '../config/setting.js'
import Vue from 'vue'

export default {
    login(data) {
        localStorage.setItem(setting.userToken, JSON.stringify(data))
    },
    // authentication status
    authenticated() {
        var t = localStorage.getItem(setting.userToken);
        return t && t.length > 0;
    },
    getToken() {
        let josn = localStorage.getItem(setting.userToken)
        return josn ? JSON.parse(localStorage.getItem(setting.userToken)) : '';
    },
    delToken() {
        localStorage.removeItem(setting.userToken)
    },
    logout() {
        localStorage.setItem(setting.userToken, "");
    }
}