<template>
    <div class="">
        <div class="main-wrapper">
            <el-row :gutter="20">
                <el-col :md="18" :sm="18" :xs="24" class="m-no-padding">
                    <MemberListView></MemberListView>
                </el-col>
                <el-col :md="6" :sm="6" :xs="24">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import MemberListView from './components/member/ListView.vue'
import RightSiderBar from './components/common/RightSiderBar.vue'

export default {
    name: 'Index',
    components: {
        MemberListView, 
        RightSiderBar
    },
    data: function () {
        return {}
    },
    methods: {},
    created() {
        let query = this.$route.query;
        if (query.GoMessage) {
            setTimeout(() => {
                this.$router.push({path: "/message"})
            }, 2000);
        }
        //console.log(query)
    },

}
</script>

<style scoped>


</style>