<template>
    <div class="table-wrapper row">
        <el-table :ref="`multipleTable${activeName}`" :data="list" tooltip-effect="dark" style="width: 100%"
            :show-header="isShowCheckbox" :row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" v-if="isShowCheckbox && activeName != 'trash'">
            </el-table-column>
            <el-table-column label="" width="">
                <template slot="header" slot-scope="scope">
                    <el-row class="hidden-xs-only">
                        <el-col :xs="24" :sm="18">
                            <div class="float-left hidden-xs-only" v-if="activeName == 'inbox'">
                                <el-button class="fw-600" type="primary" @click="filterList('all')"
                                    :plain="filter != 'all'">
                                    {{ $t('All') }}
                                </el-button>
                                <el-button class="fw-600" type="primary" @click="filterList('unread')"
                                    :plain="filter != 'unread'">
                                    {{ $t('Unread') }}
                                </el-button>
                                <el-button class="fw-600" type="primary" @click="filterList('unanswered')"
                                    :plain="filter != 'unanswered'">
                                    {{ $t('Unanswered') }}
                                </el-button>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="6" class="float-right">
                            <div class="float-right">
                                <el-button v-if="activeName != 'trash'" class="fw-600" type="danger"
                                    @click="deleteSelected()" plain>{{ $t('DeleteSelected') }}</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </template>
                <template slot-scope="scope">
                    <div class="mail-wrapper" @click="showDatail(scope.row, $event)">
                        <div class="mail-star">
                            <i class="icon-al-star-off"
                                v-if="scope.row.starred == userinfo.id || scope.row.starred == -1"
                                @click="star(scope.row)"></i>
                            <i class="icon-al-star-on" v-else @click="star(scope.row)"></i>
                        </div>
                        <div class="user-block" style="position: relative;">
                            <div v-if="activeName == 'inbox'">
                                <el-avatar size="medium" :src="scope.row.from_userinfo.avatar | cdnurl"></el-avatar>
                                <i class="online-dot"
                                    :class="{ 'icon-al-zhuangtai': true, 'online-isactive': scope.row.from_userinfo.userDetail.online == 1 }"></i>
                            </div>
                            <div v-if="activeName == 'outbox'">
                                <el-avatar size="medium" :src="scope.row.to_userinfo.avatar | cdnurl"></el-avatar>
                                <i class="online-dot"
                                    :class="{ 'icon-al-zhuangtai': true, 'online-isactive': scope.row.to_userinfo.userDetail.online == 1 }"></i>
                            </div>
                            <template v-if="activeName == 'starred' || activeName == 'trash'">
                                <div v-if="userinfo.id == scope.row.from_userinfo.id">
                                    <el-avatar size="medium" :src="scope.row.to_userinfo.avatar | cdnurl"></el-avatar>
                                    <i class="online-dot"
                                        :class="{ 'icon-al-zhuangtai': true, 'online-isactive': scope.row.to_userinfo.userDetail.online == 1 }"></i>
                                </div>
                                <div v-if="userinfo.id == scope.row.to_userinfo.id">
                                    <el-avatar size="medium" :src="scope.row.from_userinfo.avatar | cdnurl"></el-avatar>
                                    <i class="online-dot"
                                        :class="{ 'icon-al-zhuangtai': true, 'online-isactive': scope.row.from_userinfo.userDetail.online == 1 }"></i>
                                </div>
                            </template>

                            <div class="table-list-row" style="width: 100%;height: 50px;">
                                <div class="username">
                                    <div class="username-left">
                                        <el-link :underline="false" v-if="activeName == 'inbox'">
                                            {{ scope.row.from_userinfo.nickname }}
                                            <el-tag v-if="scope.row.isread !== 1" type="danger" size="mini">
                                                {{ $t('Unread') }}
                                            </el-tag>
                                        </el-link>
                                        <el-link :underline="false" v-if="activeName == 'outbox'">
                                            {{ scope.row.to_userinfo.nickname }}
                                        </el-link>
                                        <template v-if="activeName == 'starred' || activeName == 'trash'">
                                            <el-link :underline="false" v-if="userinfo.id == scope.row.to_userinfo.id">
                                                {{ scope.row.from_userinfo.nickname }}
                                            </el-link>
                                            <el-link :underline="false"
                                                v-if="userinfo.id == scope.row.from_userinfo.id">
                                                {{ scope.row.to_userinfo.nickname }}
                                            </el-link>
                                        </template>
                                        <p class="table-list-content">{{ scope.row.subject }}</p>
                                    </div>
                                    <div class="times">
                                        <p v-if="activeName != 'trash'">
                                            <i class="el-icon-time"></i>
                                            {{ scope.row.createtime | getDateDiff }}
                                        </p>
                                        <p class="mail-delete" @click="del(scope.row, scope.$index)">
                                            <span v-if="activeName == 'trash'">
                                                <el-button>{{ $t('RestoreFromTrash') }}</el-button>
                                            </span>
                                            <i class="el-icon-delete-solid" style="float: right;" v-else></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-col class="pagination" style="margin-top: 30px;" :xs="24" :sm="24" :md="24" :lg="24">
            <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="total"
                :page-size="limit" :current-page="page" @current-change="changePage">
            </el-pagination>
        </el-col>
    </div>
</template>
<script>
import Auth from '../../../services/auth'
import i18n from "../../../lang";
export default {
    name: 'table-list',
    components: {},
    props: {
        activeName: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            list: [],
            page: 1,
            limit: 10,
            loading: false,
            total: 0,
            userinfo: [],
            filter: "all",
            selectedIds: [],
            isShowCheckbox: true,
        }

    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            if (row.isread === 1) {
                return 'success-row';
            }
            return '';
        },
        deleteSelected() {
            let that = this
            that.$http.post('/mail/delete', { ids: that.selectedIds })
                .then((res) => {
                    if (res.code == 1) {
                        that.list = []
                        that.page = 1
                        that.getList()
                    } else {
                        that.$message.error(res.msg);
                    }
                    that.loading = false
                })
        },
        filterList(filter) {
            let that = this
            that.filter = filter
            that.list = []
            that.page = 1
            that.getList()
        },
        getList() {
            let that = this
            that.loading = true
            let params = {
                page: that.page,
                limit: that.limit,
                type: that.activeName,
                filter: that.filter,
            }
            that.$http.get('/mail/getList', { params: params })
                .then((res) => {
                    if (res.code == 1) {
                        that.list = res.data.list || []
                        that.total = res.data.total;
                    } else {
                        that.$message.error(res.msg);
                    }
                    that.loading = false
                })
        },
        star(row) {
            let that = this
            that.$http.post('/mail/star', { id: row.id })
                .then((res) => {
                    if (res.code == 1) {
                        row.starred = res.data.starred
                    } else {
                        that.$message.error(res.msg);
                    }
                    that.loading = false
                })
        },
        del(row, index) {

            let that = this
            that.$http.post('/mail/delete', { ids: [row.id] })
                .then((res) => {
                    if (res.code == 1) {
                        that.list.splice(index, 1)
                    } else {
                        that.$message.error(res.msg);
                    }
                    that.loading = false
                })
        },
        jump(url) {
            let that = this;
            that.$router.push({ path: url })
        },

        changePage(page) {
            let that = this
            that.$scrollTo()
            that.page = page;
            that.getList();
        },
        showDatail(row, event) {
            let that = this
            that.$http.post('/mail/checkMailPaid', { id: row.id })
                .then((res) => {
                    if (res.code == 1) {
                        if (res.data.status == true) {
                            that.$router.push({ path: "/maildetail/" + row.id })
                        } else {
                            that.$confirm(i18n.t('CreditsInsufficient'), i18n.t("warning"), {
                                confirmButtonText: i18n.t("confirm"),
                                cancelButtonText: i18n.t("cancel"),
                                type: 'warning'
                            }).then(() => {
                                that.jump("/benefits")
                            }).catch(() => {

                            });
                        }
                    } else {
                        that.$message.error(res.msg);
                    }
                    that.loading = false
                })
        },
        handleSelectionChange(val) {
            let that = this;

            that.selectedIds = [];
            for (let i = 0; i < val.length; i++) {
                that.selectedIds.push(val[i].id)
            }
        }
    },
    created() {
        this.getList()
        this.userinfo = Auth.getToken()
        if (window.innerWidth < 768) {
            this.isShowCheckbox = false
        }
    },
}
</script>
<style scoped>
.user-block {
    margin-bottom: 5px;
    flex: 1;
}

.user-block .el-avatar {
    float: left;
    height: 50px !important;
    width: 50px !important;
    cursor: pointer;
}

.user-block .el-avatar:hover {
    opacity: 0.8;
}

.user-block .online-dot {
    position: absolute;
    top: 30px;
    left: 30px;
}

.user-block .username {
    display: flex;
    justify-content: space-between;
    height: 100%;
    line-height: 1;
    padding: 3px 0;
    font-size: 16px;
    font-weight: 600;
    margin-top: -1px;

}

.user-block .username .username-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.user-block .username .username-left .el-link {
    justify-content: start;
}

.user-block .username .times {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
}

.user-block .description {
    color: #6c757d;
    font-size: 12px;
    clear: both;
}

.float-right {
    float: right !important;
}

.table-list-row {
    cursor: pointer;

}

.el-table__body {

    width: 99.9% !important;

}

.mail-wrapper {
    display: flex;
}

.mail-star {
    position: relative;
    width: 5%;
}

.mail-star .el-tag {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.mail-star .icon-al-star-off {
    cursor: pointer;
    font-size: 20px;
    color: #ffd22e;
}

.mail-star .icon-al-star-on {
    cursor: pointer;
    font-size: 20px;

}

.mail-wrapper {
    display: flex;
    align-items: center;
}

.mail-delete .el-icon-delete-solid {
    font-size: 18px;
}

.el-table {
    ::v-deep(.warning-row) {
        background: oldlace;
    }
    ::v-deep(.success-row) {
        background: #f0f9eb;
    }
}

</style>
<style lang="scss" scoped>
.table-list-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

@media only screen and (max-width: 767px) {
    .mail-star {
        width: auto;
        margin-right: 10px;
    }

    .table-wrapper {

        :deep(.el-table) {}
    }
}
</style>