<template>
  <div>
    <div class="main-wrapper">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">
          <hot-list-view></hot-list-view>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6">
          <right-sider-bar></right-sider-bar>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import HotListView from './components/hot/HotListView.vue'
import RightSiderBar from './components/common/RightSiderBar.vue'
export default {
  name: 'Hot',
  components: { HotListView,RightSiderBar },
  data() {
    return {
      commentData: []
    }
  },

  methods: {
  },
  created() {

  },
}


</script>
  
<style scoped>
.post {
  border-bottom: 1px solid #adb5bd;
  color: #666;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.post .user-block {
  margin-bottom: 15px;
  width: 100%;
}

.user-block {
  float: left;
}

.user-block img {
  float: left;
  height: 40px;
  width: 40px;
}

.img-circle {
  border-radius: 50%;
}

.img-bordered-sm {
  border: 2px solid #adb5bd;
  padding: 2px;
}

img {
  vertical-align: middle;
  border-style: none;
}

.user-block .username {
  font-size: 16px;
  font-weight: 600;
  margin-top: -1px;
}

.user-block .comment,
.user-block .description,
.user-block .username {
  display: block;
  margin-left: 50px;
}

.btn-tool {
  background-color: transparent;
  color: #adb5bd;
  font-size: .875rem;
  margin: -0.75rem 0;
  padding: 0.25rem 0.5rem;
}

</style>

  