import { render, staticRenderFns } from "./PopUpH5.vue?vue&type=template&id=a60e5cde&scoped=true"
import script from "./PopUpH5.vue?vue&type=script&setup=true&lang=ts"
export * from "./PopUpH5.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./PopUpH5.vue?vue&type=style&index=0&id=a60e5cde&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a60e5cde",
  null
  
)

export default component.exports