import i18n from '../lang'
export default [
  {
    label: i18n.t('emoji'),
    children: [
      { name: "buman", title: "buman", src: "img/face/buman.gif" },
      { name: "weixiao", title: "weixiao", src: "img/face/weixiao.gif" },
      { name: "xixi", title: "xixi", src: "img/face/xixi.gif" },
      { name: "xiaokule", title: "xiaokule", src: "img/face/xiaokule.gif" },
      { name: "daxiao", title: "daxiao", src: "img/face/daxiao.gif" },
      { name: "xieyanxiao", title: "xieyanxiao", src: "img/face/xieyanxiao.gif" },
      { name: "weixiaotianshi", title: "weixiaotianshi", src: "img/face/weixiaotianshi.gif" },
      { name: "zhayan", title: "zhayan", src: "img/face/zhayan.gif" },
      { name: "songlekouqi", title: "songlekouqi", src: "img/face/songlekouqi.gif" },
      { name: "huachi", title: "huachi", src: "img/face/huachi.gif" },
      { name: "mojingxiaolian", title: "mojingxiaolian", src: "img/face/mojingxiaolian.gif" },
      { name: "deyi", title: "deyi", src: "img/face/deyi.gif" },
      { name: "bugaoxing", title: "bugaoxing", src: "img/face/bugaoxing.gif" },
      { name: "feiwen", title: "feiwen", src: "img/face/feiwen.gif" },
      { name: "danyantushe", title: "danyantushe", src: "img/face/danyantushe.gif" },
      { name: "ku", title: "ku", src: "img/face/ku.gif" },
      { name: "aoman", title: "aoman", src: "img/face/aoman.gif" },
      { name: "leisile", title: "leisile", src: "img/face/leisile.gif" },
      { name: "fangshengdaku", title: "fangshengdaku", src: "img/face/fangshengdaku.gif" },
      { name: "jianmo", title: "jianmo", src: "img/face/jianmo.gif" },
      { name: "xiasile", title: "xiasile", src: "img/face/xiasile.gif" },
      { name: "lianhong", title: "lianhong", src: "img/face/lianhong.gif" },
      { name: "shuizhele", title: "shuizhele", src: "img/face/shuizhele.gif" },
      { name: "daolian", title: "daolian", src: "img/face/daolian.gif" },
      { name: "fanbaiyan", title: "fanbaiyan", src: "img/face/fanbaiyan.gif" },
      { name: "bizui", title: "bizui", src: "img/face/bizui.gif" },
      { name: "facai", title: "facai", src: "img/face/facai.gif" },
      { name: "fashao", title: "fashao", src: "img/face/fashao.gif" },
      { name: "shudaizilian", title: "shudaizilian", src: "img/face/shudaizilian.gif" },
      { name: "xiangyixiang", title: "xiangyixiang", src: "img/face/xiangyixiang.gif" },
      { name: "shoushang", title: "shoushang", src: "img/face/shoushang.gif" },
      { name: "niuzaimaolian", title: "niuzaimaolian", src: "img/face/niuzaimaolian.gif" },
      { name: "xiaodemandidagun", title: "xiaodemandidagun", src: "img/face/xiaodemandidagun.gif" },
      { name: "liukoushui", title: "liukoushui", src: "img/face/liukoushui.gif" },
      { name: "shuohuang", title: "shuohuang", src: "img/face/shuohuang.gif" },
      { name: "dapenti", title: "dapenti", src: "img/face/dapenti.gif" },
      { name: "haochongbaio", title: "haochongbaio", src: "img/face/haochongbaio.gif" },
      { name: "huaji", title: "huaji", src: "img/face/huaji.gif" },
      { name: "anjingdelian", title: "anjingdelian", src: "img/face/anjingdelian.gif" },
      { name: "outu", title: "outu", src: "img/face/outu.gif" },
      { name: "baozhatou", title: "baozhatou", src: "img/face/baozhatou.gif" },
      { name: "xixiaoyankai", title: "xixiaoyankai", src: "img/face/xixiaoyankai.gif" },
      { name: "daheqian", title: "daheqian", src: "img/face/daheqian.gif" },
      { name: "juhuixiaolian", title: "juhuixiaolian", src: "img/face/juhuixiaolian.gif" },
      { name: "touhunyanhua", title: "touhunyanhua", src: "img/face/touhunyanhua.gif" },
      { name: "lianfashao", title: "lianfashao", src: "img/face/lianfashao.gif" },
      { name: "lenglian", title: "lenglian", src: "img/face/lenglian.gif" },
      { name: "kenqiudelian", title: "kenqiudelian", src: "img/face/kenqiudelian.gif" },
      { name: "daidanpianyanjingdelian", title: "daidanpianyanjingdelian", src: "img/face/daidanpianyanjingdelian.gif" }
    ]
  },
  // {
  //   label: "收藏",
  //   children: [
  //     {
  //       name: "1f62c",
  //       title: "微笑",
  //       src: "https://twemoji.maxcdn.com/2/72x72/1f62c.png",
  //     },
  //     {
  //       name: "1f621",
  //       title: "微笑",
  //       src: "https://twemoji.maxcdn.com/2/72x72/1f621.png",
  //     },
  //   ],
  // },
];
