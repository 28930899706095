<template>
    <div class="photo-list">
        <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <template v-if="images.length == 1">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" v-for="(image,index) in images" :key="index">
                <el-image class="image-item" :src="image|cdnurl" lazy fit="contain " :preview-src-list="images|cdnurlArr">
                    <div slot="placeholder" class="image-slot">
                        Loading<span class="dot">...</span>
                    </div>
                </el-image>
            </el-col>
            </template>
            <template v-if="images.length == 2 || images.length == 4">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" v-for="(image,index) in images" :key="index">
                <el-image class="image-item" style="height: 350px;" :src="image|cdnurl" lazy fit="contain" :preview-src-list="images|cdnurlArr">
                    <div slot="placeholder" class="image-slot">
                        Loading<span class="dot">...</span>
                    </div>
                </el-image>
            </el-col>
            </template>
            <template v-if="images.length == 3 ||images.length == 6 ||images.length == 5">
                <el-col :xs="8" :sm="8" :md="8" :lg="8" v-for="(image,index) in images" :key="index">
                <el-image class="image-item" style="height: 200px;" :src="image|cdnurl" lazy fit="contain" :preview-src-list="images|cdnurlArr">
                    <div slot="placeholder" class="image-slot">
                        Loading<span class="dot">...</span>
                    </div>
                </el-image>
            </el-col>
            </template>
            


        </el-col>
    </div>
</template>
<script>
export default {
    name: 'PhotoShowView',
    props: {
        images: []
    }, data: function () {
        return {
            
        }
    },
    methods: {

    },
    created() {
        
    },
}
</script>
<style>
.photo-list {
    clear: both;
    margin-top: 10px;
}
.image-item{
    height: 400px;
}
</style>