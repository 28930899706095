<!--评论模块-->
<template>
  <div>
    <div class="new-comment" @click="showNewCommentInput()">
      <i class="el-icon-edit"></i> {{ $t('WriteComment') }}
    </div>
    <transition name="fade">
      <div class="input-wrapper new-comment-input" v-if="showNewCommentInputStatus">
        <el-input class="gray-bg-input" v-model="inputComment" type="textarea" :rows="3" autofocus placeholder="写下你的评论">
        </el-input>
        <div class="btn-control">
          <el-button class="cancel" size="small" @click="showNewCommentInputStatus = !showNewCommentInputStatus"
            round>{{ $t('cancel') }}</el-button>
          <el-button class="btn" type="primary" size="small" round @click="commitComment">{{ $t('send') }}</el-button>
        </div>
      </div>
    </transition>
    <div class="container comment-item" ref="scrollRef">

      <el-empty v-if="list.length == 0 && !loading" :description="$t('NoMore')" :image-size="50"></el-empty>
      <div class="comment" v-for="item in list">
        <div class="info">
          <el-avatar :size="36" :src="item.fromUser.avatar | cdnurl"></el-avatar>
          <div class="right">
            <div class="name">{{ item.fromUser.nickname }}</div>
            <div class="date">{{ item.createtime | getDateDiff }}</div>
          </div>
        </div>
        <div class="content">{{ item.content }}</div>
        <div class="control">
        <!-- <span class="like" :class="{active: item.isLike}" @click="likeClick(item)">
          <i class="iconfont icon-like"></i>
          <span class="like-num">{{item.likeNum > 0 ? item.likeNum + '人赞' : '赞'}}</span>
                  </span> -->
          <span class="comment-reply" @click="showCommentInput(item, item)">
            <i class="icon-al-pinglun"></i>
            {{ $t('reply') }}
          </span>
        </div>
        <div class="reply">
          <div class="item" v-for="reply in item.reply">
            <div class="reply-content">
              <span class="from-name">{{ reply.fromUser.nickname }}</span><span>: </span>
              <span class="to-name">@{{ reply.toUser.nickname }}</span>
              <span>{{ reply.content }}</span>
            </div>
            <div class="reply-bottom">
              <span>{{ reply.date }}</span>
              <span class="reply-text" @click="showCommentInput(item, reply)">
                <i class="icon-al-pinglun"></i>
                {{ $t('reply') }}

              </span>
            </div>
          </div>

          <transition name="fade">
            <div class="input-wrapper" v-if="showItemId === item.id">
              <el-input class="gray-bg-input" v-model="inputComment" type="textarea" :rows="3" autofocus
                :placeholder="inputPlaceholder">

              </el-input>
              <div class="btn-control">
                <el-button class="cancel" size="small" @click="cancel" round>{{ $t('cancel') }}</el-button>
                <el-button class="btn" type="primary" size="small" round @click="commitComment">{{ $t('send') }}</el-button>
              </div>
            </div>
          </transition>
        </div>
        <el-divider></el-divider>
      </div>
      <el-col class="pagination" :xs="24" :sm="24" :md="24" :lg="24">
        <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="total" :page-size="limit"
          :current-page="page" @current-change="changePage">
        </el-pagination>
      </el-col>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import { cdnurl } from '../../../filters'

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: {},
  data() {
    return {
      inputPlaceholder: '',
      inputComment: '',
      showItemId: '',
      toUserId: '',
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      showNewCommentInputStatus: false,
    }
  },
  computed: {},
  methods: {
    getList() {
      let that = this
      that.loading = true
      let params = {
        id: that.id,
        page: that.page,
        limit: that.limit,
      }
      that.$http.get('/comment/list', { params: params })
        .then((res) => {
          if (res.code == 1) {
            that.list = res.data.list || []
            that.total = res.data.total;
          } else {
            that.$message.error(res.msg);
          }
          that.loading = false
        })
    },

    /**
     * 点击取消按钮
     */
    cancel() {
      this.showItemId = '';
      this.toUserId = '';
      this.inputComment = '';
      this.showNewCommentInputStatus = false;
    },

    /**
     * 提交评论
     */
    commitComment() {
      let that = this;
      let formData = {
        hotId: that.id,
        pid: that.showItemId,
        toUserId: this.toUserId,
        content: that.inputComment,

      }
      that.$http.post('/comment/add', formData)
        .then((res) => {
          if (res.code == 1) {
            that.page = 1;
            that.getList();
            this.showItemId = '';
            this.toUserId = '';
            this.inputComment = '';
            this.showNewCommentInputStatus = false;
            that.$message.success(res.msg)

          } else {
            that.$message.error(res.msg);
          }
          that.loading = false
        })
    },
    changePage: function (page) {
      this.page = page;
      this.getList();
      // 回到顶部
      this.$refs.scrollRef.scrollTop = 0;
    },

    /**
     * 点击评论按钮显示输入框
     * item: 当前大评论
     * reply: 当前回复的评论
     */
    showCommentInput(item, reply) {
      this.inputComment = '';
      this.showNewCommentInputStatus = false;
      if (reply) {
        this.inputPlaceholder = "@" + reply.fromUser.nickname + " "
        this.toUserId = reply.fromUser.id;
      } else {
        this.inputPlaceholder = ''
      }
      this.showItemId = item.id;

    },
    showNewCommentInput() {
      this.inputComment = '';
      this.showItemId = '';
      this.toUserId = '';
      this.showNewCommentInputStatus = !this.showNewCommentInputStatus
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped >
.container {
  margin-top: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #f4f7ff;
}

.container .comment {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #F2F6FC;

}

.comment-item {
  height: calc(100vh - 340px);
  overflow: auto;
  padding-bottom: 10px;
}

.container .comment .info {
  display: flex;
  align-items: center;
}

.container .comment .info .avatar {
  border-radius: 50%;
}

.container .comment .info .right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.container .comment .info .right .name {
  font-size: 16px;
  color: #303133;
  margin-bottom: 5px;
  font-weight: 500;
}

.container .comment .info .right .date {
  font-size: 12px;
  color: #909399;
}

.container .comment .content {
  font-size: 16px;
  color: #303133;
  line-height: 20px;
  padding: 10px 0;
}

.container .comment .control {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #909399;
}

.container .comment .control .like {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.container .comment .control .like.active,
.container .comment .control .like:hover {
  color: #409EFF;
}

.container .comment .control .like .iconfont {
  font-size: 14px;
  margin-right: 5px;
}

.container .comment .control .comment-reply {
  align-items: center;
  cursor: pointer;
}

.container .comment .control .comment-reply:hover {
  color: #333;
}

.container .comment .control .comment-reply .iconfont {
  font-size: 16px;
  margin-right: 5px;
}

.container .comment .reply {
  margin: 10px 0;
  border-left: 2px solid #DCDFE6;
}

.container .comment .reply .item {
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px dashed #EBEEF5;
}

.container .comment .reply .item .reply-content {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #303133;
}

.container .comment .reply .item .reply-content .from-name {
  color: #409EFF;
}

.container .comment .reply .item .reply-content .to-name {
  color: #409EFF;
  margin-left: 5px;
  margin-right: 5px;
}

.container .comment .reply .item .reply-bottom {
  display: flex;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
  color: #909399;
}

.container .comment .reply .item .reply-bottom .reply-text {
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.container .comment .reply .item .reply-bottom .reply-text:hover {
  color: #333;
}

.container .comment .reply .item .reply-bottom .reply-text .icon-comment {
  margin-right: 5px;
}

.container .comment .reply .write-reply {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #909399;
  padding: 10px;
  cursor: pointer;
}

.container .comment .reply .write-reply:hover {
  color: #303133;
}

.container .comment .reply .write-reply .el-icon-edit {
  margin-right: 5px;
}

.container .comment .reply .fade-enter-active,
.container .comment .reply fade-leave-active {
  transition: opacity 0.5s;
}

.container .comment .reply .fade-enter,
.container .comment .reply .fade-leave-to {
  opacity: 0;
}

.container .comment .reply .input-wrapper {
  padding: 10px;
}

.container .comment .reply .input-wrapper .gray-bg-input,
.container .comment .reply .input-wrapper .el-input__inner {
  background-color: #67C23A;
}

.container .comment .reply .input-wrapper .btn-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

.container .comment .reply .input-wrapper .btn-control .cancel {
  font-size: 16px;
  color: #606266;
  cursor: pointer;
}

.container .comment .reply .input-wrapper .btn-control .cancel:hover {
  color: #333;
}

.container .comment .reply .input-wrapper .btn-control .confirm {
  font-size: 16px;
}

.new-comment {

  align-items: center;
  font-size: 16px;
  color: #3B5998;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
}

.new-comment-input {
  padding: 10px 10px;
}

.new-comment-input .btn-control {
  margin-top: 10px;
  text-align: right;
}
</style>
