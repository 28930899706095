<template>
    <div style="margin-bottom:20px">
        <el-row :gutter="1" class="card-header shadow">
            <div class="card-header-row">
                <el-col :lg="18" :md="18" :sm="18" :xs="18">
                    <h1 class="header-title" style="float: left;"><i class="icon-al-xianxingduoseremen"
                                                                     style="font-size: 24px;"></i> {{
                            $t('DynamicSquare')
                        }}</h1>
                    <span class="header-type"><el-button :round="true" :type="follow == 0 ? 'primary' : 'default'"
                                                         size="small" @click="changeFollow(0)">{{
                            $t('All')
                        }}</el-button></span>
                    <span class="header-type"><el-button :round="true" :type="follow == 1 ? 'primary' : 'default'"
                                                         size="small" @click="changeFollow(1)"><i
                        class="el-icon-star-on pink-color"></i> {{
                            $t('Following')
                        }}</el-button></span>
                </el-col>
                <el-col :lg="6" :md="6" :sm="6" :xs="6">
                    <el-popover v-model="publishVisible" :close-on-click-modal="false" placement="bottom-end"
                                popper-class="publish-pop"
                                trigger="manual" width="470">
                        <publish @publishAfter="publishAfter"></publish>
                        <i slot="reference" class="el-icon-s-promotion float-right"
                           @click="publishVisible = !publishVisible"></i>
                    </el-popover>

                </el-col>
            </div>


        </el-row>
        <el-row :gutter="20" class="card-body card-dynamic-body">
            <el-skeleton :count="12" :loading="loading" :throttle="200" animated>
                <template slot="template">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="card-item">
                        <div class="list-avatar">
                            <el-skeleton-item style="width: 120px;height: 120px;" variant="image"/>
                        </div>
                        <div class="list-content">
                            <el-col :lg="16" :md="18" :sm="24" :xs="24" class="list-left">
                                <el-skeleton-item style="width: 30%;margin: 0 auto;" variant="h3"/>
                                <br>
                                <el-skeleton-item style="width: 50%;margin: 0 auto;" variant="h3"/>
                            </el-col>
                            <el-col :lg="8" :md="6" :sm="24" :xs="24" class="list-right">
                                <el-skeleton-item style="width: 100%;margin: 0 auto;" variant="h3"/>
                            </el-col>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="list-desc">
                                <el-skeleton-item style="width: 100%;margin: 0 auto;" variant="h3"/>
                                <el-skeleton-item style="width: 100%;margin: 0 auto;" variant="h3"/>
                                <el-skeleton-item style="width: 100%;margin: 0 auto;" variant="h3"/>
                            </el-col>
                        </div>

                        <el-divider></el-divider>
                    </el-col>
                </template>
            </el-skeleton>

            <el-card v-for="(row, index) in list" :key="index" class="list-item box-card" style="margin-top: 10px;">
                <div class="post">
                    <div class="user-block">
                        <el-avatar :src="row ? cdnurl(row.avatar) : ''" size="medium"
                                   @click="jump(row)"></el-avatar>
                        <span class="username">
                            <el-link :underline="false" style="float: left;margin-top: 10px;" @click="jump(row)">
                                {{ row ? row.nickname : '' }}
                            </el-link>
                            <div class="float-right times">
                                <p>
                                    <el-button v-if="row.followed == 0" size="medium" @click="toFollow(row)">
                                        <i class="icon-al-guanzhu"></i> 
                                        {{ $t('Follow') }}
                                    </el-button>
                                    <el-button v-else size="medium" type="info" @click="toFollow(row)">
                                        <i class="icon-al-yiguanzhu"></i> 
                                        {{ $t('Followed') }}
                                    </el-button>
                                </p>
                                <!-- <p><i class="el-icon-time"></i> {{ row.createtime | getDateDiff }}</p> -->
                            </div>
                        </span>


                    </div>
                    <el-divider></el-divider>
                    <p class="content">
                        {{ row.content }}
                    </p>

                    <photo-show-view :images="row.imagesArr"></photo-show-view>

                    <p class="post-footer">
                        <el-button size="medium" @click="like(row)">
                            <i :class="{ 'icon-al-zans': row.liked == 0, 'icon-al-icon_goodfill': row.liked > 0 }"></i>
                            {{
                                $t('Like')
                            }}
                        </el-button>

                        <el-button size="medium" style="float: right;" type="primary" @click="jump(row)">
                            {{ $t('ViewProfile') }} <i class="el-icon-arrow-right"></i>
                        </el-button>
                        <!-- <span class="footer-comments" @click="comment(row)">
                            <i class="icon-al-pinglun"></i> {{ $t('Comments') }} {{ row.comment > 0 ? `(${row.comment})` :
                                '' }}
                        </span> -->
                    </p>
                    <el-dialog :before-close="closeCommentList" :title="$t('CommentList')"
                               :visible.sync="showCommentItemId == row.id"
                               custom-class="commentList" width="600px">
                        <comment v-if="showCommentItemId == row.id" :id="row.id"></comment>
                    </el-dialog>
                </div>
            </el-card>

            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="pagination" style="margin-top: 30px;">
                <el-pagination :current-page="page" :hide-on-single-page="true" :page-size="limit" :total="total"
                               background layout="prev, pager, next" @current-change="changePage">
                </el-pagination>
            </el-col>
            <el-col :lg="24" :md="24" :sm="24" :xs="24">
                <el-empty v-if="list.length == 0 && !loading" :description="$t('DataIsEmpty')"></el-empty>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import PhotoShowView from './PhotoShowView.vue'
import Publish from './Publish.vue'
import comment from './comment.vue'

export default {
    name: 'HotListView',
    components: {PhotoShowView, Publish, comment},
    data: function () {
        return {
            list: [],
            page: 1,
            limit: 12,
            loading: false,
            total: 0,
            publishVisible: false,
            showCommentItemId: '',
            follow: 0,
        }
    },
    methods: {
        changeFollow: function (follow) {
            this.follow = follow;
            this.page = 1;
            this.list = [];
            this.getList();
        },
        toFollow: function (row) {
            let that = this;
            that.$http.post('/user/follow', {to_user_id: row.userId})
                .then((res) => {
                    if (res.code == 1) {
                        if (row.followed > 0) {
                            row.followed = 0
                        } else {
                            row.followed = 1;
                        }
                        //that.$message.success(res.msg);

                    } else {
                        that.$message.error(res.msg);
                    }

                })
        },
        getList: function () {
            let that = this
            that.loading = true
            let params = {
                page: that.page,
                limit: that.limit,
                follow: that.follow
            }
            that.$http.get('/hot/list', {params: params})
                .then((res) => {
                    if (res.code == 1) {
                        that.$scrollTo()
                        that.list = res.data.list || []
                        that.total = res.data.total;
                    } else {
                        that.$message.error(res.msg);
                    }
                    that.loading = false
                })
        },

        changePage: function (page) {
            let that = this

            that.page = page;
            that.getList();
        },
        publishAfter: function (status) {
            this.publishVisible = false;
            if (status) {
                this.page = 1

                this.list = [];
                this.getList()
            }

        },
        like: function (row) {
            let that = this
            that.$http.post('/hot/like', {id: row.id})
                .then((res) => {
                    if (res.code == 1) {
                        if (row.liked == 1) {
                            row.liked = 0
                        } else {
                            row.liked = 1
                        }

                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        jump(row) {
            let that = this;
            that.$router.push({path: `memberinfo/${row.userId}`})
        },
        comment: function (row) {
            this.showCommentItemId = row.id
        },
        closeCommentList: function () {
            this.showCommentItemId = ''
        }

    },
    created() {

        this.getList();
    },
}
</script>

<style scoped>
.post {
    border-bottom: 1px solid #ebebeb;
    color: #666;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.post .user-block {
    margin-bottom: 5px;
    width: 100%;
}

.user-block {
    float: left;
}

.user-block .el-avatar {
    float: left;
    height: 50px !important;
    width: 50px !important;
}

.user-block .username .times {
    font-size: 12px;
    font-weight: 600;
}

.user-block .description {
    color: #6c757d;
    font-size: 12px;
    clear: both;
}

.post .content {
    color: #6c757d;
    font-size: 14px;

}

.user-block .comment,
.user-block .description {
    display: block;
    margin-left: 60px;
}


.card-header-row .el-icon-s-promotion {
    font-size: 24px !important;
    color: #3b5998;
    cursor: pointer;
}

.post-footer {
    clear: both;
    font-size: 14px;
    padding-top: 10px;
}

.footer-zan {
    margin-left: 20px;
    cursor: pointer;
}

.footer-comments {
    margin-left: 20px;
    cursor: pointer;
}

.footer-zan .icon-al-dianzan1 {
    color: #ff7c7c !important;
}

.list-item {
    border-radius: 15px !important;
}

.commentList .el-dialog__body {
    padding: 0 20px 10px 20px;
}

.card-dynamic-body {
    padding: 20px 120px !important;
}

.header-type {
    margin-left: 10px;
}
</style>

<style lang="scss" scoped>
.card-header {
    height: 60px;
    padding: 0 15px;
    border: 1px solid #ebebeb;
    border-bottom: none;

    .card-header-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}
.user-block {
    .username {
        display: block;
        margin-top: -1px;
        margin-left: 60px;
        font-size: 16px;
        font-weight: 600;

    }
}

@media only screen and (max-width: 767px) {
    .card-dynamic-body {
        padding: 20px 5px !important;
    }
    .card-header {
        padding: 5px 10px 5px 10px;
        position: fixed;
        top: 60px;
        z-index: 10;
        width: 100%;

    }
    .card-body {
        padding-top: 45px !important;
    }

    .header-title {
        font-size: 18px;
    }
    .header-title i {
        font-size: 18px !important;
    }

}
</style>