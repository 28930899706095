<template>
    <div ref="presents" class="presents">
        <el-row :gutter="20">
            <el-col :md="18" :sm="18" :xs="24" class="m-no-padding">
                <div ref="cateRef" class="categories-header real-presents__header">
                    <div class="top-bar">
                        <a class="back-btn" href="javascript:;" @click="goBack()">
                            <div class="ui-icon ui-icon__display-inline" style="width: 24px; height: 24px;">
                                <span class="ui-icon__display-block" style="width: 24px; height: 24px;">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                                        clip-rule="evenodd" d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
                                        fill-rule="evenodd"></path></svg>
                                </span>
                            </div>
                        </a>
                        <div class="caption">Choose a real gift for <span class="giftedUser">{{ nickname }}</span></div>
                    </div>
                    <el-radio-group v-model="cateVal" class="cate-group" @input="handleCateChange">
                        <el-radio v-for="item in giftCategory" :key="item.id" :label="item.nameEn" border>{{
                                item.name
                            }}
                        </el-radio>
                    </el-radio-group>
                </div>

                <div class="present-wrapper">
                    <van-sticky v-if="cartData.length" class="cart-wrap" offset-top="75vh">
                        <van-icon color="#fff" name="cart-o" size="28"
                                  @click="routeTo(`/presents/cart?to_uid=${userId}&nickname=${nickname}`)"/>
                        <span class="cart-num">+{{ cartData.length }}</span>
                    </van-sticky>
                    <template v-if="giftList.length">
                        <div v-for="item in giftList" :id="item.nameEn" :key="item.id" class="present-wrap">
                            <div class="present-caption">
                                {{ item.nameEn }}
                            </div>
                            <div class="present-category">
                                <div v-for="gift in item.list" :key="gift.id"
                                     class="present-container present-category__item ">
                                    <div class="present">
                                        <div class="present__image-wrap">
                                            <img :alt="gift.nameEn" :src="gift.image | cdnurl" class="present__image">
                                        </div>
                                        <div class="present__item-info">
                                            <div class="present__present-name-wrapper">
                                                <p class="present__present-name">{{ gift.name }}</p>
                                                <div class="present__present-price">
                                                    <p class="t-gray-700">
                                                        <span
                                                            v-if="systemVersion === 'B' ||systemVersion === 'C'||systemVersion === 'D'">$</span>{{
                                                            gift.price
                                                        }}
                                                    </p>
                                                    <img v-if="systemVersion === 'A'" alt="credits"
                                                         class="credits-icon" src="@/assets/svg/creditсoin.svg">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="present__buy">
                                            <button :class="{disabled: gift.added}"
                                                    class="ui-button ui-button__type-primary"
                                                    @click="handleAddShoppingCart(gift)">
                                                <div class="ui-button__label">
                                                    {{ $t('OrderNow') }}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <el-empty v-else :description="$t('DataIsEmpty')"></el-empty>
                </div>
            </el-col>

            <el-col :md="6" :sm="6" :xs="24">
                <right-sider-bar></right-sider-bar>
            </el-col>
        </el-row>


    </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import {Icon, Sticky} from 'vant';
import RightSiderBar from '@/page/components/common/RightSiderBar.vue'
import {cdnurl} from '@/filters';
import {throttle} from '@/utils/utils';

export default {
    name: 'presents',
    components: {
        RightSiderBar,
        VanIcon: Icon,
        VanSticky: Sticky
    },
    data: function () {
        return {
            userId: '',
            nickname: '',
            cateVal: '',
            giftCategory: [],
            giftList: [],
        }
    },
    computed: {
        ...mapState({
            cartData: 'cartData'
        }),
        ...mapGetters({
            systemVersion: 'getSystemVersion'
        }),
    },
    created() {
        this.userId = this.$route.query.to_uid
        this.nickname = this.$route.query.nickname
        this.getGiftCategory()
    },
    mounted() {
        document.querySelector('body').addEventListener('scroll', this.handleScroll);
        this.getAll()
    },
    beforeDestroy() {
        document.querySelector('body').removeEventListener('scroll', this.handleScroll);
    },
    beforeRouteEnter(to, from, next) {
        if (from.path !== '/presents/cart') {
            next((vm) => {
                vm.clearCartData()
            })
        } else {
            next()
        }
    },
    methods: {
        ...mapMutations([
            'addCartData',
            'clearCartData'
        ]),
        handleScroll() {
            const currentPosition = document.body.scrollTop;
            const headerHeight = this.$refs.cateRef.clientHeight
            let newActiveSection = 0;
            for (let i = this.giftList.length - 1; i >= 0; i--) {
                const section = document.getElementById(this.giftList[i].nameEn);
                const sectionTop = section.offsetTop;
                // console.log(currentPosition, sectionTop, headerHeight)
                if (currentPosition >= sectionTop - headerHeight) {
                    newActiveSection = i;
                    break;
                }
            }
            this.cateVal = this.giftList[newActiveSection].nameEn;
        },
        scrollToSection(id) {
            const section = document.getElementById(id);
            const headerHeight = this.$refs.cateRef.clientHeight
            // console.log('scrollToSection ', section, section.offsetTop)
            document.querySelector('body').removeEventListener('scroll', this.handleScroll);
            document.body.scrollTo({
                top: section.offsetTop - headerHeight,
                behavior: 'smooth',
            });
            setTimeout(() => {
                document.querySelector('body').addEventListener('scroll', this.handleScroll);
            }, 500);
        },
        routeTo(path) {
            this.$router.push(path)
        },
        handleCateChange(val) {
            this.scrollToSection(val)
        },
        goBack() {
            this.$router.go(-1)
        },
        /**
         * 添加到购物车
         */
        handleAddShoppingCart(item) {
            if (!item.added) {
                item.added = true
                this.$set(item, 'num', 1);
                this.addCartData(item)
            }
        },
        getGiftCategory() {
            this.$http.post('/mall/getCategory', {type: 'real_gift'})
                .then(res => {
                    if (res.code == 1) {
                        this.giftCategory = res.data
                        this.cateVal = res.data[0].nameEn
                        // this.getGiftList(res.data[0].id)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        getGiftList(cid) {
            this.$http.post('/mall/getRealGiftsList', {category_id: cid, page: 1, limit: 999, type: ''})
                .then(res => {
                    if (res.code == 1) {
                        this.giftList = res.data.list || []
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        getAll() {
            this.$http.post('/mall/getAll', {type: 'real_gift'})
                .then(res => {
                    if (res.code == 1) {
                        this.giftList = res.data || []
                        this.$nextTick(() => {
                            this.handleScroll()
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        }
    }
}
</script>

<style scoped>
.ui-button__type-primary {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: .2s all linear;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    color: #fff;
    border-color: #f77705;
    background: #f77705;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
}

.ui-button__type-primary.disabled {
    background: #aca9a7;
    border-color: #aca9a7;
}

.ui-button__label {
    position: relative;
    display: flex;
}

.ui-button__type-primary:not(.disabled):hover {
    border-color: #da6a05;
    background: #da6a05;
}
</style>

<style lang="scss" scoped>

.categories-header {
    position: sticky;
    z-index: 2;
    top: 0;
    box-shadow: 0 2px 4px 0 #00000029;
    background-color: #fff;

    .top-bar {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 5px;
        border-bottom: 1px solid #d6d6d6;
        background-color: #fff;
        color: #333;

        .back-btn {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333;;
        }

        .caption {
            flex: 1;
            text-align: center;
            font-size: 18px;

            .giftedUser {
                font-size: 26px;
                color: #b86800;
            }
        }
    }

    .cate-group {
        overflow: auto;
        box-sizing: border-box;
        width: 100%;
        padding: 5px;
        white-space: nowrap;
    }
}

.present-wrapper {
    padding-bottom: 80px;

    .cart-wrap {
        position: fixed;
        top: 75vh;
        right: 20px;
        z-index: 999;
        width: 60px;
        height: 60px;

        .cart-num {
            position: absolute;
            top: 0;
            right: 0;
            padding: 4px;
            border-radius: 50%;
            background-color: var(--theme-primary);
            font-size: 12px;
            color: var(--white);
        }

        ::v-deep(.van-sticky) {
            right: 20px;
            left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: var(--primaryColor);
        }
    }

    .present-wrap {

        .present-caption {
            padding: 20px 20px 0;
            font-size: 16px;
            font-weight: 600;
        }

        .present-category {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            padding: 20px 20px 0px;

            .present-category__item {
                width: calc(50% - 10px);
                min-height: 260px;

                .present {
                    position: relative;
                    width: 100%;
                    min-height: 260px;
                    transition: all .25s ease-out;
                    border: 1px solid #d6d6d6;
                    border-radius: 4px;
                    background-color: #fff;

                    &:hover {
                        cursor: pointer;
                        transition: all .25s ease-out;
                        border: 1px solid #f77705;
                        box-shadow: 0 3px 16px 2px #0000001f, 0 9px 12px 1px #00000024, 0 5px 6px -3px #0003;
                    }

                    .present__image-wrap {
                        height: 180px;
                        border-radius: 4px 4px 0 0;

                        .present__image {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }

                    .present__item-info {
                        padding-right: 8px;
                        padding-left: 8px;
                        padding-bottom: 12px;
                        border-radius: 0 0 4px 4px;

                        .present__present-name-wrapper {
                            overflow: hidden;
                            padding-right: 16px;
                            padding-left: 16px;

                            .present__present-name {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .present__present-price {
                                display: flex;
                                align-items: center;

                                .credits-icon {
                                    width: 20px;
                                    height: auto;
                                    margin-left: 2px;
                                }
                            }
                        }
                    }

                    .present__buy {
                        border-radius: 8px;
                        padding-right: 24px;
                        padding-left: 24px;
                        padding-bottom: 16px;
                    }
                }
            }
        }
    }
}

</style>