<template slot-scope="scope">
    <div>

        <el-form class="profileForm" :model="profileForm" :rules="rules" ref="profileForm" label-width="160px" :label-position="labelPosition">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24">
                    <el-form-item :label="$t('avatar')" style="margin-bottom: 0;">
                        <cropper @changeAvatar="changeAvatar" :avatar="userinfo.avatar"></cropper>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('nickname')" prop="nickname">
                        <el-input v-model="profileForm.nickname" :placeholder="$t('nicknameRegister')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('birthday')" prop="birthday">
                        <el-date-picker v-model="profileForm.birthday" value-format="yyyy-MM-dd" type="date"
                            :placeholder="$t('PleaseSelect')">
                        </el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('country')" prop="country">
                        <el-cascader v-model="profileForm.country" :placeholder="$t('trySearch')" style="width: 100%;"
                            :options="options.country" :props="{ emitPath: false }" :clearable="true" filterable
                            :show-all-levels="false"></el-cascader>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('Occupation')" prop="occupation">
                        <el-select v-model="profileForm.occupation" filterable :placeholder="$t('PleaseSelect')">
                            <el-option v-for="item in options.option.occupation" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('height')" prop="height">
                        <el-input v-model="profileForm.height" :placeholder="$t('PleaseEnterContent')">
                            <template slot="append">cm</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('weight')" prop="weight">
                        <el-input v-model="profileForm.weight" :placeholder="$t('PleaseEnterContent')">
                            <template slot="append">kg</template>
                        </el-input>
                    </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('eyeColor')" prop="eye_color">
                        <el-select v-model="profileForm.eye_color" filterable :placeholder="$t('PleaseSelect')">
                            <el-option v-for="item in options.option.eye_color" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('hairColor')" prop="hair_color">
                        <el-select v-model="profileForm.hair_color" filterable :placeholder="$t('PleaseSelect')">
                            <el-option v-for="item in options.option.hair_color" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('marriageStatus')" prop="marriage">
                        <el-radio-group v-model="profileForm.marriage">
                            <el-radio v-for="item in options.option.marriage" :label="item.value" :key="item.value">
                                {{ item.label }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <!-- <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('kidsNum')" prop="kids_num">
                        <el-input v-model="profileForm.kids_num" type="number"></el-input>
                    </el-form-item>
                </el-col> -->
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('Education')" prop="edu">
                        <el-select v-model="profileForm.edu" filterable :placeholder="$t('PleaseSelect')">
                            <el-option v-for="item in options.option.edu" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('religion')" prop="religion">
                        <el-select v-model="profileForm.religion" filterable :placeholder="$t('PleaseSelect')">
                            <el-option v-for="item in options.option.religion" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('smokingStatus')" prop="smoking">
                        <el-select v-model="profileForm.smoking" filterable :placeholder="$t('PleaseSelect')">
                            <el-option v-for="item in options.option.smoking" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12">
                    <el-form-item :label="$t('drinkingStatus')" prop="drinking">
                        <el-select v-model="profileForm.drinking" filterable :placeholder="$t('PleaseSelect')">
                            <el-option v-for="item in options.option.drinking" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24">
                    <el-form-item :label="$t('lookingfor')" prop="lookingfor">
                        <el-input type="textarea" :rows="2" :placeholder="$t('PleaseEnterContent')"
                            v-model="profileForm.lookingfor">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24">
                    <el-form-item :label="$t('introduction')" prop="introduction">
                        <el-input type="textarea" :rows="2" :placeholder="$t('PleaseEnterContent')"
                            v-model="profileForm.introduction">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24">
                    <el-form-item :label="$t('interest')">
                        <el-checkbox-group v-model="profileForm.interest" size="small">
                            <el-checkbox v-for="item in options.option.interest" :label="item.value.toString()"
                                :key="item.value.toString()" border>{{ item.label }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24">
                    <el-form-item>
                        <el-button type="primary" @click="submitForm()">{{ $t('submit') }}</el-button>
                        <el-button @click="resetForm('profileForm')">{{ $t('reset') }}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>


        </el-form>
    </div>
</template>
         
<script>
import cropper from './cropper.vue';
import Auth from '../../../services/auth';
export default {
    name: 'profile',
    props: {
        userinfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    components: { cropper },
    data: function () {
        return {
            nickname: '',
            profileForm: {
                avatar: '',
                nickname: '',
                birthday: '',
                occupation: '',
                country: '',
                height: '',
                weight: '',
                eye_color: '',
                hair_color: '',
                marriage: '',
                kids_num: '',
                edu: '',
                religion: '',
                smoking: '',
                drinking: '',
                lookingfor: '',
                introduction: '',
                interest: []
            },
            rules: {
                nickname: [
                    { required: true, message: this.$t('nicknameRegister'), trigger: 'blur' },
                ],
                birthday: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                occupation: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                country: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                height: [
                    { required: true, message: this.$t('PleaseEnterContent'), trigger: 'blur' },
                ],
                weight: [
                    { required: true, message: this.$t('PleaseEnterContent'), trigger: 'blur' },
                ],
                eye_color: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                hair_color: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                marriage: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                edu: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                religion: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                smoking: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
                drinking: [
                    { required: true, message: this.$t('PleaseSelect'), trigger: 'blur' },
                ],
            },
            options: {
                country: [],
                option: {}
            },
            labelPosition: 'right'
        }
    },
    methods: {
        changeValue(e) {
            this.$forceUpdate()
        },
        changeAvatar(data) {
            this.profileForm.avatar = data.avatar
        },
        getOption() {
            this.$http.get('/config/getOption')
                .then((res) => {
                    if (res.code == 1) {
                        this.options = res.data
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        submitForm() {
            this.$refs['profileForm'].validate((valid) => {
                if (valid) {
                    this.$http.post('/user/profile', this.profileForm)
                        .then((res) => {
                            if (res.code == 1) {
                                let userinfo = Auth.getToken()
                                userinfo.avatar = this.profileForm.avatar;
                                userinfo.nickname = this.profileForm.nickname;
                                Auth.login(userinfo)
                                let headerAvatarArr = document.querySelectorAll('.headerAvatar')[0]
                                let headerAvatar = headerAvatarArr.getElementsByTagName("img")[0];
                                headerAvatar.src = this.cdnurl(this.profileForm.avatar)
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'success'
                                });
                                this.$emit("updateUserInfoSuccess")
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'error'
                                });
                            }

                        })
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
    created() {
        this.getOption();
        if (this.userinfo.userDetail) {
                this.profileForm = {
                    birthday: this.userinfo.birthday ? this.userinfo.birthday : '',
                    occupation: this.userinfo.userDetail.occupation ? this.userinfo.userDetail.occupation : '',
                    country: this.userinfo.userDetail.country ? this.userinfo.userDetail.country : '',
                    height: this.userinfo.userDetail.height ? this.userinfo.userDetail.height : '',
                    weight: this.userinfo.userDetail.weight ? this.userinfo.userDetail.weight : '',
                    eye_color: this.userinfo.userDetail.eye_color ? this.userinfo.userDetail.eye_color : '',
                    hair_color: this.userinfo.userDetail.hair_color ? this.userinfo.userDetail.hair_color : '',
                    marriage: this.userinfo.userDetail.marriage ? this.userinfo.userDetail.marriage : '',
                    kids_num: this.userinfo.userDetail.kids_num ? this.userinfo.userDetail.kids_num : '',
                    edu: this.userinfo.userDetail.edu ? this.userinfo.userDetail.edu : '',
                    religion: this.userinfo.userDetail.religion ? this.userinfo.userDetail.religion : '',
                    smoking: this.userinfo.userDetail.smoking ? this.userinfo.userDetail.smoking : '',
                    drinking: this.userinfo.userDetail.drinking ? this.userinfo.userDetail.drinking : '',
                    lookingfor: this.userinfo.userDetail.lookingfor ? this.userinfo.userDetail.lookingfor : '',
                    introduction: this.userinfo.userDetail.introduction ? this.userinfo.userDetail.introduction : '',
                    interest: this.userinfo.userDetail.interest.split(","),
                    avatar: this.userinfo.avatar,
                    nickname: this.userinfo.nickname
                }

            }


    },

}
</script>
         
<style>
.profileForm .el-input,
.profileForm .el-select {
    width: 100% !important;
}

.profileForm .el-checkbox {
    margin-left: 0 !important;
}

.profileForm .el-input-group__append {
    background-color: #e8f0fe !important;
    border-color: #e8f0fe  !important;
    color: #787878 !important;

}
.profileForm .el-form-item{
    margin-bottom: 18px;
}
</style>