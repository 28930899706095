<template>
    <div class="login-wrap transition-box">
        <!-- <transition name="el-fade-in"> -->
        <el-form v-show="show === 'login'" ref="loginForm" :model="loginForm" :rules="rules"
                 class="login-container login-from" label-position="top">
            <h1 class="title">{{ $t('login') }}</h1>
            <el-form-item :label="$t('email')" prop="email">
                <el-input v-model="loginForm.email" :placeholder="$t('emailRegister')" autocomplete="off"
                          type="text"></el-input>
            </el-form-item>
            <el-form-item :label="$t('password')" prop="password">
                <el-input v-model="loginForm.password" :placeholder="$t('passwordLoginRequire')" autocomplete="off"
                          type="password" @click="alert(111)" @keyup.enter.native="doLogin"></el-input>
            </el-form-item>
            <el-form-item>
                <el-link :underline="false" type="primary" @click="show = 'register'">
                    {{ $t('register') }}
                </el-link>
                <el-link :underline="false" style="float: right;" type="primary" @click="show = 'resetpwd'">
                    {{ $t('forgotPassword') }}
                </el-link>

            </el-form-item>
            <div class="form-bottom">
                <el-form-item>
                    <el-button style="width: 100%;" type="primary" @click="doLogin">{{ $t('login') }}</el-button>
                </el-form-item>
            </div>


        </el-form>
        <!-- </transition>

        <transition name="el-fade-in"> -->
        <el-form v-show="show == 'register'" ref="registerForm" :model="registerForm" :rules="rules"
                 class="login-container" label-position="top" label-width="80px">
            <h1 class="title">{{ $t('register') }}</h1>
            <el-form-item :label="$t('email')" prop="email">
                <el-input v-model="registerForm.email" :placeholder="$t('emailRegister')" autocomplete="off"
                          type="text"></el-input>
            </el-form-item>
            <el-form-item :label="$t('nickname')" prop="nickname">
                <el-input v-model="registerForm.nickname" :placeholder="$t('nicknameRegister')" autocomplete="off"
                          type="text"></el-input>
            </el-form-item>
            <el-form-item :label="$t('password')" prop="password">
                <el-input v-model="registerForm.password" :placeholder="$t('passwordLoginRequire')"
                          autocomplete="off"
                          type="password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('repassword')" prop="repassword">
                <el-input v-model="registerForm.repassword" :placeholder="$t('repasswordRequire')"
                          autocomplete="off"
                          type="password"></el-input>
            </el-form-item>
            <!--            <el-form-item :label="$t('registerCode')" prop="code">
                            <el-input v-model="registerForm.code" :placeholder="$t('registerCode')" autocomplete="off"
                                      type="text">
                                <template slot="append" class="sendBtn">
                                    <el-button :disabled="sendWait" plain type="primary" @click="sendCode('register')"><i
                                        class="el-icon-message "></i> {{ sendBtnText }}
                                    </el-button>
                                </template>
                            </el-input>

                        </el-form-item>-->
            <span v-if="showRegisterTips" class="sendTips">If you have not received the verification code, please check your spam mailbox </span>
            <el-form-item style="margin-bottom: 0;">
                <el-link :underline="false" type="primary" @click="show = 'login'">{{
                        $t('registerSignInNow')
                    }}
                </el-link>
            </el-form-item>
            <div class="form-bottom">
                <el-form-item>
                    <el-button style="width: 100%;" type="primary" @click="doRegister">{{
                            $t('register')
                        }}
                    </el-button>
                </el-form-item>
            </div>

        </el-form>
        <!-- </transition>
        <transition name="el-fade-in"> -->
        <el-form v-show="show == 'resetpwd'" ref="resetPwdForm" :model="resetPwdForm" :rules="rules"
                 class="login-container" label-position="top" label-width="80px">
            <h1 class="title">{{ $t('resetPwd') }}</h1>
            <el-form-item :label="$t('email')" prop="email">
                <el-input v-model="resetPwdForm.email" :placeholder="$t('emailRegister')" autocomplete="off"
                          type="text"></el-input>
            </el-form-item>

            <el-form-item :label="$t('password')" prop="password">
                <el-input v-model="resetPwdForm.password" :placeholder="$t('passwordLoginRequire')"
                          autocomplete="off"
                          type="password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('repassword')" prop="resetPwdRepassword">
                <el-input v-model="resetPwdForm.resetPwdRepassword" :placeholder="$t('repasswordRequire')"
                          autocomplete="off" type="password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('registerCode')" prop="code">
                <el-input v-model="resetPwdForm.code" :placeholder="$t('registerCode')" autocomplete="off"
                          type="text">
                    <template slot="append" class="sendBtn">
                        <el-button :disabled="sendWait" plain type="primary" @click="sendCode('resetpwd')"><i
                            class="el-icon-message "></i> {{ sendBtnText }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <span v-if="showResetTips" class="sendTips">If you have not received the verification code, please check your spam mailbox </span>
            <el-form-item>
                <el-link :underline="false" type="primary" @click="show = 'login'">{{ $t('signInNow') }}</el-link>
            </el-form-item>
            <div class="form-bottom">
                <el-form-item>
                    <el-button style="width: 100%;" type="primary" @click="doReSetPwd">
                        {{ $t('submit') }}
                    </el-button>
                </el-form-item>
            </div>
            <!-- <el-row style="text-align: center;" :gutter="20">
                    <el-col :span="12">
                        <el-link type="primary" @click="show = 'login'">{{ $t('login') }}</el-link>
                    </el-col>
                    <el-col :span="12">
                        <el-link type="primary" @click="show = 'register'">{{ $t('register') }}</el-link>
                    </el-col>

                </el-row> -->
        </el-form>
        <!-- </transition> -->
    </div>
</template>
<script>
import {mapMutations, mapActions} from 'vuex';
import Auth from '../../../services/auth.js'
import {cdnurl} from "@/filters";

export default {
    name: 'LoginView',
    props: ['redirect'],
    data: function () {
        var checkPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('repasswordRequire')));
            } else if (value !== this.registerForm.password) {
                callback(new Error(this.$t('repasswordRule')));
            } else {
                callback();
            }
        };
        var resetPwdCheckPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('repasswordRequire')));
            } else if (value !== this.resetPwdForm.password) {
                callback(new Error(this.$t('repasswordRule')));
            } else {
                callback();
            }
        };
        return {
            showRegisterTips: false,
            showResetTips: false,
            loginForm: {
                email: '',
                password: '',

            },
            registerForm: {
                email: '',
                nickname: '',
                password: '',
                repassword: '',
                code: '',
                inviterId: 0,
            },
            resetPwdForm: {
                email: '',
                password: '',
                resetPwdRepassword: '',
                code: ''
            },
            rules: {
                email: [
                    {required: true, message: this.$t('emailRegister'), trigger: 'blur'},
                    {type: 'email', message: this.$t('emailRule'), trigger: 'blur'}
                ],
                password: [
                    {required: true, message: this.$t('passwordLoginRequire'), trigger: 'blur'},
                    {min: 6, max: 30, message: this.$t('passwordLoginRule'), trigger: 'blur'}
                ],
                repassword: [
                    {required: true, message: this.$t('repasswordRequire'), trigger: 'blur'},
                    {validator: checkPassword, trigger: 'blur'},
                ],
                resetPwdRepassword: [
                    {required: true, message: this.$t('repasswordRequire'), trigger: 'blur'},
                    {validator: resetPwdCheckPassword, trigger: 'blur'},
                ],

                nickname: [
                    {required: true, message: this.$t('nicknameRegister'), trigger: 'blur'},
                ],

                code: [
                    {required: true, message: this.$t('registerCodeRule'), trigger: 'blur'},
                ],

            },
            show: 'login',

            sendWait: false,
            waitSecond: 60,
            sendBtnText: this.$t('send'),

        }
    },
    methods: {
        ...mapMutations([
            'setIsLogin'
        ]),
        ...mapActions([
            'requestUserinfo'
        ]),
        cdnurl,
        doLogin: function () {
            this.$refs['loginForm'].validate((valid) => {
                if (valid) {
                    this.$http.post('/user/login', this.loginForm)
                        .then((res) => {
                            if (res.code == 1) {
                                this.setIsLogin(true)
                                // 设置用户信息
                                Auth.login(res.data.userinfo)
                                this.requestUserinfo()
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'success'
                                });

                                this.$router.push({path: this.redirect})
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'error'
                                });
                            }

                        })
                } else {
                    return false;
                }
            });

        },
        doRegister: function () {
            this.$refs['registerForm'].validate((valid) => {
                if (valid) {
                    this.$http.post('/user/register', this.registerForm)
                        .then((res) => {
                            if (res.code == 1) {
                                // 设置用户信息
                                Auth.login(res.data.userinfo)
                                this.requestUserinfo()
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'success'
                                });
                                this.$router.push({path: this.redirect})
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'error'
                                });
                            }

                        })
                } else {
                    return false;
                }
            });
        },
        doReSetPwd: function () {
            this.$refs['resetPwdForm'].validate((valid) => {
                if (valid) {
                    this.$http.post('/user/resetpwd', this.resetPwdForm)
                        .then((res) => {
                            if (res.code == 1) {
                                // 设置用户信息
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'success'
                                });
                                this.show = 'login'
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'error'
                                });
                            }

                        })
                } else {
                    return false;
                }
            });
        },
        sendCode: function (event) {
            let that = this;
            if (that.sendWait == true) {
                return;
            }
            let email = event == "register" ? that.registerForm.email : that.resetPwdForm.email;
            let verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            if (!verify.test(email)) {
                that.$message.error(that.$t('emailRule'));
            } else {
                let obj = {
                    email: email,
                    event: event || 'register'
                }
                that.$http.post('/common/sendEmail', obj)
                    .then((res) => {
                        if (res.code == 1) {
                            // 设置用户信息
                            that.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            that.countDown()
                            that.smsInterval = setInterval(function () {
                                that.countDown()
                            }, 1000)
                            if (event === "register") {
                                that.showRegisterTips = true
                            } else {
                                that.showResetTips = true
                            }
                        } else {
                            that.$message.error(res.msg);
                        }

                    })


            }
        },
        countDown() {
            var that = this
            that.waitSecond--
            console.log(that.waitSecond)
            if (that.waitSecond === 0) {
                clearInterval(that.smsInterval)
                that.sendBtnText = this.$t("sendAgain")
                that.waitSecond = 60
                that.sendWait = false;
            } else {
                that.sendWait = true;
                that.sendBtnText = that.$t("waitBtnText", {
                    second: that.waitSecond,
                    plural: that.waitSecond > 1 ? 's' : ''
                })
            }
        },


    },
    created() {
        if (this.$route.query.show) {
            this.show = this.$route.query.show;
        }
        if (this.$route.query.inviter_id) {
            this.registerForm.inviterId = this.$route.query.inviter_id;
        }


        if (Object.keys(this.websocket.ws).length != 0) {
            this.websocket.ws.close()
        }

    },
}
</script>
<style scoped>
.sendTips {
    font-size: 12px;
    color: #8f8f8f;
}


.el-form-item__label {
    font-weight: 800;
}

.login-container {
    border-radius: 10px;
    margin: 0px auto;
    width: 350px;
    padding: 10px 35px 0 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    text-align: left;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);

    /* background: linear-gradient( to bottom, white 0%, white 78%, #d6dcf5 78.01%, #d6dcf5 100% ) */
}

.login-wrap {
    box-sizing: border-box;
    /* background-color: #112346; */
    /* background-repeat: no-repeat; */
    background-position: center right;
    /* background-size: 100%; */
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    z-index: 2000;
}

.title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
    border: none !important;
    padding: 0 !important;
}

.el-input-group__append {
    color: #fff !important;
    background-color: #F2C94C !important;
    border-color: #F2C94C !important;
}

.el-input-group__append button.el-button {
    color: #fff !important;
    background-color: #F2C94C !important;
    border-color: #F2C94C !important;
}

.el-form--label-top .el-form-item__label {
    padding: 0 !important;
}

.el-link {
    font-weight: 900 !important;
}

.form-bottom {
    background-color: #e8f0fe;
    padding: 30px 35px 15px 35px;
    margin: 0 -35px;
    border-radius: 0 0 10px 10px;
}

.login-from .el-form-item__label:before {
    display: none;
}


</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
    .login-main {
        height: calc(100vh - 60px);
        margin-top: 0;
        background-size: cover;
    }
    .login-container {
        width: 80%;
    }

    .login-wrap {
        width: 90%;
        left: 50%;
    }

    .carousel-item {
        background-repeat: initial;
        background-size: unset;
    }
}
</style>