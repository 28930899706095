<template>
    <div class="mailbox-list" style="margin-bottom:20px">
        <el-row class="card-header shadow" :gutter="1">
            <div class="card-header-row">
                <el-col :xs="18" :sm="18" :md="18" :lg="18">
                    <h1 style="float: left;" class="header-title"><i class="icon-al-youjian_" style="font-size: 24px;"></i>
                        {{ $t('Mailbox') }}</h1>
                </el-col>
            </div>
        </el-row>

        <el-row class="card-body m-no-padding">
            <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                <el-tab-pane :label="$t('Inbox')" name="inbox">
                    <template v-if="activeName == 'inbox'">
                        <MailboxTable activeName="inbox"></MailboxTable>
                    </template>
                </el-tab-pane>
                <el-tab-pane :label="$t('Outbox')" name="outbox">
                    <template v-if="activeName == 'outbox'">
                    <MailboxTable activeName="outbox"></MailboxTable>
                    </template>
                </el-tab-pane>
                <el-tab-pane :label="$t('Starred')" name="starred">
                    <template v-if="activeName == 'starred'">
                    <MailboxTable activeName="starred"></MailboxTable>
                    </template>
                </el-tab-pane>
                <el-tab-pane :label="$t('Trash')" name="trash">
                    <template v-if="activeName == 'trash'">
                    <MailboxTable activeName="trash"></MailboxTable>
                    </template>
                </el-tab-pane>
            </el-tabs>
        </el-row>
    </div>
</template>

<script>
import MailboxTable from './table.vue'
export default {
    name: 'list',
    components: { MailboxTable },
    data: function () {
        return {
            activeName: 'inbox'
        }
    },
    methods: {
        handleClick(tab, event) {
            this.activeName = tab.name
        }
    },
    created() {

    },
}
</script>

<style lang="scss" scoped>
.card-header {
    height: 60px;
    padding: 0 15px;
    border: 1px solid #ebebeb;
    border-bottom: none;

    .card-header-row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 767px) {
    

    .card-header {
        display: none;
    }

    .mailbox-list {

        :deep(.el-tabs){
            .el-tabs__nav {
                width: 100%;
            }
            .el-tabs__item {
                width: 25%;
                text-align: center;
            }
        }
    }

}
</style>


