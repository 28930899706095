<template>
  <div class="">
    <div class="main-wrapper">
      <el-row :gutter="15">
        <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">

          <el-col :xs="24" :sm="24" :md="24" class="hidden-xs-only">
            <el-card class="box-card">
              <userinfo></userinfo>
            </el-card>
          </el-col>
          <el-col style="margin-top: 15px;" :xs="24" :sm="6" :md="6" class="hidden-xs-only">
            <el-card class="siderbar-card">
              <siderbar></siderbar>
            </el-card>
          </el-col>
          <el-col class="right-content m-no-padding m-no-margin" style="margin-top: 15px;" :xs="24" :sm="18" :md="18" >

            <el-card class="box-card">
              <h1 class="header-title" style="margin-bottom: 20px;"><i class="icon-al-xianxingduoseremen"
                  style="font-size: 24px;"></i>
                {{ $t('MyTopics') }}
                <el-popover placement="bottom-end" trigger="manual" width="470" :close-on-click-modal="false"
                  v-model="publishVisible" popper-class="publish-popper">
                  <publish @publishAfter="publishAfter"></publish>
                  <i class="el-icon-s-promotion float-right" style="cursor: pointer;" @click="publishVisible = !publishVisible"
                    slot="reference"></i>
                </el-popover>
              </h1>
              <el-timeline v-infinite-scroll="getList" infinite-scroll-immediate="false" infinite-scroll-delay="200">
                <el-timeline-item color="#3B5998" v-for="(row, index) in list" :key="index"
                  :timestamp="row.createtime | getDateDiff" placement="top">
                  <el-card>
                    <h4> {{ row.content }}</h4>
                    <photo-show-view :images="row.imagesArr"></photo-show-view>
                    <p class="post-footer">
                      <span class="footer-zan" @click="like(row)"><i
                          :class="{ 'icon-al-zans': row.liked == 0, 'icon-al-icon_goodfill': row.liked > 0 }"></i>
                        {{ $t('Like') }}{{ row.like > 0 ? `(${row.like})` : '' }}</span>

                      <!-- <span class="footer-comments" @click="comment(row)">
                        <i class="icon-al-pinglun"></i> {{ $t('Comments') }} {{ row.comment > 0 ? `(${row.comment})` :
                          '' }}
                      </span> -->
                      <span class="footer-delete" @click="del(row,index)">
                        <i class="el-icon-delete"></i> {{ $t('delete') }} 
                      </span>
                    </p>
                    <el-dialog :title="$t('CommentList')" :visible.sync="showCommentItemId == row.id" width="600px"
                      custom-class="commentList" :before-close="closeCommentList">
                      <comment v-if="showCommentItemId == row.id" :id="row.id"></comment>
                    </el-dialog>
                  </el-card>
                </el-timeline-item>

              </el-timeline>
              <el-empty v-if="list.length == 0 && !loading" :description="$t('DataIsEmpty')"></el-empty>
            </el-card>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6">
          <right-sider-bar></right-sider-bar>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
         
<script>
import RightSiderBar from '../components/common/RightSiderBar.vue'
import userinfo from '../components/user/userinfo.vue'
import PhotoShowView from '../components/hot/PhotoShowView.vue'
import siderbar from '../components/user/siderbar.vue'
import comment from '../components/hot/comment.vue'
import auth from '../../services/auth'
import Publish from '../components/hot/Publish.vue'
export default {
  name: 'Myhot',
  components: { userinfo, siderbar, RightSiderBar, PhotoShowView, comment, Publish },
  data: function () {
    return {
      list: [],
      page: 1,
      limit: 12,
      loading: false,
      total: 0,
      publishVisible: false,
      showCommentItemId: '',
      isEnd: false,
    }
  },
  methods: {
    getList() {
      let that = this;
      if (that.isEnd == true) {
        return
      }

      let params = {
        page: that.page,
        limit: that.limit,
        user_id: auth.getToken().id
      }
      that.$http.get('/hot/list', { params: params })
        .then((res) => {
          if (res.code == 1) {
            let list = res.data.list || []
            if (list.length > 0) {
              that.list = that.list.concat(list);
              that.page += 1
            } else {
              that.isEnd = true;
            }
          } else {
            that.$message.error(res.msg);
          }
          that.loading = false
        })
    },
    closeCommentList() {
      this.showCommentItemId = ''
    },
    comment(row) {
      this.showCommentItemId = row.id
    },
    publishAfter: function (status) {
            this.publishVisible = false;
            if (status) {
                this.page = 1

                this.list = [];
                this.getList()
            }

        },
    like(row) {
      let that = this
      that.$http.post('/hot/like', { id: row.id })
        .then((res) => {
          if (res.code == 1) {
            if (row.liked == 1) {
              row.liked = 0
            } else {
              row.liked = 1
            }

          } else {
            that.$message.error(res.msg);
          }
        })
    },
    del(item,index) {
      this.$confirm(this.$t('confirmDel'), this.$t('warning'), {
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.$http.post('/hot/del', { id: item.id })
                    .then((res) => {
                        if (res.code == 1) {
                            this.list.splice(index, 1)
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: 'success'
                            });

                        } else {
                            this.$message.error(res.msg);
                        }

                    })

            });
    }
  },
  created() {
    this.getList()
  },

}
</script>

<style>
.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
  text-align: left !important;
}

.user-tabs {
  height: calc(100vh - 280px);
  ;
}

.user-tabs .el-tabs__item {
  height: 50px;
  line-height: 50px;
}

.siderbar-card .el-card__body {
  padding: 0;
}

.right-content {
  height: calc(100vh - 307px);
  overflow: auto;
}

.footer-delete {
  margin-left: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .publish-popper {
    max-width: 85vw;
  }
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  .right-content {
    overflow: auto;
    height: auto;
  }
}
</style>