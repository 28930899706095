<template>
    <div>
        <el-tabs type="border-card" v-model="category_name" @tab-click="handleClick">
            <el-tab-pane v-for="(cate, index) in categoryList" :key="cate.id" :name="cate.name">
                <span slot="label">{{ cate.name }}</span>
                <el-row 
                    :gutter="20" 
                    style="height: 400px;overflow: auto;" 
                    v-infinite-scroll="getList"
                    infinite-scroll-immediate="false"
                >
                    <el-col class="photo-item"  v-for="(item, index) in list" :key="item.id" :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
                        <div @mouseenter="showOperateId = item.id" @mouseleave="showOperateId = 0">
                            <el-image 
                                :src="item.image | cdnurl" lazy 
                                :class="{ 'is-active': isActiveId == item.id }"
                                @click="checkUrl(item)"
                            >
                                <div slot="placeholder" class="image-slot">
                                    Loading<span class="dot">...</span>
                                </div>
                            </el-image>
                            <div v-if="showOperateId == item.id">
                                <i v-if="isActiveId == item.id" class="el-icon-success" @click="checkUrl(item)"></i>
                                <i v-else class="el-icon-circle-check" @click="checkUrl(item)"></i>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <div v-if="isEnd" style="font-size: 12px;text-align:center;">{{ $t('NoMore') }}</div>
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { cdnurl } from '../../../filters';

import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
    components: { ElImageViewer },
    data() {
        return {
            searchData: {
                type: 'sticker',
                category_id: 0,
                page: 1,
                limit: 24,
            },
            list: [],
            imageUrl: '',
            isActiveId: 0,
            isEnd: false,
            categoryList: [],
            showViewer: false,
            viewerList: [],
            viewerIndex: 0,
            showOperateId: 0,
            category_name: '',
            currentTabIndex:0,
        }
    },
    methods: {
        showViewerList(index) {
            for (let i = 0; i < this.list.length; i++) {
                this.viewerList.push(this.cdnurl(this.list[i].file))
            }
            this.showViewer = true;
            this.viewerIndex = index;
        },
        closeViewer() {
            this.showViewer = false;
            this.viewerList = [];
        },
        getList() {
            let that = this;
            if (that.isEnd == true) {
                return
            }
            that.$http.get('/mall/getStickerList', { params: that.searchData })
                .then((res) => {
                    if (res.code == 1) {
                        let list = res.data.list || []
                        if (list.length > 0) {
                            that.list = that.list.concat(list);
                            that.searchData.page += 1
                        } else {
                            that.isEnd = true;
                            
                        }
                    } else {
                        that.$message.error(res.msg);
                    }

                })

        },
        getCategoryList() {
            let that = this;
            that.$http.get('/mall/getCategory', { params: { type: that.searchData.type } })
                .then((res) => {
                    if (res.code == 1) {
                        that.categoryList = res.data || []
                        if (that.categoryList.length > 0) {
                            that.category_name = that.categoryList[0].name
                            that.searchData.category_id = that.categoryList[0].id
                        }
                    } else {
                        that.$message.error(res.msg);
                    }

                })
        },
        handleClick(tab, event) {
            this.currentTabIndex = tab.index
            this.getData()
        },
        getData(){
            this.searchData.category_id = this.categoryList[this.currentTabIndex].id
            this.searchData.page = 1;
            this.list = [];
            this.isEnd = false;
            this.getList()
        },
        checkUrl(item) {
            if (this.isActiveId == item.id) {
                this.isActiveId = 0
                this.imageUrl = ''
            } else {
                this.isActiveId = item.id
                this.imageUrl = item.image
                this.$emit('changeFileUrl', { value: item.image, id: item.id })
            }

        },
    },
    created() {
        this.getCategoryList()
        setTimeout(() => {
            this.getList()
        }, 500);
    }
}
</script>

<style scoped>
.photo-item {
    height: 200px;
    line-height: 200px;
    text-align: center;
    margin-top: 10px;
    position: relative;

}

.el-image {
    width: 100%;
    background-color: #f5f7fa;
    border-radius: 6px;
}

.el-image img {
    cursor: pointer;
}

.el-image img {
    vertical-align: middle !important;
}

.photo-checkbox {
    position: absolute !important;
    top: -85px;
    right: 15px;
}

.photo-checkbox .el-checkbox__inner {
    width: 20px;
    height: 20px;
}

.photo-checkbox .is-checked .el-checkbox__inner::after {
    width: 8px !important;
    height: 10px !important;
}

.photo-item .is-active {
    outline: 5px solid #1538A2;
    outline-offset: 0px;
    background: linear-gradient(to right, #ffffff, rgb(210 221 255));
}

.photo-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.photo-uploader .el-upload {
    width: 100%;
}

.phpoto-uploader-icon {
    font-size: 28px;
    color: #8c939d;

    line-height: 178px;
    text-align: center;
}

.photo-item .el-icon-circle-check {
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 5px;
    cursor: pointer;
    color: #d5d5d5;
}

.photo-item .el-icon-success {
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 5px;
    cursor: pointer;
    color: #1538A2;
}

.photo-item .icon-al-delete {
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    color: #ff8282;
    line-height: 1;
}

.photo-item .el-icon-zoom-in {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: rgb(19 50 146 / 19%);
    font-size: 36px;
    border-radius: 50%;
    padding: 10px;
    color: #f7f7f7;
    cursor: pointer;
}

.photo-item .el-image {
    border: 1px solid #e1e1e1;
}

.photo-item .gift-footer {
    position: absolute;
    top: 90px;
    right: 20px;
    color: #1538A2;
}
.originalPrice{
    position: absolute;
    top: 90px;
    left: 20px;
    color:#adadad;
    text-decoration: line-through;
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {

}
</style>