<template>
    <div>
        <dialog id="dialog">
            <form method="dialog">
                <p>Hi, I'm a dialog.</p>
                <button>OK</button>
            </form>
        </dialog>

        <button onclick="dialog.showModal()">Open Dialog</button>
        <input type="datetime-local" />
    </div>
</template>

<script>
export default {
    created() {

    },
    mounted() {

    }
}
</script>