import Test from '@/page/Test.vue'
import login from '@/page/login.vue'
import message from '@/page/message.vue'
import index from '@/page/index.vue'
import hot from '@/page/hot.vue'
import Recharge from '@/page/credits.vue'
import userIndex from '@/page/user/index.vue'
import myhot from '@/page/user/myhot.vue'
// import userProfile from '@/page/user/profile.vue'
import changepwd from '@/page/user/changepwd.vue'
import NotFoundView from '@/page/404.vue'
import memberinfo from '@/page/user/memberinfo.vue'
import myphotos from '@/page/user/myphotos.vue'
import myvideos from '@/page/user/myvideos.vue'
import benefits from '@/page/credits/benefits.vue'
import mailbox from '@/page/mailbox.vue'
import maildetail from '@/page/mailbox/maildetail.vue'
import PaymentResult from '@/page/payment/result.vue'
import PeopleIndex from '@/page/people/Index.vue'
import ArticleDetail from '@/page/article/Detail.vue'
import PresentsList from '@/page/presents/List.vue'
import PhoneReserve from '@/page/presents/PhoneReserve.vue'
import OrdersGift from '@/page/orders/List.vue'
import OrdersPhone from '@/page/orders/PhoneOrders.vue'
import ChatVoiceChat from '@/page/chat/VoiceChat.vue'
import ChatVoiceChatRoom from '@/page/chat/VoiceChatRoom.vue'

export default [
    {
        path: '/test',
        component: Test,
        meta: {
            title: "Test",
            keepAlive: true
        }
    },
    {
        path: '/voicechat',
        component: ChatVoiceChat,
        meta: {
            title: "VoiceChat",
            keepAlive: true
        }
    },
    {
        path: '/voicechatroom',
        component: ChatVoiceChatRoom,
        meta: {
            title: "VoiceChatRoom",
            keepAlive: true
        }
    },
    {
        path: '/',
        component: index,
        meta: {
            title: "home",
            keepAlive: true
        }
    },
    {
        path: '/login',
        component: login,
        meta: {
            title: "login",
            keepAlive: false
        }
    },
    {
        path: '/message',
        component: message,
        meta: {
            title: 'message',
            keepAlive: true

        },

    },
    {
        path: '/mailbox',
        component: mailbox,
        meta: {
            title: 'Mailbox',
            keepAlive: true

        },
    },
    {
        path: '/maildetail/:id',
        component: maildetail,
        meta: {
            title: "maildetail",
            keepAlive: true
        }
    },
    {
        path: '/hot',
        component: hot,
        meta: {
            title: 'hotTopic',
            keepAlive: true

        },
    },
    {
        path: '/recharge',
        component: Recharge,
        meta: {
            title: 'Recharge',
            keepAlive: true

        },
    },
    {
        path: '/benefits',
        component: benefits,
        meta: {
            title: 'Benefits',
            keepAlive: true

        },
    },
    {
        path: '/user',
        component: userIndex,
        meta: {
            title: 'UserInformation',
            keepAlive: true

        },
    },

    {
        path: '/user/myhot',
        component: myhot,
        meta: {
            title: 'UserInformation',
            keepAlive: true

        }
    },
    {
        path: '/user/myphotos',
        component: myphotos,
        meta: {
            title: 'MyPhotos',
            keepAlive: true

        }
    },
    {
        path: '/user/myvideos',
        component: myvideos,
        meta: {
            title: 'MyVideos',
            keepAlive: true

        }
    },
    {
        path: '/user/changepwd',
        component: changepwd,
        meta: {
            title: 'ChangePassword',
            keepAlive: true

        }
    },
    {
        path: '/memberinfo/:id',
        component: memberinfo,
        meta: {
            title: "memberinfo",
            keepAlive: true
        }
    },
    {
        path: '/payment/result',
        component: PaymentResult,
        meta: {
            title: 'PaymentResult',
            keepAlive: true
        }
    },
    {
        path: '/people',
        component: PeopleIndex,
        meta: {
            title: 'People',
            keepAlive: true
        }
    },
    {
        path: '/article',
        component: ArticleDetail,
        meta: {
            title: 'Article',
            keepAlive: true
        }
    },
    {
        path: '/presents',
        component: PresentsList,
        meta: {
            title: 'Presents',
            keepAlive: true
        }
    },
    {
        path: '/presents/phone',
        component: PhoneReserve,
        meta: {
            title: 'PhoneReserve',
            keepAlive: true
        }
    },
    {
        path: '/orders',
        component: OrdersGift,
        meta: {
            title: 'OrdersGift',
            keepAlive: true
        }
    },
    {
        path: '/orders/phone',
        component: OrdersPhone,
        meta: {
            title: 'OrdersPhone',
            keepAlive: true
        }
    },
    {
        path: '/mine',
        redirect: {
            path: 'user',
            component: userIndex,
            meta: {
                title: 'UserInformation',
                keepAlive: true
            }
        }
    },
    {
        // not found handler
        path: '*',
        component: NotFoundView
    }
]