<template>
    <div class="mine main-wrapper">
        <div class="more__profile d-flex flex-column align-items-center s-p-y-2">
            <div class="more__profile-avatar s-p-2 s-m-b-1">
                <router-link class="" to="/mine">
                    <div class="d-flex align-items-center more__profile-avatar__img">
                        <div class="avatar" style="width: 96px; height: 96px;">
                            <img v-if="userinfo.avatar" :alt="`${userinfo.nickname} avatar`"
                                 :src="userinfo.avatar|cdnurl" class="avatar__photo">
                        </div>
                    </div>
                </router-link>
                <router-link class="icon-edit d-flex justify-content-center align-items-center" to="/user?profile=1">
                    <div class="ui-icon ui-icon__display-inline" style="width: 24px; height: 24px;">
                        <span class="ui-icon__display-block" style="width: 24px; height: 24px;">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path clip-rule="evenodd"
                                      d="M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z">
                                </path>
                            </svg>
                        </span>
                    </div>
                </router-link>
            </div>
            <div class="t-a-c">
                <div class="s-m-b-1 d-flex align-items-center justify-content-center">
                    <h1 class="t-gray-700 t-b-1 f-w-m"> {{ userinfo.nickname }} <span
                        class="s-m-l-2">( ID: {{ userinfo.id }} )</span></h1>
                </div>
                <router-link class="more__link-button t-b-2 f-w-m s-m-b-2" tag="h2" to="/benefits?type=vip">
                    <el-tag effect="plain" type="warning">
                        {{
                            userinfo.userDetail ?
                                dayjs(userinfo.userDetail.vip_expire_time * 1000).isBefore(dayjs()) ? 'Get VIP' : dayjs(userinfo.userDetail.vip_expire_time * 1000).format('MM/DD/YYYY')
                                : 'Get VIP'
                        }}
                    </el-tag>
                </router-link>
            </div>
        </div>

        <div class="s-p-x-3">
            <div class="s-m-b-3">
                <div class="get-credits s-p-1">
                    <div class="get-credits__container align-items-center">
                        <div class="credits_info__wrapper">
                            <div class="credits_info__block t-gray-600 t-a-c s-p-x-4 s-p-y-3">
                                <div class="d-flex justify-content-center t-t-4 f-w-m s-m-b-2">
                                    <img alt="credits" class="s-m-r-1" src="@/assets/svg/star.svg">
                                    {{ userinfo.free_money }}
                                </div>
                                <span class="t-c-1"> Complimentary </span>
                            </div>
                            <div class="credits_info__block t-gray-600 s-p-x-4 s-p-y-3 t-a-c">
                                <div class="d-flex justify-content-center t-t-4 f-w-m s-m-b-2">
                                    <img alt="credits" class="s-m-r-1" src="@/assets/svg/creditсoin.svg">
                                    {{ userinfo.money }}
                                </div>
                                <span class="t-c-1"> Purchased </span>
                            </div>
                        </div>
                        <button class="get-credits__icon" @click="handleRoute('/benefits?type=money')">
                            <div class="ui-icon ui-icon__display-inline" style="width: 65px; height: 65px;">
                                <span class="ui-icon__display-block" style="width: 65px; height: 65px;">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path clip-rule="evenodd"
                                              d="M12 3c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm4.5 9.9h-3.6v3.6h-1.8v-3.6H7.5v-1.8h3.6V7.5h1.8v3.6h3.6v1.8z"
                                              fill-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="s-m-b-4">
                <router-link class="d-block s-m-b-1" to="/recharge">
                    <ListItem title="Recharge"/>
                </router-link>
                <router-link class="d-block s-m-b-1" to="/user/myhot">
                    <ListItem title="Topics"/>
                </router-link>
                <router-link class="d-block s-m-b-1" to="/user/myphotos">
                    <ListItem title="Photos"/>
                </router-link>
                <router-link class="d-block s-m-b-1" to="/user/myvideos">
                    <ListItem title="Videos"/>
                </router-link>
                <router-link class="d-block s-m-b-1" to="/user/changepwd">
                    <ListItem title="Password"/>
                </router-link>
                <div class="d-block s-m-b-1" @click="customerService">
                    <ListItem title="Contact Us"/>
                </div>
            </div>
            <div class="s-m-b-5">
                <button class="button__logout d-flex s-p-3 f-w-m t-b-2" @click="logout">
                    <span>Log Out</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import ListItem from '@/widgets/listItem/ListItem.vue'

import Auth from '@/services/auth.js';
import {cdnurl} from '@/filters';
import {mapGetters} from "vuex";

export default {
    components: {
        ListItem
    },
    data: function () {
        return {
            dayjs: dayjs,
            userinfo: {},
        }
    },
    computed: {
        ...mapGetters({
            customerId: 'getCustomerId'
        }),

    },
    created() {
        this.getUserDetail()
    },
    methods: {
        customerService() {
            this.$router.push({
                path: '/message',
                query: {
                    id: this.customerId
                }
            })
        },
        getUserDetail() {
            let that = this;
            that.$http.post('/user/userinfo')
                .then((res) => {
                    if (res.code == 1) {
                        that.userinfo = res.data
                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        handleRoute(path) {
            this.$router.push(path)
        },
        logout: function () {
            let that = this;

            that.$http.post('/user/logout')
                .then((res) => {
                    if (res.code == 1) {
                        // 设置用户信息
                        that.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        Auth.delToken()
                        that.$router.push("/login")
                    } else {
                        that.$message.error(res.msg);
                    }

                })


        },
    }
}
</script>

<style lang="scss" scoped>
.mine {

    .s-p-y-2 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .more__profile {

        .more__profile-avatar {
            position: relative;

            &::before {
                position: absolute;
                top: 3px;
                right: 3px;
                bottom: 3px;
                left: 3px;
                content: "";
                opacity: .3;
                border-radius: 50%;
                background-color: #f77705;
            }

            &.s-p-2 {
                padding: 8px;
            }

            .avatar {
                position: relative;
                border: 2px solid #fff;
                border-radius: 50%;
                cursor: pointer;

                .avatar__photo {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: #ebebeb;
                    object-fit: cover;
                }
            }

            .icon-edit {
                position: absolute;
                right: -40%;
                bottom: 0;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: 0 2px 4px 1px #00000040;
                transform: translateX(-50%);

                .ui-icon {
                    display: inline-flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .ui-icon__display-block {
                        display: flex;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                        fill: #f77705;
                    }
                }
            }
        }

        .more__link-button {
            color: #f77705;
            font-family: Open Sans, sans-serif;
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
    }

    .get-credits {
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 6px #00000040;

        .get-credits__container {
            display: grid;
            grid-template-columns: 73% auto;
            justify-items: center;

            .credits_info__wrapper {
                display: grid;
                width: 100%;
                grid-template-columns: 50% 50%;

                .credits_info__block {
                    width: 100%;

                    &:first-child {
                        border-right: 1px solid #f5f5f5;
                    }
                }
            }

            .get-credits__icon {
                border: none;
                background-color: #0000;

                .ui-icon__display-inline {
                    display: inline-flex;
                }

                .ui-icon {
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    svg {
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        fill: #f77705;
                    }
                }

                .ui-icon__display-block {
                    display: flex;
                }
            }
        }
    }


    .button__logout {
        margin: 0 auto;
        color: #f77705;
        border: none;
        background-color: #0000;
    }
}
</style>