<script>
import i18n from '../../../lang'
export default {
  name: "lemonMessageSticker",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-sticker"
        props={{ ...this.$attrs }}
        scopedSlots={{
          content: props => {
            return <div class="sticker-wrapper"><img  src={this.cdnurl(props.content)} /></div>;
          }
        }}
      />
    );
  }
};
</script>
<style lang="scss" scoped>
.lemon-message.lemon-message-sticker .lemon-message__content {
    padding: 10px;
    background: unset !important;
}

.lemon-message.lemon-message-sticker {
  ::v-deep(.lemon-message__content) {
    img {
      background: unset;    
    }
  }
}

.lemon-message {
  .sticker-wrapper {
    width: 120px;
    height: 120px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
