<template>
  <div class="">
    <div class="main-wrapper">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="18" :md="18">
          <el-row class="card-header shadow" :gutter="1">
            <div class="card-header-row">
              <el-col :xs="24" :sm="24" :md="24" :lg="24">
                <h1 style="float: left;cursor: pointer;" class="header-title" @click="jump('/mailbox')">
                  <i class="el-icon-arrow-left" style="font-size: 24px;"></i>
                  {{ $t('Mailbox') }}
                </h1>
                <span style="float: right;">
                  <el-button class="btn-warning" size="small " style="font-weight: 600;" @click="jump('/message?id=' + detail.from_userinfo.id)">
                    <i class="icon-al-jiaoyou"></i> 
                    {{ $t('GoChat') }}
                  </el-button>
                </span>
              </el-col>
            </div>
          </el-row>

          <el-row class="card-body" style="padding:10px" v-if="Object.keys(detail).length > 0">
            <div class="user-block maildetail-user-block">
              <div class="detail-userinfo">
                <el-avatar size="medium" :src="detail.from_userinfo.avatar | cdnurl"></el-avatar>
                <i style="position: absolute;top:30px;left:35px"
                  :class="{ 'icon-al-zhuangtai': true, 'online-isactive': detail.from_userinfo.userDetail.online == 1 }"></i>
                <span class="user-block-nickname">{{ detail.from_userinfo.id == userinfo.id ? "Me" :
                  detail.from_userinfo.nickname }}</span>
              </div>
              <div class="detail-userinfo">
                <i class="icon-al-fasongyoujian middle-icon"></i>
              </div>

              <div class="detail-userinfo">
                <el-avatar size="medium" :src="detail.to_userinfo.avatar | cdnurl"></el-avatar>
                <i style="position: absolute;top:30px;left:35px"
                  :class="{ 'icon-al-zhuangtai': true, 'online-isactive': detail.to_userinfo.userDetail.online == 1 }"></i>
                <span class="user-block-nickname">{{ detail.to_userinfo.id == userinfo.id ? "Me" :
                  detail.to_userinfo.nickname
                }}</span>
              </div>

              <div class="maildetail-star">
                <span class="detail-times"><i class="el-icon-time"></i> {{ detail.createtime |
                  getDateDiff }}</span>
                <i class="icon-al-star-off" v-if="detail.starred == userinfo.id || detail.starred == -1"
                  @click="star()"></i>
                <i class="icon-al-star-on" v-else @click="star()"></i>
              </div>
            </div>

            <el-divider></el-divider>

            <div class="detail-content">
              {{ detail.content }}
            </div>

            <el-row style="padding: 0;" :gutter="20" v-if="imageList.length > 0">
              <p class="atta_title">{{ $t('AttachmentImages') }}</p>
              <el-col class="attach-list" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in imageList" :key="item.id">
                <template v-if="item.type == 'image'">
                  <el-image style="width: 100%; height: 100px" :src="item.file ? item.file : item.preview | cdnurl"
                    fit="contain" @click="showImage(item)"></el-image>
                </template>

              </el-col>
            </el-row>

            <el-row style="padding: 0;" :gutter="20" v-if="videoList.length > 0">
              <p class="atta_title">{{ $t('AttachmentVideos') }}</p>
              <el-col class="attach-list" :xs="12" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in videoList" :key="item.id">
                <template v-if="item.type == 'video'">
                  <el-image style="width: 100%; height: 100px" :src="item.preview | cdnurl" fit="contain"
                    ></el-image>
                  <i class='icon-al-bofang' @click="playVideo(item)"></i>
                </template>
              </el-col>
            </el-row>
          </el-row>

          <ElImageViewer v-if="showViewer" :on-close="closeViewer" :url-list="viewerList" />

          <el-dialog :visible.sync="playVisible" append-to-body width="750px"  top="7vh" custom-class="player-video"
            @close="closeDialog" @opened="openedDialog">
            <player-video :src="videoUrl" :volume="volume" ref="video"></player-video>
            <div slot="footer" class="dialog-footer">
              <el-button @click="playVisible = false">{{ $t("close") }}</el-button>
            </div>
          </el-dialog>

          <el-row class="card-body detail-send-content" v-if="Object.keys(detail).length > 0">
            <h1 class="detail-send-header">
              {{ $t('SendYourLetterTo') }} {{ detail.user_id == userinfo.id ? detail.to_userinfo.nickname
                : detail.from_userinfo.nickname }}
            </h1>
            <send-mail :id="detail.user_id == userinfo.id ? detail.to_userinfo.id
              : detail.from_userinfo.id" :reply_id="detail.user_id == userinfo.id ? 0
              : detail.id"></send-mail>

            <div class="send-ul" v-html="emailIntroduce">

            </div>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6">
          <right-sider-bar></right-sider-bar>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import RightSiderBar from '../components/common/RightSiderBar.vue'
import Auth from '../../services/auth'
import { cdnurl } from '../../filters'
import SendMail from '../components/mailbox/send.vue'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import PlayerVideo from "../components/common/player-video.vue";
export default {
  name: 'maildetail',
  data: function () {
    return {
      mailId: 0,
      userinfo: [],
      detail: [],
      Pricing: [],
      imageList: [],
      videoList: [],
      showViewer: false,
      viewerList: [],
      videoUrl: "",
      volume: 0.5,
      playVisible: false,
        emailIntroduce:""
    }
  },
  components: { RightSiderBar, SendMail, ElImageViewer,PlayerVideo },
  computed: {
    video() {
      return this.$refs.video;
    },
  },
  methods: {
    closeViewer() {
      this.showViewer = false;
      this.viewerList = [];
    },
    
    openedDialog() {
      this.video.play();
    },
    closeDialog() {
      this.video.stop();
    },
    getDetail() {
      let that = this;
      that.$http.post('/mail/detail', { id: that.mailId })
        .then((res) => {
          if (res.code == 1) {
            that.detail = res.data.detail
            that.websocket.notMailReadNum = res.data.notMailReadNum
            if (res.data.detail.atta_list != null) {
              for (let i = 0; i < res.data.detail.atta_list.length; i++) {
                let row = res.data.detail.atta_list[i]
                if (row.type == 'image') {
                  that.imageList.push(row)
                } else {
                  that.videoList.push(row)
                }
              }
            }
          } else {
            that.$message.error(res.msg);
          }

        })

    },
    getPricing() {
      this.$http.get('/credits/getPricing', {})
        .then((res) => {
          if (res.code == 1) {
            this.Pricing = res.data
          } else {
            this.$message.error(res.msg);
          }

        })
    },
    star() {
      let that = this
      that.$http.post('/mail/star', { id: that.detail.id })
        .then((res) => {
          if (res.code == 1) {
            that.detail.starred = res.data.starred
          } else {
            that.$message.error(res.msg);
          }
          that.loading = false
        })
    },
    jump(url) {
      this.$router.push(url)
    },
    showImage(row) {
      let that = this
      // 如果支付过file字段会有值可以直接用
      if (row.file == "") {
        that.$http.post('/mail/getAttaDetail', { id: row.id })
          .then((res) => {
            if (res.code == 1) {
              row.file = res.data.file
              that.viewerList.push(cdnurl(res.data.file))
              that.showViewer = true
            } else {
              that.$message.error(res.msg);
            }
            that.loading = false
          })
      } else {
        that.viewerList.push(cdnurl(row.file))
        that.showViewer = true
      }
    },
    playVideo(row) {
      let that = this
      if (row.file == "") {
        that.$http.post('/mail/getAttaDetail', { id: row.id })
          .then((res) => {
            if (res.code == 1) {
              row.file = res.data.file
              that.videoUrl= cdnurl(res.data.file)
              that.playVisible = true
            } else {
              that.$message.error(res.msg);
            }
            that.loading = false
          })
      } else {
        that.videoUrl = cdnurl(row.file);
        that.playVisible = true;
      }
    },
      getEmailIntroduce() {
          this.$http.get('/config/getEmailIntroduce', {})
              .then((res) => {
                  if (res.code == 1) {
                      this.emailIntroduce = res.data
                  } else {
                      this.$message.error(res.msg);
                  }

              })
      },
  },
  created() {
    let that = this;
    that.mailId = that.$route.params.id;
    that.userinfo = Auth.getToken()
    that.getDetail()
    that.getPricing()
    that.getEmailIntroduce()
  },
}
</script>
    
<style>
.middle-icon {
  float: left;
  font-size: 26px !important;
  margin: 0 10px;
  color: #3B5998;
}

.user-block-nickname {
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
  color: #666666;
}

.maildetail-user-block {
  position: relative;

}

.detail-userinfo {
  position: relative;
  align-items: center;
  display: flex;
  float: left;
  height: 50px;
}

.maildetail-star {
  float: right;
  align-items: center;
  display: flex;
  height: 50px;
  margin-right: 10px;
}

.detail-times {
  font-size: 16px;
  font-weight: 600;
  color: gray;
  margin-right: 20px;
}

.detail-content {
  padding: 40px;
  word-break: break-word;
  background-color: #fff;
  color: #333;
}

.detail-send-content {
  padding: 40px;
  margin-top: 20px;
}

.detail-send-header {
  padding: 0 0 30px 0;
  font-weight: 600;
}

.send-ul {
  margin: 0;
  padding: 0;
  list-style: inside;
  letter-spacing: .25px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  list-style-type: none;
}

.maildetail-star .icon-al-star-off {
  cursor: pointer;
  color: #ffd22e;
}

.maildetail-star .icon-al-star-on {
  cursor: pointer;
}

.atta_title {
  font-size: 14px;
  color: #5e6d82;
  line-height: 1.5em;
  padding: 10px 0;

}

.attach-list {
  position: relative;
}

.attach-list .icon-al-bofang {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-color: rgb(19 50 146 / 19%);
  font-size: 26px;
  border-radius: 50%;
  padding: 10px;
  color: #f7f7f7;
  cursor: pointer;
}</style>
    