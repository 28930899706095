import { render, staticRenderFns } from "./check-sticker.vue?vue&type=template&id=85e40988&scoped=true"
import script from "./check-sticker.vue?vue&type=script&lang=js"
export * from "./check-sticker.vue?vue&type=script&lang=js"
import style0 from "./check-sticker.vue?vue&type=style&index=0&id=85e40988&prod&scoped=true&lang=css"
import style1 from "./check-sticker.vue?vue&type=style&index=1&id=85e40988&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e40988",
  null
  
)

export default component.exports