import Vue from 'vue'
import i18n from '../lang'

const urlParser = document.createElement('a')
import setting from '../config/setting.js'

export function domain(url) {
    urlParser.href = url
    return urlParser.hostname
}

export function count(arr) {
    return arr.length
}

export function getDateDiff(dateTimeStamp) {
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var halfamonth = day * 15;
    var month = day * 30;
    var now = new Date().getTime();
    var diffValue = now - dateTimeStamp;

    if (diffValue < 0) {
        //非法操作
        //alert("结束日期不能小于开始日期！");
    }

    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    let result = Vue.prototype.formatDate(dateTimeStamp, 'MM/dd hh:mm')
    if (monthC >= 1) {
        result = Vue.prototype.formatDate(dateTimeStamp, 'MM/dd hh:mm')
    } else if (weekC >= 1) {
        result = Vue.prototype.formatDate(dateTimeStamp, 'MM/dd hh:mm')
    } else if (dayC >= 1) {
        result = Vue.prototype.formatDate(dateTimeStamp, 'MM/dd hh:mm')
    } else if (hourC >= 1) {
        result = i18n.t('PublishedAgo', {
            nums: parseInt(hourC),
            unit: i18n.t('hour'),
            s: parseInt(hourC) > 1 ? 's' : ''
        });
    } else if (minC >= 1) {
        result = i18n.t('PublishedAgo', {
            nums: parseInt(minC),
            unit: i18n.t('minute'),
            s: parseInt(minC) > 1 ? 's' : ''
        });
    } else
        result = i18n.t('JustPublished');
    return result;
}

export function expiredTime(dateTimeStamp) {
    if (dateTimeStamp.toString().length == 10) {
        dateTimeStamp = Number(dateTimeStamp) * 1000
    }
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var halfamonth = day * 15;
    var month = day * 30;
    var now = new Date().getTime();
    var diffValue = dateTimeStamp - now;

    if (diffValue < 0) {
        return i18n.t("VIPExpired")
    }
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    let result = '';
    if (minC >= 1) {

        result = i18n.t('minutes', {num: minC.toFixed(0), s: minC > 1 ? 's' : ''})
    }
    if (hourC >= 1) {
        result = i18n.t('hours', {num: hourC.toFixed(0), s: hourC > 1 ? 's' : ''})
    }
    if (dayC >= 1) {
        result = i18n.t('days', {num: dayC.toFixed(0), s: dayC > 1 ? 's' : ''})
    }
    return result
}

export function formatDate(date, fmt) {
    if (date.toString().length == 10) {
        date = Number(date) * 1000
    }

    var date = new Date(Number(date)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;


}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

export function pluralize(time, label) {
    if (time === 1) {
        return time + label
    }

    return time + label + 's'
}

export function cdnurl(url) {
    if (!url) {
        return url
    }
    if (!url.includes("data:image/") && !(url.includes("http://") || url.includes("https://"))) {
        url = setting.cdnUrl + url;
    }

    return url;
}

export function isUrl(url) {
    if (/^https?:\/\//.test(url)) {
        return true
    } else {
        return false
    }
}

export function cdnurlArr(urls) {

    for (let i = 0; i < urls.length; i++) {
        urls[i] = cdnurl(urls[i])
    }
    return urls;
}

/*
*  校验上传文件
* */
export function validUploadFile(type = 'img', file) {
    if (!file) {
        file = type;
        type = 'img'
    }
    const fileName = file.name;
    const m = fileName.match(/\.(\w+)(#|\?|$)/);
    const fileType = (m && m[1]).toString().toLowerCase();
    const allowHook = {
        video: ['mp4', 'ogv', 'ogg', 'webm', 'mov'],
        audio: ['wav', 'mp3', 'ogg', 'acc', 'webm', 'amr'],
        file: ['doc', 'docx', 'xlsx', 'xls', 'pdf'],
        excel: ['xlsx', 'xls'],
        img: ['jpg', 'jpeg', 'png', 'gif']
    }
    const validType = (allowHook[type] || []).includes(fileType);
    if (!validType) {
        const supprtTypes = allowHook[type].join(',');

        this.$message.error(i18n.t('uploadVideoValid', {type: supprtTypes}));
        return false;
    }
    if (fileName.indexOf('%') > -1 || fileName.indexOf('&') > -1) {
        this.$message.error(i18n.t('uploadFileNameValid'));
        return false;
    }
    const isLt30M = file.size / 1024 / 1024 < 30;
    const isLt5M = file.size / 1024 / 1024 < 5;
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (type == "img" && !isLt5M) {
        this.$message.error(i18n.t('uploadFileSizeValid', {num: 5}));
        return false;
    } else if (type == "video" && !isLt30M) {
        this.$message.error(i18n.t('uploadFileSizeValid', {num: 30}));
        return false;
    } else if (!(type == "img" || type == "video") && !isLt10M) {
        this.$message.error(i18n.t('uploadFileSizeValid', {num: 10}));
        return false;
    }

    return true;
}
