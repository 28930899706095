<template>
    <div>
        <div class="main-wrapper">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">
                    <el-tabs v-model="activeName" type="border-card">
                          <!-- VIP充值 -->
                          <el-tab-pane :label="$t('TopUpVIP')" name="vip">
                            <el-card class="box-card" style="padding-bottom: 20px;">
                                <div v-html="VIPIntro"></div>
                            </el-card>

                            <el-row class="card-header shadow" :gutter="1" style="margin-top: 20px;">
                                <h1 style="float: left;" class="header-title">
                                    <i class="icon-al-jinbi1" style="font-size: 24px;"></i>
                                    VIP {{ $t('VIPExpirationDate') }}
                                </h1>
                            </el-row>
                            <el-row :gutter="20" class="card-body" style="padding: 20px">
                                <el-col :xs="24" :sm="24" :md="24">
                                    <div class="credits-item-count">
                                        <h2>{{ $t('VIPExpirationDate') }}</h2>
                                        <div class="premium-credits-balance">{{ VIPExpireTime | formatExpireTime }}</div>
                                        <p class="premium-credits-desc">{{ $t('VIPPurchasedTips') }}</p>
                                        <div class="credits-item-btn">
                                            <el-button type="primary" @click="jump('/benefits')"> {{ $t('GetVIP') }} </el-button>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <!-- 积分充值 -->
                        <el-tab-pane :label="$t('TopUpCredits')" name="money">
                            <el-card class="box-card" style="padding-bottom: 20px;">
                                <div v-html="creditsIntro"></div>
                            </el-card>

                            <el-row class="card-header shadow" :gutter="1" style="margin-top: 20px;">
                                <h1 style="float: left;" class="header-title">
                                    <i class="icon-al-jinbi1" style="font-size: 24px;"></i>
                                    {{ $t('YourCredits') }}
                                </h1>
                            </el-row>
                            <el-row :gutter="20" class="card-body" style="padding: 20px">
                                <el-col :xs="24" :sm="12" :md="12">
                                    <div class="credits-item-count">
                                        <h2>{{$t('Complimentary')}}</h2>
                                        <div class="free-credits-balance">{{ data.free_money }}</div>
                                        <p class="free-credits-desc">{{ $t('CreditFreeTips') }}</p>
                                        <div class="credits-item-btn" style="background-color: unset;"></div>
                                    </div>
                                </el-col>
                                <el-col :xs="24" :sm="12" :md="12">
                                    <div class="credits-item-count">
                                        <h2>{{ $t('Purchased') }} </h2>
                                        <div class="premium-credits-balance">{{ data.money }}</div>
                                        <p class="premium-credits-desc">{{ $t('CreditPurchasedTips') }}</p>
                                        <div class="credits-item-btn">
                                            <el-button type="primary" @click="jump('/benefits')"> {{ $t('GetCredits') }} </el-button>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>

                    <!-- 定价介绍 -->
                    <el-row class="card-header shadow" :gutter="1" style="margin-top: 20px;">
                        <h1 style="float: left;" class="header-title">
                            {{ $t('Pricing') }}
                        </h1>
                    </el-row>
                    <el-row :gutter="20" class="card-body" style="padding: 20px">
                        <div class="section" style="margin-top: 0;" v-html="priceIntro">
                        </div>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="6" :md="6">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import RightSiderBar from './components/common/RightSiderBar.vue'
import { formatDate } from '@/filters/index.js'
import i18n from "@/lang";

export default {
    name: 'Credits',
    components: { 
        RightSiderBar, 
    },
    data() {
        return {
            whyDoYouNeedCreditsUrl: [
                "/assets/img/mails.svg",
                "/assets/img/chats.svg",
                "/assets/img/stickers.svg",
                "/assets/img/profileVideo.svg",
                "/assets/img/sendPhotos.svg",
                "/assets/img/sendVirtualGifts.svg",
            ],
            data:[],
            activeName: 'vip',
            VIPIntro: '',
            VIPExpireTime: 0,

            
            creditsIntro: '',

            priceIntro: '', // 定价介绍
        }
    },
    filters: {
        formatExpireTime: (timestamp) => {
        
            if (!timestamp) {
                return i18n.t('NotVIPActivateed')
            } else {
                return formatDate(new Date(timestamp*1000), 'yyyy-MM-dd')
            }
        }
    },
    watch: {
        activeName: function (newVal) {
            if (newVal === 'vip') {
                this.getVIPIntroduce()
                this.getVIPExpire()
            } else {
                this.getCreditsIntroduce()
            }
        }
    },
    methods: {
        getPricing(){
            this.$http.get('/credits/getPricing', {})
                .then((res) => {
                    if (res.code == 1) {
                        this.data = res.data
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        jump:function(url){
            let that = this
            that.$router.push({ path: url , query: {type: this.activeName}})
        },
        getVIPIntroduce() {
            this.$http.get('/config/getVipIntroduce', {})
                .then((res) => {
                    if (res.code == 1) {
                        this.VIPIntro = res.data
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        getVIPExpire() {
            this.$http.post('/user/userinfo')
                .then((res) => {
                    if (res.code == 1) {
                        this.VIPExpireTime = res.data.userDetail.vip_expire_time
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        getCreditsIntroduce() {
            this.$http.get('/config/getCreditsIntroduce', {})
                .then((res) => {
                    if (res.code == 1) {
                        this.creditsIntro = res.data
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        },
        /**
         * 获取定价介绍
         */
        getPriceIntro() {
            this.$http.get('/config/getPricingIntroduce', {})
                .then((res) => {
                    if (res.code == 1) {
                        this.priceIntro = res.data
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        }
    },
    created() {
        this.getPricing()
        this.getVIPIntroduce()
        this.getVIPExpire()
        this.getPriceIntro()
    },
}


</script>
    
<style scoped>
.credits-info_list {
    margin-top: 20px;
}

.credits-info_list div {
    text-align: center;
}

.credits-info_list-item {
    margin-top: 10px;
}



.credits-info_img {
    width: 50px;
    height: 50px;
}

.credits-info_text {
    font-size: 14px;
}

.credits-item-count {
    text-align: center;
    background-color: #f7faff;
    padding: 20px;
}

.free-credits-balance,
.premium-credits-balance {
    margin-top: 40px;
    font-size: 40px;
    font-weight: bold;
    color: #3B5998;
}

.free-credits-desc,
.premium-credits-desc {
    margin-top: 40px;
    color: #3c3c3c;
}

.credits-item-btn {
    margin: 40px -20px -20px -20px;
    background-color: #e8f1ff;
    padding: 20px;
    height: 40px;
}

.section {
    margin-top: 20px;
}

.section_list {
    color: #3c3c3c;
    margin-top: 20px;
    margin-left: 20px;
}

.section_list li {
    margin-top: 10px;
}
.t-green-700{
    color: #2ECC71;
    font-size: 14px;

}
.f-w-b{
    font-weight: bold;
}
.credits-item-btn button{
    width: 60%;
}
@media only screen and (max-width: 767px) {
    .header-title{
        font-size: 18px;
    }
    .header-title i{
        font-size: 18px !important;
    }
}
</style>
  
    