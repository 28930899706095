<template>
    <div class="people">
        <div class="main-wrapper">
            <el-row :gutter="20">
                <el-col :md="18" :sm="18" :xs="24" class="m-no-padding">
                    <template v-if="recommendList.length">
                        <el-row class="faces-main-title hidden-xs-only">
                            <el-col :sm="12" :xs="24">
                                <h1>{{ $t('SeeNext') }}</h1>
                            </el-col>
                            <el-col :sm="12" :xs="24">
                                <span class="see-more">{{ $t('SeeMore') }}</span>
                            </el-col>
                        </el-row>
                        <div class="faces-slider-wrap hidden-xs-only">
                            <div class="profile-slider-wrap">
                                <div class="list-container">
                                    <div class="profile-slides">
                                        <div
                                            v-for="(item, index) in recommendList"
                                            :key="item.id"
                                            :class="{ selected: activeIndex === index, item_close: item.close === 1 }"
                                            class="profile-slide "
                                            @click="handleClickSlide(index)"
                                        >
                                            <img :alt="item.nickname" :src="item.avatar | cdnurl" class="item_photo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="faces-wrap">
                            <div class="faces-title"> {{ $t('DoYouLike') }} <span class="username"
                                                                                  @click="routeTo('/memberinfo/' + recommendList[activeIndex].user_id)">{{
                                    recommendList[activeIndex].nickname
                                }}</span>
                                {{ $t('QuestionMark') }}
                            </div>
                            <div class="faces-info">
                                <span class="nickname"
                                      data-test-id="file:people profile-id"> {{ recommendList[activeIndex].nickname }}, {{
                                        recommendList[activeIndex].age
                                    }} </span>
                                <span class="from"> </span>
                                <div :class="recommendList[activeIndex].online === 0 ? 'offline' : 'online'"
                                     class="tag-marker online">
                                    {{ recommendList[activeIndex].online === 0 ? 'offline' : 'online' }}
                                </div>
                            </div>
                        </div>
                        <div class="carousel-wrapper">
                            <div class="carousel-wrap">
                                <el-carousel
                                    ref="carsouselRef"
                                    :autoplay="false"
                                    :initial-index="activeIndex"
                                    :loop="false"
                                    arrow="never"
                                    indicator-position="none"
                                    @change="handleCarouselChange"
                                >
                                    <el-carousel-item v-for="item in recommendList" :key="item.id">
                                        <img :src="item.avatar | cdnurl" alt="" class="carousel-poto">
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                            <div class="operation-wrapper">
                                <ul class="op-ul">
                                    <li class="op-li" @click="handleLike">
                                        <like-button :is-active="recommendList[activeIndex].liked"></like-button>
                                    </li>
                                    <li class="op-li" @click="handleNext">
                                        <next-button></next-button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="faces-btn-wrap">
                            <el-button class="btn-event" plain size="medium" type="primary" @click="toChat">
                                <i class="el-icon-s-comment"></i>
                                {{ $t('GoChat') }}
                            </el-button>
                            <el-button :plain="recommendList[activeIndex].winked !== 1" class="btn-event" size="medium"
                                       type="primary" @click="toWink">
                                <i class="el-icon-star-on"></i>
                                {{ recommendList[activeIndex].winked === 1 ? $t('Winked') : $t('SendWink') }}
                            </el-button>
                            <el-button :plain="recommendList[activeIndex].followed !== 1" class="btn-event"
                                       size="medium"
                                       type="primary" @click="toFollow">
                                <i class="el-icon-user-solid"></i>
                                {{ recommendList[activeIndex].followed === 1 ? $t('UnFollow') : $t('Follow') }}
                            </el-button>
                        </div>
                    </template>
                    <div v-else class="empty-wrapper">
                        <el-empty :description="$t('EmptyRecommend')"></el-empty>
                    </div>
                </el-col>
                <el-col :md="6" :sm="6" :xs="24">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import RightSiderBar from '../components/common/RightSiderBar.vue'
import NextButton from '@/widgets/button/NextButton.vue'
import LikeButton from '@/widgets/button/LikeButton.vue'

export default {
    name: 'People',
    components: {
        RightSiderBar,
        NextButton,
        LikeButton,
    },
    data() {
        return {
            recommendList: [],
            activeIndex: 0,
            page: 1,
            pageSize: 10,
            total: 0,
        }
    },
    created() {
        this.getRecommendList()
    },
    methods: {
        routeTo(url) {
            this.$router.push(url)
        },
        toChat() {
            const userId = this.recommendList[this.activeIndex].user_id
            this.$router.push({
                path: '/message',
                query: {
                    id: userId
                }
            })
        },
        toWink() {
            const userId = this.recommendList[this.activeIndex].user_id
            let that = this;
            that.$http.post('/user/wink', {to_user_id: userId})
                .then((res) => {
                    if (res.code == 1) {
                        this.recommendList[this.activeIndex].winked = 1
                        that.$message.success(res.msg);
                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        toFollow: function () {
            const userId = this.recommendList[this.activeIndex].user_id
            const followed = this.recommendList[this.activeIndex].followed
            let that = this;
            that.$http.post('/user/follow', {to_user_id: userId})
                .then((res) => {
                    if (res.code == 1) {
                        if (followed > 0) {
                            this.recommendList[this.activeIndex].followed = 0
                        } else {
                            this.recommendList[this.activeIndex].followed = 1;
                        }
                        that.$message.success(res.msg);
                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        handleLike() {
            let that = this;
            const userId = this.recommendList[this.activeIndex].user_id
            const liked = this.recommendList[this.activeIndex].liked
            that.$http.post('/user/like', {to_user_id: userId})
                .then((res) => {
                    if (res.code == 1) {
                        if (liked > 0) {
                            this.recommendList[this.activeIndex].liked = 0
                        } else {
                            this.recommendList[this.activeIndex].liked = 1
                        }
                        that.$message.success(res.msg);
                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        handleNext() {
            this.recommendList[this.activeIndex].close = 1
            this.activeIndex++
            // 没有更多了，回到第一个
            if (this.activeIndex >= this.total) {
                this.activeIndex = 0
                this.$refs.carsouselRef.setActiveItem(0)
                return
            }
            this.$refs.carsouselRef.next()
        },
        handleClickSlide(index) {
            if (index !== this.activeIndex) {
                this.recommendList[this.activeIndex].close = 1
                this.activeIndex = index
                this.$refs.carsouselRef.setActiveItem(index)
            }
        },
        handleCarouselChange(index) {
            console.log('index ', index);
            // 加载下一页
            if (index >= this.page * this.pageSize - 1) {
                this.page++
                this.getRecommendList();
            }
        },
        getRecommendList() {
            this.$http.get('/user/getList', {params: {page: this.page, limit: this.pageSize, type: 'recommend'}})
                .then(res => {
                    if (res.code === 1) {
                        console.log(res);
                        this.recommendList = this.recommendList.concat(res.data.list || [])
                        this.total = res.data.total
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        },
        validateIsMore(curIndex, page, pageSize) {

        }
    }
}
</script>

<style lang="scss" scoped>
.empty-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background-color: #fff;
}

.faces-main-title {
    padding: 24px;
    background-color: #fff;

    h1 {
        margin-top: 0;
        margin-bottom: 0;
        color: #2b3038;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
    }

    span {
        letter-spacing: .25px;
        color: #262626;
        font-size: 14px;
        line-height: 23px;
    }

    .see-more {
        display: block;
        text-align: right;
    }
}


.faces-slider-wrap {
    overflow: hidden;
    padding-top: 16px;
    padding-bottom: 32px;
    border-bottom: 1px solid #e8e8e8;
    background-color: #fff;
}

.profile-slider-wrap {
    position: relative;
    padding-left: 24px;
}

.profile-slider-wrap::after, .profile-slider-wrap::before {
    position: absolute;
    z-index: 2;
    top: -12px;
    bottom: 0;
    display: block;
    width: 96px;
    height: 96px;
    content: "";
    pointer-events: none;
}

.profile-slider-wrap:before {
    left: 0;
    background: linear-gradient(90.03deg, #fff 34.38%, #fff0 97.38%);
}

.profile-slider-wrap:after {
    right: 0;
    background: linear-gradient(270.52deg, #fff 33.42%, #fff0 90.31%);
}

.profile-slides {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-slide {
    position: relative;
    width: 72px;
    min-width: 72px;
    height: 72px;
    margin-right: 8px;
    cursor: pointer;
    transition: all .3s ease;
}

.profile-slide::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    transition: background-color .3s linear;
    pointer-events: none;
    background-color: #3330;
}

.profile-slide.item_close::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    pointer-events: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xMC4xMjQgOC4wMDIgNS44OC01Ljg4TDEzLjg4NSAwIDguMDAyIDUuODggMi4xMjIgMCAwIDIuMTIxbDUuODggNS44ODFMMCAxMy44ODJsMi4xMjEgMi4xMiA1Ljg4MS01Ljg4IDUuODgyIDUuODggMi4xMi0yLjEyLTUuODgtNS44OFoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=) no-repeat 50% 50%, #3339;
}

.profile-slide.selected {
    z-index: 1;
    transition: all .3s ease;
    transform: scale(1.33);
    box-shadow: 0 16px 20px #00000017, 0 8px 16px #0000001f, 0 4px 8px #00000021;
}

.faces-slider-wrap .item_photo {
    display: block;
    width: 72px;
    height: 72px;
    object-fit: cover;
    object-position: center;
}


.faces-wrap {
    background-color: #fff;
    text-align: center;
}

.faces-title {
    padding: 16px 0 0;
    color: #000;
    font-size: 32px;
    line-height: 52px;
}

.faces-title .username {
    position: relative;
    color: var(--primaryColor);
    cursor: pointer;
    transition: all .3s;
}

.faces-title .username::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--primaryColor);
    transition: 0.3s ease-out;
}

.faces-title .username:hover::before {
    width: 100%;
}

.faces-info {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
}

.faces-info .tag-marker {
    margin-left: 11px;
}

.tag-marker {
    display: inline-block;
    margin-right: 10px;
    padding: 1px 8px;
    vertical-align: middle;
    color: #fff;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    line-height: normal;
}

.tag-marker.online {
    background-color: #04c01b;
}

.tag-marker.offline {
    background-color: #95a0ab;
}

.carousel-wrapper {
    position: relative;
    display: flex;
    padding: 30px 0 55px 0;
    background-color: #fff;
}

.carousel-wrap {
    flex: 1;
    display: flex;
    justify-content: center;
}

.el-carousel {
    width: 333px;
}

.el-carousel__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.carousel-poto {
    width: 100%;
}

.operation-wrapper {
    position: absolute;
    top: 0;
    right: 120px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.op-ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.op-li {
    margin-bottom: 60px;
}

.op-li:last-child {
    margin-bottom: 0;
}

.faces-btn-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 22px 0;
    background: #fff;
}

@media only screen and (max-width: 767px) {
    .faces-main-title {
        padding: 5px;

        .see-more {
            text-align: left;
        }
    }

    .carousel-wrapper {
        flex-direction: column;

        .operation-wrapper {
            position: inherit;
            right: 0;

            .op-ul {
                flex-direction: row;
                justify-content: space-around;
                width: 100%;

                .op-li {
                    margin-bottom: 0;
                }
            }
        }
    }
    .btn-event {
        padding: 10px 10px;
    }
}
</style>