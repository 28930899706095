<template>
    <div ref="scrollRef">
        <el-row :gutter="1" class="card-header shadow">
            <div class="card-header-row ">
                <el-col :lg="18" :md="18" :sm="18" :xs="12">
                    <el-col :lg="5" :md="6" :sm="24" :xl="4" :xs="24" class="hidden-xs-only">
                        <h1 class="header-title" style="float: left;">
                            <i class="icon-al-chazhao" style="font-size: 24px;"></i>
                            {{ $t('search') }}
                        </h1>
                    </el-col>
                    <div class="hidden-xs-only">
                        <span class="header-type"><el-button :round="true"
                                                             :type="searchData.type=='all'?'primary':'default'"
                                                             size="small" @click="changeType('all')">{{
                                $t('All')
                            }}</el-button></span>
                        <span class="header-type"><el-button :round="true"
                                                             :type="searchData.type=='online'?'primary':'default'"
                                                             size="small" @click="changeType('online')">{{
                                $t('Online')
                            }}</el-button></span>
                        <span class="header-type"><el-button :round="true"
                                                             :type="searchData.type=='follow'?'primary':'default'"
                                                             size="small" @click="changeType('follow')">{{
                                $t('Following')
                            }}</el-button></span>

                    </div>
                </el-col>
                <el-col :lg="6" :md="6" :sm="6" :xs="12" class="hidden-xs-only">
                    <i class="header-icon icon-al-shaixuan" @click="searchShow = !searchShow"></i>
                </el-col>
                <el-col class="hidden-sm-and-up">
                    <span class="header-type"><el-button :round="true"
                                                         :type="searchData.type=='all'?'primary':'default'"
                                                         size="small" @click="changeType('all')">{{
                            $t('All')
                        }}</el-button></span>
                    <span class="header-type"><el-button :round="true"
                                                         :type="searchData.type=='online'?'primary':'default'"
                                                         size="small" @click="changeType('online')">{{
                            $t('Online')
                        }}</el-button></span>
                    <span class="header-type"><el-button :round="true"
                                                         :type="searchData.type=='follow'?'primary':'default'"
                                                         size="small" @click="changeType('follow')">{{
                            $t('Following')
                        }}</el-button></span>
                    <span class="header-type search-span"><i class="header-icon icon-al-shaixuan"
                                                             @click="searchShow = !searchShow"></i></span>
                </el-col>
            </div>
            <el-collapse-transition>
                <div v-show="searchShow" class="card-header-row card-header-search">
                    <el-row :gutter="20">
                        <el-col :lg="8" :md="12" :sm="12" :xs="24">
                            <el-input v-model="searchData.nickname" :placeholder="$t('trySearch')"></el-input>
                        </el-col>
                        <el-col :lg="8" :md="12" :sm="12" :xs="24">
                            <el-select v-model="searchData.occupation_ids" :placeholder="$t('Occupation')" collapse-tags
                                       multiple
                                       style="width: 100%;">
                                <el-option v-for="item in (option.option ? option.option.occupation : [])"
                                           :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :lg="8" :md="12" :sm="12" :xs="24">
                            <el-col :lg="10" :md="10" :sm="11" :xs="11" class="m-no-padding">
                                <el-select v-model="searchData.min_age" :placeholder="$t('MinimumAge')">
                                    <el-option v-for="item in age" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :lg="4" :md="4" :sm="2" :xs="2" style="text-align: center;line-height:35px">
                                -
                            </el-col>
                            <el-col :lg="10" :md="10" :sm="11" :xs="11" class="m-no-padding">
                                <el-select v-model="searchData.max_age" :placeholder="$t('MaximumAge')">
                                    <el-option v-for="item in age" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-col>
                        <el-col :lg="8" :md="12" :sm="12" :xs="24">
                            <el-col :lg="10" :md="10" :sm="11" :xs="11" class="m-no-padding no-padding">
                                <el-select v-model="searchData.min_height" :placeholder="$t('MinimumHeight')">
                                    <el-option v-for="item in height" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :lg="4" :md="4" :sm="2" :xs="2" style="text-align: center;line-height:35px">
                                -
                            </el-col>
                            <el-col :lg="10" :md="10" :sm="11" :xs="11" class="m-no-padding no-padding">
                                <el-select v-model="searchData.max_height" :placeholder="$t('MaximumHeight')">
                                    <el-option v-for="item in height" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-col>
                        <el-col :lg="8" :md="12" :sm="12" :xs="24">
                            <el-col :lg="10" :md="10" :sm="11" :xs="11" class="m-no-padding no-padding">
                                <el-select v-model="searchData.min_weight" :placeholder="$t('MinimumWeight')">
                                    <el-option v-for="item in weight" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :lg="4" :md="4" :sm="2" :xs="2" style="text-align: center;line-height:35px">
                                -
                            </el-col>
                            <el-col :lg="10" :md="10" :sm="11" :xs="11" class="m-no-padding no-padding">
                                <el-select v-model="searchData.max_weight" :placeholder="$t('MaximumWeight')">
                                    <el-option v-for="item in weight" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-col>
                        <el-col :lg="8" :md="12" :sm="12" :xs="24">
                            <el-button class="btn-search" size="small" type="primary" @click="search"><i
                                class="icon-al-chazhao"></i> {{
                                    $t('search')
                                }}
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-collapse-transition>
        </el-row>

        <el-row :gutter="20" class="card-body">
            <template>
                <el-skeleton :count="12" :loading="loading" :throttle="200" animated>
                    <template slot="template">
                        <el-col :lg="6" :md="8" :sm="12" :xs="12" class="card-item m-no-padding">
                            <el-skeleton-item style="width: 100%;height: 240px;" variant="image"/>
                            <div style="padding: 14px;text-align: center;">
                                <el-skeleton-item style="width: 50%;margin: 0 auto;" variant="h3"/>
                                <div
                                    style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;">
                                    <el-skeleton-item style="margin-right: 16px;" variant="text"/>
                                    <el-skeleton-item style="width: 30%;" variant="text"/>
                                </div>
                            </div>
                        </el-col>
                    </template>
                </el-skeleton>
                <el-col v-for="(row, index) in list" :key="index" :lg="8" :md="8" :sm="12" :xs="12" class="card-item ">
                    <el-card :body-style="{ padding: '0px' }" class="card-row" shadow="hover">
                        <div>
                            <div class="image-wrap">
                                <img :src="row.avatar | cdnurl" class="card-image"
                                     @click="jump(row.user_id)">
                                <i v-if="row.vip_expire_time > (new Date().getTime() / 1000)"
                                   class="lemon-message-vip-icon icon-al-VIP"></i>
                            </div>
                            <div class="card-desc">
                                <div class="card-info">
                                    <span class="float-left card-nickname">
                                        {{ row.nickname.substr(0, 10) }}
                                        {{ row.birthday && row.age > 0 ? "," : "" }}
                                        {{ row.birthday && row.age > 0 ? row.age : "" }}
                                        <i :class="{ 'icon-al-zhuangtai': true, 'online-isactive': row.online == 1 }"></i>
                                    </span>
                                    <el-button class="chatNowBtn" size="small" type="warning" @click="toChat(row)">Chat
                                        Now
                                    </el-button>
                                </div>

                            </div>
                        </div>
                    </el-card>
                </el-col>
            </template>

            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="pagination">
                <el-pagination
                    :current-page="searchData.page"
                    :hide-on-single-page="true"
                    :page-size="searchData.limit"
                    :pager-count="isMobile ? 5 : 7"
                    :total="total"
                    background
                    layout="prev, pager, next"
                    @current-change="changePage">
                </el-pagination>
            </el-col>

            <el-empty v-if="list.length == 0 && !loading" :description="$t('DataIsEmpty')"></el-empty>
        </el-row>
    </div>
</template>
<script>
import ChatJs from '../../../services/chat.js'
import {mapState} from "vuex";

export default {
    name: 'ListView',
    data: function () {
        return {
            searchData: {
                nickname: '',
                min_age: '',
                max_age: '',
                min_height: '',
                max_height: '',
                min_weight: '',
                max_weight: '',
                occupation_ids: '',
                page: 1,
                limit: 12,
                type: 'all'
            },
            list: [],
            total: 0,
            cardInfoShow: 0,
            guanzhuHover: 0,
            loading: true,
            searchShow: false,
            option: [],
            age: [],
            height: [],
            weight: [],
        }
    },
    watch: {
        newMessageInfo(msgInfo) {

            this.updateOnlineStatus(msgInfo)
        }
    },
    computed: {
        ...mapState({
            isMobile: 'isMobile',
            newMessageInfo: 'newMessageInfo'
        }),
    },
    methods: {
        updateOnlineStatus: function (msgInfo) {

            let msgData = JSON.parse(msgInfo.data);
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].user_id == msgData.data.user_id) {
                    if (msgData.data.status == "online") {
                        this.list[i].online = 1
                    }
                    if (msgData.data.status == "offline") {
                        this.list[i].online = 0
                    }
                }
            }
        },
        changeType: function (type) {
            this.searchData.type = type;
            this.searchData.page = 1;
            this.list = [];
            this.getList();
        },
        search: function () {
            this.searchData.page = 1;
            this.list = [];
            this.getList();
            this.searchShow = false;
        },
        getList: function () {
            let that = this;
            this.$http.get('/user/getList', {params: this.searchData})
                .then((res) => {
                    if (res.code == 1) {

                        this.list = res.data.list || [];
                        this.total = res.data.total;
                        this.loading = false
                        that.$scrollTo()
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        toFollow: function (row) {
            let that = this;
            that.$http.post('/user/follow', {to_user_id: row.user_id})
                .then((res) => {
                    if (res.code == 1) {
                        if (row.followed > 0) {
                            row.followed = 0
                            that.guanzhuHover = 0
                        } else {
                            row.followed = 1;
                        }
                        that.$message.success(res.msg);

                    } else {
                        that.$message.error(res.msg);
                    }

                })
        },
        toChat: function (row) {
            let that = this;

            that.$router.push({
                path: '/message',
                query: {
                    id: row.user_id
                }
            })
        },
        getOption: function () {
            this.$http.get('/config/getOption')
                .then((res) => {
                    if (res.code == 1) {
                        this.option = res.data
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        changePage: function (page) {
            this.searchData.page = page;
            this.getList();
            // 回到顶部
            this.$refs.scrollRef.scrollTop = 0;
        },
        jump: function (id) {
            let that = this;
            that.$router.push({path: `memberinfo/${id}`})
        }
    },
    created() {
        this.getOption();
        this.getList();

        for (var age = 18; age <= 90; age++) {
            this.age.push({
                value: age,
                label: this.$t('ageText', {age: age, s: "s"})
            })
        }

        for (var height = 140; height <= 200; height++) {
            this.height.push({
                value: height,
                label: height + 'cm'
            })
        }
    },
}
</script>
<style scoped>
.card-header {
    border: 1px solid #ebebeb;
    border-radius: 3px 3px 0 0;
    transition: .2s;
    padding: 15px;
    border-bottom: none;
}

.header-title {
    color: #3B5998;
}

.card-header-search {
    margin-top: 50px;

}

.card-header-search .el-col {
    padding-bottom: 10px !important;
}

.card-header-row {
    /*overflow: hidden;
     height: 30px;
    line-height: 30px; */
}

.header-icon {
    font-size: 24px;
    float: right;
    margin-left: 15px;
    color: #3B5998;
    cursor: pointer;
}

.icon-al-shaixuan {
    font-size: 26px !important;
    font-weight: bold;
}

.card-body {
    width: 100%;
    border: 1px solid #ebebeb;

    border-radius: 0 0 3px 3px;
    transition: .2s;
    padding: 15px;

}

.card-row {
    border-radius: 10px !important;
    position: relative;
}

.card-item {
    margin-bottom: 20px;
}

.card-item:hover .card-operate {
    transform: translate(0, 0);
}

.image-wrap {
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1 / 1.3;
}

.card-image, .list-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all .3s;
    object-fit: cover;
}

.card-item:hover .card-image {
    transform: scale(1.05);
}

.card-nickname {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
}

.card-desc {
    position: relative;
    padding: 10px 10px 10px;
}

.card-item .vip-icon {

    font-size: 30px !important;
    color: #3B5998;
    position: absolute;
    right: 5px;
    top: 218px;
}

.list-item .vip-icon {
    position: relative;
    top: 5px;
}

.card-operate {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    line-height: 1;
    background-color: #f5f5f5;
    transform: translate(0, 100%);
    transition: all .3s;
}

.card-info {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    padding: 0 10px;
}

.card-operate-row {
    text-align: center;
    color: #3B5998;
}

.card-operate-row i {
    cursor: pointer;
    font-size: 22px;
    border: 2px solid;
    border-radius: 50%;
    padding: 5px;
}

.card-operate-guanzhu {
    color: #FF6347;
}

.card-operate-duihua {
    color: #3B5998;
}

.guanzhu-isactive {
    background: #FF6347;
    color: #fff;
    border-color: #FF6347 !important;
}

.icon-al-zhuangtai {
    color: #bbbbbb
}

.online-isactive {
    color: #03cf03;
}

.pagination {
    width: 100%;
    text-align: center;
}

.list-item {
    padding: 10px;
}

.list-avatar {
    width: 120px;
    height: 120px;
    float: left;
    position: relative;
}


.list-avatar img {
    width: 100%;
}

.list-content {
    margin-left: 130px;

}

.list-nickname {
    font-weight: bold;
    padding: 5px 0;
}

.el-divider {
    clear: both;
}

.list-info {
    font-size: 14px;
    padding: 5px 0;
}

.list-desc {
    font-size: 12px;
    color: #a9a9a9;
    margin-top: 10px;
    padding-bottom: 10px;
}

.header-type {
    margin-left: 10px;
    color: #606266;
}

.header-type-active {
    background-color: #d8e2ff;
    padding: 5px;
    border-radius: 20px;
    color: #3B5998;
}

.list-right {
    height: 40px;
    line-height: 40px;
}

.chatNowBtn {
    /*margin-left: 10px;*/
    float: right
}
</style>

<style lang="scss" scoped>
.image-wrap {
    .lemon-message-vip-icon {
        top: 4px;
        right: 5px;
        font-size: 50px !important;
    }
}

@media only screen and (max-width: 991px) {
    .header-type {
        display: inline-block;

        &:first-of-type {
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    .card-header-search {
        margin-top: 20px;
    }
    .btn-search {
        width: 100%;
    }
    .chatNowBtn {
        margin: 0;
        width: 100%;
    }
    .card-info {
        height: 75px;
    }
    .card-desc {
        padding: 10px 0px 10px;
    }
    .header-title {
        font-size: 18px;
    }
    .header-title i {
        font-size: 18px !important;
    }
    .search-span {
        float: right;
    }
    .card-header {
        padding: 5px 10px 5px 10px;
        position: fixed;
        top: 60px;
        z-index: 10;
        width: 100%;

    }
    .card-body {
        padding: 5px;
        padding-top: 55px !important;
        padding-bottom: 100px !important;
    }
}
</style>