<script>
export default {
    name: "lemonMessageImage",
    inheritAttrs: false,
    inject: ["IMUI"],
    render() {
        return (
            <lemon-message-basic
                class="lemon-message-image"
                props={{...this.$attrs}}
                scopedSlots={{
                    content: props => {
                        return (
                            <div class="image-wrapper">
                                <img src={this.cdnurl(props.content)}/>
                            </div>
                        );
                    }
                }}
            />
        );
    }
};
</script>

<style lang="scss" scoped>
.lemon-message.lemon-message-image .lemon-message__content {
    padding: 10px;
    background: unset !important;
}

.lemon-message.lemon-message-image {
    ::v-deep(.lemon-message__content) {
        img {
            background: unset;
        }
    }
}

.lemon-message {
    .image-wrapper {
        width: 120px;
        height: 120px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>
