<template>
    <div class="v--modal-overlay">
        <div class="v--modal-background-click">
            <div class="v--modal-top-right"></div>
            <div class="v--modal-box b-gallery b-gallery_scrollable"
                style="top: 0px; left: 0px; width: 375px; height: 667px;">
                <div class="gallery-modal">
                    <div class="gallery-header d-flex flex-row justify-content-between align-items-center" mode="user">
                        <div class="gallery-header__item gallery-nav__counter t-b-2 s-p-l-4">
                            {{ initialSwipe + 1 }}/{{ list.length }}
                        </div>
                        <h3 class="gallery-header__item t-b-2 f-w-m t-a-c"> 
                            {{ type === 'photo' ? 'Photos' : 'Videos' }}
                        </h3>
                        <div class="gallery-header__item t-a-r">
                            <button class="close s-p-5" @click="$emit('close', false)">
                                <div class="ui-icon ui-icon__display-inline" style="width: 14px; height: 14px;">
                                    <span class="ui-icon__display-block" style="width: 14px; height: 14px;">
                                        <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="m10.125 8.001 5.88-5.88L13.884 0 8.002 5.88 2.122 0 0 2.121l5.88 5.88L0 13.881l2.121 2.122 5.881-5.88 5.88 5.88 2.124-2.121-5.881-5.88z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="gallery-body">
                        <div class="gallery-slider-wrapper d-flex">
                            <div class="gallery-slider-wrapper">
                                <div
                                    class="splide gallery-slider splide--slider splide--ltr splide--draggable is-active">
                                    <div class="splide__arrows">
                                        <button
                                            class="splide__arrow gallery-slider__arrow gallery-slider__arrow_transparent splide__arrow--prev gallery-slider__arrow_prev"
                                            type="button"
                                            @click="handlePrev"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40"
                                                height="40">
                                                <path
                                                    d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z">
                                                </path>
                                            </svg>
                                        </button>
                                        <button
                                            class="splide__arrow gallery-slider__arrow gallery-slider__arrow_transparent splide__arrow--next gallery-slider__arrow_next"
                                            type="button"
                                            @click="handleNext"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40"
                                                height="40">
                                                <path
                                                    d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>

                                    <div class="splide__track">
                                        <van-swipe class="splide__list" :autoplay="0" :initial-swipe="defaultIndex"
                                            ref="refSwipe"
                                            @change="handleSwipeChange">
                                            <van-swipe-item v-for="item in list" :key="item.id">
                                                <div class="splide__slide gallery-slider__item is-active is-visible"
                                                    :style="`background-image: url(&quot;${cdnurl(item.cover)}&quot;);`">
                                                    <div
                                                        class="gallery-slider__image-container d-flex align-items-center gallery-slider__item--blurred">
                                                        <div class="touchZoom d-flex">
                                                            <div class="transformDom"
                                                                style="transform: translate(0px, 0px) scale(1, 1);">
                                                                <template v-if="item.type === 'image'">
                                                                    <div class="gallery-slider__image"
                                                                        :style="`background-image: url(&quot;${cdnurl(item.cover)}&quot;);`">
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <template v-if="item.file === ''">
                                                                        <div class="gallery-slider__image"
                                                                            :style="`background-image: url(&quot;${cdnurl(item.cover)}&quot;);`">
                                                                        </div>
                                                                        <i class="icon-al-bofang" @click="handlePlayVideo(item)"></i>
                                                                    </template>
                                                                    <player-video v-else :src="item.file" :volume="volume" height="70vh" :ref="`video`"></player-video>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </van-swipe-item>
                                        </van-swipe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gallery-footer">
                        <div class="gallery-footer-wrapper justify-content-center">
                            <button
                                class="ui-button ui-button__full-width gap-1 ui-button__size-m ui-button__type-secondary ui-button__icon-position-left trigger-tips-animated button"
                                :class="{ 'ui-button__disabled': list[initialSwipe].liked }" @click="handleLike">
                                <div class="ui-icon ui-icon__display-inline"
                                    style="width: 24px; height: 24px; visibility: visible;">
                                    <span class="ui-icon__display-block" style="width: 24px; height: 24px;">
                                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M18.818 8.894h-4.78l.72-3.462.022-.242c0-.311-.128-.599-.333-.803l-.803-.796-4.985 4.992c-.28.273-.447.652-.447 1.069v7.575a1.52 1.52 0 0 0 1.515 1.516h6.818c.63 0 1.167-.38 1.394-.925l2.288-5.34c.068-.175.106-.357.106-.554V10.41a1.52 1.52 0 0 0-1.515-1.515zm-15.151.758h3.03v9.09h-3.03v-9.09z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                                <div class="ui-button__label"> {{ list[initialSwipe].liked ? 'Liked' : 'Like' }} </div>
                            </button>
                            <button
                                class="ui-button ui-button__full-width gap-1 ui-button__size-m ui-button__type-primary ui-button__icon-position-right button_main"
                                @click="routeTo(`/message?id=${userinfo.id}`)">
                                <div class="ui-icon ui-icon__display-inline"
                                    style="width: 24px; height: 24px; visibility: visible;">
                                    <span class="ui-icon__display-block" style="width: 24px; height: 24px;">
                                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20.1 6.6h-1.8v8.1H6.6v1.8c0 .495.405.9.9.9h9.9L21 21V7.5c0-.495-.405-.9-.9-.9zM16.5 12V3.9c0-.495-.405-.9-.9-.9H3.9c-.495 0-.9.405-.9.9v12.6l3.6-3.6h9c.495 0 .9-.405.9-.9z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                                <div class="ui-button__label"> Chat now </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';
import PlayerVideo from "@/page/components/common/player-video.vue";

import { cdnurl } from '@/filters'

export default {
    components: {
        VanSwipe: Swipe,
        VanSwipeItem: SwipeItem,
        PlayerVideo
    },
    model: {
        prop: 'modelValue',
        event: 'close'
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        userinfo: {
            type: Object
        },
        list: {
            type: Array,
            required: true
        },
        defaultIndex: {
            type: Number,
        },
        type: {
            type: String,
        }
    },
    data() {
        return {
            initialSwipe: this.defaultIndex
        }
    },
    methods: {
        handlePrev() {
            this.$refs.refSwipe.prev()
        },
        handleNext() {
            this.$refs.refSwipe.next()
        },
        handleSwipeChange(index) {
            this.initialSwipe = index
        },
        handlePlayVideo(item) {
            let that = this
            that.$http.post('/file/play', { id: item.id })
                .then((res) => {
                    if (res.code == 1) {
                        item.file = res.data
                    } else {
                        that.$message.error(res.msg);
                    }

                })
        },
        handleLike() {
            let that = this;
            let file = this.list[this.initialSwipe];
            this.$http.post('/file/like', { id: file.id })
                .then((res) => {
                    if (res.code == 1) {
                        file.liked = 1
                        that.liked = 1
                    } else {
                        that.$message.error(res.msg);
                    }

                })
        },
        routeTo(url) {
            this.$router.push({ path: url })
        }
    }
}
</script>

<style lang="scss" scoped>
.v--modal-overlay {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1501;
    opacity: 1;

    .v--modal-background-click {
        width: 100%;
        height: 100%;
    }

    .v--modal-top-right {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
    }

    .v--modal-box {
        overflow: visible;
        position: relative;
        box-sizing: border-box;
    }

    .gallery-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: var(--white);

        .gallery-header {
            z-index: 1502;
            height: 56px;
            box-shadow: 0 2px 4px #00000029;

            .gallery-header__item {
                flex-basis: 33.3333333333%;

                .close {
                    cursor: pointer;
                    border: none;
                    background: #0000;
                    line-height: 14px;
                }
            }
        }

        .gallery-body {
            position: relative;
            display: flex;
            height: 100%;
            background-color: var(--gray-10);

            .gallery-slider-wrapper {
                position: relative;
                flex: 1 0 auto;
                max-width: 100%;

                .gallery-slider {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    visibility: visible;

                    .splide__arrows {
                        .splide__arrow {
                            position: absolute;
                            z-index: 1;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 2em;
                            height: 2em;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: none;
                            padding: 0;
                            opacity: .7;
                            background: #ccc;

                            svg {
                                width: 1.2em;
                                height: 1.2em;
                            }
                        }

                        .splide__arrow--prev {
                            left: 1em;

                            svg {
                                transform: scaleX(-1);
                            }
                        }

                        .gallery-slider__arrow {
                            z-index: 4;
                            width: 56px;
                            height: 56px;
                            transition: background-color .3s ease-in;
                            opacity: 1;
                            background-color: #1414144d;

                            &:active {
                                background-color: #14141499 !important;
                            }

                            svg {
                                fill: var(--white);
                            }
                        }

                        .gallery-slider__arrow_transparent {
                            width: 40px;
                            height: 80px;
                            padding: 5px;
                        }

                        .gallery-slider__arrow_prev {
                            left: 2px;
                        }

                        .gallery-slider__arrow_transparent.gallery-slider__arrow_prev {
                            left: 0;
                            justify-content: flex-start;
                            border-radius: 0 40px 40px 0;
                            background: radial-gradient(circle at 0, #0000004d, #0000004d 29%, #0000 80%);
                        }

                        .gallery-slider__arrow_transparent.gallery-slider__arrow_next {
                            right: 0;
                            justify-content: flex-end;
                            border-radius: 40px 0 0 40px;
                            background: radial-gradient(circle at 130%, #0000004d, #0000004d 29%, #0000 80%);
                        }
                    }

                    .splide__track {
                        position: relative;
                        z-index: 0;
                        overflow: hidden;
                        height: 100% !important;

                        .splide__list {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin: 0 !important;
                            padding: 0 !important;
                            will-change: transform;

                            .splide__slide {
                                position: relative;
                                outline: none;
                                box-sizing: border-box;
                                margin: 0;
                                flex-shrink: 0;
                                user-select: none;
                            }

                            .gallery-slider__item {
                                width: 100%;
                                height: 100%;
                                background-color: var(--gray-50);
                                background-repeat: no-repeat;
                                background-position: 50%;
                                background-size: cover;

                                .gallery-slider__image-container {
                                    height: 100%;
                                }

                                .gallery-slider__item--blurred {
                                    overflow: hidden;
                                    width: 100%;
                                    height: 100%;
                                    -webkit-backdrop-filter: blur(15px);
                                    backdrop-filter: blur(15px);
                                }

                                .touchZoom {
                                    overflow: hidden;
                                    width: 100%;
                                    height: 100%;

                                    .transformDom {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        transform-origin: top left;

                                        .gallery-slider__image {
                                            position: relative;
                                            overflow: hidden;
                                            width: 100%;
                                            height: 100%;
                                            background-repeat: no-repeat;
                                            background-position: 50%;
                                            background-size: contain;
                                        }

                                        .icon-al-bofang {
                                            position: absolute;
                                            left: 50%;
                                            top: 50%;
                                            background-color: rgba(60, 61, 63, 0.19);
                                            font-size: 100px;
                                            border-radius: 50%;
                                            padding: 10px;
                                            color: #f7f7f7;
                                            cursor: pointer;
                                            z-index: 2;
                                            transform: translate(-50%, -50%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .gallery-footer {
            box-sizing: border-box;
            z-index: 1;
            min-height: 64px;
            padding: 8px 10px;
            box-shadow: 0 -2px 4px #00000029;

            .gallery-footer-wrapper {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin: 0 auto;
                gap: 8px;

                .trigger-tips-animated {
                    animation: pulse-0f41933e 12s forwards;
                }

                .button {
                    width: 35%;
                }

                .button_main {
                    width: 65%;
                }
            }
        }
    }
}

@keyframes pulse-0f41933e {
    0% {
        transform: scale(1);
    }

    8% {
        transform: scale(.9);
    }

    12% {
        transform: scale(1);
    }

    16% {
        transform: scale(.9);
    }

    20% {
        transform: scale(1);
    }

    24% {
        transform: scale(.9);
    }

    28% {
        transform: scale(1);
    }

    32% {
        transform: scale(.9);
    }

    36% {
        transform: scale(1);
    }

    68% {
        transform: scale(1);
    }

    72% {
        transform: scale(.9);
    }

    76% {
        transform: scale(1);
    }

    80% {
        transform: scale(.9);
    }

    84% {
        transform: scale(1);
    }

    88% {
        transform: scale(.9);
    }

    92% {
        transform: scale(1);
    }

    96% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}
</style>