<template>
    <el-dialog :close-on-click-modal="false" :visible.sync="show" align-center class="payino-dialog"
        width="450px" @close="close()">
        <div class="payinfo-form">
            <div class="modal">
                <div class="form">
                    <div class="separator">
                        <hr class="line">
                        <p>pay using credit card</p>
                        <hr class="line">
                    </div>
                    <div class="credit-card-info--form">
                        <div class="input_container">
                            <label class="input_label" for="full_name">Card holder full name</label>
                            <input id="full_name" v-model="formData.name" class="input_field" name="input-name"
                                placeholder="Enter your full name" title="Inpit title" type="text">
                        </div>
                        <div class="input_container">
                            <label class="input_label" for="card_number">Card Number</label>
                            <input id="card_number" v-model="formData.cardnum" class="input_field" name="input-name"
                                placeholder="0000 0000 0000 0000" title="Inpit title" type="number">
                        </div>
                        <div class="input_container">
                            <label class="input_label" for="expire_date">Expiry Date / CVC</label>
                            <div class="split">
                                <input id="expire_date" v-model="formData.date" class="input_field" name="input-name"
                                    placeholder="01/23" title="Expiry Date" type="text">
                                <input id="cvc" v-model="formData.cvc" class="input_field" name="cvc" placeholder="CVC"
                                    title="CVC" type="number">
                            </div>
                        </div>
                    </div>
                    <button class="purchase--btn" @click="confirm()">Checkout</button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        show: {
            require: true,
            default: false
        }
    },
    data() {
        return {
            formData: {
                name: '',
                cardnum: '',
                date: '',
                cvc: '',
            },
            rules: [
                {key: 'name', errMsg: 'Please enter card holder full name'},
                {key: 'cardnum', errMsg: 'Please enter card number'},
                {key: 'date', errMsg: 'Please enter expiry date'},
                {key: 'cvc', errMsg: 'Please enter CVC'},
            ],
        }
    },
    created() {

    },
    methods: {
        confirm() {
            console.log(this.rules)
            for (let index = 0; index < this.rules.length; index++) {
                const rule = this.rules[index];
                console.log(this.formData[rule.key] === '')
                if (this.formData[rule.key] === '') {
                    this.$message.error(rule.errMsg)
                    return
                }
            }
            this.$emit('confirm', this.formData)
        },
        close() {
            this.formData = this.$options.data().formData
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>