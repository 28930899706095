<template>
    <div class="sidebar-right">
        <div class="box-card sidebar-item">
            <h4 class="header">
                {{ $t('MyVIP') }}
            </h4>
            <div class="content">
                <p class="content-text">
                    <img alt="" src="@/assets/svg/vip_chat.svg"/>
                    {{ $t('VIPIntro1') }}
                </p>
                <p class="content-text">
                    <img alt="" src="@/assets/svg/vip_stickers.svg"/>
                    {{ $t('VIPIntro2') }}
                </p>
                <p class="content-text">
                    <img alt="" src="@/assets/svg/vip_mail.svg"/>
                    <template v-if="systemVersion == 'B'">{{ $t('VIPIntro3') }}</template>
                    <template v-if="systemVersion == 'A' || systemVersion == 'C' ||systemVersion === 'D'">View and send
                        photos
                    </template>
                </p>
            </div>
            <div class="footer">
                <el-button plain type="primary" @click="jump('/benefits?type=vip')">{{ $t('GetVIP') }}</el-button>
            </div>
        </div>

        <div class="box-card sidebar-item" style="margin-top: 20px;">
            <h4 class="header">
                {{ $t('GetMoreWithCredits') }}
            </h4>
            <div class="content">
                <p class="content-text">
                    <img alt="" src="@/assets/svg/chat.svg"/>

                    <template v-if="systemVersion == 'B'"> {{ $t('ChatWithAnyoneYouLike') }}</template>
                    <template v-if="systemVersion == 'A' || systemVersion == 'C' ||systemVersion === 'D'">View and send
                        videos in mails
                    </template>
                </p>
                <p class="content-text">
                    <img alt="" src="@/assets/svg/gift.svg"/>
                    <template v-if="systemVersion == 'B'"> {{ $t('SendVirtualGifts') }}</template>
                    <template v-if="systemVersion == 'A' || systemVersion == 'C' ||systemVersion === 'D'">View and send
                        videos in chat
                    </template>
                </p>
                <p class="content-text">
                    <img alt="" src="@/assets/svg/email.svg"/>
                    {{ $t('RespondToMails') }}
                </p>
            </div>
            <div class="footer">
                <el-button plain type="primary" @click="jump('/benefits?type=money')">{{ $t('GetCredits') }}</el-button>
            </div>
        </div>

        <div class="box-card sidebar-item" style="margin-top: 20px;min-height: 130px">
            <h4 class="header">
                {{ $t('MyActivity') }}
            </h4>
            <div class="content content-notice">
                <p class="content-text" @click="jump('/message')">
                    <span class="float-left">{{ $t('message') }}</span>
                    <el-tag effect="dark" size="mini" type="warning">{{ unreadMessageCount }}</el-tag>
                </p>
                <p class="content-text" @click="jump('/mailbox')">
                    <span class="float-left">{{ $t('Mailbox') }}</span>
                    <el-tag effect="dark" size="mini" type="warning">{{ unreadMailCount }}</el-tag>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {cdnurl} from '../../../filters';
import setting from '../../../config/setting'

export default {
    components: {},
    props: {},
    data() {
        return {
            VIPIntro: '',
        }
    },
    computed: {
        ...mapGetters({
            unreadMessageCount: 'getUnreadMessageCount',
            unreadMailCount: 'getUnreadMailCount',
            systemVersion: 'getSystemVersion'
        })
    },
    methods: {
        jump(url) {
            this.$router.push(url)
        },
        // getVIPIntroduce() {
        //     this.$http.get('/config/getVipIntroduce', {})
        //         .then((res) => {
        //             if (res.code == 1) {
        //                 this.VIPIntro = res.data
        //             } else {
        //                 this.$message.error(res.msg);
        //             }

        //         })
        // }
    },
    created() {

    }
}
</script>

<style lang="scss" scoped>
.box-card {
    overflow: hidden;
    padding: 20px 0;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    color: #3c3c3c;
    transition: .3s;

    .header {
        padding: 0 20px;
    }

    .content {
        margin-top: 15px;
        padding: 0 20px;

        .content-text {
            display: flex;
            align-items: center;
            padding: 6px 0;

            img {
                margin-right: 5px;
            }
        }

        &.content-notice {
            padding: 0;
            font-size: 14px;

            .content-text {
                display: flex;
                justify-content: space-between;
                height: 20px;
                padding: 10px 20px;
                cursor: pointer;
                transition: all .3s ease-in-out;

                &:hover {
                    background-color: var(--hoverGrayColor);
                }
            }
        }

    }

    .footer {
        margin-top: 20px;
        padding: 0 20px;

        button {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .sidebar-right {
        margin-top: 20px;
        display: none
    }
}
</style>