<template>
    <div class="aaa">
        <template
            v-if="isLogin  /*|| ['/login', '/article'].includes(currentPath)*/ || getSystemVersion !=='B' ">
            <el-container>
                <el-header v-if="isMobile ? isShowHeader : true">
                    <header-view></header-view>
                </el-header>

                <el-main :class="{ 's-p-0': $route.path === '/login' }">
                    <router-view class="view" keep-alive transition transition-mode="out-in"></router-view>
                </el-main>

                <el-footer height="auto">
                    <footer-view class="hidden-xs-only"></footer-view>
                    <van-tabbar v-show="isShowTabbar" v-model="active" class="hidden-sm-and-up">
                        <van-tabbar-item icon="search" name="/" to="/">{{ $t("search") }}</van-tabbar-item>
                        <van-tabbar-item :badge="unreadMessageCount || ''" icon="chat-o" name="/message" to="/message">
                            {{ $t('message') }}
                        </van-tabbar-item>
                        <van-tabbar-item :badge="unreadMailCount || ''" icon="coupon-o" name="/mailbox" to="/mailbox">
                            {{ $t("Mailbox") }}
                        </van-tabbar-item>
                        <van-tabbar-item icon="fire-o" name="/hot" to="/hot">{{ $t("DynamicSquare") }}</van-tabbar-item>
                        <van-tabbar-item icon="friends-o" name="/people" to="/people">{{
                                $t("People")
                            }}
                        </van-tabbar-item>
                    </van-tabbar>
                </el-footer>
            </el-container>

            <div v-if="!isMobile && isShowService" class="toolkit-wrapper">
                <div class="toolkit-item">
                    <van-icon color="#3b5998" name="service" size="32px" @click="customerService"/>
                </div>
            </div>
        </template>

        <comp-website v-else></comp-website>
    </div>
</template>

<script>
import HeaderView from '@/components/common/layout/HeaderView.vue';
import FooterView from '@/components/common/layout/FooterView.vue';
import Website from '@/page/Website.vue'
import {Icon, Tabbar, TabbarItem} from 'vant';
import {mapState, mapGetters, mapMutations} from 'vuex';

export default {
    components: {
        CompWebsite: Website,
        HeaderView,
        FooterView,
        VanIcon: Icon,
        VanTabbar: Tabbar,
        VanTabbarItem: TabbarItem,
    },
    data() {
        return {
            active: '',
            isShowService: false, // 是否显示客服按钮
            isShowHeader: false,
        }
    },
    computed: {
        ...mapState({
            isLogin: 'isLogin',
            currentPath: 'currentPath',
            isMobile: 'isMobile',
            isShowTabbar: 'isShowTabbar'
        }),
        ...mapGetters({
            unreadMessageCount: 'getUnreadMessageCount',
            unreadMailCount: 'getUnreadMailCount',
            customerId: 'getCustomerId',
            getSystemVersion: 'getSystemVersion',
        })
    },
    watch: {
        '$route.path': function (newVal) {
            // console.log('newVal', newVal)
            const routes = ['/', '/message', '/mailbox', '/hot', '/mine', '/people']
            if (routes.includes(newVal)) {
                this.isShowService = true
                this.active = newVal
                this.setIsShowTabbar(true)
            } else {
                if (newVal === '/login') {
                    this.isShowService = false
                } else {
                    this.isShowService = true
                }
                this.active = ''
                this.setIsShowTabbar(false)
            }
        },
        '$route.meta': function (newPathMeta) {
            this.isShowHeader = newPathMeta.isShowHeader
        }
    },
    created() {
        const currentPath = this.$route.path
        if (currentPath === '/login') {
            this.isShowService = false
            this.active = ''
            this.setIsShowTabbar(false)
        } else {
            this.isShowService = true
            this.active = currentPath
        }
        const pathMeta = this.$route.meta
        this.isShowHeader = pathMeta.isShowHeader
    },
    methods: {
        ...mapMutations(['setIsShowTabbar']),
        customerService() {
            const currentPath = this.$route.path
            if (currentPath === "/message") {
                const route = this.$router.resolve('/message?id=' + this.customerId);
                window.location = route.href
            } else {
                this.$router.push({
                    path: '/message',
                    query: {
                        id: this.customerId
                    }
                })
            }


        }
    }
}
</script>

<style lang="scss" scoped>
.el-main {
    overflow: visible;
    min-height: calc(100vh - 59px - 119px);

    &.s-p-0 {
        padding: 0;
    }
}

.toolkit-wrapper {
    position: fixed;
    right: 0;
    bottom: 100px;
    width: 56px;
    border-radius: 10px 0 0 10px;
    box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
    background-color: #fff;

    .toolkit-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        cursor: pointer;

        span {
            font-size: 12px;
        }
    }
}

.el-footer {
    width: 100%;
    background: #3B5998;
}

@media only screen and (max-width: 767px) {
    .el-main {
        min-height: calc(100vh);
        padding: 0 0 0;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
}
</style>