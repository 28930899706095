<template>
    <div>
        <!-- element 上传图片按钮 -->

        <el-upload class="avatar-uploader" action="" :auto-upload="false" :show-file-list="false"
            :on-change='handleChangeUpload'>
            <img v-if="previewImg" :src="previewImg | cdnurl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <!-- vueCropper 剪裁图片实现-->
        <el-dialog :title="$t('PictureClipping')" :visible.sync="dialogVisible" custom-class="crop-dialog" append-to-body>
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vueCropper ref="cropper" :img="option.img" :outputSize="option.size"
                        :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop"
                        :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox"
                        :infoTrue="option.infoTrue" :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight" @cropMoving="cropMoving" />
                </div>
            </div>
            <div class="action-box">
                <el-col :xs="12" :sm="6" :md="8">
                    <el-upload action="" class="change-upload btn-cropper" :auto-upload="false" :show-file-list="false"
                        :on-change='handleChangeUpload'>
                        <el-button type="primary" plain>{{ $t('ReplacePicture') }}</el-button>
                    </el-upload>
                </el-col>
                <el-col :xs="12" :sm="6" :md="8">
                    <el-button class="btn-cropper" type="primary" plain @click="clearImgHandle">{{ $t('ClearPicture')
                        }}</el-button>
                </el-col>
                <el-col :xs="12" :sm="6" :md="8">
                    <el-button class="btn-cropper" type="primary" plain @click="rotateLeftHandle">{{ $t('anticlockwise')
                        }}</el-button>
                </el-col>
                <el-col :xs="12" :sm="6" :md="8">
                    <el-button class="btn-cropper" type="primary" plain @click="rotateRightHandle">{{
                        $t('ClockwiseRotation') }}</el-button>
                </el-col>
                <el-col :xs="12" :sm="6" :md="8">
                    <el-button class="btn-cropper" type="primary" plain @click="changeScaleHandle(1)">{{ $t('amplify')
                        }}</el-button>
                </el-col>
                <el-col :xs="12" :sm="6" :md="8">
                    <el-button class="btn-cropper" type="primary" plain @click="changeScaleHandle(-1)">{{ $t('reduce')
                        }}</el-button>
                </el-col>
                <!-- <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button> -->
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="finish" :loading="loading">{{ $t('confirm') }}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { cdnurl } from '../../../filters';

export default {
    name: "Cropper",
    components: {},
    props: {
        avatar: '',
        fixedNumber: {
            required: false,
            default: [1, 1]
        }
    },
    data() {
        return {
            isPreview: false,
            dialogVisible: false,
            previewImg: this.avatar, // 预览图片地址
            // 裁剪组件的基础配置option
            option: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                canMoveBox: true, // 截图框能否拖动
                autoCropWidth: 200, // 默认生成截图框宽度
                autoCropHeight: 200, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: this.fixedNumber, // 截图框的宽高比例
                full: false, // 是否输出原图比例的截图
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            // 防止重复提交
            loading: false,
            fileInfo: {}
        }
    },
    methods: {
        // 上传按钮 限制图片大小和类型
        handleChangeUpload(file, fileList) {
            const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('The image type can only be JPG/PNG format!');
                return false
            }
            if (!isLt2M) {
                this.$message.error('The image size cannot exceed 2MB!');
                return false
            }
            // 上传成功后将图片地址赋值给裁剪框显示图片
            this.$nextTick(async () => {
                // base64方式
                // this.option.img = await fileByBase64(file.raw)
                this.option.img = URL.createObjectURL(file.raw)
                this.loading = false
                this.dialogVisible = true
                this.fileInfo = file
            })
        },
        // 放大/缩小
        changeScaleHandle(num) {
            num = num || 1;
            this.$refs.cropper.changeScale(num);
        },
        // 左旋转
        rotateLeftHandle() {
            this.$refs.cropper.rotateLeft();
        },
        // 右旋转
        rotateRightHandle() {
            this.$refs.cropper.rotateRight();
        },
        // 下载
        downloadHandle(type) {
            let aLink = document.createElement('a')
            aLink.download = 'author-img'
            if (type === 'blob') {
                this.$refs.cropper.getCropBlob((data) => {
                    let downImg = window.URL.createObjectURL(data)
                    aLink.href = window.URL.createObjectURL(data)
                    aLink.click()
                })
            } else {
                this.$refs.cropper.getCropData((data) => {
                    let downImg = data;
                    aLink.href = data;
                    aLink.click()
                })
            }
        },
        // 清理图片
        clearImgHandle() {
            this.option.img = ''
        },
        // 截图框移动回调函数
        cropMoving(data) {
            // 截图框的左上角 x，y和右下角坐标x，y
            // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
            // console.log(cropAxis)
        },
        finish() {
            // 获取截图的 blob 数据
            this.$refs.cropper.getCropBlob((blob) => {
                let file = new FormData()
                var abc = new File([blob], this.fileInfo.name, { type: this.fileInfo.raw.type, lastModified: Date.now() });
                file.append('file', abc)

                this.$http.post('/common/upload', file,)

                    .then((res) => {
                        if (res.code == 1) {
                            this.loading = true
                            this.dialogVisible = false
                            this.previewImg = URL.createObjectURL(blob)
                            this.isPreview = true
                            this.$emit('changeAvatar', { avatar: res.data.url })
                        } else {
                            this.$message.error(res.msg);
                        }

                    })

            })
            // 获取截图的 base64 数据
            // this.$refs.cropper.getCropData(data => {
            //     console.log(data)
            // })
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px !important;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}

.cropper-content .cropper {

    width: auto;
    height: 350px;
}

.action-box {
    margin-top: 10px;
    height: 110px;
}
.action-box .el-col{
        margin-top: 5px
    }
.change-upload {
    display: inline-block;
  
}
.btn-cropper {
        width: 100%;
    }
.btn-cropper .el-upload,.btn-cropper .el-upload .el-button{
    width: 100%;
}
@media only screen and (max-width: 767px) {
    .crop-dialog  {
        width: 90% !important;
        z-index: 2500;
    }

   
    .action-box{
        height: 110px;
    }
   
}
</style>