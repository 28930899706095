<template>
    <div>
        <ul>
            <li v-for="item in orderList" :key="item.id" class="order-item">
                <el-badge
                    :is-dot="(item.user_id === userinfo.id && item.delivery_status === 2 && item.confirm ===0) || (item.to_user_id === userinfo.id && item.delivery_status === 1)"
                    class="item" style="width: 100%">
                    <el-card class="box-card">
                        <div slot="header">
                            <el-row align="middle" type="flex">
                                <el-col :sm="12" :xs="24">
                                    <span class="label">OrderNo</span>:
                                    <span class="value">{{ item.orderid }}</span>
                                </el-col>
                                <el-col v-if="item.user_id === userinfo.id" :sm="8" :xs="16" class="order-to">
                                    to：<img :src="item.touser_avatar | cdnurl" alt="" class="avatar" height="50"
                                            width="50"/>{{ item.touser_nickname }}
                                </el-col>
                                <el-col v-else :sm="8" :xs="16" class="order-to">
                                    from：<img :src="item.user_avatar | cdnurl" alt="" class="avatar" height="50"
                                              width="50"/>{{ item.user_nickname }}
                                </el-col>
                                <el-col :sm="4" :xs="8" style="text-align: right;">
                                    <el-tag v-if="item.delivery_status == 1" class="value" type="warning">{{
                                            $t('OrderInDelivery')
                                        }}
                                    </el-tag>
                                    <el-tag v-else class="value" type="success">{{ $t('OrderReceived') }}</el-tag>
                                </el-col>
                                <el-col v-if="item.delivery_no!=''" :sm="24" :xs="24"
                                        class="s-m-r-3">
                                    <span class="label">Logistics tracking number</span>:
                                    <span class="value">{{ item.delivery_no }}</span>
                                </el-col>
                            </el-row>
                        </div>
                        <div>
                            <el-row>
                                <el-col v-for="gift in item.gifts_list" :key="gift.id" :sm="12" :xs="24"
                                        class="gift-item">
                                    <img :src="gift.image | cdnurl" alt="" height="100" width="100">
                                    <div class="gift-item_info">
                                        <p>{{ gift.name }}</p>
                                        <p>x{{ gift.num }}</p>
                                        <p v-if="item.paytype === 'money'" style="display: flex;align-items: center">
                                            {{ gift.money }} <img
                                            alt="credits" class="credits-icon"
                                            src="@/assets/svg/creditсoin.svg"></p>
                                        <p v-else>${{ gift.price }}</p>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <div class="d-flex justify-content-end align-items-center">

                                <div v-if="item.to_user_id === userinfo.id && item.delivery_status === 1"
                                     class="s-m-r-3">
                                    <el-button size="medium" type="primary" @click="handleSignFor(item)">Sign For
                                    </el-button>
                                </div>
                                <div class="info-item">
                                    <span class="label">{{ $t('OrderAmount') }}</span>:
                                    <span class="value">${{ item.money }}</span>
                                </div>
                            </div>
                        </div>
                    </el-card>
                    <el-divider></el-divider>
                </el-badge>
            </li>
        </ul>
        <el-pagination :current-page.sync="page" :hide-on-single-page="true" :page-size="pageSize"
                       :total="total" background layout="total, ->, prev, pager, next" @current-change="getOrderList">
        </el-pagination>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {Dialog} from 'vant'

export default {
    props: {
        deliveryStatus: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            orderList: [],
            total: 0,
        }
    },
    computed: {
        ...mapState({
            userinfo: 'userinfo'
        }),
        ...mapGetters({
            userinfo: 'getUserinfo',
        }),

    },
    created() {

        this.getOrderList()
    },
    methods: {
        ...mapMutations([
            'setGiftNoticeNum',
            'setPhoneNoticeNum',
        ]),
        /**
         * 签收
         */
        handleSignFor(item) {
            Dialog.confirm({
                title: 'Warning',
                message: 'Please confirm that you have received the order?',
            })
                .then(() => {
                    this.$http.post('/mall/signFor', {
                        id: item.id
                    })
                        .then(res => {
                            if (res.code == 1) {
                                this.$message.success('Successfully signed for')
                                item.delivery_status = 2
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                        .catch(err => {
                            this.$message.error(err.message)
                        })
                })
        },
        getOrderList() {
            let that = this
            that.$http.get('/mall/orderList', {
                params: {
                    type: 'real_gift',
                    page: that.page,
                    limit: that.pageSize,
                    delivery_status: that.deliveryStatus
                }
            })
                .then(res => {
                    if (res.code === 1) {
                        that.orderList = res.data.list || []
                        that.total = res.data.total
                        that.setGiftNoticeNum(res.data.giftNoticeNum)
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.order-item {
}

.order-item .el-row {
    flex-wrap: wrap;
}

.info-item {
    display: flex;
}

.info-item .label {
    white-space: nowrap;
    color: #959595;
}

.info-item .value {
    margin-left: 10px;
}

.order-to {
    display: flex;
    align-items: center
}

.order-to .avatar {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.gift-item {
    display: flex;
    margin-top: 20px;
}

.gift-item_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
    padding: 15px 0;
}


</style>