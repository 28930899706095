<template>
    <div class="main-wrapper">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">
                <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                    <el-tab-pane :label="$t('All')" name="0">
                        <template v-if="activeName == '0'">
                            <OrderTable deliveryStatus="0"></OrderTable>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('OrderDelivery')" name="1">
                        <template v-if="activeName == '1'">
                            <OrderTable deliveryStatus="1"></OrderTable>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('OrderReceived')" name="2">
                        <template v-if="activeName == '2'">
                            <OrderTable deliveryStatus="2"></OrderTable>
                        </template>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6">
                <right-sider-bar></right-sider-bar>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import RightSiderBar from '@/page/components/common/RightSiderBar.vue'
import OrderTable from '@/components/common/order/PhoneTable.vue'

export default {
    name: 'OrderList',
    components: {
        RightSiderBar,
        OrderTable,
    },
    data() {
        return {
            activeName: '0',
        }
    },
    created() {
        
    },
    methods: {
        handleClick(val) {
            console.log(val)
        },
    }
}
</script>

<style lang="scss" scoped>

   
</style>