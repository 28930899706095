<template>
  <div class="like-dislike-container">		
    <div class="icons-box">
      <div class="icons">
        <div class="username-wrapper ani-spaceInUp">
          <div class="username">
            From： <span class="strong"> {{ giftInfo.fromUserName }}</span>
          </div>
          <i v-if="!autoClose" class="el-icon-close close-icon" @click="handleCloseGift"></i>
        </div>
        <div  class="swashIn">
          <label class="btn-label" for="like-checkbox">
            <input class="input-box" id="like-checkbox" type="checkbox">
            <div class="img-box">
              <img :src="giftInfo.giftUrl" class="gift-img" />
            </div>
            <div class="fireworks">
              <div class="checked-like-fx"></div>
            </div>
          </label>
          <div class="faces-btn-wrap">
            <el-button type="primary" plain @click="toChat">
                <i class="el-icon-s-comment"></i> 
                Chat now
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['hideGift'],
  props: {
    giftInfo: {
      require: true,
      defaultValue: {},
    },
    autoClose: {
      require: true,
      defaultValue: true,
    }
  },
  methods: {
    handleCloseGift() {
      this.hideGift()
    },
    toChat() {
      this.hideGift()
      this.$router.push({
        path: '/message',
        query: {
          id: this.giftInfo.fromUserId
        }
      })
    }
  }
}
</script>

<style scoped>
.faces-btn-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 22px 0;
    background: #fff;
}

.like-dislike-container {
  --dark-grey: #353535;
  --middle-grey: #767676;
  --lightest-grey: linear-gradient(#fafafa,#ebebeb);
  --shadow: 0 5px 15px 0 #00000026;
  --shadow-active: 0 5px 5px 0 #00000026;
  --border-radius-main: 10px;
  --border-radius-icon: 50px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: var(--dark-grey);
  margin: auto;
  padding: 1.5rem;
  font-weight: 600;
  background: rgba(0, 0, 0, .6);
  border-radius: var(--border-radius-main);
  box-shadow: var(--shadow);
  transition: .2s ease all;
}

.like-dislike-container .icons-box {
  display: flex;
}

.like-dislike-container .icons {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  cursor: pointer;
  user-select: none;
  transition: .2s ease all;
}

.username-wrapper {
  position: relative;
  width: 100%;
}

.icons .username {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  font-size: 28px;
  color: #fff;
  cursor: auto;
}

.icons .username .strong {
  font-size: 34px;
  color: #fdb515;
}

.icons .close-icon {
  position: absolute;
  top: 23px;
  right: 0px;
  font-size: 34px;
  color: #fff;
  transition: all .3s;
}

.icons .close-icon:hover {
  transform: scale(1.2);
}

.like-dislike-container .icons .btn-label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.like-dislike-container .like-text-content {
  border-right: 0.1rem solid var(--dark-grey);
  padding: 0 0.6rem 0 0.5rem;
  pointer-events: none;
}

.like-dislike-container .icons .svgs {
  width: 1.3rem;
  fill: #000000;
  box-sizing: content-box;
  padding: 10px 10px;
  transition: .2s ease all;
}

/* Hide the default checkbox */
.like-dislike-container .icons .input-box {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
}

.gift-img {
  display: block;
  max-width: 100%;
}

.fireworks > .checked-like-fx {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  /* animation: 1s fireworks-bang ease-out infinite, 1s fireworks-gravity ease-in infinite, 5s fireworks-position linear infinite; */
  /* animation: 1s fireworks-bang ease-out infinite, 1s fireworks-gravity ease-in infinite; */
  animation-duration: 1.25s, 1.25s, 6.25s;
  transform: translate(-50%);
}

/* Fireworks Animation */
@keyframes fireworks-position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes fireworks-gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes fireworks-bang {
  to {
    box-shadow: 114px -207.3333333333px #8800ff, 212px -166.3333333333px #a600ff, 197px -6.3333333333px #ff006a, 179px -329.3333333333px #3300ff, -167px -262.3333333333px #ff0062, 233px 65.6666666667px #ff008c, 81px 42.6666666667px #0051ff, -13px 54.6666666667px #00ff2b, -60px -183.3333333333px #0900ff, 127px -259.3333333333px #ff00e6, 117px -122.3333333333px #00b7ff, 95px 20.6666666667px #ff8000, 115px 1.6666666667px #0004ff, -160px -328.3333333333px #00ff40, 69px -242.3333333333px #000dff, -208px -230.3333333333px #ff0400, 30px -15.3333333333px #e6ff00, 235px -15.3333333333px #fb00ff, 80px -232.3333333333px #d5ff00, 175px -173.3333333333px #00ff3c, -187px -176.3333333333px #aaff00, 4px 26.6666666667px #ff6f00, 227px -106.3333333333px #ff0099, 119px 17.6666666667px #00ffd5, -102px 4.6666666667px #ff0088, -16px -4.3333333333px #00fff7, -201px -310.3333333333px #00ffdd, 64px -181.3333333333px #f700ff, -234px -15.3333333333px #00fffb, -184px -263.3333333333px #aa00ff, 96px -303.3333333333px #0037ff, -139px 10.6666666667px #0026ff, 25px -205.3333333333px #00ff2b, -129px -322.3333333333px #40ff00, -235px -187.3333333333px #26ff00, -136px -237.3333333333px #0091ff, -82px -321.3333333333px #6a00ff, 7px -267.3333333333px #ff00c8, -155px 30.6666666667px #0059ff, -85px -73.3333333333px #6a00ff, 60px -199.3333333333px #55ff00, -9px -289.3333333333px #00ffaa, -208px -167.3333333333px #00ff80, -13px -299.3333333333px #ff0004, 179px -164.3333333333px #ff0044, -112px 12.6666666667px #0051ff, -209px -125.3333333333px #ff00bb, 14px -101.3333333333px #00ff95, -184px -292.3333333333px #ff0099, -26px -168.3333333333px #09ff00, 129px -67.3333333333px #0084ff, -17px -23.3333333333px #0059ff, 129px 34.6666666667px #7300ff, 35px -24.3333333333px #ffd900, -12px -297.3333333333px #ff8400, 129px -156.3333333333px #0dff00, 157px -29.3333333333px #1a00ff, -221px 6.6666666667px #ff0062, 0px -311.3333333333px #ff006a, 155px 50.6666666667px #00ffaa, -71px -318.3333333333px #0073ff;
  }
}

.swashIn {
  animation: swashIn 2s ;
}
@keyframes swashIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

.ani-tinUpIn {
  animation: tinUpIn 4s;
}

@keyframes tinUpIn {
  0% {
    opacity: 0;
    transform: scale(1, 1) translateY(-900%);
  }
  50%, 70%, 90% {
    opacity: 1;
    transform: scale(1.1, 1.1) translateY(0);
  }
  60%, 80%, 100% {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }
}

.ani-spaceInUp {
  animation: spaceInUp 2s;
}

@keyframes spaceInUp {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(.2) translate(0%,-200%);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 0%;
    transform: scale(1) translate(0%,0%);
  }
}
</style>