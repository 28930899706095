var render, staticRenderFns
import script from "./lemon-message-gift.vue?vue&type=script&lang=js"
export * from "./lemon-message-gift.vue?vue&type=script&lang=js"
import style0 from "./lemon-message-gift.vue?vue&type=style&index=0&id=4eded87e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eded87e",
  null
  
)

export default component.exports