<template>
    <div>
        <el-col v-for="(row, index) in list" :key="index" :lg="8" :md="8" :sm="12" :xs="12" class="card-item ">
            <el-card :body-style="{ padding: '0px' }" class="card-row" shadow="hover">
                <div>
                    <img :src="row.avatar | cdnurl" class="card-image" @click="jump(row.user_id)">
                    <div class="card-desc">
                        <div class="card-info">
                                    <span class="float-left card-nickname">{{ row.nickname }}, {{ row.age }}
                                        <i :class="{ 'icon-al-zhuangtai': true, 'online-isactive': row.online == 1 }"></i>
                                    </span>
                            <el-button class="chatNowBtn" size="medium" type="warning" @click="toChat(row)">Chat Now
                            </el-button>
                        </div>

                    </div>
                </div>
            </el-card>
        </el-col>
    </div>
</template>
<script>
import ChatJs from '../../../services/chat.js'

export default {
    name: 'cardList',
    props: {
        list: []
    },
    data: function () {
        return {

            total: 0,
            cardInfoShow: 0,
            guanzhuHover: 0,
            loading: true,
            mode: localStorage.getItem("viewMode") || "card",
            searchShow: false,
            option: [],
            age: [],
            height: [],
            weight: [],
        }
    },
    methods: {
        toFollow: function (row) {
            let that = this;
            that.$http.post('/user/follow', {to_user_id: row.user_id})
                .then((res) => {
                    if (res.code == 1) {
                        if (row.followed > 0) {
                            row.followed = 0
                            that.guanzhuHover = 0
                            //ChatJs.delContacts(row.user_id)
                        } else {
                            row.followed = 1;
                            // 加入联系人
                            let contact = ChatJs.getContact(row.user_id)
                            if (!contact) {
                                ChatJs.setContacts({
                                    id: row.user_id,
                                    displayName: row.nickname,
                                    avatar: that.cdnurl(row.avatar),
                                    index: row.index,
                                    unread: 0,
                                    lastSendTime: +new Date(),
                                    lastContent: that.$t('SaySomething'),
                                    isvip: row.vip_expire_time
                                })
                            }

                        }
                        that.$message.success(res.msg);

                    } else {
                        that.$message.error(res.msg);
                    }

                })
        },
        toChat: function (row) {
            let that = this;

            that.$router.push({
                path: '/message',
                query: {
                    id: row.user_id
                }
            })
        },
    },
    created() {
        console.log(this.list)
    },
}
</script>

<style scoped>
.card-nickname {
    display: inline-block;
    text-align: center;
    font-weight: bold;
}

.card-info {
    height: 40px;
    margin-top: 10px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
}
.card-header {
    border: 1px solid #ebebeb;
    border-radius: 3px 3px 0 0;
    transition: .2s;
    padding: 15px;
    border-bottom: none;
}
</style>

<style>

.header-title {
    color: #3B5998;
}

.card-header-search {
    margin-top: 30px;

}

.card-header-search .el-col {
    padding-bottom: 10px !important;
}

.card-header-row {
    height: 30px;
    line-height: 30px;
}

.header-icon {
    font-size: 24px;
    float: right;
    margin-left: 15px;
    color: #3B5998;
    cursor: pointer;
}

.icon-al-shaixuan {
    font-size: 26px !important;
    font-weight: bold;
}

.card-body {
    border: 1px solid #ebebeb;

    border-radius: 0 0 3px 3px;
    transition: .2s;
    padding: 15px;

}

.card-row {
    border-radius: 10px !important;
    position: relative;
}

.card-item {
    margin-bottom: 20px;
    padding: 0;
}

.card-image {
    width: 100%;
}

.card-desc {
    padding: 10px;
}

.vip-icon {

    font-size: 30px !important;
    color: #3B5998;
}

.card-operate {
    height: 40px;
    margin-top: 10px;
    line-height: 40px;
}

.card-operate-row {
    text-align: center;
    color: #3B5998;
}

.card-operate-row i {
    cursor: pointer;
    font-size: 26px;
    border: 2px solid;
    border-radius: 50%;
    padding: 5px;
}

.card-operate-guanzhu {
    color: #ff7c7c;
}

.card-operate-duihua {
    color: #3B5998;
}

.guanzhu-isactive {
    background: #FF6347;
    color: #fff;
    border-color: #FF6347 !important;
}

.icon-al-zhuangtai {
    color: #bbbbbb
}

.online-isactive {
    color: #03cf03;
}

.pagination {
    width: 100%;
    text-align: center;
}

.list-item {
    padding: 10px;
}

.list-avatar {
    width: 120px;
    height: 120px;
    float: left;
    position: relative;
}

.list-avatar .vip-icon {

    font-size: 30px !important;
    color: #3B5998;
}

.list-avatar img {
    width: 100%;
}

.list-content {
    margin-left: 130px;

}

.list-nickname {
    font-weight: bold;
    padding: 5px 0;
}

.el-divider {
    clear: both;
}

.list-info {
    font-size: 14px;
    padding: 5px 0;
}

.list-desc {
    font-size: 12px;
    color: #a9a9a9;
    margin-top: 10px;
    padding-bottom: 10px;
}


.list-right {
    height: 40px;
    line-height: 40px;
}

@media only screen and (max-width: 767px) {
    .chatNowBtn {
        margin: 0;
        width: 100%;
    }

    .card-info {
        height: 75px !important;
        margin-top: 0 !important;
    }

    .card-desc {
        padding: 0 10px 10px 10px;
    }
}
</style>
  