import { render, staticRenderFns } from "./conference.vue?vue&type=template&id=f46a6e24&scoped=true"
import script from "./conference.vue?vue&type=script&setup=true&lang=ts"
export * from "./conference.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./conference.vue?vue&type=style&index=0&id=f46a6e24&prod&lang=scss"
import style1 from "./conference.vue?vue&type=style&index=1&id=f46a6e24&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f46a6e24",
  null
  
)

export default component.exports