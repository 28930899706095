<template>
    <div id="app">
        <layout-view></layout-view>

        <transition name="el-zoom-in-top">
            <div v-show="show">
                <firework :autoClose="isCloseGiftDialog" :giftInfo="giftInfo"></firework>
            </div>
        </transition>

        <van-notify v-model="isShowMessageNotify" background="#3B5998" duration="3000" type="success"
                    @onClose="closeMessageNotify">
            <div class="notify-message-wrapper">
                <div class="avatar">
                    <van-image :src="senderInfo.data?.fromUser?.avatar|cdnurl" height="50px" round width="50px"/>
                </div>
                <div class="userinfo s-p-x-3 s-p-y-2">
                    <p class="display-name">{{ senderInfo.data?.fromUser?.displayName }}</p>
                    <p class="content">You have received a new message!</p>
                </div>
                <div class="btn-wrapper d-flex align-items-center">
                    <van-button class="chat-now" color="#f77705" size="small" style="line-height: 0"
                                @click="toChat(`/message?id=${senderInfo.from}`)">Chat Now
                    </van-button>
                </div>
            </div>
        </van-notify>
    </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import {Notify, Button, Icon, Image} from 'vant'

import setting from './config/setting.js'
import {cdnurl} from './filters'
import Auth from './services/auth.js'
import Chat from './services/chat.js'
import Push from 'push.js'
import LayoutView from '@/layout/Index.vue'
import Firework from "@/widgets/firework/Index.vue"
import {validateIsMobile} from './utils/utils.js'

export default {
    name: 'app',
    components: {
        VanImage: Image,
        VanIcon: Icon,
        VanButton: Button,
        VanNotify: Notify.Component,
        Firework,
        LayoutView: LayoutView
    },
    provide() {
        return {
            showGift: this.showGift,
            hideGift: this.hideGift,
        }
    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            connectInterval: null,
            timeout: 30000,
            timeoutObj: null,
            show: false,
            giftInfo: {
                giftUrl: '',
                fromUserName: '',
                fromUserId: '',
            },
            isCloseGiftDialog: true,
            isShowMessageNotify: false,
            senderInfo: {} // 发送人信息
        }
    },
    watch: {
        '$route.path': function (newVal) {
            this.setCurrentPath(newVal)
        }
    },
    computed: {
        ...mapState({
            isMobile: 'isMobile',
            currentPath: 'currentPath',
            newMessageInfo: 'newMessageInfo',
        }),
        ...mapGetters({
            unreadMessageCount: 'getUnreadMessageCount',
            systemVersion: 'getSystemVersion',
        })
    },
    created() {
        const isMobile = validateIsMobile()
        this.setIsMobile(isMobile)

        if (Auth.authenticated()) {
            this.setIsLogin(true)
            this.getUserinfo()
        }

        this.setCurrentPath(this.$route.path)

        this.createWebSocket()
        Push.Permission.request();

        // this.pushMessage('消息通知的内容');
    },
    methods: {
        ...mapMutations([
            'setIsLogin',
            'setSystemVersion',
            'setIsMobile',
            'setUserinfo',
            'setCurrentPath',
            'setUnreadMessageCount',
            'setUnreadMailCount',
            'setCustomerId',
            'setResidue',
            'setNewMessageInfo',
            'setGiftNoticeNum',
        ]),
        /**
         * 获取系统版本
         */
        getSystemVersion() {
            this.$http.get('/config/getVersion')
                .then((res) => {
                    if (res.code == 1) {
                        this.setSystemVersion(res.data.version)
                        this.setCustomerId(res.data.customerId)
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            // .catch(() => {})
        },
        getUserinfo() {
            this.$http.post('/user/userinfo')
                .then((res) => {
                    if (res.code == 1) {
                        this.setUserinfo(res.data)
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            // .catch(() => {})
        },
        showGift(info, time = 5000) {
            // console.log(info);
            this.show = true
            this.giftInfo = info
            if (time) {
                this.isCloseGiftDialog = true
                setTimeout(() => {
                    this.show = false
                }, time)
            } else {
                this.isCloseGiftDialog = false
            }
        },
        hideGift() {
            this.show = false
            // console.log( this.$options.data() )
        },
        toChat(path) {
            this.isShowMessageNotify = false
            this.$router.push(path)
        },
        // 创建websocket连接
        createWebSocket() {
            let query = this.$route.query;
            let that = this;
            let userInfo = Auth.getToken();
            if (!userInfo && !query.token) {

                if (!that.connectInterval) {
                    that.connectInterval = setInterval(() => {
                        that.reconnect();
                    }, 2000);
                }
                return
            }

            that.ws = null;
            that.ws = new WebSocket(setting.wsUrl + "?token=" + (query.token ? query.token : userInfo.token));
            that.websocket.setWs(that.ws)
            //that.resend()
            //链接成功时
            that.ws.onopen = function () {
                console.log("Connected")
                clearInterval(that.connectInterval)
                that.connectInterval = null;
                that.timer = setInterval(() => {
                    if (that.ws.readyState != 1) {
                        if (!that.connectInterval) {
                            that.connectInterval = setInterval(() => {
                                that.reconnect();
                            }, 2000);
                        }
                        clearInterval(that.timer)
                    }
                    let userInfo = Auth.getToken();
                    if (!userInfo) {
                        clearInterval(that.timer)
                        clearInterval(that.timeoutObj)
                        that.websocket.ws.close();
                    }
                }, 5000);
                //开启心跳
                that.start();


            }
            //收到消息时
            that.ws.onmessage = (msgInfo) => {


                let msgData = JSON.parse(msgInfo.data)
                console.log('APP receive messages', msgData);
                if (msgData) {
                    switch (msgData.type) {
                        case "ping":
                            that.setUnreadMessageCount(msgData.data.notReadNum)
                            that.setUnreadMailCount(msgData.data.notMailReadNum)
                            that.setResidue(msgData.data.residue)
                            that.setGiftNoticeNum(msgData.data.giftNoticeNum)
                            break;
                        case "online":
                            // 通知上下线

                            this.setNewMessageInfo(msgInfo)

                            break;
                        case "friend":
                            if (this.currentPath === '/message') {
                                this.setNewMessageInfo(msgInfo)
                                return
                            }
                            that.setUnreadMessageCount(that.unreadMessageCount + 1)
                            if (msgData.data.type == 'gift') {
                                msgData.data.open = 0;
                            }
                            if (this.systemVersion === "C") {
                                Chat.playVoice("receive.mp3")
                            } else {
                                Chat.playVoice("receive.wav")
                            }

                            let id = that.randomString(5)
                            msgData.data.content = msgData.data.translatedContent ? msgData.data.translatedContent : msgData.data.content
                            //console.log(msgData.data)
                            let message = `<div id="${id}" style="width:100%;cursor: pointer;">
                                <span class="el-avatar el-avatar--large el-avatar--circle " style="float:left;background:none">
                                    <img src="${cdnurl(msgData.data.fromUser.avatar)}" style="object-fit: cover;">
                                </span>
                                <span class="notice-text" style="line-height: 40px;margin-left: 5px;">${msgData.data.type == 'text' ? Chat.convertEmoji(msgData.data.content) : `[${this.$t(msgData.data.type)}]`}</span>
                                <div class="el-progress-bar__outer" style="height: 6px;margin-top:10px; background-color: rgb(235, 238, 245);"><div class="el-progress-bar__inner" style="width: 100%; background-color: #3B5998;"></div></div>
                                </div>`
                            if (document.visibilityState != "visible") {
                                that.pushMessage(msgData.data.type == 'text' ? Chat.convertEmoji(msgData.data.content) : this.$t(msgData.data.type))
                                document.title = 'You have new messages';
                            }

                            if (this.isMobile) {
                                this.senderInfo = msgData
                                this.isShowMessageNotify = true
                                this.closeMessageNotify()
                            } else {
                                that.$notify({
                                    title: that.$t('NewMsgNotice'),
                                    duration: 0,
                                    dangerouslyUseHTMLString: true,
                                    position: 'bottom-right',
                                    message: message,
                                    offset: 700,
                                    onClick: function () {
                                        that.$notify.closeAll()
                                        that.$router.push({
                                            path: '/message',
                                            query: {
                                                id: msgData.data.fromUser.id
                                            }
                                        })
                                    }
                                });
                                // 让提示框向下动画
                                let messageDom = document.getElementById(id);
                                let parentDom = messageDom.parentNode.parentNode.parentNode.parentNode;
                                setTimeout(() => {
                                    let bottomVal = parseInt(parentDom.style.bottom)
                                    let interval = setInterval(function () {
                                        parentDom.style.bottom = bottomVal + "px"
                                        bottomVal -= 10
                                        if (bottomVal <= 60) {
                                            clearInterval(interval)
                                            let proBarWidth = 100;
                                            let proBarInterval = setInterval(function () {
                                                let proBarDom = messageDom.querySelector('.el-progress-bar__inner');

                                                if (proBarWidth <= 0) {
                                                    proBarDom.style.width = 0;
                                                    clearInterval(proBarInterval)
                                                    setTimeout(function () {
                                                        parentDom.remove()
                                                    }, 500)
                                                    proBarWidth = 0
                                                }
                                                proBarDom.style.width = proBarWidth + "%";
                                                //proBarDom.style.width = "0%";
                                                proBarWidth -= 1
                                            }, 50)
                                        }
                                    }, 50)
                                }, 200);
                            }
                            // 如果接收到的消息是礼物，以动画方式展示出来
                            if (msgData.data.type === 'gift') {
                                this.showGift(
                                    {
                                        giftUrl: cdnurl(msgData.data.content),
                                        fromUserId: msgData.data.fromUser.id,
                                        fromUserName: msgData.data.fromUser.displayName,
                                    },
                                    0
                                )
                            }
                            break;

                    }
                }


            }
            //连接错误
            that.ws.onerror = function () {
                console.log("WebSocket Connection error");
                //重连
                if (!that.connectInterval) {
                    that.connectInterval = setInterval(() => {
                        that.reconnect();
                    }, 2000);
                }
            };
            that.ws.onclose = function () {
                console.log("WebSocket Connection close");
                if (!that.connectInterval) {
                    that.connectInterval = setInterval(() => {
                        that.reconnect();
                    }, 2000);
                }
            }
            // 监听组件的销毁
            that.$once('hook:beforeDestroy', () => {
                if (that.ws.close) {
                    that.ws.close();
                    that.ws.onclose = () => {
                        console.log('web socket closed');
                    };
                }
            })
        },
        randomString(len) {
            len = len || 32;
            var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            var maxPos = $chars.length;
            var pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },
        reconnect() {//重新连接
            let that = this;
            if (that.ws && that.ws.readyState == 1) {
                return;
            }
            console.log("reconnect")
            that.createWebSocket();
        },
        reset() {//重置心跳
            //清除时间
            clearTimeout(this.timeoutObj);
            clearTimeout(this.serverTimeoutObj);
            //重启心跳
            this.start();
        },
        start() {//开启心跳
            let that = this;
            let pingData = {
                type: "ping",
            };
            that.ws.send(JSON.stringify(pingData));
            that.timeoutObj && clearTimeout(that.timeoutObj);
            that.serverTimeoutObj && clearTimeout(that.serverTimeoutObj);
            that.timeoutObj = setInterval(function () {
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                if (that.ws && that.ws.readyState == 1) {//如果连接正常
                    let pingData = {
                        type: "ping",
                    };
                    that.ws.send(JSON.stringify(pingData));

                } else {//否则重连
                    that.reconnect();
                }
                // that.resend()
            }, that.timeout)

        },
        // resend() {
        //     let that = this
        //     that.$http.get('/websocket/reSend').then((res) => {
        //         if (res.code == 1) {
        //             that.setUnreadMessageCount(res.data.notReadNum)
        //             that.setUnreadMailCount(res.data.notMailReadNum)
        //             that.setResidue(res.data.residue)
        //             that.setGiftNoticeNum(res.data.giftNoticeNum)
        //         }
        //
        //     })
        // },
        //推送弹框消息
        pushMessage(message) {
            Push.create("You have new messages", {
                body: message,
                requireInteraction: true,
                timeout: 600000,
            });
        },
        closeMessageNotify(time = 5000) {
            clearTimeout(this._messageTimer)
            this._messageTimer = setTimeout(() => {
                this.senderInfo = {}
                this.isShowMessageNotify = false
            }, time)
        }
    },
    beforeDestroy() {
        console.log("关闭")
        let that = this;
        that.websocket.ws.close();
    },
}
</script>

<style>
@import './assets/scss/_var.scss';
@import './assets/scss/_basic.scss';
@import './assets/scss/_styles.scss';

body {
    background: #f7faff;
    font-family: Open Sans, sans-serif;
    width: 100%;
    min-height: calc(100vh);
}

ul,
li {
    list-style: none;
}

.el-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.view {
    width: 100%;
}

.el-container {
    /* height: 100%; */
    /* padding-top: 90px; */
}

.container {
    height: 100%;
}

.el-loading-spinner {
    display: none !important;
}

/* .main {
    max-width: 1300px;
    overflow: auto;
    margin: 0 auto;
    min-height: calc(100vh - 170px);
    padding-bottom: 20px;
} */
.main-wrapper {
    max-width: 1300px;
    margin: 0 auto;
    min-height: calc(100vh);
    padding-bottom: 50px;
}

.el-popconfirm__action {
    margin-top: 10px !important;
}

.el-avatar {
    background-color: unset !important;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 5px;
    /*滚动条宽度*/
    height: 5px;
    /*滚动条高度*/
    background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #3B5998;
    /*滚动条的背景颜色*/
}

.primary-color {
    color: #3B5998;
}

.pink-color {
    color: #ff7c7c;
}

.el-notification__group {
    width: 100%;
}

.shadow {
    box-shadow: 0 2px 4px #00000029;
}

.card-header {
    background-color: #fcfdff;
}

.card-body {
    background-color: #fff;
}

.el-tabs__nav-next {
    width: 20px;
}

.el-tabs__nav-next i {
    font-size: 16px;
    font-weight: bold;
}

.el-tabs__nav-prev {
    width: 20px;
}

.el-tabs__nav-prev i {
    font-size: 16px;
    font-weight: bold;
}

input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #bbb6b5 !important;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #bbb6b5 !important;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #bbb6b5 !important;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbb6b5 !important;
}

input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bbb6b5 !important;
}

input::-webkit-input-placeholder {
    color: #bbb6b5 !important;
}

textarea::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #bbb6b5 !important;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #bbb6b5 !important;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #bbb6b5 !important;
}

textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbb6b5 !important;
}

textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bbb6b5 !important;
}

textarea::-webkit-input-placeholder {
    color: #bbb6b5 !important;
}

.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev:disabled {
    color: #cecece !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.no-padding {
    padding: 0 !important;
}

@media only screen and (max-width: 767px) {
    html {
        overflow: hidden;
        position: relative;
        height: 100%;
    }

    body {
        overflow: auto;
        position: relative;
        height: 100%;
    }

    .m-no-padding {
        padding: 0 !important;
    }

    .m-no-margin {
        margin: 0 !important;
    }

    .header-title {
        font-size: 18px !important;
    }

    .header-title i {
        font-size: 18px !important;
    }

    .el-dialog {
        width: 95% !important;
        margin-top: 5vh !important;
    }

}
</style>

<style lang="scss" scoped>
.notify-message-wrapper {
    display: flex;
    width: 100%;

    .avatar {
        width: 50px;
        height: 50px;
    }

    .userinfo {
        overflow: hidden;
        flex: 1;
        height: auto;

        .display-name {
            font-size: 16px;
            text-align: left;
        }

        .content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 12px;
            color: var(--gray-20);
        }
    }

    .btn-wrapper {
        .chat-now {
            border-radius: 5px;
        }
    }
}
</style>