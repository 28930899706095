import Vue from "vue";
import Vuex from "vuex"

Vue.use(Vuex)
import {Message} from "element-ui";
import http from '@/services/http.js'

export default new Vuex.Store({
    state: {
        isLogin: false, // 是否登录
        systemVersion: '', // 系统版本
        currentPath: '', // 当前路由
        unreadMessageCount: 0, // 未读消息
        unreadMailCount: 0, // 未读邮件
        userinfo: {}, // 用户信息
        cartData: [], // 购物车数据
        isMobile: false, // 是否是移动端
        isShowTabbar: true, // 是否显示底部导航
        customerId: 0, // 客服id
        residue: 0, // 免费条数
        newMessageInfo: {}, // 是否有新消息进来
        giftNoticeNum: 0, // 礼物订单提示数量
        phoneNoticeNum: 0, // 电话订单提示数量
    },
    getters: {
        getSystemVersion: state => {
           
            return state.systemVersion.slice(0, 1)
        },
        getCustomerId: state => {
            return state.customerId
        },
        getResidue: state => {
            return state.residue
        },
        getGiftNoticeNum: state => {
            return state.giftNoticeNum
        },
        getIsVIP: state => {
            return state.userinfo?.userDetail?.vip_expire_time > (new Date().getTime() / 1000)
        },
        getUserinfo: state => {
            return state.userinfo
        },
        getCurrentPath: (state) => {
            return state.currentPath
        },
        getUnreadMessageCount: (state) => {
            return state.unreadMessageCount
        },
        getUnreadMailCount: (state) => {
            return state.unreadMailCount
        }
    },
    mutations: {
        setNewMessageInfo(state, palyload) {
            state.newMessageInfo = palyload
        },
        setIsLogin(state, palyload) {
            state.isLogin = palyload
        },
        setSystemVersion(state, payload) {
            state.systemVersion = payload
        },
        setCustomerId(state, payload) {
            state.customerId = payload
        },
        setResidue(state, payload) {
            state.residue = payload
        },
        setGiftNoticeNum(state, payload) {
            state.giftNoticeNum = payload
        },
        setPhoneNoticeNum(state, payload) {
            state.phoneNoticeNum = payload
        },
        setIsShowTabbar(state, payload) {
            state.isShowTabbar = payload
        },
        setIsMobile(state, payload) {
            state.isMobile = payload
        },
        addCartData(state, payload) {
            state.cartData.push(payload)
        },
        deleteCartData(state, payload) {
            const idx = state.cartData.indexOf(payload)
            state.cartData.splice(payload, 1)
        },
        clearCartData(state) {
            state.cartData = []
        },
        setUserinfo(state, payload) {
            state.userinfo = payload
        },
        setCurrentPath(state, payload) {
            state.currentPath = payload
        },
        setUnreadMessageCount(state, payload) {
            state.unreadMessageCount = payload
        },
        setUnreadMailCount(state, payload) {
            state.unreadMailCount = payload
        },
    },
    actions: {
        requestUserinfo({commit}) {
            http.post('/user/userinfo')
                .then((res) => {
                    if (res.code == 1) {
                        commit('setUserinfo', res.data)
                    } else {
                        Message.error(res.msg);
                    }
                })
        }
    }
})