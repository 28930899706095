<template>
    <div class="people">
        <div class="main-wrapper">
            <el-row :gutter="20">
                <el-col :md="18" :sm="18" :xs="24" class="m-no-padding">
                    <CompVoiceChatRoom />
                </el-col>
                <el-col :md="6" :sm="6" :xs="24">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import RightSiderBar from '@/page/components/common/RightSiderBar.vue'
import CompVoiceChatRoom from '@/components/common/CompVoiceChatRoom.vue'

export default {
    name: 'VoiceChat',
    components: {
        RightSiderBar,
        CompVoiceChatRoom,
    },
    data() {
        return {
            
        }
    },
    created() {
        
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>

</style>