<template>
    <el-dialog
        :close-on-click-modal="false"
        :visible.sync="show"
        align-center
        class="payino-dialog"
        width="450px"
        @close="close()"
    >
        <div class="payinfo-form">
            <div class="modal">
                <div class="form">
                    <div class="separator">
                        <hr class="line">
                        <p>pay using credit card</p>
                        <hr class="line">
                    </div>
                    <div class="credit-card-info--form">
                        <!-- 国家代码简称 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Billing country</label>
                            <el-select v-model="formData.billingCountry" placeholder="Please select">
                                <el-option
                                    v-for="item in countryData"
                                    :key="item.altSpellings"
                                    :label="item.name_en"
                                    :value="item.altSpellings">
                                </el-option>
                            </el-select>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <!-- 签收洲 -->
                                <div class="input_container">
                                    <label class="input_label" for="full_name">Billing state</label>
                                    <input id="full_name" v-model="formData.billingState" class="input_field"
                                           name="input-name" placeholder="Enter your billing state" title="Inpit title"
                                           type="text">
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <!-- 城市 -->
                                <div class="input_container">
                                    <label class="input_label" for="full_name">Billing city</label>
                                    <input id="full_name" v-model="formData.billingCity" class="input_field"
                                           name="input-name" placeholder="Enter your full name" title="Inpit title"
                                           type="text">
                                </div>
                            </el-col>
                        </el-row>
                        <!-- 详情地址 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Billing address</label>
                            <input id="full_name" v-model="formData.billingAddress" class="input_field"
                                   name="input-name" placeholder="Enter your full name" title="Inpit title" type="text">
                        </div>
                        <!-- 账单邮编 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Billing zip</label>
                            <input id="full_name" v-model="formData.billingZip" class="input_field" name="input-name"
                                   placeholder="Enter your full name" title="Inpit title" type="text">
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <!-- 持卡人名 -->
                                <div class="input_container">
                                    <label class="input_label" for="full_name">Card holder first name</label>
                                    <input id="full_name" v-model="formData.billingFirstName" class="input_field"
                                           name="input-name" placeholder="Enter your full name" title="Inpit title"
                                           type="text">
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <!-- 持卡人姓 -->
                                <div class="input_container">
                                    <label class="input_label" for="full_name">Card holder last name</label>
                                    <input id="full_name" v-model="formData.billingLastName" class="input_field"
                                           name="input-name" placeholder="Enter your full name" title="Inpit title"
                                           type="text">
                                </div>
                            </el-col>
                        </el-row>
                        <!-- 持卡人电话 -->
                        <div class="input_container">
                            <label class="input_label" for="card_number">Card holder telephone</label>
                            <input id="card_number" v-model="formData.billingTelephone" class="input_field"
                                   name="input-name"
                                   placeholder="Enter your phone number" title="Inpit title" type="number">
                        </div>
                        <!-- 持卡人邮箱 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Card holder email</label>
                            <input id="full_name" v-model="formData.billingEmail" class="input_field" name="input-name"
                                   placeholder="Enter your full name" title="Inpit title" type="text">
                        </div>
                    </div>
                    <button class="purchase--btn" @click="confirm()">Checkout</button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        show: {
            require: true,
            default: false
        }
    },
    data() {
        return {
            countryData: [], // 国家代码简称
            rules: [
                {key: 'billingCountry', errMsg: 'Please select country'},
                {key: 'billingState', errMsg: 'Please enter state'},
                {key: 'billingCity', errMsg: 'Please enter city'},
                {key: 'billingAddress', errMsg: 'Please enter address'},
                {key: 'billingZip', errMsg: 'Please enter zip'},
                {key: 'billingFirstName', errMsg: 'Please enter first name'},
                {key: 'billingLastName', errMsg: 'Please enter last name'},
                {key: 'billingTelephone', errMsg: 'Please enter telephone'},
                {key: 'billingEmail', errMsg: 'Please enter email'},
            ],
            formData: {
                billingCountry: '',
                billingState: '',
                billingCity: '',
                billingAddress: '',
                billingZip: '',
                billingFirstName: '',
                billingLastName: '',
                billingTelephone: '',
                billingEmail: '',
            },
        }
    },
    created() {
        this.getCountryCode()
    },
    methods: {
        confirm() {
            for (let index = 0; index < this.rules.length; index++) {
                const rule = this.rules[index];
                if (this.formData[rule.key] === '') {
                    this.$message.error(rule.errMsg)
                    return
                }
            }
            this.$emit('confirm', this.formData)
        },
        getCountryCode() {
            this.$http.get('/config/getCountrys').then(res => {
                this.countryData = res.data
            })
        },
        close() {
            this.formData = this.$options.data().formData
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>