<template>
    <div>
        <div class="main-wrapper">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="18" :md="18">
                    <h2 class="article-title">{{ articleDetail.title }}</h2>
                    <div v-html="articleDetail.content" class="article-content"></div>
                </el-col>
                <el-col :xs="24" :sm="6" :md="6">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import RightSiderBar from '../components/common/RightSiderBar.vue'

export default {
    name: 'Hot',
    components: { RightSiderBar },
    data() {
        return {
            articleDetail: {},

        }
    },
    watch: {
        '$route': {
            handler: function (to) {
                this.getArticleDetail(to.query.id)
            },
            deep: true
        }
    },
    methods: {
        getArticleDetail(id) {
            this.$http.get('/article/get-detail', { params: { id } })
                .then(res => {
                    this.articleDetail = res.data
                })
        }
    },
    created() {
        this.getArticleDetail(this.$route.query.id)
    },
}
</script>

<style scoped>
.article-title {
    padding: 30px 0 0;
    background-color: #fff;
    text-align: center;
    font-size: 40px;
    color: #333;
}
.article-content {
    padding: 10px 30px 30px;
    background-color: #fff;
}
</style>