var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.componentConfig.visible)?_c('div',{staticClass:"virtualBackground-control-container"},[_c(_setup.IconButton,{attrs:{"title":_setup.t('VirtualBackground')},on:{"click-icon":_setup.openSettingPanel}},[_c(_setup.VirtualBackgroundIcon)],1),_c(_setup.Dialog,{attrs:{"title":_setup.t('VirtualBackground'),"width":"600px","modal":true,"append-to-room-container":true,"close-on-click-modal":false},on:{"close":_setup.closeSettingPanel},model:{value:(_setup.isDialogVisible),callback:function ($$v) {_setup.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c('div',{staticClass:"stream-preview",attrs:{"id":"stream-preview"}},[(_setup.isLoading)?_c('div',{staticClass:"mask"}):_vm._e(),(_setup.isLoading)?_c('div',{staticClass:"spinner"}):_vm._e()]),_c('div',{staticClass:"setting"},[_c('div',{class:[
          'setting-item',
          _setup.selectedBackground === 'close' ? 'active' : '',
        ],on:{"click":function($event){return _setup.applyVirtualBackground('close')}}},[_c('i',{staticClass:"setting-item-icon"},[_c('img',{staticStyle:{"width":"32px"},attrs:{"src":_setup.CloseVirtualBackground,"alt":"close"}})]),_c('span',[_vm._v(_vm._s(_setup.t('Close')))])]),_c('div',{class:[
          'setting-item',
          _setup.selectedBackground === 'blur' ? 'active' : '',
        ],on:{"click":function($event){return _setup.applyVirtualBackground('blur')}}},[_c('i',{staticClass:"setting-item-icon"},[_c('img',{attrs:{"src":_setup.BlurredBackground,"alt":"blurred"}})]),_c('span',[_vm._v(_vm._s(_setup.t('BlurredBackground')))])])]),_c('div',{staticClass:"footer"},[_c(_setup.TuiButton,{staticClass:"button",attrs:{"disabled":!_setup.isAllowed},on:{"click":_setup.confirmVirtualBackground}},[_vm._v(" "+_vm._s(_setup.t('Save'))+" ")]),_c(_setup.TuiButton,{staticClass:"button",attrs:{"type":"primary"},on:{"click":_setup.closeSettingPanel}},[_vm._v(_vm._s(_setup.t('Cancel')))])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }