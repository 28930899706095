<template>
    <div>
        <div v-if="event=='check'" class="add-wrapper">
            <el-button class="add-category" plain type="primary" @click="profileVisible = true">
                {{ $t('AddCategory') }}
            </el-button>
        </div>
        <el-tabs v-if="editableTabs.length > 0" v-model="searchData.group_id" :closable="event=='check'"
                 type="border-card" @tab-click="handleClick" @tab-remove="removeTab">
            <el-tab-pane v-for="item in editableTabs" :key="item.id" :label="item.name" :name="item.id + ''">
                <!-- <span slot="label"><i class="icon-al-unlock"></i> {{ $t('public') }}</span> -->
                <el-row
                    v-infinite-scroll="getList"
                    :gutter="20"
                    class="video-wrapper"
                    infinite-scroll-immediate="false"
                >
                    <!-- 上传视频 -->
                    <el-col :lg="6" :md="6" :sm="12" :xl="6" :xs="12" class="photo-item">
                        <el-upload
                            :action="uploadUrl"
                            :before-upload='beforeUpload'
                            :headers="headers"
                            :multiple="true"
                            :on-success="uploadSuccess"
                            :show-file-list="false"
                            accept="video/*"
                            class="photo-uploader"
                        >
                            <i class="el-icon-circle-plus phpoto-uploader-icon"></i>
                            <div class="el-upload__text phpoto-uploader-text">
                                {{ $t('AddVideos') }}
                            </div>
                        </el-upload>
                    </el-col>
                    <!-- 已上传的视频 -->
                    <el-col v-for="(item, index) in list" :key="item.id" :lg="6" :md="6" :sm="12" :xl="6"
                            :xs="12" class="photo-item">
                        <div @mouseenter="showOperateId = item.id"
                             @mouseleave="!dropdownVisible && (showOperateId = 0)">
                            <el-image
                                :class="{ 'is-active': checkedId.includes(item.id) }"
                                :src="item.cover | cdnurl"
                                fit="contain "
                                lazy
                                @click="checkUrl(item)"
                            >
                                <div slot="placeholder" class="image-slot">
                                    Loading<span class="dot">...</span>
                                </div>
                            </el-image>
                            <div v-if="showOperateId == item.id" class="operated-wrapper" @click="checkUrl(item)">
                                <el-dropdown
                                    class="operated-dropdown"
                                    size="small"
                                    @command="(val) => handleCommand(val, item, index)"
                                    @visible-change="handleDropdownVisibleChange"
                                >
                                    <i class="el-icon-more"></i>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="delete" icon="el-icon-delete">
                                            Delete
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="event=='check'" command="move" divided
                                                          icon="el-icon-rank">
                                            Move
                                        </el-dropdown-item>
                                        <!-- <el-dropdown-item v-if="item.purview === 'privacy'" command="privacy" icon="el-icon-lock" divided>
                                            Privacy
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="item.purview === 'public'" command="public" icon="el-icon-unlock" >
                                            Public
                                        </el-dropdown-item> -->
                                    </el-dropdown-menu>
                                </el-dropdown>

                                <i class='icon-al-bofang' @click="play(item)"></i>
                                <template v-if="event == 'check'">
                                    <i v-if="checkedId.includes(item.id)" class="el-icon-success"></i>
                                    <i v-else class="el-icon-circle-check" @click="checkUrl(item)"></i>
                                </template>
                                <div class="file-desc">
                                    {{ item.memo.slice(0, 10) }}
                                </div>

                            </div>
                            <div v-if="item.isread == 1" class="file-sended">
                                <i class='el-icon-check'></i>
                            </div>
                        </div>
                    </el-col>
                    <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" style="margin-top: 20px">
                        <div v-if="isEnd" style="font-size: 12px;text-align:center;">{{ $t('NoMore') }}</div>
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        <el-empty v-else :description="$t('EmptyPrompt')"></el-empty>

        <el-dialog
            :visible.sync="videoVisible"
            append-to-body
            custom-class="player-video"
            top="7vh"
            width="40%"
            @close="closeDialog"
            @opened="openedDialog"
        >
            <player-video ref="video" :src="videoUrl" :volume="volume" height="70vh"></player-video>
            <div slot="footer" class="dialog-footer">
                <el-button @click="videoVisible = false">{{ $t('close') }}</el-button>
            </div>
        </el-dialog>

        <!-- 添加分类 开始 -->
        <el-dialog
            :append-to-body="true"
            :close-on-click-modal="false"
            :title="$t('AddCategory')"
            :visible.sync="profileVisible"
            center
            width="50%"
            @close="resetForm"
        >
            <el-form ref="addCategoryForm" label-width="160px">
                <el-form-item :label="$t('CategoryName')">
                    <el-input v-model="formParams.name" :placeholder="$t('CategoryNamePlaceholder')"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{ $t('submit') }}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 添加分类 结束 -->

        <!-- 移动视频 开始 -->
        <el-dialog
            :append-to-body="true"
            :close-on-click-modal="false"
            :title="$t('MovePhoto')"
            :visible.sync="movePhotoVisible"
            center
            width="50%"

            @close="resetMovePhotoForm"
        >
            <el-form ref="movePhotoForm" label-width="160px">
                <el-form-item :label="$t('CategoryName')">
                    <el-select v-model="movePhotoParams.group_id" :placeholder="$t('MovePhotoCategoryNamePlaceholder')">
                        <el-option
                            v-for="item in editableTabs.filter(item => item.id != searchData.group_id)"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmitMovePhoto">{{ $t('submit') }}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 移动视频 结束 -->
    </div>
</template>

<script>
import {cdnurl} from '../../../filters';
import setting from '../../../config/setting'
import Auth from '../../../services/auth'
import PlayerVideo from "../common/player-video.vue";

export default {
    components: {
        PlayerVideo
    },
    props: {
        event: {
            type: String,
            default() {
                return "check"
            }
        },
        toUserId: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            searchData: {
                to_user_id: 0,
                group_id: '',
                type: 'video',
                purview: '',
                page: 1,
                limit: 24,
            },
            list: [],
            isActiveId: 0,
            isEnd: false,
            uploadUrl: setting.apiUrl + "/common/upload",
            headers: {
                token: Auth.getToken().token
            },
            videoUrl: "",
            checkedId: [],
            checkedUrl: [],
            videoVisible: false,
            volume: 0.5,
            showOperateId: 0,

            editableTabs: [],
            profileVisible: false,
            formParams: {
                name: '',
            },
            dropdownVisible: false,

            // 移动视频相关逻辑
            movePhotoVisible: false,
            movePhotoParams: {
                id: '',
                group_id: '',
            },
            uploadedCount: 0, // 已上传文件数量
        }
    },
    computed: {
        video() {
            return this.$refs.video;
        },
    },
    methods: {
        // 移动照片相关逻辑 开始
        resetMovePhotoForm() {
            this.movePhotoParams.id = ''
            this.movePhotoParams.group_id = ''
        },
        onSubmitMovePhoto() {
            this.$http.post('/file/moveFile', this.movePhotoParams)
                .then(res => {
                    if (res.code === 1) {
                        let curPhotoIndex = this.list.findIndex(item => item.id === this.movePhotoParams.id)
                        // console.log(curPhotoIndex);
                        this.list.splice(curPhotoIndex, 1)
                        this.resetMovePhotoForm()
                        this.movePhotoVisible = false
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        },
        // 移动照片相关逻辑 结束

        handleDropdownVisibleChange(bool) {
            // console.log(bool);
            this.dropdownVisible = bool
            if (!bool) {
                this.showOperateId = 0
            }
        },
        handleCommand(val, item, index) {
            // console.log(val, item, index);
            if (val === 'delete') {
                this.deleteItem(item, index)
            } else if (val === 'move') {
                this.movePhotoVisible = true
                this.movePhotoParams.id = item.id
            } else if (val === 'privacy') {
                this.editPurview('public', item, index)
            } else if (val === 'public') {
                this.editPurview('privacy', item, index)
            }
        },
        resetForm() {
            this.formParams.name = ''
        },

        /**
         * 添加tab
         */
        onSubmit() {
            this.$http.post('/file/addGroup', {type: 'video', name: this.formParams.name})
                .then(res => {
                    if (res.code === 1) {
                        this.resetForm()
                        this.getGroupList()
                        this.profileVisible = false
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        },
        /**
         * 删除tab
         */
        removeTab(id) {
            this.$confirm(this.$t('ConfirmDelete'), this.$t('warning'), {
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.$http.post('/file/delGroup', {id})
                    .then(res => {
                        if (res.code === 1) {
                            this.getGroupList()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
            })
        },
        // 上传按钮 限制图片大小和类型
        beforeUpload(file) {
            if (!this.validUploadFile("video", file)) {
                return false
            }
        },
        getGroupList() {
            this.$http.get('/file/getGroupList', {params: {type: 'video'}})
                .then(res => {
                    if (res.code === 1) {
                        this.editableTabs = res.data.list || []
                        if (res.data.list && res.data.list.length > 0) {
                            this.searchData.page = 1
                            this.searchData.group_id = res.data.list[0].id + ''
                            this.getList()
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        },
        getList() {
            let that = this;
            if (that.isEnd == true) {
                return
            }
            this.isEnd = true
            that.$http.get('/file/getList', {params: that.searchData})
                .then((res) => {
                    if (res.code == 1) {
                        let list = res.data.list || []
                        if (list.length > 0) {
                            if (this.searchData.page === 1) {
                                that.list = list
                            } else {
                                that.list = that.list.concat(list);
                            }
                            that.searchData.page += 1
                            if (res.data.total <= that.list.length) {
                                that.isEnd = true;
                            } else {
                                that.isEnd = false;
                            }
                        } else {
                            that.isEnd = true;
                        }
                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        uploadSuccess(res, file, fileList) {
            let that = this;
            if (res.code == 1) {
                let newVideoUrl = res.data.url;
                let params = {
                    type: 'video',
                    file: newVideoUrl,
                    purview: 'public',
                    group_id: this.searchData.group_id,
                    memo: file.name
                }
                that.$http.post('/file/addFile', params)
                    .then((res) => {
                        if (res.code == 1) {
                            // this.list = [];
                            this.uploadedCount++
                            console.log(this.uploadedCount, fileList.length, this.uploadedCount === fileList.length)
                            if (this.uploadedCount === fileList.length) {
                                this.searchData.page = 1;
                                this.isEnd = false;
                                this.getList()
                            }
                        } else {
                            that.$message.error(res.msg);
                        }
                    })
            } else {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: 'error'
                });
            }
        },
        handleClick(tab, event) {
            this.searchData.page = 1;
            // this.list = [];
            this.isEnd = false;
            this.getList()
        },
        checkUrl(item) {
            if (this.event != "check") {
                return
            }
            const idx = this.checkedId.findIndex(id => id === item.id)
            if (idx > -1) {
                this.checkedId.splice(idx, 1)
                this.checkedUrl.splice(idx, 1)
            } else {
                this.checkedId.push(item.id)
                this.checkedUrl.push(item.file)
            }
        },
        play(item) {
            this.videoUrl = this.cdnurl(item.file);
            this.videoVisible = true;
        },
        openedDialog() {
            this.video.play();
        },
        closeDialog() {
            this.video.stop();
        },
        /**
         * 删除视频
         * @param {*} item
         * @param {*} index
         */
        deleteItem(item, index) {
            this.$confirm(this.$t('deleteFileConfirm'), this.$t('warning'), {
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {
                this.$http.post('/file/delFile', {id: item.id})
                    .then((res) => {
                        if (res.code == 1) {
                            this.list.splice(index, 1)
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: 'success'
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
            });
        },
        /**
         * 修改附件隐私状态
         */
        editPurview(purview, item) {
            this.$http.post('/file/setPurview', {id: item.id, purview})
                .then((res) => {
                    if (res.code == 1) {
                        item.purview = purview
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'success'
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        }
    },
    created() {
        this.searchData.to_user_id = this.toUserId;
        if (this.event == "check") {
            this.getGroupList()
        } else {
            this.editableTabs = [{
                "id": 0,
                "name": "Profile"
            }]
            this.searchData.group_id = "0"
            this.getList()
        }
    }
}
</script>

<style scoped>
.add-wrapper {
    display: flex;
}

.add-category {
    margin-bottom: 20px;
}

.photo-item {
    height: 200px;
    text-align: center;
    margin-top: 10px;
    position: relative;

}

.file-desc {
    position: absolute;
    bottom: 0;
    padding: 0 5px 0 5px;
    color: #fff;
    z-index: 2;
}

.photo-item .icon-al-bofang {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    padding: 10px;
    border-radius: 50%;
    background-color: rgb(19 50 146 / 19%);
    font-size: 36px;
    color: #f7f7f7;
    cursor: pointer;
    transform: translate(-50%, -50%);
}

.el-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background-color: #f5f7fa;
    border-radius: 6px;
}

.el-image img {
    cursor: pointer;
}

.photo-item .is-active {
    box-shadow: 0 0 5px 2px #1538A2;
    background: linear-gradient(to right, #ffffff, rgb(210 221 255));
}

.photo-uploader {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
}

.photo-uploader .el-upload {
    width: 100%;
}

.phpoto-uploader-icon {
    text-align: center;
    font-size: 40px;
    color: #1538A2;
}

.phpoto-uploader-text {
    margin-top: 20px;
    color: #1538A2;
    font-weight: bold;
}

/* 照片悬浮操作 开始 */
.operated-wrapper {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    left: 10px;
}

.operated-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .4;
    background-color: #000;
}

.operated-dropdown {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 1;
    height: 24px;
    cursor: pointer;
}

.operated-dropdown .el-icon-more {
    margin-right: 5px;
    font-size: 24px;
    color: #d0d7dd;
}

.photo-item .el-icon-circle-check {
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 5px;
    z-index: 1;
    cursor: pointer;
    color: #d5d5d5;
}

.file-sended .el-icon-check {
    font-size: 22px;
    position: absolute;
    left: 28px;
    top: 5px;
    z-index: 1;
    cursor: pointer;
    color: #d5d5d5;
}

.photo-item .el-icon-success {
    font-size: 24px;
    position: absolute;
    left: 15px;
    top: 5px;
    z-index: 1;
    cursor: pointer;
    color: #1538A2;
}

.photo-item .el-icon-zoom-in {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 1;
    background-color: rgb(19 50 146 / 19%);
    font-size: 36px;
    border-radius: 50%;
    padding: 10px;
    color: #f7f7f7;
    cursor: pointer;
}

/* 照片悬浮操作 结束 */
</style>

<style lang="scss" scoped>
.video-wrapper {
    overflow: auto;
    height: 400px;
}

@media only screen and (max-width: 767px) {
    .el-tabs {
        margin-bottom: 30px;
    }
    .video-wrapper {
        height: calc(100vh - 330px);
    }

}
</style>