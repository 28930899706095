<template>
  <div class="">
    <div class="main-wrapper">
      <el-row :gutter="15">
        <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">
          <el-col :xs="24" :sm="24" :md="24" class="hidden-xs-only">
            <el-card class="box-card">
              <userinfo ref="userinfoComponent"></userinfo>
            </el-card>
          </el-col>
          <el-col class="hidden-xs-only" style="margin-top: 15px;" :xs="24" :sm="6" :md="6" >
            <el-card class="siderbar-card">
              <siderbar></siderbar>
            </el-card>
          </el-col>
          <el-col style="margin-top: 15px;" :xs="24" :sm="18" :md="18" class="m-no-padding m-no-margin">
            <el-card class="box-card">
              <h1 class="header-title" style="margin-bottom: 20px;"><i class="icon-al-gerenzhongxin"
                  style="font-size: 24px;"></i>
                {{ $t('PersonalCenter') }}
              </h1>
               <userDetail :user_id="0" @updateUserInfoSuccess="updateUserInfoSuccess"></userDetail> 
            </el-card>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6">
          <right-sider-bar></right-sider-bar>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import userinfo from '../components/user/userinfo.vue'
import userDetail from '../components/user/userDetail.vue'
import RightSiderBar from '../components/common/RightSiderBar.vue'
import siderbar from '../components/user/siderbar.vue'
export default {
  name: 'Index',
  components: { userinfo, siderbar, RightSiderBar, userDetail, },
  data: function () {
    return {
      userinfo: {},
      type: "profile"
    }
  },
  methods: {
    changeType(type) {
      this.type = type
    },
    getUserDetail() {
      let that = this;
      that.$http.post('/user/userinfo')
        .then((res) => {
          if (res.code == 1) {
            that.userinfo = res.data
          } else {
            that.$message.error(res.msg);
          }

        })

    },
    updateUserInfoSuccess() {
      console.log("index")
      this.$refs.userinfoComponent.getUserInfo()
    }
  },
  created() {

  },

}
</script>
       
<style lang="scss" scoped>
.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
  text-align: left !important;
}

.user-tabs {
  height: calc(100vh - 280px);
  ;
}

.user-tabs .el-tabs__item {
  height: 50px;
  line-height: 50px;
}

.siderbar-card .el-card__body {
  padding: 0;
}

</style>