import enLocale from 'element-ui/lib/locale/lang/en'
import enOrder from './order/en'
import enMessage from './message/en'
export default {
  ...enOrder,
  ...enMessage,
  home: 'home page',
  name: "I'm six",
  login: "Log in",
  logout: "Log out",
  message: "Message",
  search: "Search",
  language: "Language",
  DynamicSquare: "Square",
  GiftOrder: "Gift Order",
  usernameLogin: "Email",
  usernameLoginRequire: "Please enter a username",
  usernameLoginRule: "Username must be 6 to 30 characters",
  password: "Password",
  OldPassword: "Old password",
  NewPassword: "New password",
  repassword: "Confirm Password",
  passwordLoginRequire: "Please enter a password",
  oldPasswordRequire: "Please enter your old password",
  newPasswordRequire: "Please enter a new password",
  reNewPasswordRequire: "Please enter the new password again",
  repasswordRequire: "Please enter the password again",
  repasswordRule: "The two password entries are inconsistent",
  passwordLoginRule: "Password must be between 6 and 30 characters",
  email: 'Email',
  emailRegister: "Please enter email address",
  emailRule: "Email incorrect format",
  nickname: 'Nickname',
  nicknameRegister: "Please enter a nickname",
  nicknameRule: "Please enter a nickname",
  birthday: "Birthday",
  birthdayRule: "Please select the date of birth",
  registerCode: "Verification Code",
  registerCodeRule: "Verification code cannot be empty",
  register: "Register",
  forgotPassword: "Forgot your password?",
  warning: "Warning",
  send: "Send",
  waitBtnText: "{second} second{plural}",
  sendAgain: "Send again",
  resetPwd: "Reset password",
  submit: "Submit",
  signInNow: "Sign in now",
  registerSignInNow: "Registered?  Sign in now!",
  notNeedLogin: "You've logged in, do not login again",
  ageText: "{age} year{s} old",
  DataIsEmpty: "No data matched",
  FindABosomFriend: "Find a bosom friend",
  trySearch: "To Search:Username Or ID",
  MinimumAge: "Min age",
  MaximumAge: "Max age",
  MinimumHeight: "Min height",
  MaximumHeight: "Max height",
  MinimumWeight: "Min weight",
  MaximumWeight: "Max weight",
  Occupation: "Occupation",
  CancelFollow: "Are you sure to cancel the following?",
  cancel: "Cancel",
  confirm: "Confirm",
  emoji: "Emojis",
  ResendingMessage: "Resending message...",
  NewMsgNotice: "You have new message",
  SaySomething: "Say something?",
  Today: "Today",
  NoMore: "No more",
  ReadMore:"Read more",
  ShowLess:"Show less",
  SelectImage: "Select photos",
  SelectVideo: "Select videos",
  SelectGift: "Choose Virtual gifts",
  SelectSticker:"Choose a sticker",
  image: "Images",
  video: "Videos",
  voice: "voice",
  sendTips: "Use Ctrl + Enter to quickly send messages",
  public: "public",
  privacy: "privacy",
  close: "Close",
  deleteFileConfirm: "This operation will permanently delete the file. Do you want to continue?",
  PublishingContent: "Publishing content",
  PublishingThink: "Thoughts at this moment",
  JustPublished: "Just ",
  PublishedAgo: "{nums} {unit}{s} ago",
  hour: "hour",
  minute: "minute",
  Like: 'Like',
  Comments: "Comments",
  ClearChatRecord: "Clear chat record",
  DeleteContact: "Delete Contact",
  DeleteContactConfirm: "This operation will delete local contacts and chat records. Do you want to continue?",
  SearchContacts: "Search username or ID",
  Contacts: "Contacts",
  ChatSettings: "Chat settings",
  EnableNewMessageVoiceReminder: "Enable new message voice reminder",
  OpenChatRoundAvatar: "Open chat round avatar (needs to be refreshed)",
  WhetherToHideContactNames: "Whether to hide contact names in chat window",
  WhetherToHideTime: "Whether to hide the message sending time in the chat window",
  CommentList: "Comment list",
  WriteComment: "Write a new comment",
  reply: 'reply',
  changepwd: 'Change Password',
  UserInformation: 'User information',
  PersonalCenter: 'Profiles',
  VIPExpirationTime: 'VIP Expiration Time',
  days: '{num} day{s}',
  hours: "{num} hour{s}",
  minutes: "{num} minute{s}",
  notVIP: "You are not a VIP yet,activate now",
  BuyNow: "Buy Now",
  VIPExpired: "VIP has expired",
  RenewNow: "Renew Now",
  Iliked: "Followed",
  LikeMe: "Fans",
  LikeEachOther: 'Mutual attention',
  dataList: "Data List",
  PersonalInformation: "Personal Information",
  MyTopics: "My Topics",
  ChangePassword: "Change Password",
  gift: "Gifts",
  PictureClipping: "Picture clipping",
  ReplacePicture: "Replace Picture",
  ClearPicture: "Clear Picture",
  anticlockwise: "anticlockwise",
  ClockwiseRotation: "Clockwise rotation",
  amplify: "amplify",
  reduce: "reduce",
  delete: "Delete",
  confirmDel: "Are you sure to delete this information?",
  uploadVideoValid: "Only supports uploading files of {type} types",
  uploadFileNameValid: 'The uploaded file name cannot contain the characters "%","&"',
  uploadFileSizeValid: 'Uploading files cannot exceed {num}MB',
  avatar: 'Avatar',
  country: 'Country',
  height: 'Height',
  weight: 'Weight',
  eyeColor: 'Pupil color',
  hairColor: 'Hair color',
  marriageStatus: 'Marital status',
  kidsNum: 'Number of children',
  Education: 'Education',
  religion: 'Religious belief',
  smokingStatus: 'Smoking status',
  drinkingStatus: 'Drinking state',
  lookingfor: 'Ideal companion',
  introduction: 'Introduce oneself to',
  interest: 'Interest',
  reset: 'Reset',
  PleaseEnterContent: "Please enter content",
  PleaseSelect: "Please select",
  MyGift: "My gift",
  EditProfile: "Edit profile",
  uploadExceed: "Upload up to 6 photos!",
  BasicInformation: "Basic information",
  OtherInformation: "Other information",
  MyProfile: "My profile",
  AddPhotos: "Add Photos",
  AddVideos: "Add Videos",
  NoPhotosSelected: "No photos selected",
  NoVideoSelected: "No video selected",
  NoGiftSelected: "No gift selected",
  NoStickerSelected:"No stickers selected",
  memberinfo: "User information",
  YouToOtherGift: "The gift you gave to the other party",
  OtherToYouGift: "The other party's gift to you",
  ViewProfile: "View profile",
  Wink:"Wink",
  UnFollow: "UnFollow",
  Winked:"Winked",
  SendWink: "Send a wink",
  Follow:"Follow",
  Followed:"Followed",
  SPosts:"'S POSTS",
  Liked:"Liked",
  All:"All",
  Online:"Online",
  Following:"Following",
  Recharge:"Recharge",
  TopUpCredits:"Top-up Credits",
  Credits:"Credits",
  WhatAreCredits:"What are credits?",
  CreditsDesOne:"Credits are the internal currency used for paid services on the site.",
  CreditsDesTwo:"Upon signing up, you get some credits for free. Afterward, top up your balance.",
  WhyDoYouNeedCredits:"Why do you need credits?",
  RespondToMails:"Respond to mails",
  MessageInChats:"Message in chats",
  ReplyWithStickers:"Reply with stickers",
  ViewMediaInChat:"View media in chat",
  ShareMediaInChat:"Share media in chat",
  SendVirtualGifts:"Send virtual gifts",
  YourCredits:"Your Credits",
  Pricing:"Pricing",
  Chats:"Chats",
  LiveChat:"Live chat",
  StickersInChat:"Stickers in chat",
  SendingPhotosInChat:"Sending photos in chat",
  OpeningVideosInChat:"Opening videos in chat",
  Mails:"Mails",
  SendingMailsToOneParticularMember:"Sending mails to one particular member",
  OpeningMails:"Opening mails",
  SendingPhotosInMails:"Sending photos in mails",
  OpeningPhotosInMails:"Opening photos in mails",
  OpeningVideosInMails:"Opening videos in mails",
  Other:"Other",
  OpeningVideosInProfiles:"Opening videos in profiles",
  VirtualGifts:"Virtual gifts",
  ClickVideoTips:"you’ll be charged immediately after clicking on a video",
  GiftsPriceTips:"find prices in the gift catalog",
  HowManyCredits:"{digit} credit{s}",
  freeForVip:"free for vip",
  perMinute:"per minute",
  firstMailCosts:"first mail costs",
  eachFollowingMail:" each following mail",
  firstMailIsFree:"first mail is free",
  eachFollowingMailCosts:"each following mail costs",
  freeOfCharge:"free of charge",
  firstPhotoIsFree:"first photo is free",
  eachFllowingPhotos:"each following photos",
  perVideo:"per video",
  CreditFreeTips:"Credits you get as your Welcome Credits or with a special offer.",
  CreditPurchasedTips:"Credits you additionally purchase and can use anytime.",
  Complimentary:"Complimentary",
  Purchased:"Purchased",
  GetCredits:"Get cedits",
  popular:"Popular",
  best:"Best",
  preferential:"Preferential",
  WelcomeOffer:"Welcome offer",
  CreditsInsufficient:"Your credits are insufficient. Do you want to obtain them now",
  MarkOrUnmark:"Mark or Unmark",
  Benefits:"Benefits",
  GetCreditsToUnlockAllFeatures:"Get credits to unlock all features!",
  GetVIPToUnlockAllFeatures:"Get VIP to enjoy more privileges!",
  RegularPrice:"regular price",
  ChatWithAnyoneYouLike:"  Chat with anyone you like",
  ViewHiddenPrivateContent:"  View hidden private content",
  ReplyWithStickersInChats:"  Reply with stickers in chats",
  Sticker:"Stickers",
  sticker:"sticker",
  Mailbox:"Mailbox",
  GoChat:"Chat Now",
  TypeYourTextHere:"Type your text here...",
  UploadImgTips:"Files in GIF, JPG and PNG maximum 5 Mb size.",
  UploadVideoTips:"Files in MP4, MOV and AVI maximum 30 Mb size.",
  Inbox:"Inbox",
  Starred:"Starred",
  Outbox:"Outbox",
  Trash:"Trash",
  RestoreFromTrash:"Restore",
  SendYourLetterTo:"Send your letter to ",
  maildetail:"Email Details",
  SendMail:"Send Mail",
  AttachmentImages:"Attachment Images",
  AttachmentVideos:"Attachment Videos",
  MyPhotos:"My Photos",
  MyVideos:"My Videos",
  picture:"picture",
  Attachment:"Attachment",
  Chat:"Chat",
  HowItWorks:"How it works",
  chatPrice1:"Sending your first message costs",
  chatPrice2:"No matter how many messages yousend, you are being charged for each 60 seconds you are sending the messages.",
  chatPrice3:"If you sent no message for more than 60 seconds, timer resets.",
  GetMoreWithCredits:"Get more with Credits",
  MyActivity:"My Activity",
  NoChatContent:"No chat content",
  AddCategory:"Add Category",
  CategoryName:"Category Name",
  CategoryNamePlaceholder:"Please enter a category name",
  ConfirmDelete:"Are you sure you want to delete it?",
  MovePhoto:"Move photo",
  MovePhotoCategoryNamePlaceholder:"Please select a category name",
  TopUpVIP: "Top-up VIP",
  VIP: "VIP",
  Day:"Day",
  Month:"Month",
  Pay:"Pay",
  BackToHomepage:"Back to homepage",
  Unread:"Unread",
  Unanswered:"Unanswered",
  DeleteSelected:"Delete selected",
  DoYouLike:"Do you like",
  QuestionMark:"? ",
  EmptyRecommend:"Please complete the information first",
  EmptyPrompt:"Please add category",
  SeeNext: "See Who’s Up Next",
  SeeMore: "They will be next, keep playing to see them!",
  PaymentResult: "Payment Result",
  People: "People",
  Article: "Article",
  MyVIP: "VIP Introduce",
  VIPExpirationDate: "Expiration Date",
  VIPPurchasedTips: "Renew a fee for more benefits",
  GetVIP: "Get VIP",
  NotVIPActivateed: "Not VIP activated",
  Continue: "continue",
  Tip: "Tip",
  GiftDelivered: "The gifts are being delivered",
  ConfirmOrder: "Confirm the order?",
  OrderNow: "Order Now",
  VIPIntro1: "Free to chat",
  VIPIntro2: "Free to send stickers",
  VIPIntro3: "Free to see mail",
  OrderAll: "All",
  OrderDelivery: "Delivery",
  OrderNo: "Order No.",
  OrderAmount: "Order amount",
  OrderTo: "To",
  OrderInDelivery: "In delivery",
  OrderReceived: "Received",
  SurpriseWithPresent: "Surprise with Present!",
  SpecialDeliveryDirectlyTo: "Special delivery directly to ",
  ChoosePresent: "Choose Present",
  ...enLocale,

}