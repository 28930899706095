import Vue from 'vue'
import loading001 from './index.vue';
import ElementUI from 'element-ui';

const LoadingConstructor = Vue.extend(loading001)

const instance = new LoadingConstructor({
    el: document.createElement('div')
})

instance.show = false
var load = null;
var done = false;
const loading = {
    show() {
        done = false;
        //loading参数配置
        let loadingConfig = {
            lock: true,
            text: '',
            spinner: '',
            background: 'rgba(0, 0, 0, 0)'
        }
        setTimeout(() => {
            if(!done){
                instance.show = true
                document.body.appendChild(instance.$el)
                load = ElementUI.Loading.service(loadingConfig);
            }
        }, 200)

    },
    hide() {
        done = true;
        instance.show = false;
        if (load!=null){
            load.close();
        }
       

    }
}

export default {
    install() {
        if (!Vue.$showLoading) {
            Vue.$showLoading = loading
        }
        Vue.mixin({
            created() {
                this.$showLoading = Vue.$showLoading
            }
        })
    }
}
