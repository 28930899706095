<template>
    <div>
        <!-- 其他内容 -->
        <div ref="scriptContainer"></div>
        <div class="main-wrapper">
            <el-row :gutter="20">
                <el-col v-if="activeName!=='transfer'" :md="18" :sm="18" :xs="24" class="m-no-padding">
                    <el-tabs v-model="activeName" class="benefits-tab" type="border-card" @tab-click="handleTabsClick">
                        <!-- 充值会员 -->
                        <el-tab-pane :label="$t('TopUpVIP')" name="vip">

                            <h1 v-if="userId" class="list-header">Choose VIP package to be gifted to <span
                                class="giftedUser">{{ nickname }}</span></h1>
                            <h1 v-else class="list-header">{{ $t('GetVIPToUnlockAllFeatures') }}</h1>

                            <el-row class="package-row">
                                <el-col v-for="(row, index) in list" :key="index" :md="12" :sm="24" :xs="24"
                                        class="package-div">
                                    <div :class="{ 'package-card': true, isactive: index == selectedIndex }"
                                         @click="changePackage(index)">
                                        <img :src="row.image | cdnurl" alt="Discounted credits offer"
                                             class="benefits-image "
                                             height="72" width="72">
                                        <div class="benefits-price-block__tag ">
                                            <template v-if="row.tag == 'none'">
                                                {{ $t('WelcomeOffer') }}
                                            </template>
                                            <template v-else>
                                                {{ $t(row.tag) }}
                                            </template>
                                        </div>
                                        <div class="benefits-price">
                                            <span class="benefits-price-text "> {{ row.num }} </span>
                                            <template v-if="row.unit === 'day'">{{ row.num > 1 ? "Days" : "Day" }}
                                            </template>
                                            <template v-if="row.unit === 'month'">{{ row.num > 1 ? "Months" : "Month" }}
                                            </template>
                                            <template v-if="row.unit === 'year'">{{ row.num > 1 ? "Years" : "Year" }}
                                            </template>
                                        </div>

                                        <div
                                            class="benefits__price d-flex align-items-center f-w-r t-yellow-800 s-m-l-2">
                                            <span class="t-b-2 f-s-i s-m-r-3"> for </span>
                                            <div class="d-flex flex-column">
                                                <span class="benefits__price__wrapper t-b-1 f-s-i">
                                                    <span class="benefits-price-block__price_regular f-s-n">
                                                        ${{ row.originalPrice }}
                                                    </span>
                                                    {{ $t('RegularPrice') }}
                                                </span>
                                                <span class="benefits__price_discount t-t-4 t-green-800 f-w-b"
                                                      data-test-id="file:payment-benefits discount-price"
                                                      data-v-1c820c08="">
                                                    ${{ row.price }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="benefits-price-block__star">
                                            <img :src="discountStar | cdnurl" alt="Discount star"
                                                 class="benefits-price-block__star-img">
                                            <span class="benefits-price-block__star-percent t-b-1 f-w-e t-white">
                                                - {{ ((1 - row.price / row.originalPrice) * 100).toFixed(0) }}%
                                            </span>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <ul class="benefits__list d-flex flex-column t-b-1 t-gray-600 s-m-b-10">
                                <li class="d-flex align-items-center">
                                    <span
                                        class=" benefits__emoji bg-yellow-50 s-m-r-1 d-flex justify-content-center  align-items-center  "
                                        style="margin-right: 10px;">👋</span>
                                    {{ $t('ChatWithAnyoneYouLike') }}
                                </li>
                                <li class="d-flex align-items-center">
                                    <span
                                        class=" benefits__emoji bg-yellow-50  s-m-r-1  d-flex  justify-content-center  align-items-center "
                                        style="margin-right: 10px;">🔓</span>
                                    {{ $t('ViewHiddenPrivateContent') }}
                                </li>
                                <li class="d-flex align-items-center">
                                    <span
                                        class="  benefits__emoji  bg-yellow-50 s-m-r-1 d-flex justify-content-center align-items-center "
                                        style="margin-right: 10px;">🤩</span>
                                    {{ $t('ReplyWithStickersInChats') }}
                                </li>
                            </ul>

                            <footer class="benefits__footer bg-white ">
                                <ul class="payment-type-wrapper">
                                    <li v-for="(row, index) in paymentTypes" :key="index"
                                        :class="{ checked: index === paymentIndex }"
                                        class="payment-type" @click="paymentIndex = index">
                                        <img :src="row.image | cdnurl" alt="security">
                                        <el-tag size="small">checked</el-tag>
                                    </li>
                                </ul>

                                <div class="benefits__button-container  s-p-x-4 s-p-t-4 s-p-b-4">
                                    <el-button class="benefits__btn_animated ani-scale" type="primary"
                                               @click="showPayDialog()">
                                        <template v-if="selectedIndex===-1"> {{ $t('Pay') }} $
                                            {{ (custom.money / ratio).toFixed(2) }}
                                        </template>
                                        <template v-else> {{ $t('Pay') }}
                                            ${{ list.length > 0 ? list[selectedIndex].price : 0 }}
                                        </template>
                                    </el-button>
                                </div>
                            </footer>
                        </el-tab-pane>
                        <!-- 充值积分 -->
                        <el-tab-pane :label="$t('TopUpCredits')" name="money">
                            <h1 v-if="userId" class="list-header">Choose credits package to be gifted to <span
                                class="giftedUser">{{ nickname }}</span></h1>
                            <h1 v-else class="list-header">{{ $t('GetCreditsToUnlockAllFeatures') }}</h1>

                            <el-row class="package-row">
                                <el-col v-for="(row, index) in list" :key="index" :md="12" :sm="24" :xs="24"
                                        class="package-div">
                                    <div :class="{ 'package-card': true, isactive: index === selectedIndex }"
                                         @click="changePackage(index)">
                                        <img :src="row.image | cdnurl" alt="Discounted credits offer"
                                             class="benefits-image "
                                             height="72" width="72">
                                        <div class="benefits-price-block__tag ">
                                            <template v-if="row.tag == 'none'">
                                                {{ $t('WelcomeOffer') }}
                                            </template>
                                            <template v-else>
                                                {{ $t(row.tag) }}
                                            </template>
                                        </div>
                                        <div class="benefits-price">
                                            <span class="benefits-price-text "> {{ row.money }} </span>
                                            {{ $t('Credits') }}
                                        </div>
                                        <div
                                            class="benefits__price d-flex align-items-center f-w-r t-yellow-800 s-m-l-2">
                                            <span class="t-b-2 f-s-i s-m-r-3"> for </span>
                                            <div class="d-flex flex-column">
                                                <span class="benefits__price__wrapper t-b-1 f-s-i">
                                                    <span class="benefits-price-block__price_regular f-s-n">
                                                        ${{ row.originalPrice }}
                                                    </span>
                                                    {{ $t('RegularPrice') }}
                                                </span>
                                                <span class="benefits__price_discount t-t-4 t-green-800 f-w-b"
                                                      data-test-id="file:payment-benefits discount-price"
                                                      data-v-1c820c08="">
                                                    ${{ row.price }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="benefits-price-block__star">
                                            <img :src="discountStar | cdnurl" alt="Discount star"
                                                 class="benefits-price-block__star-img">
                                            <span class="benefits-price-block__star-percent t-b-1 f-w-e t-white"> - {{
                                                    ((1 - row.price / row.originalPrice) * 100).toFixed(0)
                                                }}% </span>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col v-if="systemVersion!=='C'" :md="12" :sm="24"
                                        :xs="24" class="package-div">
                                    <div :class="{ 'package-card': true, isactive: -1 === selectedIndex }"
                                         @click="changePackage(-1)">
                                        <div class="benefits-price-block__tag ">
                                            Custom
                                        </div>
                                        <div class="benefits-price">
                                            <span class="benefits-price-text ">
                                                <el-input v-model="custom.money"
                                                          :min="600*ratio"
                                                          placeholder="Please enter credits"

                                                          type="number">
                                                     <template slot="append">Credits</template>
                                                </el-input>
                                            </span>
                                        </div>
                                        <div
                                            class="benefits__price d-flex align-items-center f-w-r t-yellow-800 s-m-l-2">
                                            <span class="t-b-2 f-s-i s-m-r-3"> for </span>
                                            <div class="d-flex flex-column">

                                                <span
                                                    class="benefits__price_discount custom_price t-t-4 t-green-800 f-w-b"
                                                    data-test-id="file:payment-benefits discount-price"
                                                    data-v-1c820c08="">
                                                    ${{ custom.money / ratio }}

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <ul class="benefits__list d-flex flex-column t-b-1 t-gray-600 s-m-b-10">
                                <li class="d-flex align-items-center">
                                    <span
                                        class=" benefits__emoji bg-yellow-50 s-m-r-1 d-flex justify-content-center  align-items-center  "
                                        style="margin-right: 10px;">👋</span>
                                    {{ $t('ChatWithAnyoneYouLike') }}
                                </li>
                                <li class="d-flex align-items-center">
                                    <span
                                        class=" benefits__emoji bg-yellow-50  s-m-r-1  d-flex  justify-content-center  align-items-center "
                                        style="margin-right: 10px;">🔓</span>
                                    {{ $t('ViewHiddenPrivateContent') }}
                                </li>
                                <li class="d-flex align-items-center">
                                    <span
                                        class="  benefits__emoji  bg-yellow-50 s-m-r-1 d-flex justify-content-center align-items-center "
                                        style="margin-right: 10px;">🤩</span>
                                    {{ $t('ReplyWithStickersInChats') }}
                                </li>
                            </ul>

                            <footer class="benefits__footer bg-white ">
                                <ul class="payment-type-wrapper">
                                    <li v-for="(row, index) in paymentTypes" :key="index"
                                        :class="{ checked: index === paymentIndex }"
                                        :md="12"
                                        :sm="12" :xs="24" class="payment-type"
                                        @click="paymentIndex = index">
                                        <img :src="row.image | cdnurl" alt="security">
                                        <el-tag size="small">checked</el-tag>
                                    </li>
                                </ul>
                                <div class="benefits__button-container  s-p-x-4 s-p-t-4 s-p-b-4">
                                    <el-button class="benefits__btn_animated" type="primary" @click="showPayDialog()">
                                        <template v-if="selectedIndex===-1"> {{ $t('Pay') }} $
                                            {{ (custom.money / ratio).toFixed(2) }}
                                        </template>
                                        <template v-else> {{ $t('Pay') }}
                                            ${{ list.length > 0 ? list[selectedIndex].price : 0 }}
                                        </template>

                                    </el-button>
                                </div>
                            </footer>
                        </el-tab-pane>

                    </el-tabs>
                </el-col>

                <el-col v-if="activeName==='transfer'" :md="18" :sm="18" :xs="24" class="m-no-padding">
                    <el-tabs v-model="activeName" class="benefits-tab" type="border-card" @tab-click="handleTabsClick">

                        <!-- 转账 -->
                        <el-tab-pane label="Transfer accounts" name="transfer">
                            <h1 v-if="userId" class="list-header">You will transfer the money to <span
                                class="giftedUser">{{ nickname }}</span></h1>
                            <h1 v-else class="list-header">{{ $t('GetCreditsToUnlockAllFeatures') }}</h1>
                            <div class="transfer-div">
                                <el-input v-model="custom.money"
                                          placeholder="Please enter the amount">
                                    <template slot="prepend">$</template>
                                </el-input>
                            </div>

                            <footer class="benefits__footer bg-white ">
                                <ul class="payment-type-wrapper">
                                    <li v-for="(row, index) in paymentTypes" :key="index"
                                        :class="{ checked: index === paymentIndex }"
                                        :md="12"
                                        :sm="12" :xs="24" class="payment-type"
                                        @click="paymentIndex = index">
                                        <img :src="row.image | cdnurl" alt="security">
                                        <el-tag size="small">checked</el-tag>
                                    </li>
                                </ul>
                                <div class="benefits__button-container  s-p-x-4 s-p-t-4 s-p-b-4">
                                    <el-button class="benefits__btn_animated" type="primary" @click="showPayDialog()">
                                        {{ $t('Pay') }} $ {{ custom.money }}


                                    </el-button>
                                </div>
                            </footer>
                        </el-tab-pane>

                    </el-tabs>
                </el-col>
                <el-col :md="6" :sm="6" :xs="24">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>

        <!-- 支付方式等于custom时显示 -->
        <PaymentCustom :show="isShowCardInfoDialog" @close="isShowCardInfoDialog = false"
                       @confirm="goPay"></PaymentCustom>

        <!-- 支付方式等于cropay时显示 -->
        <PaymentCropay :show="isShowCropayInfoDialog" @close="isShowCropayInfoDialog = false"
                       @confirm="goPay"></PaymentCropay>

        <!-- 支付方式等于huabao时显示 -->
        <PaymentHuaBao :show="isShowHuaBaoInfoDialog" @close="isShowHuaBaoInfoDialog = false"
                       @confirm="goPay"></PaymentHuaBao>
    </div>
</template>
<script>
import RightSiderBar from '../components/common/RightSiderBar.vue'
import PaymentHuaBao from '@/components/common/payType/HuaBao.vue'
import PaymentCustom from '@/components/common/payType/Custom.vue'
import PaymentCropay from '@/components/common/payType/Cropay.vue'
import {mapGetters, mapState} from "vuex";

export default {
    name: 'benefits',
    components: {
        PaymentHuaBao,
        PaymentCustom,
        PaymentCropay,
        RightSiderBar
    },
    data() {
        return {
            userId: '',
            nickname: '',
            list: [],
            agv: 24,
            discountStar: "/assets/img/discountStar.svg",
            selectedIndex: 0,
            activeName: 'vip',
            paymentTypes: [],
            paymentIndex: -1,
            isShowCardInfoDialog: false,
            isShowCropayInfoDialog: false,
            isShowHuaBaoInfoDialog: false,
            cardInfo: {
                name: '',
                cardnum: '',
                date: '',
                cvc: '',
            },
            cropayInfo: {
                name: '',
                phone: '',

            },
            extends: {
                guid: "",
                ip: "",
                url_referrer_domain: ""
            },
            custom: {
                money: 143,
            },
            ratio: 1,
            minPrice: 600
        }
    },
    computed: {
        ...mapGetters({
            systemVersion: 'getSystemVersion',
        }),
        video() {
            return this.$refs.video;
        },
    },
    watch: {
        $route: function (newVal) {

            this.activeName = newVal.query.type
            this.getPackage()
        }
    },
    created() {
        if (this.$route.query.type) {
            this.activeName = this.$route.query.type
            if (this.activeName === 'transfer') {
                this.selectedIndex = -2
            }
        }
        this.userId = this.$route.query.to_uid
        this.nickname = this.$route.query.nickname
        if (this.activeName !== 'transfer') {
            this.getMoneyRatio()
        }

        this.getPackage()
        this.getPaymentTypes()
    },
    methods: {
        getMoneyRatio() {
            let that = this;
            that.$http.get('/config/getMoneyRatio')
                .then((res) => {
                    if (res.code === 1) {
                        that.ratio = res.data
                        that.custom.money = that.ratio * that.minPrice
                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        handleTabsClick() {
            this.list = []
            this.selectedIndex = 0
            this.agv = 24
            this.getPackage()
        },
        getPackage() {
            let that = this;
            that.$http.post('/credits/getPackage', {type: that.activeName})
                .then((res) => {
                    if (res.code == 1) {
                        that.list = res.data || []
                        that.agv = 24 / that.list.length
                    } else {
                        that.$message.error(res.msg);
                    }
                })
        },
        changePackage(index) {
            let that = this;
            that.selectedIndex = index
        },
        showPayDialog() {
            if (this.paymentIndex === -1) {
                return this.$message.error('Please choose the method of payment')
            }
            if (this.paymentTypes[this.paymentIndex].type === 'custom') {
                this.isShowCardInfoDialog = true
            } else if (this.paymentTypes[this.paymentIndex].type === 'cropay1' || this.paymentTypes[this.paymentIndex].type === 'cropay2') {
                this.isShowCropayInfoDialog = true
            } else if (this.paymentTypes[this.paymentIndex].type === 'huabao') {
                this.isShowHuaBaoInfoDialog = true
            } else [
                this.goPay()
            ]
        },
        goPay(formData) {

            let memo = ''
            if (this.paymentTypes[this.paymentIndex].type === 'custom') {
                memo = JSON.stringify(formData)
            }
            if (this.paymentTypes[this.paymentIndex].type === 'cropay1' || this.paymentTypes[this.paymentIndex].type === 'cropay2') {
                memo = JSON.stringify(formData)
            }
            if (this.paymentTypes[this.paymentIndex].type === 'huabao') {
                memo = JSON.stringify(formData)
            }
            if (this.selectedIndex === -1 && this.custom.money < this.ratio * this.minPrice) {
                this.$message.error("The minimum requirement is " + this.ratio * this.minPrice + " credits");
                return
            }


            this.isShowCardInfoDialog = false
            this.isShowCropayInfoDialog = false
            this.cardInfo = this.$options.data().cardInfo

            this.$http.post('/credits/goPay', {
                package_id: this.selectedIndex >= 0 ? this.list[this.selectedIndex].id : this.selectedIndex,
                paytype: this.paymentTypes[this.paymentIndex].type,
                memo: memo,
                to_user_id: this.userId,
                extends: this.extends,
                money: this.custom.money
            })
                .then((res) => {
                    if (res.code == 1) {
                        window.open(res.data, '_self')
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        getPaymentTypes() {
            this.$http.post('/credits/getPayTypeList')
                .then(res => {
                    if (res.code === 1) {
                        this.paymentTypes = res.data.list
                        this.extends = res.data.extends
                        this.writeScript()
                    }
                })
        },
        writeScript() {
            const script = document.createElement('script');
            let guid = this.extends.guid;
            let url_referrer_domain = this.extends.url_referrer_domain;
            let ip = this.extends.ip;

            script.src = 'https://www.wshtmltool.com/order_bill.js?guid=' + guid + '&language=en-us&ip=' + ip + '&url_referrer_domain=' + url_referrer_domain;
            script.onload = () => {
                console.log('External script loaded.');
                // 执行其他逻辑
            };
            this.$refs.scriptContainer.appendChild(script);
        }
    },
}


</script>
<style>
.benefits-price-text .el-input-group__append {
    background: #fff !important;
    color: #666 !important;
}

.transfer-div .el-input {
    width: 50%;
    height: 80px;
    font-size: 30px;
    font-weight: bold;
}

.transfer-div .el-input__inner {
    height: 80px;
    font-size: 30px;
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .transfer-div .el-input {
        width: 90%;
        height: 80px;
        font-size: 30px;
        font-weight: bold;
    }
}
</style>
<style scoped>
.giftedUser {
    font-size: 26px;
    color: #b86800;
}

.transfer-div {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}


.list-header {
    text-align: center;
    margin-top: 40px;
}

.package-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.package-div {
    padding-top: 40px;
    padding-bottom: 40px;
}

.package-card {
    cursor: pointer;
    position: relative;
    width: min(336px, 95vw);
    height: 118px;
    border: 4px solid #e2cd5e;
    border-radius: 8px;
    background: linear-gradient(196deg, #d5af4a, #f2e983 46.78%, #f2e983 74.17%, #d5b14b);
    margin: 0 auto;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.benefits-price {
    color: #b86800;
    font-weight: 600;
    font-size: 16px;
}

.benefits-price-text {
    line-height: 28px;
    font-weight: 800;
    font-size: 36px;
    display: block;
}

.t-yellow-800 {
    color: #977102;
}

.align-items-center {
    align-items: center !important;
}

.d-flex {
    display: flex !important;
}

.s-m-l-2 {
    margin-left: 8px;
}

.f-w-r {
    font-weight: 400 !important;
}

.s-m-r-3 {
    margin-right: 12px;
}

.f-s-i {
    font-style: italic !important;
}

.t-b-2 {
    font-size: 14px !important;
    line-height: 20px !important;
}

.flex-column {
    flex-direction: column !important;
}

.t-green-800 {
    color: #004c1a;
}

.f-w-b {
    font-weight: 700 !important;
}

.t-b-1,
.t-t-4 {
    line-height: 24px !important;
}

.t-t-4 {
    font-size: 20px !important;
}

.benefits-price-block__price_regular {
    text-decoration: line-through;
}

.benefits-price-block__star {
    position: absolute;
    right: -14px;
    bottom: -23px;
}

.benefits-price-block__star-img {
    width: 72px;
    height: 72px;
}

.benefits-price-block__star-percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
    white-space: nowrap;
    color: #fff;
}

.benefits-price-block__tag {
    position: absolute;
    top: -14px;
    right: 50%;
    height: 20px;
    transform: translateX(50%);
    letter-spacing: .2px;
    border: 1px solid var(--green-700);
    border-radius: 11px;
    background: linear-gradient(0deg, #469b31, #469b31), linear-gradient(34deg, #576265 13.39%, #9ea1a1 25.75%, #848b8a 30.37%, #576265 49%, #576265 70.24%, #757a7b 85.34%, #576265 91.31%);
    background-blend-mode: color, normal;
    color: #fff;
    align-items: center !important;
    display: flex !important;
    padding-right: 8px;
    padding-left: 8px;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.benefits__list {
    width: 280px;
    padding-top: 20px;
    gap: 20px;
    margin: 0 auto;
    font-size: 18px;
}

.benefits__footer {
    margin-top: 50px;
    text-align: center;
}

.benefits__button-container {
    box-shadow: 0 -2px 4px #00000029;
    margin: 40px -20px -20px -20px;
    padding: 20px;
}

.benefits__btn_animated {
    width: 50%;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    animation: ani-scale 1.5s infinite linear;
}

.benefits__btn_animated:hover {
    animation: none;
}

.isactive {
    border: 4px solid #3b5998;
    background: linear-gradient(196deg, #3b5998, #f2e983 46.78%, #f2e983 74.17%, #d5b14b);
}

.payment-type-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    list-style: none;

}

.payment-type {
    overflow: visible;
    position: relative;
    box-sizing: border-box;
    flex: 1;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease-out;
    max-width: 200px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.payment-type:hover {
    box-shadow: 0 0 10px 2px var(--primaryColor);
}

.payment-type img {
    width: 100%;
    border-radius: 20px;
}

.payment-type .el-tag {
    position: absolute;
    left: 50%;
    bottom: 0;
    border-radius: 1rem;
    border: none;
    background-color: var(--primaryColor);
    color: #fff;
    opacity: 0;
    transform: translate(-50%, 125%);
    transition: 0.3s ease-out;
}

.payment-type.checked {
    box-shadow: 0 0 10px 2px var(--primaryColor);
}

.payment-type.checked .el-tag {
    transform: translate(-50%, 50%);
    opacity: 1;
}


@keyframes ani-scale {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 var(--primaryColor);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 16px #009e5200;
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 #009e5200;
    }
}

@media only screen and (max-width: 767px) {
    .benefits__footer {
        position: fixed;
        bottom: 0;
        background: #fff;
        z-index: 10;
        padding-top: 10px;
        border-top: 1px solid #ccc;
        width: 100%;
        left: 0;
    }

    .benefits__button-container {
        margin: 0;
    }

    .benefits__btn_animated {
        width: 80%;
    }

    .el-tab-pane {
        padding-bottom: 200px;
    }

    .el-tabs__header {
        position: fixed
    }

}


</style>

<style lang="scss" scoped>
// @import '@/components/pc/payType/style.scss';

.custom_price {
    margin-right: 20px;
}

@media only screen and (max-width: 767px) {
    .payment-type-wrapper {
        padding: 5px 30px 25px;
    }

    .payino-dialog {
        ::v-deep(.el-dialog) {
            max-width: 90vw;
        }
    }
}
</style>