<template>
    <div class="footer">
        <img src="https://www.wshtmltool.com/logo/vm.png">
        <ul class="friendlinks">
            <li v-for="item in friendlinks" :key="item.id" @click="handleRoute(item.id)">{{item.title}}</li>
        </ul>
        <p class="copyright">HONGKONG JIHONGYUN TRADE DEVELOPMENT CO., LIMITED(UNIT D 16/F ONE CAPITAL PLACE 18 LUARD ROAD WAN CHAI HK</p>
    </div>
</template>    
<script>
export default {
    name: 'FooterView',
    data:function(){
        return {
            friendlinks: [],
            
        }
    },
    methods: {
        handleRoute(id) {
            this.$router.push({
                path: 'article',
                query: {
                    id
                }
            })
        },
        getFriendlinks() {
            this.$http.get('/article/get-list', { 
                params: { lang: 'zh-cn' }
            })
            .then(res => {
                this.friendlinks = res.data
            })
        }
    },
    created() {
        this.getFriendlinks()

	},
}
</script>
<style scoped>
.copyright{
    font-size: 14px;
    margin-bottom: 10px;
}
.footer {
    margin-top: 10px;
    text-align: center;
    color: #e8e8e8;
}
.friendlinks {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.friendlinks li {
    line-height: 1;
    padding: 0 10px;
    border-right: 2px solid #fff;
    cursor: pointer;
}
.friendlinks li:last-of-type {
    border-right: 0;
}
</style>
  