import { render, staticRenderFns } from "./profileMobile.vue?vue&type=template&id=3267e24c&scoped=true&slot-scope=scope"
import script from "./profileMobile.vue?vue&type=script&lang=js"
export * from "./profileMobile.vue?vue&type=script&lang=js"
import style0 from "./profileMobile.vue?vue&type=style&index=0&id=3267e24c&prod&lang=css"
import style1 from "./profileMobile.vue?vue&type=style&index=1&id=3267e24c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3267e24c",
  null
  
)

export default component.exports