<template>
    <div class="phone-reserve main-wrapper">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="18" :md="18">
                <div class="aside-content">
                    <div class="notification" v-html="callIntroduce"></div>
                    <el-form ref="form" :model="form" :rules="rules" label-position="top" label-width="80px" class="form-wrapper">
                        <el-form-item label="Date" prop="date">
                            <el-date-picker 
                                type="date" 
                                placeholder="Select Date" 
                                v-model="form.date"
                                format="MM/dd/yyyy"
                                value-format="MM/dd/yyyy"
                                style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="Start Time" prop="starttime">
                                    <el-time-picker 
                                        placeholder="Select Time" 
                                        v-model="form.starttime" 
                                        format="hh:mm a"
                                        value-format="hh:mm a"
                                        style="width: 100%;">
                                    </el-time-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="line center" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-form-item label="End Time" prop="endtime">
                                    <el-time-picker 
                                        placeholder="Select Time" 
                                        v-model="form.endtime" 
                                        format="hh:mm a"
                                        value-format="hh:mm a"
                                        style="width: 100%;">
                                    </el-time-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="Your Contact Numbers"
                            prop="contactNumbers"
                            :rules="[
                                { required: true, message: 'Please enter the contact number', trigger: 'blur', validator: (rule, value, callback) => {
                                    if (value[0].phone === '') {
                                        return callback('Please enter the contact number')
                                    }
                                    else {
                                        callback()
                                    }
                                } },
                            ]"
                        >
                            <el-row style="margin-bottom: 10px;">
                                <el-col :span="6">
                                    <el-input type="text" v-model="form.contactNumbers[0].country"
                                        placeholder="Country code"></el-input>
                                </el-col>
                                <el-col :span="1" class="line">-</el-col>
                                <el-col :span="6">
                                    <el-input type="text" v-model="form.contactNumbers[0].city"
                                        placeholder="City/Area code"></el-input>
                                </el-col>
                                <el-col :span="1" class="line">-</el-col>
                                <el-col :span="10">
                                    <el-input type="text" v-model="form.contactNumbers[0].phone"
                                        placeholder="Phone number"></el-input>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" style="color: #737373;">You can also enter additional phone number.</el-col>
                                <el-col :span="6">
                                    <el-input type="text" v-model="form.contactNumbers[1].country"
                                        placeholder="Country code"></el-input>
                                </el-col>
                                <el-col :span="1" class="line">-</el-col>
                                <el-col :span="6">
                                    <el-input type="text" v-model="form.contactNumbers[1].city"
                                        placeholder="City/Area code"></el-input>
                                </el-col>
                                <el-col :span="1" class="line">-</el-col>
                                <el-col :span="10">
                                    <el-input type="text" v-model="form.contactNumbers[1].phone"
                                        placeholder="Phone number"></el-input>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="Your Contact Email" 
                            prop="contactEmail"
                            :rules="[
                                { required: true, message: 'Please enter your email address', trigger: 'blur' },
                                { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
                            ]"
                        >
                            <el-input type="text" v-model="form.contactEmail"></el-input>
                        </el-form-item>
                        <div v-if="amount > 0" class="amount-wrapper d-flex justify-content-between">
                            <span class="label">Your Amount:</span>
                            <span>${{ amount }}</span>
                        </div>
                        <el-divider v-if="amount > 0"></el-divider>
                        <el-form-item label="Your Payment" required>
                            <div class="payment-type-wrapper">
                                <ul class="payment-type-wrap">
                                    <li v-for="(row, index) in paymentTypes" :key="index"
                                        :class="{ checked: index === paymentIndex }"
                                        class="payment-type" 
                                        @click="paymentIndex = index">
                                        <img :src="row.image | cdnurl" alt="security">
                                        <el-tag size="small">checked</el-tag>
                                    </li>
                                </ul>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="btns-wrapper">
                                <el-button type="primary" @click="onSubmit('form')">Order Phone Call</el-button>
                                <el-button class="cancel-btn" @click="$router.back()">Cancel</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
            <el-col :xs="24" :sm="6" :md="6">
                <right-sider-bar></right-sider-bar>
            </el-col>
        </el-row>

        <!-- 支付方式等于custom时显示 -->
        <PaymentCustom :show="isShowCardInfoDialog" @confirm="goPay" @close="isShowCardInfoDialog = false"></PaymentCustom>
        
        <!-- 支付方式等于cropay时显示 -->
        <PaymentCropay :show="isShowCropayInfoDialog" @confirm="goPay" @close="isShowCropayInfoDialog = false"></PaymentCropay>

        <!-- 支付方式等于huabao时显示 -->
        <PaymentHuaBao :show="isShowHuaBaoInfoDialog" @confirm="goPay" @close="isShowHuaBaoInfoDialog = false"></PaymentHuaBao>
    </div>
</template>

<script>
import RightSiderBar from '@/page/components/common/RightSiderBar.vue'
import PaymentHuaBao from '@/components/common/payType/HuaBao.vue'
import PaymentCustom from '@/components/common/payType/Custom.vue'
import PaymentCropay from '@/components/common/payType/Cropay.vue'
import * as dayjs from 'dayjs'

export default {
    components: {
        RightSiderBar,
        PaymentHuaBao,
        PaymentCustom,
        PaymentCropay,
    },
    data() {
        const validateDateTime = (callback) => {
            
        }
        const checkDate = (rule, value, callback) => {
            if (value === '') {
                callback('Please select the date')
            } else {
                if (this.form.date && this.form.starttime && this.form.endtime) {
                    this.$refs.form.validateField('endtime')
                }
            }
        }
        const checkStartTime = (rule, value, callback) => {
            if (value === '') {
                callback('Please select the start time')
            } else {
                if (this.form.date && this.form.starttime && this.form.endtime) {
                    this.$refs.form.validateField('endtime')
                }
            }
        }
        const checkEndTime = (rule, value, callback) => {
            if (value === '') {
                callback('Please select the end time')
            } else {
                if (this.form.date && this.form.starttime && this.form.endtime) {
                    let starttime = dayjs(this.form.date + ' ' + this.form.starttime)
                    let endtime = dayjs(this.form.date + ' ' + this.form.endtime)
                    let diffTime = endtime.diff(starttime, 'minute')
                    if (diffTime <= 0) {
                        callback('The end time must be greater than the start time')
                    } else {
                        callback()
                    }
                }
                callback()
            }
        }
        return {
            rules: {
                date: [
                    { validator: checkDate, trigger: 'blur', required: true }
                ],
                starttime: [
                    { validator: checkStartTime, trigger: 'blur', required: true }
                ],
                endtime: [
                    { validator: checkEndTime, trigger: 'blur', required: true }
                ],
            },
            form: {
                date: '',
                starttime: '',
                endtime: '',
                contactEmail: '',
                contactNumbers: [
                    { country: '', city: '', phone: '' },
                    { country: '', city: '', phone: '' },
                ],
            },
            extends: {},
            to_user_id: '',
            paymentTypes: [],
            paymentIndex: 0,
            isShowCardInfoDialog: false,
            isShowCropayInfoDialog: false,
            isShowHuaBaoInfoDialog: false,
            callIntroduce: '',
            callPrice: 0,
        }
    },
    computed: {
        amount: function () {
            if (this.form.date && this.form.starttime && this.form.endtime) {
                let starttime = dayjs(this.form.date + ' ' + this.form.starttime)
                let endtime = dayjs(this.form.date + ' ' + this.form.endtime)
                let diffTime = endtime.diff(starttime, 'minute')
                if (diffTime < 0) {
                    return 0
                }
                else {
                    return diffTime * this.callPrice
                }
            }
        }
    },
    created() {
        this.to_user_id = this.$route.query.id
        this.$http.post('/credits/getPayTypeList')
            .then(res => {
                if (res.code === 1) {
                    this.extends = res.data.extends
                    this.paymentTypes = res.data.list
                }
            })
        this.getCallIntroduce()
        this.getTheCallPricePerMinute()
    },
    methods: {
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (valid) {
                    const PaymentType = this.paymentTypes[this.paymentIndex].type
                    if (PaymentType === 'custom') {
                        this.isShowCardInfoDialog = true
                    } 
                    else if (PaymentType === 'cropay1' || PaymentType === 'cropay2') {
                        this.isShowCropayInfoDialog = true
                    } 
                    else if (PaymentType === 'huabao') {
                        this.isShowHuaBaoInfoDialog = true
                    }
                    else {
                        this.goPay()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        goPay(formData) {
            this.$http.post('/credits/phonePay', {
                phoneData: this.form,
                package_id : 0,
                extends: this.extends,
                paytype: this.paymentTypes[this.paymentIndex].type,
                to_user_id: this.to_user_id,
                memo: formData ? JSON.stringify(formData) : undefined
            })
                .then(res => {
                    if (res.code === 1) {
                        this.$refs['form'].resetFields();
                        location.replace(res.data)
                    }
                })
        },
        getCallIntroduce() {
            this.$http.get('/config/getCallIntroduce', {})
                .then(res => {
                    if (res.code === 1) {
                        this.callIntroduce = res.data
                    }
                })
        },
        getTheCallPricePerMinute() {
            this.$http.get('/config/getCallPerMinutePrice', {})
                .then(res => {
                    if (res.code === 1) {
                        this.callPrice = res.data
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.aside-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    .notification {
        width: 100%;
        max-width: 550px;
        padding: 20px 0;
        color: #737373;
    }
}

.form-wrapper {
    width: 100%;
    max-width: 550px;

    .line {
        text-align: center;

        &.center {
            transform: translateY(280%);
        }
    }
}

.amount-wrapper {
    line-height: 40px;
    color: #636363;

    .label {
        font-size: 14px;
        color: #737373;
    }
}

.payment-type-wrapper {
    padding: 0px 0 15px;

    .payment-type-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px 50px;
        list-style: none;
    
        .payment-type {
            overflow: visible;
            position: relative;
            box-sizing: border-box;
            border-radius: 20px;
            cursor: pointer;
            transition: 0.3s ease-out;
            padding: 10px;
            display: flex;
            align-items: center;
    
            &:hover {
                box-shadow: 0 0 10px 2px var(--primaryColor);
            }
    
            &.checked {
                box-shadow: 0 0 10px 2px var(--primaryColor);
    
                .el-tag {
                    transform: translate(-50%, 50%);
                    opacity: 1;
                }
            }
    
            img {
                width: 100%;
                border-radius: 20px;
            }
    
            .el-tag {
                position: absolute;
                left: 50%;
                bottom: 0;
                border-radius: 1rem;
                border: none;
                background-color: var(--primaryColor);
                color: #fff;
                opacity: 0;
                transform: translate(-50%, 125%);
                transition: 0.3s ease-out;
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .phone-reserve {
        box-sizing: border-box;
        padding: 0 10px 50px;
        background-color: #fff;
    }

    .form-wrapper {

        .btns-wrapper {
            display: flex;
            flex-direction: column;

            .cancel-btn {
                margin-top: 20px;
                margin-left: 0;
            }
        }
    }
}
</style>