import Vue from 'vue'
import Auth from './auth.js'
import i18n from '../lang'
import Emoji from './emoji'


export default {
    setContacts(data) {
        let userInfo = Auth.getToken()
        let contactsKey = userInfo ? ("chat-contacts-" + userInfo.id) : "";
        data.lastContent = this.convertEmoji(data.lastContent)
        data.avatar = Vue.prototype.cdnurl(data.avatar)
        let contactList = this.getContactList() || [];
        let update = false;
        for (let i = 0; i < contactList.length; i++) {
            if (data.id == contactList[i].id) {
                contactList[i] = data;
                update = true;
            }
        }
        if (!update) {
            contactList.push(data)
        }
        localStorage.setItem(contactsKey, JSON.stringify(contactList))
    },
    delContacts(id) {
        let userInfo = Auth.getToken()
        let contactsKey = userInfo ? ("chat-contacts-" + userInfo.id) : "";
        let contactList = this.getContactList() || [];
        for (let i = 0; i < contactList.length; i++) {
            if (id == contactList[i].id) {
                contactList.splice(i, 1);
            }
        }
        localStorage.setItem(contactsKey, JSON.stringify(contactList))
    },
    getContact(id, list) {

        let data = ''
        if (list.length > 0) {
            data = list.find(contact => contact.id == id);
        }

        return data;
    },
    getContactList() {
        let userInfo = Auth.getToken()
        let contactsKey = userInfo ? ("chat-contacts-" + userInfo.id) : "";
        var contactList = localStorage.getItem(contactsKey) ? JSON.parse(localStorage.getItem(contactsKey)) : []


        return contactList
    },
    setMessage(message) {

        let contact = this.getContact(message.toContactId)
        if (!contact) {
            contact = message.fromUser
        }
        if (message.fromUser.id == message.toContactId) {
            contact.unread = !parseInt(contact.unread) ? 1 : (parseInt(contact.unread) + 1)
        } else {
            contact.unread = message.status == 'succeed' ? 0 : contact.unread
        }
        this.setContacts({
            id: contact.id,
            displayName: contact.displayName,
            avatar: contact.avatar,
            index: contact.index,
            unread: contact.unread,
            lastSendTime: +new Date(),
            lastContent: message.type == 'text' ? message.content : `[${i18n.t(message.type)}]`,
            isvip: contact.isvip
        })
        message = this.formatMessage(message)

        let messageList = this.getMessageList(message.toContactId, -1)

        let update = false;
        for (let i = 0; i < messageList.length; i++) {
            if (message.id == messageList[i].id) {
                messageList[i] = message;
                update = true;
            }
        }
        if (!update) {
            messageList.unshift(message)
        }
        this.setMessageObj(message.toContactId, messageList)


    },
    getMessageList(contact_id, offset, limit) {
        let userInfo = Auth.getToken()
        let messageKey = userInfo ? ("chat-contacts-message-" + userInfo.id) : "";
        let messageObj = JSON.parse(localStorage.getItem(messageKey))
        let messageList = messageObj ? (messageObj[contact_id] || []) : [];
        messageList.sort((a, b) => {
            return b.sendTime - a.sendTime
        })
        if (offset < 0) {
            return messageList;
        }
        let count = messageList.length
        // messageList = messageList.slice((count - (offset + limit)) < 0 ? 0 : (count - (offset + limit)), count - offset)
        messageList = messageList.slice(offset, offset + limit).reverse()
        return messageList;
    },
    delMessage(contact_id, id) {
        if (id == 0) {
            this.setMessageObj(contact_id, [])

        } else {
            let messageList = this.getMessageList(contact_id, -1) || [];
            for (let i = 0; i < messageList.length; i++) {
                if (id == messageList[i].id) {
                    messageList.splice(i, 1);
                }
            }
            this.setMessageObj(contact_id, messageList)
        }

    },
    setMessageObj(contact_id, messageList) {
        let userInfo = Auth.getToken()
        let messageKey = userInfo ? ("chat-contacts-message-" + userInfo.id) : "";
        let messageObj = JSON.parse(localStorage.getItem(messageKey)) || {}

        messageObj[contact_id] = messageList
        localStorage.setItem(messageKey, JSON.stringify(messageObj))
    },
    convertEmoji(text) {
        for (let i = 0; i < Emoji.length; i++) {
            for (let j = 0; j < Emoji[i].children.length; j++) {
                let emojiObj = Emoji[i].children[j]
                text = this.replaceText(emojiObj, text)
            }
        }
        return text
    },
    replaceText(emojiObj, text) {
        // [!zhayan]
        let str = `[!${emojiObj.name}]`
        text = text.replace(str, `<img emoji-name="${emojiObj.name}" src="img/face/${emojiObj.name}.gif">`)
        if (text.includes(str)) {
            return this.replaceText(emojiObj, text)
        }
        return text
    },
    playVoice(type) {
        let status = localStorage.getItem("newMessageNotice") == 'false' ? false : true
        if (!status) {
            return false
        }
        let signSucc = new Audio()
        signSucc.src = require(`../../static/voice/${type}`)

        const promise = signSucc.play()
        if (promise !== undefined) {
            promise.then(() => {
                console.log('--Play Success--');
                signSucc.play();
            }).catch(() => {
                console.log('--Play error--');
                //弹框提示，获取点击事件，重新播放
            })
        }
    },
    formatMessage(message) {
        switch (message.type) {
            case 'image':
            case 'video':
            case 'gift':
                message.content = Vue.prototype.cdnurl(message.content)
                break;
        }
        message.fromUser.avatar = Vue.prototype.cdnurl(message.fromUser.avatar)
        return message
    }
}

let demo = {
    id: 1,
    displayName: "像梦一样自由",
    avatar: "https://p.qqan.com/up/2020-2/2020022821001845128.jpg",
    index: "X",
    unread: 0,
    lastSendTime: 1566047865417,
    lastContent: "你开心吗",
}

let demo2 = {
    1: [
        {
            id: 1,
            status: "succeed",
            type: "text",
            sendTime: 1566047865417,
            content: "问你件事",
            toContactId: 1,
            fromUser: 1,
        }
    ]
}