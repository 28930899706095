<template>
    <el-row>
        <el-button round size="mini" style="float: right;" type="primary" @click="handleShowEdit"><i
            class="el-icon-edit"></i> {{ $t('EditProfile') }}
        </el-button>
        <div v-if="Object.keys(userinfo).length > 0">
            <el-descriptions :column="basicInfoColumn" :title="$t('BasicInformation')" border direction="horizontal">
                <el-descriptions-item :label="$t('nickname')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.nickname
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('birthday')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.birthday
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('country')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.country_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('Occupation')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.occupation_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('height')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.height
                    }}cm
                </el-descriptions-item>
                <el-descriptions-item :label="$t('weight')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.weight
                    }}kg
                </el-descriptions-item>
                <el-descriptions-item :label="$t('eyeColor')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.eye_color_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('hairColor')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.hair_color_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('marriageStatus')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.marriage_info.name
                    }}
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions :column="otherInfoColumn" :title="$t('OtherInformation')" border
                             direction="horizontal" style="margin-top: 20px;">

                <el-descriptions-item :label="$t('smokingStatus')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.smoking_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('drinkingStatus')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.drinking_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('Education')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.edu_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('religion')" :span="isMobile ? otherInfoSpan : 0">{{
                        userinfo.userDetail.religion_info.name
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('lookingfor')" :span="otherInfoSpan">{{
                        userinfo.userDetail.lookingfor
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('introduction')" :span="otherInfoSpan">{{
                        userinfo.userDetail.introduction
                    }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('interest')">
                    <template v-for="(row, index) in userinfo.userDetail.interest_info">
                        <el-tag size="small" style="margin-left: 10px;margin-top: 5px">{{ row }}</el-tag>
                    </template>
                </el-descriptions-item>
            </el-descriptions>
        </div>

        <el-dialog :close-on-click-modal="false" :title="$t('PersonalInformation')" :visible.sync="profileVisible"
                   top="10px" width="60%">
            <profile :userinfo="userinfo" @updateUserInfoSuccess="updateUserInfoSuccess"></profile>
        </el-dialog>

        <van-popup v-model="profileMobile" closeable get-container="body" position="bottom" round>
            <div class="profile-mobile-wrapper">
                <div class="title">Edit profile</div>
                <profileMobile :userinfo="userinfo" @updateUserInfoSuccess="updateUserInfoSuccess"></profileMobile>
            </div>
        </van-popup>

    </el-row>
</template>

<script>
import {mapState} from "vuex";
import carList from "../member/cardList.vue"
import profile from "./profile.vue"
import profileMobile from "./profileMobile.vue"
import {Popup, Icon} from 'vant';

export default {
    name: 'detail',
    components: {
        carList,
        profile,
        profileMobile,
        VanPopup: Popup,
        VanIcon: Icon
    },
    props: {
        user_id: 0
    },
    data: function () {
        return {
            profileVisible: false,
            profileMobile: false,
            userinfo: {},
            options: [],
            basicInfoColumn: 2,
            otherInfoColumn: 2,
            otherInfoSpan: 2,
        }
    },
    computed: {
        ...mapState({
            isMobile: 'isMobile'
        })
    },
    created() {
        this.getUserInfo()
    },
    mounted() {
        window.onresize = function (e) {
            console.log(e)
        }
    },
    methods: {
        handleShowEdit() {
            if (this.isMobile) {
                this.profileMobile = true
            } else {
                this.profileVisible = true
            }
        },
        getUserInfo() {
            let that = this;
            that.$http.post('/user/detail', {user_id: that.user_id})
                .then((res) => {
                    if (res.code == 1) {
                        that.userinfo = res.data.userinfo
                        let query = this.$route.query;
                        if (this.isMobile) {
                            this.profileMobile = query.profile == 1 && that.userinfo.userDetail.perfection == 0
                        } else {
                            this.profileVisible = query.profile == 1 && that.userinfo.userDetail.perfection == 0
                        }
                    } else {
                        that.$message.error(res.msg);
                    }

                })

        },
        getOption() {
            this.$http.get('/config/getOption')
                .then((res) => {
                    if (res.code == 1) {
                        console.log(res.data)
                        this.options = res.data
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        updateUserInfoSuccess() {

            this.getUserInfo()
            this.profileVisible = false
            this.$emit("updateUserInfoSuccess")
        }
    },

}
</script>

<style>
.userinfo {
    width: 100%;
    height: 90px;
}

.userinfo .el-avatar {
    float: left;
}

.userinfo .username {
    display: block;
    color: #606266;
    font-size: 26px;
    margin-left: 90px;
}

.userinfo .username a {
    color: #606266;
    font-size: 26px;
}

.userinfo .group {
    display: block;
    color: #606266;
    font-size: 14px;
    margin-left: 90px;
    line-height: 25px;
    justify-content: center;
    height: 25px;
    margin-top: 5px;
}

.userinfo .vip {
    display: block;
    color: #606266;
    font-size: 12px;
    margin-left: 90px;
    line-height: 20px;
    margin-top: 5px;
}

.vip .el-link {
    font-size: 12px !important;
}

.heder-right {
    text-align: center;
    color: #606266;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heder-right-text {
    font-size: 14px;
}

.header-border {
    border-right: 1px solid #e7e7e7;
}

.heder-right-item {
    padding: 5px;

}

@media only screen and (max-width: 767px) {
    .el-descriptions-item__label {
        font-weight: bold !important;
        width: 45%;
    }
}
</style>

<style lang="scss" scoped>
.profile-mobile-wrapper {
    // padding-top: 50px;

    .title {
        line-height: 50px;
        font-size: 20px;
        text-align: center;
    }
}
</style>