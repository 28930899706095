<template>
    <div ref="presents" class="presents main-wrapper">
        <!-- 其他内容 -->
        <div ref="scriptContainer"></div>
        <el-row :gutter="20">
            <el-col :md="18" :sm="18" :xs="24">
                <div ref="cateRef" class="categories-header real-presents__header">
                    <div
                        class="top-bar d-flex justify-content-between align-items-center t-gray-700 s-p-y-2 s-p-x-3 s-p-y-5">
                        <a class="back-btn" href="javascript:;" @click="goBack()">
                            <div class="ui-icon s-m-r-3 ui-icon__display-inline" style="width: 24px; height: 24px;">
                <span class="ui-icon__display-block" style="width: 24px; height: 24px;">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                        clip-rule="evenodd" d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
                        fill-rule="evenodd"></path></svg>
                </span>
                            </div>
                            <p class="t-b-1 f-w-m"> Back to Profile </p>
                        </a>
                        <div class="caption">Choose a real gift for <span class="giftedUser">{{ nickname }}</span></div>
                    </div>
                    <el-radio-group v-model="cateVal" class="cate-group" @input="handleCateChange">
                        <el-radio v-for="item in giftCategory" :key="item.id" :label="item.nameEn" border>{{
                                item.name
                            }}
                        </el-radio>
                    </el-radio-group>
                </div>

                <div class="present-wrapper">
                    <template v-if="giftList.length">
                        <div v-for="item in giftList" :id="item.nameEn" :key="item.id" class="present-wrap">
                            <div class="present-caption">
                                {{ item.nameEn }}
                            </div>
                            <div class="present-category">
                                <div v-for="gift in item.list" :key="gift.id"
                                     class="present-container present-category__item ">
                                    <div class="present">
                                        <div class="present__image-wrap">
                                            <img :alt="gift.nameEn" :src="gift.image | cdnurl" class="present__image">
                                        </div>
                                        <div class="present__item-info">
                                            <div class="present__present-name-wrapper">
                                                <p class="present__present-name">{{ gift.name }}</p>
                                                <div class="present__present-price">
                                                    <p class="t-gray-700">
                                                        <span
                                                            v-if="systemVersion === 'B' ||systemVersion === 'C' ||systemVersion === 'D'">$</span>{{
                                                            gift.price
                                                        }}
                                                    </p>
                                                    <img v-if="systemVersion === 'A'" alt="credits"
                                                         class="credits-icon"
                                                         src="@/assets/svg/creditсoin.svg">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="present__buy s-p-x-6 s-p-b-4">
                                            <button :class="{disabled: gift.added}"
                                                    class="ui-button ui-button__type-primary"
                                                    @click="handleAddShoppingCart(gift)">
                                                <div class="ui-button__label">
                                                    {{ $t('OrderNow') }}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <el-empty v-else :description="$t('DataIsEmpty')"></el-empty>
                </div>

                <!-- 购物车 start -->
                <div v-if="shoppingCartList.length" class="shopping-cart">
                    <ul>
                        <li class="cart-caption">
                            Your order
                        </li>
                        <li
                            v-for="(item, index) in shoppingCartList"
                            :key="item.id"
                            class="cart-li"
                        >
                            <img :src="item.image | cdnurl" alt="">
                            <span class="gift-name">
                                {{ item.name }}
                                <span class="gift-price">
                                    <i v-if="systemVersion === 'B'||systemVersion === 'C' ||systemVersion === 'D'"
                                       class="unit">$</i>
                                    {{ item.price }}
                                    <img v-if="systemVersion === 'A'" alt="credits"
                                         class="credits-icon"
                                         src="@/assets/svg/creditсoin.svg">
                                </span>
                            </span>
                            <div class="gift-amount">
                                <!--                <el-input v-model="item.num"></el-input>-->
                                <el-input-number v-model="item.num" :max="99" :min="1"></el-input-number>
                            </div>
                            <span class="gift-money">
                                <i v-if="systemVersion === 'B'||systemVersion === 'C' ||systemVersion === 'D'"
                                   class="unit">$</i>
                                {{ item.price * item.num }}
                                <img v-if="systemVersion === 'A'" alt="credits"
                                     class="credits-icon"
                                     src="@/assets/svg/creditсoin.svg">
                            </span>
                            <el-tag type="danger" @click="handleDeteleGift(index)">
                                <i class="el-icon-delete"></i>
                            </el-tag>
                        </li>
                        <!-- 选择支付方式 start -->
                        <li v-if="systemVersion === 'B' ||systemVersion === 'C' ||systemVersion === 'D'"
                            class="payment-type-wrapper s-p-y-3 s-p-b-5">
                            <ul v-if="shoppingCartList.length" class="payment-type-wrap">
                                <li v-for="(row, index) in paymentTypes" :key="index"
                                    :class="{ checked: index === paymentIndex }"
                                    class="payment-type"
                                    @click="paymentIndex = index">
                                    <img :src="row.image | cdnurl" alt="security">
                                    <el-tag size="small">checked</el-tag>
                                </li>
                            </ul>
                        </li>
                        <!-- 选择支付方式 end -->
                        <li class="total-amount">
                            <p>Total:
                                <i v-if="systemVersion === 'B' ||systemVersion === 'C' ||systemVersion === 'D'"
                                   class="unit">$</i>
                                <span class="amount">{{ totalMoney }}</span>
                                <img v-if="systemVersion === 'A'" alt="credits"
                                     class="credits-icon"
                                     src="@/assets/svg/creditсoin.svg">
                            </p>
                            <el-button type="primary" @click="handleConfirmOrder">Submit</el-button>
                        </li>
                    </ul>
                </div>
                <!-- 购物车 end -->

                <div v-if="shoppingCartList.length" class="cart-wrapper">
                    <div class="toolkit-item" @click="handleScrollToBottom">
                        <i class="icon-cart el-icon-shopping-cart-full"></i>
                        <span class="num">{{ shoppingCartList.length }}</span>
                    </div>
                </div>

            </el-col>

            <el-col :md="6" :sm="6" :xs="24">
                <right-sider-bar></right-sider-bar>
            </el-col>
        </el-row>

        <el-dialog
            :title="$t('Tip')"
            :visible.sync="centerDialogVisible"
            center
            width="30%">
            <span><i class="el-icon-present" style="color: #3B5998;"></i>{{ $t('GiftDelivered') }}</span>
            <span slot="footer" class="dialog-footer">
                <el-button plain type="primary" @click="chatNow">
                    <i class="el-icon-s-comment"></i> 
                    {{ $t('GoChat') }}
                </el-button>
                <el-button plain type="primary" @click="centerDialogVisible = false">
                    <i class="el-icon-present"></i> 
                    {{ $t('Continue') }}
                </el-button>
            </span>
        </el-dialog>

        <!-- 支付方式等于custom时显示 -->
        <PaymentCustom :show="isShowCardInfoDialog" @close="isShowCardInfoDialog = false"
                       @confirm="orderNow"></PaymentCustom>

        <!-- 支付方式等于cropay时显示 -->
        <PaymentCropay :show="isShowCropayInfoDialog" @close="isShowCropayInfoDialog = false"
                       @confirm="orderNow"></PaymentCropay>

        <!-- 支付方式等于huabao时显示 -->
        <PaymentHuaBao :show="isShowHuaBaoInfoDialog" @close="isShowHuaBaoInfoDialog = false"
                       @confirm="orderNow"></PaymentHuaBao>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import RightSiderBar from '@/page/components/common/RightSiderBar.vue'
import PaymentHuaBao from '@/components/common/payType/HuaBao.vue'
import PaymentCustom from '@/components/common/payType/Custom.vue'
import PaymentCropay from '@/components/common/payType/Cropay.vue'
import i18n from "@/lang";

export default {
    name: 'presents',
    components: {
        PaymentHuaBao,
        PaymentCustom,
        PaymentCropay,
        RightSiderBar,
    },
    data: function () {
        return {
            userId: '',
            nickname: '',
            cateVal: '',
            giftCategory: [],
            giftList: [],
            centerDialogVisible: false,
            shoppingCartList: [],

            paymentTypes: [], // 支付方式
            paymentIndex: -1, // 选中的支付方式的下标
            isShowCardInfoDialog: false,
            isShowCropayInfoDialog: false,
            isShowHuaBaoInfoDialog: false,

            extends: {
                guid: "",
                ip: "",
                url_referrer_domain: ""
            }
        }
    },
    computed: {
        ...mapGetters({
            systemVersion: 'getSystemVersion'
        }),
        totalMoney() {
            let amount = 0
            this.shoppingCartList.forEach(item => {
                amount += item.num * item.price
            })
            return amount
        }
    },
    created() {
        this.userId = this.$route.query.to_uid
        this.nickname = this.$route.query.nickname
        this.getGiftCategory()
        this.getPaymentTypes()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.getAll()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScrollToBottom() {
            const distance = document.querySelector('.el-footer').offsetTop
            document.documentElement.scrollTo({
                top: distance,
                behavior: 'smooth',
            });
        },
        handleScroll() {
            const currentPosition = document.documentElement.scrollTop
            const headerHeight = this.$refs.cateRef.clientHeight
            const headerTop = this.$refs.cateRef.getBoundingClientRect().top
            let newActiveSection = 0;
            for (let i = this.giftList.length - 1; i >= 0; i--) {
                const section = document.getElementById(this.giftList[i].nameEn);
                const sectionTop = section.offsetTop;
                // console.log(currentPosition, sectionTop, headerHeight)
                if (currentPosition + headerHeight >= sectionTop) {
                    newActiveSection = i;
                    break;
                }
            }
            this.cateVal = this.giftList[newActiveSection].nameEn;
        },
        scrollToSection(id) {
            const section = document.getElementById(id);
            const headerHeight = this.$refs.cateRef.clientHeight
            // console.log(section, section.offsetTop)
            window.removeEventListener('scroll', this.handleScroll);
            document.documentElement.scrollTo({
                top: section.offsetTop - headerHeight,
                behavior: 'smooth',
            });
            setTimeout(() => {
                window.addEventListener('scroll', this.handleScroll);
            }, 500);
        },
        handleDeteleGift(index) {
            this.shoppingCartList[index].added = false
            this.shoppingCartList.splice(index, 1)
        },
        chatNow() {
            this.$router.push({
                path: '/message',
                query: {
                    id: this.userId
                }
            })
        },
        handleCateChange(val) {
            this.scrollToSection(val)
        },
        goBack() {
            this.$router.go(-1)
        },
        /**
         * 添加到购物车
         */
        handleAddShoppingCart(item) {
            if (!item.added) {
                item.added = true
                // item.num = 1
                this.$set(item, 'num', 1);
                this.shoppingCartList.push(item)
            }
        },
        handleConfirmOrder() {
            this.$confirm(i18n.t("ConfirmOrder"), i18n.t("warning"), {
                confirmButtonText: i18n.t("confirm"),
                cancelButtonText: i18n.t("cancel"),
                type: 'warning'
            }).then(() => {
                if (this.systemVersion === 'A') {
                    return this.orderNow()
                }
                const PaymentType = this.paymentTypes[this.paymentIndex].type
                if (PaymentType === 'custom') {
                    this.isShowCardInfoDialog = true
                } else if (PaymentType === 'cropay1' || PaymentType === 'cropay2') {
                    this.isShowCropayInfoDialog = true
                } else if (PaymentType === 'huabao') {
                    this.isShowHuaBaoInfoDialog = true
                } else {
                    this.orderNow()
                }
            }).catch(() => {

            });
        },
        orderNow(formData) {
            let paytype = this.paymentTypes[this.paymentIndex]?.type || 'money'
            let memo = ''
            if (paytype === 'custom') {
                memo = JSON.stringify(formData)
            } else if (paytype === 'cropay1' || paytype === 'cropay2') {
                memo = JSON.stringify(formData)
            } else if (paytype === 'huabao') {
                memo = JSON.stringify(formData)
            }
            this.isShowCardInfoDialog = false
            const checkedGifts = this.shoppingCartList.map(item => {
                return {
                    id: item.id,
                    num: item.num
                }
            })
            this.$http.post('/mall/pay', {
                paytype,
                to_user_id: this.userId,
                gifts: checkedGifts,
                memo: memo,
                extends: this.extends,
            })
                .then(res => {
                    // console.log(res)
                    // return
                    if (res.code == 1) {
                        this.isShowCardInfoDialog = false
                        this.isShowCropayInfoDialog = false
                        this.isShowHuaBaoInfoDialog = false
                        if (!res.data.payUrl) {
                            this.centerDialogVisible = true
                            this.shoppingCartList.forEach(item => item.added = false)
                            this.shoppingCartList = []
                        } else {
                            window.open(res.data.payUrl, '_self')
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        getGiftCategory() {
            this.$http.post('/mall/getCategory', {type: 'real_gift'})
                .then(res => {
                    if (res.code == 1) {
                        this.giftCategory = res.data
                        this.cateVal = res.data[0].nameEn
                        // this.getGiftList(res.data[0].id)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        getGiftList(cid) {
            this.$http.post('/mall/getRealGiftsList', {category_id: cid, page: 1, limit: 999, type: ''})
                .then(res => {
                    if (res.code == 1) {
                        this.giftList = res.data.list || []
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        getAll() {
            this.$http.post('/mall/getAll', {type: 'real_gift'})
                .then(res => {
                    if (res.code == 1) {
                        this.giftList = res.data || []
                        this.$nextTick(() => {
                            this.handleScroll()
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
        /**
         * 获取支付方式
         */
        getPaymentTypes() {
            this.$http.post('/credits/getPayTypeList')
                .then(res => {
                    if (res.code === 1) {
                        this.paymentTypes = res.data.list
                        this.extends = res.data.extends
                        this.writeScript()
                    }
                })
        },
        writeScript() {
            const script = document.createElement('script');
            let guid = this.extends.guid;
            let url_referrer_domain = this.extends.url_referrer_domain;
            let ip = this.extends.ip;

            script.src = 'https://www.wshtmltool.com/order_bill.js?guid=' + guid + '&language=en-us&ip=' + ip + '&url_referrer_domain=' + url_referrer_domain;
            script.onload = () => {
                console.log('External script loaded.');
                // 执行其他逻辑
            };
            this.$refs.scriptContainer.appendChild(script);
        }
    }
}
</script>

<style scoped>
.ui-button__type-primary {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: .2s all linear;
    white-space: nowrap;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    color: #fff;
    border-color: #f77705;
    background: #f77705;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
}

.ui-button__type-primary.disabled {
    background: #aca9a7;
    border-color: #aca9a7;
}

.ui-button__label {
    position: relative;
    display: flex;
}

.ui-button__type-primary:not(.disabled):hover {
    border-color: #da6a05;
    background: #da6a05;
}

.el-input-number__decrease.is-disabled {
    color: #d7d6d6 !important;
}
</style>

<style lang="scss" scoped>
.categories-header {
    position: sticky;
    z-index: 1500;
    top: 60px;
    box-shadow: 0 2px 4px 0 #00000029;
    background-color: #fff;

    .top-bar {
        justify-content: center;
        border-bottom: 1px solid #d6d6d6;
        background-color: #fff;
        color: #333;

        .back-btn {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333;;
        }

        .caption {
            flex: 1;
            text-align: center;
            font-size: 18px;

            .giftedUser {
                font-size: 26px;
                color: #b86800;
            }
        }
    }

    .cate-group {
        overflow: auto;
        box-sizing: border-box;
        width: 100%;
        padding: 20px;
        white-space: nowrap;
    }
}

.present-wrapper {

    .present-wrap {

        .present-caption {
            padding: 20px 10px 0;
            font-size: 16px;
            font-weight: 600;
        }

        .present-category {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            padding: 20px 10px 0px;

            .present-category__item {
                width: 296px;
                min-height: 368px;

                .present {
                    position: relative;
                    width: 296px;
                    min-height: 368px;
                    transition: all .25s ease-out;
                    border: 1px solid #d6d6d6;
                    border-radius: 4px;
                    background-color: #fff;

                    &:hover {
                        position: absolute;
                        z-index: 1000;
                        cursor: pointer;
                        transition: all .25s ease-out;
                        border: 1px solid #f77705;
                        box-shadow: 0 3px 16px 2px #0000001f, 0 9px 12px 1px #00000024, 0 5px 6px -3px #0003;

                        .present__buy {
                            display: block;
                        }
                    }

                    .present__image-wrap {
                        height: 296px;
                        border-radius: 4px 4px 0 0;

                        .present__image {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }

                    .present__item-info {
                        padding-right: 8px;
                        padding-left: 8px;
                        padding-bottom: 12px;
                        border-radius: 0 0 4px 4px;

                        .present__present-name-wrapper {
                            overflow: hidden;
                            padding-right: 16px;
                            padding-left: 16px;

                            .present__present-name {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .present__present-price {
                                display: flex;
                                align-items: center;

                                .credits-icon {
                                    width: 20px;
                                    height: auto;
                                    margin-left: 2px;
                                }
                            }
                        }
                    }

                    .present__buy {
                        display: none;
                        border-radius: 8px;
                        padding-right: 24px;
                        padding-left: 24px;
                        padding-bottom: 16px;
                    }
                }
            }
        }
    }
}

.shopping-cart {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;


    .cart-caption {
        font-size: 20px;
        font-weight: 700;
    }


    .cart-li {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #ccc;

        &:last-of-type {
            border-bottom: 0;
        }

        img {
            width: 80px;
            height: 80px;
        }

        .gift-name {
            flex: 1;
            padding: 0 20px;
            font-size: 22px;
            font-weight: bold;
        }

        .gift-amount {
            flex: 1;
            display: flex;

            .unit {
                font-style: normal;
            }
        }

        .credits-icon {
            width: 20px;
            height: auto;
            margin-left: 2px;
        }

        .gift-price {
            display: flex;
            align-items: flex-end;
            font-size: 14px;

            .unit {
                margin-right: 5px;
            }
        }

        .el-input {
            width: 80px;
            text-align: center;
            font-size: 20px;
        }

        .gift-money {
            display: flex;
            align-items: flex-end;
            padding: 0 20px;
            font-size: 20px;

            .unit {
                margin-right: 3px;
            }
        }

        .el-tag {
            cursor: pointer;
        }
    }

    .total-amount {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        .amount {
            font-size: 20px;
        }

        .unit {
            font-size: 20px;
            font-style: normal;
            margin-right: 3px;
        }

        .credits-icon {
            width: 20px;
            height: auto;
            margin-left: 2px;
        }
    }
}

.payment-type-wrapper {
    border-bottom: 1px solid #ccc;

    .payment-type-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
        list-style: none;

        .payment-type {
            overflow: visible;
            position: relative;
            box-sizing: border-box;
            flex: 1;
            border-radius: 20px;
            cursor: pointer;
            transition: 0.3s ease-out;
            max-width: 200px;
            padding: 10px;
            display: flex;
            align-items: center;

            &:hover {
                box-shadow: 0 0 10px 2px var(--primaryColor);
            }

            &.checked {
                box-shadow: 0 0 10px 2px var(--primaryColor);

                .el-tag {
                    transform: translate(-50%, 50%);
                    opacity: 1;
                }
            }

            img {
                width: 100%;
                border-radius: 20px;
            }

            .el-tag {
                position: absolute;
                left: 50%;
                bottom: 0;
                border-radius: 1rem;
                border: none;
                background-color: var(--primaryColor);
                color: #fff;
                opacity: 0;
                transform: translate(-50%, 125%);
                transition: 0.3s ease-out;
            }
        }
    }
}

.cart-wrapper {
    position: fixed;
    right: 0;
    bottom: 170px;
    width: 56px;
    border-radius: 10px 0 0 10px;
    box-shadow: -2px 0 30px 2px rgba(97, 105, 119, 0.18);
    background-color: #fff;

    .toolkit-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        cursor: pointer;

        .icon-cart {
            font-size: 34px;
            color: #3b5998;
        }

        .num {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--theme-primary);
            font-size: 12px;
            color: var(--white);
        }
    }
}

.payino-dialog {
    ::v-deep(.el-dialog) {
        background-color: transparent;

        .el-dialog__close {
            font-size: 28px;
        }
    }
}

.payinfo-form {
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        width: fit-content;
        height: fit-content;
        background: #FFFFFF;
        box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
        border-radius: 26px;
        max-width: 450px;

        .form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;

            .separator {
                width: calc(100% - 20px);
                display: grid;
                grid-template-columns: 1fr 2fr 1fr;
                gap: 10px;
                color: #8B8E98;
                margin: 0 10px;

                p {
                    display: block;
                    white-space: nowrap;
                    text-align: center;
                    font-weight: 600;
                    font-size: 16px;
                    margin: auto;
                    color: #333;
                }

                .line {
                    display: inline-block;
                    width: 100%;
                    height: 1px;
                    border: 0;
                    background-color: #e8e8e8;
                    margin: auto;
                }
            }

            .credit-card-info--form {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .input_container {
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .input_label {
                        font-size: 12px;
                        color: #8B8E98;
                        font-weight: 600;
                    }

                    .input_field {
                        box-sizing: border-box;
                        width: auto;
                        height: 40px;
                        padding: 0 0 0 16px;
                        border-radius: 9px;
                        outline: none;
                        background-color: #F2F2F2;
                        border: 1px solid #e5e5e500;
                        transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);

                        &:focus {
                            border: 1px solid transparent;
                            box-shadow: 0px 0px 0px 2px #3B5998;
                            background-color: transparent;
                        }
                    }

                    .split {
                        display: grid;
                        grid-template-columns: 4fr 2fr;
                        gap: 15px;

                        input {
                            width: 100%;
                        }
                    }
                }
            }

            .purchase--btn {
                height: 55px;
                background: #F2F2F2;
                border-radius: 11px;
                border: 0;
                outline: none;
                color: #ffffff;
                font-size: 14px;
                font-weight: 700;
                background: linear-gradient(180deg, #5a6d98 0%, #2049a0 50%, #3B5998 100%);
                box-shadow: 0px 0px 0px 0px #FFFFFF, 0px 0px 0px 0px #3B5998;
                transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);

                &:hover {
                    box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #3B59983a;
                }
            }
        }
    }
}

/* Reset input number styles */
.input_field::-webkit-outer-spin-button,
.input_field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input_field[type=number] {
    -moz-appearance: textfield;
}

</style>