<template>
    <div class="">
        <div class="main-wrapper">
            <el-row :gutter="15">
                <el-col :xs="24" :sm="18" :md="18" class="m-no-padding">

                    <el-col :xs="24" :sm="24" :md="24" class="hidden-xs-only">
                        <el-card class="box-card">
                            <userinfo></userinfo>
                        </el-card>
                    </el-col>
                    <el-col style="margin-top: 15px;" :xs="24" :sm="6" :md="6" class="hidden-xs-only">
                        <el-card class="siderbar-card">
                            <siderbar></siderbar>
                        </el-card>
                    </el-col>
                    <el-col class="right-content m-no-padding m-no-margin" style="margin-top: 15px;" :xs="24" :sm="18" :md="18">
                        <el-card class="box-card">
                            <h1 class="header-title" style="margin-bottom: 20px;"><i class="icon-al-xiugaimima"
                                    style="font-size: 24px;"></i>
                                {{ $t('ChangePassword') }}

                            </h1>
                            <el-form ref="changepwdFrom" :model="changepwdFrom" :rules="rules" label-width="150px">
                                <el-form-item :label="$t('OldPassword')" prop="old_password">
                                    <el-input v-model="changepwdFrom.old_password" show-password
                                        :placeholder="$t('oldPasswordRequire')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('NewPassword')" prop="new_password">
                                    <el-input v-model="changepwdFrom.new_password" show-password
                                        :placeholder="$t('newPasswordRequire')"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('repassword')" prop="re_password">
                                    <el-input v-model="changepwdFrom.re_password" show-password
                                        :placeholder="$t('reNewPasswordRequire')"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="onSubmit">{{$t('submit')}}</el-button>
                                </el-form-item>
                            </el-form>
                        </el-card>
                    </el-col>
                </el-col>
                <el-col :xs="24" :sm="6" :md="6">
                    <right-sider-bar></right-sider-bar>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
         
<script>
import userinfo from '../components/user/userinfo.vue'
import RightSiderBar from '../components/common/RightSiderBar.vue'

import siderbar from '../components/user/siderbar.vue'
export default {
    name: 'changepwd',
    components: { userinfo, siderbar, RightSiderBar },
    data: function () {
        var checkPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('repasswordRequire')));
            } else if (value !== this.changepwdFrom.new_password) {
                callback(new Error(this.$t('repasswordRule')));
            } else {
                callback();
            }
        };
        return {
            changepwdFrom: {
                old_password: "",
                new_password: "",
                re_password: ""
            },
            rules: {

                old_password: [
                    { required: true, message: this.$t('oldPasswordRequire'), trigger: 'blur' },
                    { min: 6, max: 30, message: this.$t('passwordLoginRule'), trigger: 'blur' }
                ],
                new_password: [
                    { required: true, message: this.$t('newPasswordRequire'), trigger: 'blur' },
                    { min: 6, max: 30, message: this.$t('passwordLoginRule'), trigger: 'blur' }
                ],
                re_password: [
                    { required: true, message: this.$t('reNewPasswordRequire'), trigger: 'blur' },
                    { validator: checkPassword, trigger: 'blur' },
                ],


            },
        }
    },
    methods: {
        onSubmit(){
            this.$refs['changepwdFrom'].validate((valid) => {
                if (valid) {
                    this.$http.post('/user/changepwd', this.changepwdFrom)
                        .then((res) => {
                            if (res.code == 1) {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'success'
                                });
                                this.$refs['changepwdFrom'].resetFields();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'error'
                                });
                            }

                        })
                } else {
                    return false;
                }
            });
        }
    },
    created() {

    },

}
</script>
         
<style>
.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
    text-align: left !important;
}

.user-tabs {
    height: calc(100vh - 280px);
    ;
}

.user-tabs .el-tabs__item {
    height: 50px;
    line-height: 50px;
}

.siderbar-card .el-card__body {
    padding: 0;
}</style>