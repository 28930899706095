import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

import store from './store/index.js'

import http from './services/http.js'

Vue.prototype.$http = http

import ElementUI from 'element-ui';

Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/display.css';

import i18n from './lang/index.js'

import LemonIMUI from 'lemon-imui/dist/index.umd';

Vue.use(LemonIMUI);

//import 'element-ui/lib/theme-chalk/index.css';
import '../static/css/theme/index.css' // 引入新版主题

//import '../static/css/bootstrap.min.css' 
//import '../static/css/adminlte.min.css' 
//import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
import '../static/icon/iconfont.css'; // 引入阿里图标度
// import audio from 'vue-mobile-audio';
// Vue.use(audio)
import 'lemon-imui/dist/index.css';

import loading from './page/components/loading/index.js'

Vue.use(loading)

import websocket from './services/websocket.js'

Vue.prototype.websocket = websocket

import Auth from './services/auth.js';

import videojs from "video.js";
import "video.js/dist/video-js.css";

Vue.prototype.$video = videojs;

import VueCropper from 'vue-cropper'

Vue.use(VueCropper)

//import glo_loading from './services/loading.js' //loading组件的简单封装
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(VueAwesomeSwiper)
import '../node_modules/swiper/dist/css/swiper.css'

import {Locale, Lazyload} from 'vant';

Vue.use(Lazyload);
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);

// 注册聊天消息的图片模板，必须要放在main.js中提前注册
import LemonMessageImage from "./page/components/chat/lemon-message-image.vue";
Vue.component(LemonMessageImage.name, LemonMessageImage);

import LemonMessageText from "./page/components/chat/lemon-message-text.vue";
Vue.component(LemonMessageText.name, LemonMessageText);

import LemonMessageTransfer from "./page/components/chat/lemon-message-transfer.vue";
Vue.component(LemonMessageTransfer.name, LemonMessageTransfer);


Vue.config.productionTip = false

Vue.prototype.$scrollTo = (x = 0, y = 0, type = 'smooth') => {
    window.scrollTo({
        top: x,
        left: y,
        behavior: type // 滚动行为：smooth平滑滚动，instant瞬间滚动，默认值auto，等同于instant
    })
}

router.beforeEach((to, from, next) => {
    // console.log('to', to)
    Vue.$showLoading.show()
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = i18n.t(to.meta.title)
    }
    window.scrollTo(0, 0); // 滚动到页面的顶部
    if (!['/article', '/', '/login', '/message'].includes(to.path) && to.meta.keepAlive && !Auth.authenticated()) {
        next({
            path: '/login',
            query: to.query
            // query: {
            // 	redirect: to.fullPath
            // }
        })
    } else {
        next()
    }
    Vue.$showLoading.hide()
})

// Import Helpers for filters
import * as filters from './filters/index.js'
import {mapGetters, mapMutations, mapState} from "vuex";

Vue.prototype.cdnurl = filters.cdnurl
Vue.prototype.isUrl = filters.isUrl
Vue.prototype.formatDate = filters.formatDate
Vue.prototype.validUploadFile = filters.validUploadFile
// Import Install and register helper items
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

new Vue({
    el: '#app',
    router: router,
    store,
    pinia,
    i18n,
    data() {
        return {
            shouldRender: false,
        }
    },
    render: function (h, params) {
        if (this.shouldRender) {
            return h(App)
        }
    },
    created() {
        let path = this.$route.path
        let query = this.$route.query;
        let token = query.token || ''
        if (token && path !== "/login" && !Auth.authenticated()) {
            this.reloadUserToken(token)
        }
        this.getSystemVersion()
    },
    computed: {
        ...mapState({
            systemVersion: 'systemVersion',
        }),
    },
    methods: {
        ...mapMutations([
            'setSystemVersion',
            'setCustomerId',
            'setIsLogin',
            'setUserinfo',
        ]),
        reloadUserToken(token) {
            this.$http.post('/token/checkToken', {token: token}).then((res) => {
                if (res.code === 1) {
                    // 设置用户信息
                    Auth.login(res.data.userinfo)
                    this.setIsLogin(true)
                    this.getUserinfo()
                }
            })
        },
        getUserinfo() {
            this.$http.post('/user/userinfo')
                .then((res) => {
                    if (res.code == 1) {
                        this.setUserinfo(res.data)
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            // .catch(() => {})
        },
        getSystemVersion() {
            this.$http.get('/config/getVersion')
                .then((res) => {
                    if (res.code == 1) {
                        this.setSystemVersion(res.data.version)
                        this.setCustomerId(res.data.customerId)
                        this.shouldRender = true
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            // .catch(() => {})
        },

    }
});