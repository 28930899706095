<template>
    <el-form ref="form" :model="form">
        <el-form-item :label="$t('PublishingContent')">
            <el-input rows="4" type="textarea" v-model="form.content" :placeholder="$t('PublishingThink')"></el-input>
        </el-form-item>
        <el-form-item>
            <el-upload ref="upload" :action="uploadUrl" list-type="picture-card" :limit="9" :on-preview="showViewerList"
                :multiple="true" :headers="headers" :on-remove="handleRemove" :on-success="uploadSuccess"
                :on-exceed="uploadExceed" accept="image/*">
                <i class="el-icon-plus"></i>
            </el-upload>
        </el-form-item>
        <el-form-item>
            <el-button type="default" @click="cancel">{{ $t('cancel') }}</el-button>
            <el-button class="float-right" type="primary" @click="submit">{{ $t('send') }}</el-button>
        </el-form-item>

        <ElImageViewer v-if="showViewer" :on-close="closeViewer" :url-list="viewerList" :zIndex="3000"
            :initialIndex="viewerIndex" :appendToBody="true" />
    </el-form>
</template>
<script>
import setting from '../../../config/setting'
import Auth from '../../../services/auth'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
    name: 'Publish',
    components: { ElImageViewer },
    data: function () {
        return {
            form: {
                content: '',
                images: []
            },
            uploadUrl: setting.apiUrl + "/common/upload",
            headers: {
                token: Auth.getToken().token
            },
            showViewer: false,
            viewerList: [],
            viewerIndex: 0,
        }
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file)
            let url = file.response.data.url;
            this.viewerList = [];
            for (let i = 0; i < this.form.images.length; i++) {
                if (this.form.images[i] == url) {
                    this.form.images.splice(i, 1);
                }
            }
        },
        showViewerList(file) {
            let url = file.response.data.url;
            this.viewerList = [];
            for (let i = 0; i < this.form.images.length; i++) {
                this.viewerList.push(this.cdnurl(this.form.images[i]))
                if (this.form.images[i] == url) {
                    this.viewerIndex = i;
                }
            }
            this.showViewer = true;

        },
        closeViewer() {
            this.showViewer = false;
        },
        uploadSuccess(res, file, fileList) {
            let that = this;
            if (res.code == 1) {
                let ImageUrl = res.data.url;
                that.form.images.push(ImageUrl)
            } else {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: 'error'
                });
            }
        },
        uploadExceed(files, fileList) {
            this.$message({
                showClose: true,
                message: this.$t("uploadExceed"),
                type: 'error'
            });
        },
        submit() {
            this.$http.post('/hot/add', this.form)
                .then((res) => {
                    if (res.code == 1) {
                        // 设置用户信息
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'success'
                        });
                        this.form.content = '';
                        this.form.images = [];
                        this.viewerList = [];
                        this.$refs.upload.clearFiles();
                        this.$emit('publishAfter', true)
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'error'
                        });
                    }

                })
        },
        cancel() {
            this.$emit('publishAfter', false)
        }
    },
    created() {

    },
}
</script>
<style>
.photo-list {
    margin-top: 5px;
}

@media only screen and (max-width: 767px) {
    .publish-pop {
        width: 90% !important;
    }
}
</style>
