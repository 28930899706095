import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import zh from './zh'
import en from './en'

Vue.use(VueI18n)
let i18n = new VueI18n({
    locale: 'en',
    messages: {
        'zh-cn': {
            ...zh
        },
        'en': {
            ...en
        }
    }
})
locale.i18n((key, value) => i18n.t(key, value))
export default i18n;