<template>
    <div>
        <div class="main-wrapper">
            <el-result>
                <template slot="icon">
                    <i v-if="orderDetail.status === 'paid'" class="el-icon-success"></i>
                    <i v-else class="el-icon-error"></i>
                </template>
                <template #title>
                    {{ orderDetail.status === 'paid' ? $t('OrderStatusSuccess') : $t('OrderStatusFailed') }}
                </template>
                <template #subTitle>
                    <el-descriptions v-if="orderDetail.status === 'paid'" border :column="1">
                        <el-descriptions-item :label="$t('OrderNo')">
                            {{ orderDetail.orderid }}
                        </el-descriptions-item>
                        <el-descriptions-item :label="$t('OrderPayAmount')">
                            ${{ orderDetail.payamount }}
                        </el-descriptions-item>
                        <el-descriptions-item :label="$t('OrderPayType')">
                            {{ orderDetail.paytype }}
                        </el-descriptions-item>
                        <el-descriptions-item :label="$t('OrderPayTime')">
                            {{ formatDate(orderDetail.paytime, 'yyyy/MM/dd') }}
                        </el-descriptions-item>
                    </el-descriptions>
                </template>
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="$router.replace('/')">{{ $t('BackToHomePage') }}</el-button>
                </template>
            </el-result>
        </div>
    </div>
</template>

<script>
import { formatDate } from '../../filters'
export default {
    name: 'PaymentResult',
    components: { 
    },
    data() {
        return {
            orderDetail: {},
        }
    },
    created() {
        this.getOrderDetail()
    },
    methods: {
        getOrderDetail() {
            this.$http.post('/credits/getOrderDetail', { orderid: this.$route.query.orderid || '1hd88kk6iw0cwzusvcvk14o100uv1qoh' })
                .then((res) => {
                    if (res.code == 1) {
                        this.orderDetail = res.data || {}
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        }
    },
}
</script>
    
<style scoped lang="scss">
.main-wrapper {
    background-color: #fff;
}
.el-result {
    .el-icon-success {
        font-size: 100px;
        color: var(--greenColor);
    }
    .el-icon-error {
        font-size: 100px;
        color: var(--errorColor);
    }
}
</style>