const Emoji: Record<string, string> = {
  '[TUIEmoji_Smile]': '[微笑]',
  '[TUIEmoji_Expect]': '[期待]',
  '[TUIEmoji_Blink]': '[眨眼]',
  '[TUIEmoji_Guffaw]': '[大笑]',
  '[TUIEmoji_KindSmile]': '[姨母笑]',
  '[TUIEmoji_Haha]': '[哈哈哈]',
  '[TUIEmoji_Cheerful]': '[愉快]',
  '[TUIEmoji_Speechless]': '[无语]',
  '[TUIEmoji_Amazed]': '[惊讶]',
  '[TUIEmoji_Sorrow]': '[悲伤]',
  '[TUIEmoji_Complacent]': '[得意]',
  '[TUIEmoji_Silly]': '[傻了]',
  '[TUIEmoji_Lustful]': '[色]',
  '[TUIEmoji_Giggle]': '[憨笑]',
  '[TUIEmoji_Kiss]': '[亲亲]',
  '[TUIEmoji_Wail]': '[大哭]',
  '[TUIEmoji_TearsLaugh]': '[哭笑]',
  '[TUIEmoji_Trapped]': '[困]',
  '[TUIEmoji_Mask]': '[口罩]',
  '[TUIEmoji_Fear]': '[恐惧]',
  '[TUIEmoji_BareTeeth]': '[龇牙]',
  '[TUIEmoji_FlareUp]': '[发怒]',
  '[TUIEmoji_Yawn]': '[打哈欠]',
  '[TUIEmoji_Tact]': '[机智]',
  '[TUIEmoji_Stareyes]': '[星星眼]',
  '[TUIEmoji_ShutUp]': '[闭嘴]',
  '[TUIEmoji_Sigh]': '[叹气]',
  '[TUIEmoji_Hehe]': '[呵呵]',
  '[TUIEmoji_Silent]': '[收声]',
  '[TUIEmoji_Surprised]': '[惊喜]',
  '[TUIEmoji_Askance]': '[白眼]',
  '[TUIEmoji_Ok]': '[OK]',
  '[TUIEmoji_Shit]': '[便便]',
  '[TUIEmoji_Monster]': '[怪兽]',
  '[TUIEmoji_Daemon]': '[恶魔]',
  '[TUIEmoji_Rage]': '[恶魔怒]',
  '[TUIEmoji_Fool]': '[衰]',
  '[TUIEmoji_Pig]': '[猪]',
  '[TUIEmoji_Cow]': '[牛]',
  '[TUIEmoji_Ai]': '[AI]',
  '[TUIEmoji_Skull]': '[骷髅]',
  '[TUIEmoji_Bombs]': '[炸弹]',
  '[TUIEmoji_Coffee]': '[咖啡]',
  '[TUIEmoji_Cake]': '[蛋糕]',
  '[TUIEmoji_Beer]': '[啤酒]',
  '[TUIEmoji_Flower]': '[花]',
  '[TUIEmoji_Watermelon]': '[瓜]',
  '[TUIEmoji_Rich]': '[壕]',
  '[TUIEmoji_Heart]': '[爱心]',
  '[TUIEmoji_Moon]': '[月亮]',
  '[TUIEmoji_Sun]': '[太阳]',
  '[TUIEmoji_Star]': '[星星]',
  '[TUIEmoji_RedPacket]': '[红包]',
  '[TUIEmoji_Celebrate]': '[庆祝]',
  '[TUIEmoji_Bless]': '[福]',
  '[TUIEmoji_Fortune]': '[发]',
  '[TUIEmoji_Convinced]': '[服]',
  '[TUIEmoji_Prohibit]': '[禁]',
  '[TUIEmoji_666]': '[666]',
  '[TUIEmoji_857]': '[857]',
  '[TUIEmoji_Knife]': '[刀]',
  '[TUIEmoji_Like]': '[赞]',
};

export default Emoji;
