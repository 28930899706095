<template>
    <li class="list-item__block d-flex flex-column s-p-x-2 s-p-y-3 bg-white">
        <div class="list-item__title d-flex justify-content-between align-items-center">
            <div class="list-item__text t-gray-700 f-w-m t-b-2">
                <span class="d-flex align-items-center">
                    {{title}}
                    <img v-if="icon" :src="icon" alt="icon" class="s-m-l-1">
                </span>
            </div>
            <div class="s-m-r-1"></div>
            <div class="list-item__arrow">
                <div class="ui-icon ui-icon__display-inline" style="width: 24px; height: 24px;">
                    <span class="ui-icon__display-block" style="width: 24px; height: 24px;"><svg viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                        </svg></span>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        title: {
            require: true,
            default: ''
        },
        icon: {
            require: false,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.list-item__block {
    border-radius: 4px;
    background-color: #fff;

    .list-item__title {
        width: 100%;

        .list-item__text {
            width: 100%;
        }

        .list-item__arrow {
            height: 24px;

            .ui-icon__display-inline {
                display: inline-flex;
            }

            .ui-icon {
                align-items: center;
                flex-direction: column;
                justify-content: center;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: #8f8f8f;
                }
            }

            .ui-icon__display-block {
                display: flex;
            }
        }
    }
}
</style>